import React from 'react';
import PropTypes from 'prop-types';
import { MemoizedLinearGauge } from 'src/components/LinearGauge';
import { MemoizedRangeScale } from 'src/components/RangeScale';
import { getConditionRanges, getGagueConditions } from 'src/lib/conditionUtils';
import { convertElevationToStage } from 'src/lib/stageConversionUtils';
import ScenarioSliderLegend from '../gauges/scenario/ScenarioSliderLegend';
import StageIndicator from './StageIndicator';


const linearGaugeStyle = { height: '7px', zIndex: 1000 };
const bottomStyle = { marginTop: '5px' };

const ConditionRange = ({ gauge, value }) => {
  let min = !gauge.isCoastal > 0 ? convertElevationToStage(gauge.bankFull, gauge.gageDatum) : gauge.bankFull;
  min = min > 35 ? 25 : 0;

  const max = !gauge.isCoastal > 0 ? convertElevationToStage(gauge.major, gauge.gageDatum) : gauge.major;

  const conditions = getGagueConditions(gauge);
  const ranges = getConditionRanges(conditions, min, max + 5);

  return (
    <div className="d-flex">
      <ScenarioSliderLegend showElevation={!gauge.isCoastal} />
      <div className="w-100 overflow-hidden position-relative d-flex flex-column py-0 px-2">
        {!gauge.isCoastal ? (
          <MemoizedRangeScale
            className="position-relative"
            textPosition="top"
            min={min}
            max={max + 5}
            smallStep={1}
            largeStep={2}
            size="sm"
          />) : <div className="position-relative" style={{ height: 25 }} />}
        <MemoizedLinearGauge
          className="position-relative"
          style={linearGaugeStyle}
          ranges={ranges}
        />
        <StageIndicator value={value} min={min} max={max + 5} style={{
          height: '8px',
          marginTop: '-10px',
          zIndex: 2001
        }} />
        <MemoizedRangeScale
          className="position-relative"
          style={bottomStyle}
          min={min}
          max={max + 5}
          offset={gauge.isCoastal ? 0 : -gauge.gageDatum}
          smallStep={1}
          largeStep={2}
          textPosition="bottom"
          size="lg"
        />
      </div>
    </div>
  );
}

ConditionRange.propTypes = {
  gauge: PropTypes.object.isRequired,
  value: PropTypes.number,
};

export default ConditionRange;