import React from 'react';
import { DotAssetColors } from 'src/constants/dotAssetColors';

// eslint-disable-next-line react/prop-types
const AssetsIcon = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 24 24">
      <path d="M10.462 10.4L2 4.8V22h20V2zM21 21H3V6.661l7.489 4.956L21 3.965z" fill="none" stroke={color}/>
      <path fill="none" d="M0 0h24v24H0z"/>
    </svg>
  )
}

const AssetsLegend = () => {
  const heights = [
    { color: DotAssetColors.BLUE, label: 'DOT Buildings'},
    { color: DotAssetColors.YELLOW, label: 'DOT Land'},
  ];

  return (
    <>
      {heights.map((height) => {
        return (
          <div className=" d-flex align-items-center d-flex mb-2" key={height.label}>
            <div className="me-2 rounded-circle d-flex align-items-center justify-content-center" style={{width: 25, height: 25}}>
              <AssetsIcon color = {height.color} />
            </div>
            <div>{height.label}</div>
          </div>
        )
      })}
    </>
  );
}
    
export default AssetsLegend;
  