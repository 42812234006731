import React from 'react';
import PropTypes from 'prop-types';
import NameValueText from 'src/components/NameValueText';
import { roundToTenth } from 'src/lib/mathUtils';
import { formatDate } from 'src/lib/dateUtils';


const HistoricalPeakContent = ({ peakEvent }) => {
  return (
    <>
      {peakEvent ? (
        <div className="d-flex flex-column">
          <NameValueText label="Event" value={peakEvent.name} />
          <NameValueText label="Date" value={formatDate(peakEvent.date)} />
          <NameValueText label="Peak Stage" value={`${roundToTenth(peakEvent.stage)} ft`} />
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center h-100">
          <span className="text-muted">No historical peaks</span>
        </div>
      )}
    </>
  );
}

HistoricalPeakContent.propTypes = {
  peakEvent: PropTypes.object,
};


export default HistoricalPeakContent;