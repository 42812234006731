import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AccordionContext, useAccordionButton } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';


const ContextAwareToggle = ({ eventKey, callback, children }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey =
    activeEventKey &&
    (activeEventKey === eventKey || activeEventKey.length && activeEventKey[0] === eventKey);

  return (
    <div
      className="d-flex align-items-center justify-content-between py-2 w-100 cursor-pointer"
      onClick={decoratedOnClick}
    >
      {children}
      <div
        className="text-white"
      >
        <ChevronDown className={isCurrentEventKey ? 'd-none' : ''} />
        <ChevronUp className={isCurrentEventKey ? '' : 'd-none'} />
      </div>
    </div>
  );
}

ContextAwareToggle.propTypes = {
  eventKey: PropTypes.string,
  callback: PropTypes.func,
  children: PropTypes.node,
};

export default ContextAwareToggle;