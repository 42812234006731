import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { confirm } from 'src/components/ConfirmationModal';
import { BellFill, Plus } from 'react-bootstrap-icons';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useAlertStore from 'src/stores/alertStore';
import PrimaryButton from 'src/components/PrimaryButton';
import AlertList from './AlertList';
import AlertEdit from './AlertEdit';


const SubscriptionsConfigureModal = () => {
  const { isMobile } = useCustomMediaQuery();

  const showConfigModal = useAlertStore(state => state.showConfigModal);
  const hideSubscriptionConfiguration = useAlertStore(state => state.hideSubscriptionConfiguration);
  const unsubscribe = useAlertStore(state => state.unsubscribeFromSite);
  const siteId = useAlertStore(state => state.editSiteId);
  const subscriptions = useAlertStore(state => state.siteAlerts);
  const isSiteAlertsLoading = useAlertStore(state => state.isSiteAlertsLoading);
  const isAlertDirty = useAlertStore(state => state.isAlertDirty);
  const setIsAlertDirty = useAlertStore(state => state.setIsAlertDirty);
  const showEditModal = useAlertStore(state => state.showEditModal);
  const toggleShowEditModal = useAlertStore(state => state.toggleShowEditModal);
  const editAlert = useAlertStore(state => state.editAlert);
  const setEditAlert = useAlertStore(state => state.setEditAlert);

  const { data: gauge, isLoading: gaugeIsLoading } = useGaugeDetails(siteId);

  const handleUnsubscribe = async (site) => {
    if (await confirm(
      `You are unsubscribing from alerts for ${site.name}. Continue?`,
      'Unsubscribe',
      'Continue',
      'Cancel',
      async () => {
        await unsubscribe(site.siteId);
        hideSubscriptionConfiguration();
        toggleShowEditModal(false);
        setEditAlert(null);
      }
    )) {
      toast.success(
        `Successfully unsubscribed from alerts for "${site.name}."`
      );
    }
  }

  const handleCancelEdit = async () => {
    if (isAlertDirty) {
      confirm('Are you sure you want to continue? Any unsaved changes will be lost.',
        'Unsaved Changes',
        'Continue',
        'Cancel',
        cancelEdit);
      return;
    }
    cancelEdit();
  };

  const cancelEdit = () => {
    hideSubscriptionConfiguration();
    toggleShowEditModal(false);
    setEditAlert(null);
    setIsAlertDirty(false);
  }

  if (gaugeIsLoading || !gauge) {
    return null;
  }

  const title = () => {
    if (!isMobile) {
      return (
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-center">{`Gauge: ${gauge.name}`}</div>
          <div className="d-flex justify-content-center">{`SITE ID: ${gauge.siteId}`}</div>
        </div>
      );
    } else {
      return `${gauge.name}`;
    }
  }

  return (
    <Modal
      dialogClassName="modal-size-md"
      centered
      show={showConfigModal}
      onHide={() => handleCancelEdit()}
      animation={true}
      backdrop="static"
      scrollable={true}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {title()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="d-flex align-items-center justify-content-between gap-2 dark-blue-background p-2 px-lg-4" >
          <h4 className="m-0">Alert Settings</h4>
          {!showEditModal &&
            <div className="d-flex gap-2">
              <PrimaryButton
                className="rounded-pill"
                size="sm"
                disabled={subscriptions.length === 0}
                onClick={() => handleUnsubscribe(gauge)}
              >
                <div className="d-flex align-items-center">
                  <BellFill className="me-1" size="17" />
                  Unsubscribe
                </div>
              </PrimaryButton>
              <PrimaryButton
                className="rounded-pill"
                size="sm"
                onClick={() => {
                  toggleShowEditModal(true);
                  setEditAlert(null);
                }}
              >
                <div className="d-flex align-items-center">
                  <Plus size="25" />
                  New Alert
                </div>
              </PrimaryButton>
            </div>
          }
        </div>
        <div className="p-3">
          Toggle alerts on and set preferences for when you'd like to receive notifications.
        </div>
        {isSiteAlertsLoading ?
          <div className="d-flex justify-content-center align-items-center p-3" style={{ minHeight: 350 }}>
            <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
            Loading...
          </div>
          :
          showEditModal ?
            <AlertEdit
              gauge={gauge}
              alert={editAlert}
              onCancel={() => {
                toggleShowEditModal(false);
                setEditAlert(null);
              }}
            /> :
            <AlertList gauge={gauge} />
        }

      </Modal.Body>
    </Modal>
  );
}

export default SubscriptionsConfigureModal;