import { Tabs } from 'src/constants/tabs';
import { DrawerLevels } from 'src/constants/drawerLevels';
import create from 'zustand'; 

const useGaugeStore = create((set) => ({
  drawerLevel: DrawerLevels.HALF, 
  activeTab: Tabs.CURRENT, 
  showEditGaugeModal: false,

  toggleDrawerExpand: (height) => set({ drawerLevel: height}),
  setActiveTab: (tab) => set({ activeTab: tab }),
  toggleEditGaugeModal: (show) => set((state) => ({ showEditGaugeModal: show ?? !state.showEditGaugeModal })),
}));

export default useGaugeStore;