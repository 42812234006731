import React from 'react';
import { Modal } from 'react-bootstrap';
import useMapStore from '../../../stores/mapStore';
import WelcomeModalBody from './WelcomeModalBody';
import WelcomeModalBodySm from './WelcomeModalBodySm';
import useCustomMediaQuery from '../../../lib/hooks/useCustomMediaQuery';

const WelcomeModal = () => {
  const showWelcomeModal = useMapStore(state => state.showWelcomeModal);
  const toggleWelcomeModal = useMapStore(state => state.toggleWelcomeModal);
  const toggleSignUpModal = useMapStore(state => state.toggleSignUpModal);
  const toggleSignInModal = useMapStore(state => state.toggleSignInModal);
  const mapManager = useMapStore(state => state.mapManager);
  const {  isDesktop  } = useCustomMediaQuery();

  const handleFindMe = () => {
    mapManager.locateMe();
    toggleWelcomeModal(false);
  };

  const handleExplore = () => {
    toggleWelcomeModal(false);
  };

  const handleSignUp = () => {
    toggleWelcomeModal(false);
    toggleSignUpModal(true); 
    
  };

  const handleSignIn = () => {
    toggleWelcomeModal(false);
    toggleSignInModal(true); 
  };
  return (
    <Modal
      size="lg"
      centered
      show={showWelcomeModal}
      onHide={() => toggleWelcomeModal(false)}
    >
      <Modal.Header>
        <Modal.Title>Welcome to NC FIMAN!</Modal.Title>
      </Modal.Header>
      {isDesktop 
        ? <WelcomeModalBody 
          handleFindMe={handleFindMe} 
          handleExplore={handleExplore} 
          handleSignIn={handleSignIn} 
          handleSignUp={handleSignUp} /> 
        : <WelcomeModalBodySm
          handleFindMe={handleFindMe} 
          handleExplore={handleExplore} 
          handleSignIn={handleSignIn} 
          handleSignUp={handleSignUp} /> }

    </Modal>
  );
};

export default WelcomeModal;

