import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { Form, Button } from 'react-bootstrap';
import SelectFilter from 'src/features/map/filters/SelectFilter';
import PrimaryButton from 'src/components/PrimaryButton';
import useUsers from 'src/lib/hooks/useUsers';
import LoadingDisplay from 'src/components/LoadingDisplay';
import CenteredCell from '../../map/gauges/gridgets/CenteredCell';
import useUserStore from 'src/stores/userStore';
import { formatDateTime } from 'src/lib/dateUtils';
import { toast } from 'react-toastify';
import useAlertStore from 'src/stores/alertStore';
import { confirm } from 'src/components/ConfirmationModal';
import UsersTable from './UsersTable';
const columnHelper = createColumnHelper();

const AdminAdvancedUsers = () => {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const { entries, isLoading } = useUsers();
  const [data, setData] = useState(entries);
  const handleEditUser = useUserStore(state => state.handleEditUser);
  const handleExport = useUserStore(state => state.handleExport);
  const unsubscribeUserAll = useAlertStore(state => state.unsubscribeUserAll);
  const adminDeleteUser = useUserStore(state => state.adminDeleteUser);

  useEffect(() => {
    setData(entries);
  }, [entries])

  const usubscribeUser = async (row) => {
    //const user = table.getFilteredRowModel().rows[row.index].original;
    const user = row.original;

    if (await confirm(
      `You are unsubscribing user "${user.name}" from all gauge alerts. This action cannot be undone. Continue?`,
      ' Unsubscribe User',
      'Continue',
      'Cancel',
      async () => unsubscribeUserAll(user.username)
    )) {
      toast.success(`You have unsubscribed the user "${user.name}" from all alerts.`);
    }
  }

  const handleDeleteUser = async (row) => {
    //const user = table.getFilteredRowModel().rows[row.index].original;
    const user = row.original;
    if (await confirm(
      `Are you sure you want to delete the FIMAN profile for "${user.name}"?`,
      'Delete User',
      'Continue',
      'Cancel',
      async () => adminDeleteUser(user.username, row.index, table.options.meta?.deleteRow)
    )) {
      toast.success(`You have deleted the user "${user.name}".`);
    }
  }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnsMemo = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: info => info.getValue(),
        enableSorting: true,
        enableGlobalFilter: true,
        size: 250,
      }),
      columnHelper.accessor('email', {
        header: () => 'Email',
        cell: info => info.getValue() ? info.getValue() : 'N/A',
        enableSorting: true,
        enableGlobalFilter: true,
      }),
      columnHelper.accessor('role', {
        header: () => 'Role',
        cell: info => info.getValue() ? info.getValue() : 'N/A',
        enableSorting: true,
        enableColumnFilter: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('phoneNumber', {
        header: () => 'Phone Number',
        cell: info => info.getValue(),
        enableSorting: false,
        enableGlobalFilter: true,
      }),
      columnHelper.accessor('lastLogin', {
        header: () => 'Last Login',
        cell: info => {
          if (!info.getValue()) return '';
          const date = new Date(info.getValue());
          return formatDateTime(date);
        },
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('select', {
        header: () => '',
        cell: (info) => (
          <CenteredCell>
            <Button className="p-1" variant="link" onClick={() => handleDeleteUser(info.row)}>Delete</Button> |
            <Button className="p-1" variant="link" onClick={() => handleEditUser(info.row.index, info.row.original)}>Edit</Button> |
            <Button className="p-1" variant="link" onClick={() => usubscribeUser(info.row)}>Unsub</Button>
          </CenteredCell>
        ),
        enableSorting: true,
        enableColumnFilter: false,
        size: 250,
      }),
    ];
  });

  const options = {
    data: data ?? [],
    columns: columnsMemo,
    state: {
      sorting,
      columnFilters,
      globalFilter
    },
    enableFilters: true,
    enableColumnFilters: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    meta: {
      updateRowData: (rowIndex, value) => {
        const dataClone = [...data];
        dataClone[rowIndex] = value;
        setData(dataClone)
      },
      deleteRow: (rowIndex) => {
        const dataClone = [...data];
        dataClone.splice(rowIndex, 1);
        setData(dataClone)
      }
    },
  };

  const table = useReactTable(options);

  const reset = useCallback(() => {
    if (!table) return;

    table.resetColumnFilters(true);
    table.resetGlobalFilter(true);
    setSorting([]);
  }, [table]);

  useEffect(() => {
    reset();
    return () => {
      reset();
    }
  }, [entries, table, reset]);


  if (isLoading) {
    return (<LoadingDisplay />);
  }


  if (!entries || entries.length === 0) {
    return (<div className="d-flex flex-fill align-items-center justify-content-center h-100">
      There are no Admin or Advanced users.
    </div>);
  }
  return (
    <>
      <div className="d-flex justify-content-between p-1">
        <div className="d-flex gap-1">
          <PrimaryButton
            className="rounded-pill btn-sm m-1"
            onClick={() => handleExport(table)}
          >
            Export
          </PrimaryButton>
          <PrimaryButton
            className="rounded-pill btn-sm m-1"
            onClick={() => reset()}
          >
            Reset
          </PrimaryButton>
        </div>
        <div className="d-flex gap-1 w-50">
          <SelectFilter
            className="d-flex align-items-center w-100"
            label="Role"
            column={table.getColumn('role')}
            table={table}
          />
          <Form.Control
            value={globalFilter ?? ''}
            size="sm"
            id="globalFilterID"
            name="somethingCustomThatwonttriggerautofill-1234"
            onChange={e => setGlobalFilter(e.target.value)}
            className="d-flex align-items-center w-100"
            placeholder="Search by name, email, or phone..."
          />
        </div>
      </div>
      <UsersTable table={table} />
    </>
  )
}
export default AdminAdvancedUsers;