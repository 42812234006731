import React from 'react';
import PropTypes from 'prop-types';
import useCustomMediaQuery from '../../../lib/hooks/useCustomMediaQuery';

const ActionButton = ({ icon, text, description, onClick, className }) => {
  const { isMobile, isTablet } = useCustomMediaQuery();

  const iconContainerSize = isMobile || isTablet ? '65px' : '96px';
  const iconSize = isMobile || isTablet ? '30px' : '50px';
  const fontSize = isMobile || isTablet ? '16px' : '18px';

  return (
    <div className="action-button px-md-4 d-flex flex-column align-items-center">
      <button
        className={`grow d-flex align-items-center p-0 text-black border-0 bg-transparent ${className}`}
        onClick={onClick}
      >
        <div
          className="d-flex align-items-center justify-content-center mb-2 rounded-circle bg-primary"
          style={{ width: iconContainerSize, height: iconContainerSize }}
        >
          <img src={icon} alt="icon" style={{ height: iconSize }} />
        </div>
      </button>
      <div
        className="fw-bold text-uppercase mb-1 text-yellow"
        style={{ fontSize: fontSize }}
      >
        {text}
      </div>
      { description && <div 
        className="fw-bold text-center p-1"
        style={{ fontSize: '12px', lineHeight: '17px' }}>
        {description}
      </div> }
    </div>
  );
};

ActionButton.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default ActionButton;