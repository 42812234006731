import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import OverviewContainer from './overview/OverviewContainer';
import LayersContainer from './layers/LayersContainer';
import LegendContainer from './legend/LegendContainer';


const ToolsContent = () => {
  return (
    <>
      <Tabs
        defaultActiveKey="overview"
        justify
        variant="sidebar"
        className="position-sticky top-0"
        style={{ zIndex: 100 }}
      >
        <Tab eventKey="overview" title="Overview">
          <OverviewContainer />
        </Tab>
        <Tab eventKey="layers" title="Layers">
          <LayersContainer />
        </Tab>
        <Tab eventKey="legend" title="Legend">
          <LegendContainer />
        </Tab>
      </Tabs>
    </>
  );
}

export default ToolsContent;
