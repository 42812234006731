import React from 'react';
import BuildInfo from '../components/BuildInfo';


const Footer = () => {
  return (
    <footer className="mt-auto bg-dark text-white">
      <div className="container p-4 d-flex align-items-center">
        <section>
          <BuildInfo />
        </section>
      </div>
    </footer>
  );
};

export default Footer;