import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import useMapStore from 'src/stores/mapStore';
import LayerItem from './LayerItem';
import SingleAccordion from 'src/components/SingleAccordion';
import InfoLink from 'src/components/InfoLink';


const LayerGroup = ({ group }) => {
  const showGroupLayer = useMapStore(state => state.showGroupLayer);
  const activeLayerIds = useMapStore(state => state.activeGroupLayerId);
  const setActiveLayerId = useMapStore(state => state.setActiveLayerId);

  // Default layer id is selected from activeLayerIds or defaultSelectedId from group
  const defaultLayerId = activeLayerIds[group.id] ?? group.defaultSelectedId ?? `None-${group.id}`;

  const onClick = (layerId) => {
    setActiveLayerId(group.id, layerId);
    showGroupLayer(group.id, layerId);
  };

  const header = (group) => {
    return (
      <div className="d-flex align-items-center">
        <span>{group.name}</span>
        {group.info && (
          <span className="ms-2">
            <InfoLink title={group.info.title} links={group.info.links}>
              {group.info.text}
            </InfoLink>
          </span>
        )}
      </div>
    );
  };

  return (
    <SingleAccordion title={group.name} header={header(group)} eventKey={group.id}>
      <Form.Group controlId={group.id}>
        <LayerItem
          layer={{
            id: `None-${group.id}`,
            name: 'None',
          }}
          group={group}
          isActive={defaultLayerId === `None-${group.id}`}
          handleClick={onClick}
        />

        {group.layers.map((layer) => (
          <LayerItem
            key={layer.id}
            layer={layer}
            group={group}
            isActive={layer.id === defaultLayerId}
            handleClick={onClick}
          />
        ))}
      </Form.Group>
    </SingleAccordion>
  );
};

LayerGroup.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultSelectedId: PropTypes.string,
    info: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ),
    }),
    layers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default LayerGroup;
