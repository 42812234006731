import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { deleteAttachments } from '@esri/arcgis-rest-feature-service';
import { toast } from 'react-toastify';
import GaugeImage from './GaugeImage';


const GaugeImageList = ({ images, gaugeFeatureId, editUrl, onImagesUpdated }) => {
  const onDelete = async (imageId) => {
    try {
      const result = await deleteAttachments({
        url: editUrl,
        featureId: gaugeFeatureId,
        attachmentIds: [imageId]
      });

      if (result?.deleteAttachmentResults[0]?.success === false) {
        toast.error('Error deleting photo');
        return;
      }

      onImagesUpdated();
      toast.success('Photo deleted');
    } catch (error) {
      toast.error('Error deleting photo');
    }
  };

  if (images?.length === 0) {
    return (
      <div className="border border-2 border-dark d-flex gap-2 justify-content-center mb-3 p-3 pb-3 rounded text-muted">
        No photos
      </div>
    );
  }

  return (
    <div className="border border-2 border-dark d-flex gap-2 mb-3 overflow-auto p-3 pb-3 rounded">
      {_.sortBy(images, ['id'])?.map((image) => (
        <GaugeImage key={image.id} image={image} onDelete={onDelete} />
      ))}
    </div>
  );
};

GaugeImageList.propTypes = {
  images: PropTypes.array.isRequired,
  gaugeFeatureId: PropTypes.number,
  editUrl: PropTypes.string.isRequired,
  onImagesUpdated: PropTypes.func.isRequired
};

export default GaugeImageList;
