import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ConditionRegion from './ConditionRegion';
import useAlertStore from 'src/stores/alertStore';
import { getConditionRanges, getGagueConditions } from 'src/lib/conditionUtils';


const AlertList = ({ gauge }) => {
  const subscriptions = useAlertStore(state => state.siteAlerts);

  const regions = useMemo(() => {
    if (!gauge) {
      return [];
    }

    const conditions = getGagueConditions(gauge);
    const ranges = getConditionRanges(conditions);

    return ranges;
  }, [gauge]);

  return (
    <div className="flex flex-col p-lg-3">
      {regions.map((region) => (
        <ConditionRegion
          key={region.id}
          gauge={gauge}
          region={region}
          subscriptions={subscriptions}
        />
      ))}
    </div>
  );
}

AlertList.propTypes = {
  gauge: PropTypes.object.isRequired,
};

export default AlertList;