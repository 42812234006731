import { format } from 'date-fns'; 

export const formatDate = (date, pattern = 'MM/dd/yyy') => {
  return format(date, pattern);
}; 

export const formatDateTime = (date) => {
  return format(date, 'MM/dd/yyy p');
}

export const formatLongDateTime = (date) => {
  return format(date, 'MMM dd, p');
}

export const formatReportDateTime = (date) => {
  return format(date, 'MM/d/yyy h:mm aaa');
}