import React from 'react';
import { GaugeMarkerColors } from 'src/constants/gaugeMarkerColors';


// eslint-disable-next-line react/prop-types
const Marker = ({ color }) => {
  return (
    <svg id="e7f0uFv1A8d1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 300" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
      <g transform="matrix(4.078059 0 0 4.078059-23.19801-21.543158)">
        <rect width="54.914337" height="54.914337" rx="0" ry="0" transform="matrix(.647783 0.647783-.647783 0.647783 42.470695 6.492329)" fill={color} stroke="#000" strokeWidth="0.5" />
        <ellipse rx="22.046889" ry="22.046889" transform="matrix(.868949 0 0 0.868949 42.470695 42.064922)" fill="#d8f5ff" stroke="#000" strokeWidth="0.5" />
      </g>
    </svg>
  );
}

const NwmForecastLegend = () => {
  const ratings = [
    { color: GaugeMarkerColors.PURPLE, label: 'Major Flooding' },
    { color: GaugeMarkerColors.RED, label: 'Moderate Flooding' },
    { color: GaugeMarkerColors.ORANGE, label: 'Minor Flooding' },
    { color: GaugeMarkerColors.YELLOW, label: 'Monitor' },
    { color: GaugeMarkerColors.GREEN, label: 'Normal' },
  ];

  return (
    ratings.map((rating) => {
      return (
        <div className="mb-2 d-flex align-items-center d-flex" key={rating.label}>
          <div style={{ width: '30px', height: '30px' }}>
            <Marker color={rating.color} />
          </div>
          <div className="ms-2">{rating.label}</div>
        </div>
      )
    }
    )
  );
}

export default NwmForecastLegend;
