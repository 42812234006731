import React, { useState, useEffect } from 'react';
import DarkCard from 'src/components/DarkCard';
import { Tabs, Tab, Form } from 'react-bootstrap';
import PrimaryButton from 'src/components/PrimaryButton';
import { SendFill } from 'react-bootstrap-icons';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import useMapStore from 'src/stores/mapStore';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';
import useConfigData from 'src/lib/hooks/useConfigData';
import { toTitleCase } from 'src/lib/stringUtils';

const GaugesSearch = () => {
  const { isMobile } = useCustomMediaQuery();
  const [subOptions, setSubOptions] = useState([]);
  const [geometry, setGeometry] = useState(null);

  const [findGaugeOptions, setFindGaugeOptions] = useState([]);
  const mapManager = useMapStore((state) => state.mapManager);
  const { isLoading, data: config } = useConfigData('Map');

  const toggleToolsModal = useMapStore((state) => state.toggleToolsModal);

  const setHasAreaOfInterestGraphic = useMapStore((state) => state.setHasAreaOfInterestGraphic);

  const selectedGaugeAreaOfInterest = useMapStore((state) => state.selectedGaugeAreaOfInterest);
  const setSelectedGaugeAreaOfInterest = useMapStore((state) => state.setSelectedGaugeAreaOfInterest);

  const selectedGaugeAreaOfInterestSubCategory = useMapStore((state) => state.selectedGaugeAreaOfInterestSubCategory);
  const setSelectedGaugeAreaOfInterestSubCategory = useMapStore((state) => state.setSelectedGaugeAreaOfInterestSubCategory);

  useEffect(() => {
    const customSortSubOptions = (subOptions) => {
      if (selectedGaugeAreaOfInterest.displayField === 'Branch') {
        subOptions = subOptions.map(x => {
          const name = x.attributes[selectedGaugeAreaOfInterest.displayField];
          if (name === 'Western') return { ...x, attributes: { ...x.attributes, sortOrder: 1 } };
          if (name === 'Central') return { ...x, attributes: { ...x.attributes, sortOrder: 2 } };
          if (name === 'Western') return { ...x, attributes: { ...x.attributes, sortOrder: 3 } };
          return { ...x, attributes: { ...x.attributes, sortOrder: 999 } };
        });

        subOptions.sort((a, b) => a.attributes.sortOrder - b.attributes.sortOrder);
      }
      return subOptions;
    }

    if (!isLoading && config) {
      setFindGaugeOptions(config.findGaugeLayers.map((config) => {
        return {
          value: config.id,
          label: config.name,
        };
      }));
    }

    if (selectedGaugeAreaOfInterest) {
      if (!selectedGaugeAreaOfInterest.queryFeatures?.url) {
        setSubOptions([]);
        return;
      }
      queryFeatures(selectedGaugeAreaOfInterest.queryFeatures)
        .then((response) => {
          // this is where we would add custom sort order
          // rubs me the wrong way, but we need to be able to arbitrarily sort the "Branch" sub category 
          // making generic in caase there are ever any other sub categories that need to be sorted
          const customSortedSubOptions = customSortSubOptions(response.features);
          setSubOptions(customSortedSubOptions);
        });
    }
  }, [isLoading, config, setFindGaugeOptions, selectedGaugeAreaOfInterest, setSubOptions]);

  const onAreaOfInterestChange = (e) => {
    //no value selected - clear out sub categories and current selection
    if (!e.target.value) {
      clear();
      return;
    }

    const selectedGaugeAreaOfInterest = config.findGaugeLayers.find((config) => config.id === e.target.value);

    setSelectedGaugeAreaOfInterest(selectedGaugeAreaOfInterest);
    setSelectedGaugeAreaOfInterestSubCategory(null);
    mapManager.clearGraphicsLayer();
    setHasAreaOfInterestGraphic(false);
  }

  const onSubAreaOfInterestChange = (e) => {
    if (!e.target.value) {
      setSelectedGaugeAreaOfInterestSubCategory(null);
      setGeometry(null);
      return;
    }

    queryFeatures({
      ...selectedGaugeAreaOfInterest.queryFeatures,
      returnGeometry: true,
      objectIds: [e.target.value]
    })
      .then((response) => {
        setGeometry(response.features[0].geometry);
        setSelectedGaugeAreaOfInterestSubCategory(response.features[0]);
      });
  }

  const onAreaSearch = () => {
    if (!geometry || !selectedGaugeAreaOfInterestSubCategory) return;
    if (isMobile) {
      toggleToolsModal(false);
    }

    const polygonGraphic = {
      type: 'polygon', // autocasts as new Polygon()
      rings: geometry.rings
    };
    const graphic = mapManager.createGraphic(polygonGraphic);
    mapManager.zoomToFeatureByGeometry(graphic);
    mapManager.addGraphicToLayer(graphic);
    setHasAreaOfInterestGraphic(true);
  }

  const handleFindMe = () => {
    if (isMobile) {
      toggleToolsModal(false);
    }
    mapManager.locateMe();
  };

  const clear = () => {
    setSelectedGaugeAreaOfInterest(null);
    setSelectedGaugeAreaOfInterestSubCategory(null);
    setGeometry(null);
    setSubOptions([]);
    setHasAreaOfInterestGraphic(false);
    mapManager.clearGraphicsLayer();
  }

  return (
    <DarkCard title="Find Gauges">
      <Tabs defaultActiveKey="area" justify variant="find-gauges">
        <Tab eventKey="area" title={isMobile ? 'By Area' : 'By Area of Interest'}>
          <div className="d-flex">
            <div className="mt-3 me-2 w-50">
              <Form.Select
                onChange={(e) => onAreaOfInterestChange(e)}
                value={selectedGaugeAreaOfInterest ? selectedGaugeAreaOfInterest.id : ''}
              >
                <option value={''}>Select Category</option>
                {findGaugeOptions.map((option) => (
                  <option value={option.value} key={option.value}>{option.label}</option>
                ))}
              </Form.Select>
            </div>
            <div className="mt-3 w-50">
              <Form.Select
                disabled={!selectedGaugeAreaOfInterest}
                onChange={(e) => onSubAreaOfInterestChange(e)}
                className="me-2"
                value={selectedGaugeAreaOfInterestSubCategory ? selectedGaugeAreaOfInterestSubCategory.attributes.OBJECTID : ''}
              >
                <option value={''}>{selectedGaugeAreaOfInterest?.name ? `Select ${selectedGaugeAreaOfInterest.name}` : 'Select Subcategory'}</option>
                {selectedGaugeAreaOfInterest && subOptions.map((option) => (
                  <option value={option.attributes.OBJECTID} key={option.attributes.OBJECTID}>
                    {toTitleCase(option.attributes[selectedGaugeAreaOfInterest.displayField])}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          {!isMobile && <div className="d-flex gap-2 dark-gray-background mt-3">
            <PrimaryButton disabled={(!selectedGaugeAreaOfInterest || !selectedGaugeAreaOfInterestSubCategory)} className="rounded-pill px-4" onClick={() => onAreaSearch()}>
              Go
            </PrimaryButton>
            <PrimaryButton disabled={!selectedGaugeAreaOfInterest} className="rounded-pill px-4" onClick={clear}>
              Clear
            </PrimaryButton>
          </div>}

          {isMobile &&
            <>
              <div className="d-flex flex-column dark-gray-background mt-3">
                <PrimaryButton disabled={(!selectedGaugeAreaOfInterest || !selectedGaugeAreaOfInterestSubCategory)} className="rounded-pill px-4" onClick={() => onAreaSearch()}>
                  Go
                </PrimaryButton>
              </div>
              <div className="d-flex flex-column dark-gray-background mt-3">
                <PrimaryButton disabled={!selectedGaugeAreaOfInterest} className="rounded-pill px-4" onClick={clear}>
                  Clear
                </PrimaryButton>
              </div>
            </>
          }
        </Tab>
        <Tab eventKey="location" title="By Location">
          <div className="d-flex flex-column dark-gray-background mt-3">
            <PrimaryButton className="rounded-pill" onClick={handleFindMe}>
              <SendFill className="me-2" /> Zoom to My Location
            </PrimaryButton>
          </div>
        </Tab>

      </Tabs>
    </DarkCard>
  );
}

export default GaugesSearch;
