import React from 'react';
import PropTypes from 'prop-types';
import useMapStore from 'src/stores/mapStore';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';
import PrimaryButton from 'src/components/PrimaryButton';
import LoadingDisplay from 'src/components/LoadingDisplay';
import StageHydrograph from './StageHydrograph';

const TrendContent = ({ useNwmModel = false, modalCallback }) => {
  const gauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails, isLoading } = useGaugeDetails(gauge?.attributes?.siteId);
  const { isDesktop } = useCustomMediaQuery();

  if (isLoading) {
    return <LoadingDisplay />;
  }

  return (
    <>
      <StageHydrograph gaugeDetails={gaugeDetails} useNwmModel={useNwmModel} isFullGraphDisplay={false} />
      {!isDesktop && 
        <div className="d-flex flex-column mt-3">
          <PrimaryButton size="sm" className="rounded-pill py-1" onClick={() => modalCallback()}>
            <span>View Details</span>
          </PrimaryButton>
        </div>
      }
    </>
  );
}
TrendContent.propTypes = {
  useNwmModel: PropTypes.bool,
  modalCallback: PropTypes.func
};

export default TrendContent;
