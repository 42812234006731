import React from 'react'; 
import useMapStore from 'src/stores/mapStore';
import { Modal } from 'react-bootstrap';
import AuthModalBody from './AuthModalBody';

const SignInModal = () => {
  const showSignInModal = useMapStore(state => state.showSignInModal);
  const toggleSignInModal = useMapStore(state => state.toggleSignInModal);

  return (
    <Modal
      size="md"
      centered
      show={showSignInModal}
      onHide={() => toggleSignInModal(false)}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Sign In</Modal.Title>
      </Modal.Header>
      <AuthModalBody isSignIn={true} />
    </Modal>
  );
}; 

export default SignInModal; 