import React from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Table } from 'react-bootstrap';
import useMapStore from 'src/stores/mapStore';
import { roundToTenth } from 'src/lib/mathUtils';
import useRoadsAffectedTotals from 'src/lib/hooks/useRoadsAffectedTotals';
import LoadingDisplay from 'src/components/LoadingDisplay';
import CenteredCell from './CenteredCell';


const columns = [
  {
    id: 'depthColumn',
    footer: x => x.column.id,
    columns: [
      {
        id: 'depthCat',
        header: <CenteredCell>Roadway Flood Depth Range</CenteredCell>,
        accessorKey: 'depthCat',
        cell: info => <CenteredCell>{info.getValue()}</CenteredCell>,
        footer: x => x.column.id,
      }
    ],
  },
  {
    header: <CenteredCell>Estimated Inundated Lengths (Miles)</CenteredCell>,
    id: 'group',
    footer: x => x.column.id,
    columns: [
      {
        accessorKey: 'totalMiles',
        cell: info => <CenteredCell>{roundToTenth(info.getValue())}</CenteredCell>,
        header: () => <CenteredCell>Total</CenteredCell>,
        footer: x => x.column.id,
      },
      {
        accessorKey: 'intMiles',
        cell: info => <CenteredCell>{roundToTenth(info.getValue())}</CenteredCell>,
        header: () => <CenteredCell>Interstate</CenteredCell>,
        footer: x => x.column.id,
      },
      {
        accessorKey: 'usMiles',
        cell: info => <CenteredCell>{roundToTenth(info.getValue())}</CenteredCell>,
        header: () => <CenteredCell>US Highway</CenteredCell>,
        footer: x => x.column.id,
      },
      {
        accessorKey: 'ncMiles',
        cell: info => <CenteredCell>{roundToTenth(info.getValue())}</CenteredCell>,
        header: () => <CenteredCell>NC Highway</CenteredCell>,
        footer: x => x.column.id,
      },
      {
        accessorKey: 'localMiles',
        cell: info => <CenteredCell>{roundToTenth(info.getValue())}</CenteredCell>,
        header: () => <CenteredCell>Local</CenteredCell>,
        footer: x => x.column.id,
      },
    ],
  },
  {
    accessorKey: 'blank',
    cell: () => '',
    header: () => '',
    footer: x => x.column.id,
  },
];

const RoadsImpactedTable = () => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const inundationLevel = useMapStore(state => state.inundationLevel);
  const { entries, isLoading } = useRoadsAffectedTotals(activeGauge?.attributes?.siteId, inundationLevel);

  const options = {
    data: entries,
    columns,
    getCoreRowModel: getCoreRowModel(),
  };

  const table = useReactTable(options);

  if (isLoading) {
    return (<LoadingDisplay />);
  }

  if(!entries || entries.length === 0 || entries.every(x => x.totalMiles === 0)) {
    return (<div className="d-flex flex-fill align-items-center justify-content-center h-100">
      No road impacts reported at this flood stage.
    </div>);
  }

  return (
    <Table size="sm" variant="dark" striped bordered>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <tr key={row.id} className={index === entries.length - 1 ? 'border-top' : ''}>
            {row.getVisibleCells().map(cell => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default RoadsImpactedTable;
