import React, { useCallback, useEffect, useState, useMemo } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useLibraryLevels from 'src/lib/hooks/useLibraryLevels';
import { roundToTenth } from 'src/lib/mathUtils';
import { Paramcodes } from 'src/constants/paramcodes';
import { getCoastalForecastData, getRiverForecastData } from 'src/lib/hydroUtils';
import useMapStore from 'src/stores/mapStore';
import { MemoizedRangeScale } from 'src/components/RangeScale';
import { MemoizedLinearGauge } from 'src/components/LinearGauge';
import PillButton from 'src/components/PillButton';
import LoadingDisplay from 'src/components/LoadingDisplay';
import ForecastSliderLegend from './ForecastSliderLegend';
import { MemoizedForecastRangeScale } from './ForecastRangeScale';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import useUserGroup from 'src/lib/hooks/useUserGroup';

// styles for the scale, defined here so they are not recreated on every render
// which causes the scale to rerender due to the object reference changing
const topStyle = { paddingLeft: '9px', paddingRight: '10px', marginBottom: '-13px' };
const bottomStyle = { paddingLeft: '10px', paddingRight: '10px', marginTop: '-13px' };
const linearGaugeStyle = { top: '15px', height: '7px', left: '9px', right: '9px', zIndex: 1000 };

const ForecastSlider = () => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const mapManager = useMapStore(state => state.mapManager);
  const showInundationLayer = useMapStore(state => state.showInundationLayer);
  const forecastIndex = useMapStore(state => state.forecastIndex);
  const setForecastIndex = useMapStore(state => state.setForecastIndex);
  const { isAdmin, isAdvanced } = useUserGroup();
  const { data: gauge } = useGaugeDetails(activeGauge?.attributes?.siteId);
  const { levels, isLoading: librariesLoading } = useLibraryLevels(activeGauge?.attributes?.siteId);
  const [exceededMaxElev, setExceededMaxElev] = useState(false);

  const forecasts = useMemo(() => {
    if (!gauge) {
      return null;
    }

    const data = gauge.forecasts.filter(f => Paramcodes.STAGE_CODES.includes(f.code));
    const forecasts = gauge.isCoastal ? getCoastalForecastData(gauge, data) : getRiverForecastData(gauge, data);

    return forecasts;
  }, [gauge]);

  /**
   * Changes the inundation level to the specified forecast index
   * index 0 is now, index 1 is 6 hours from now, index 2 is 12 hours from now, etc.
   */
  const changeLevel = useCallback(async (index) => {
    if (!gauge || !forecasts) {
      return;
    }

    // if the passed index is null, first load, check the store for the global store index
    // if the global store index is null, use index 0 (represents now)
    if (index === null) {
      index = forecastIndex ?? 0;
    }

    // current forecast slider position is stored in the global store
    setForecastIndex(index);

    // given the current slider position, get the height from the forecast data
    // the logic is extracted from the existing fiman code

    // if the index is greater than 0, get the MSL height from the forecast data
    // otherwise use the gauge current MSL height.
    // coastal gauges have 1 forecast per hour, river gauges have 1 forecast per 6 hours
    let height = gauge.currentElevationMsl;

    if (index > 0) {
      // index 0 is now, index 1 is 6 hours from now, index 2 is 12 hours from now, etc.
      // Index 0 is the first value in the array. Slider position 0 is considered NOW. So, when 
      // the slider is at position 0, the value displayed should be the current condition of the gauge. 
      // When the slider is at position 1, the value displayed should be the first value in the array, index 0, etc.
      // logic to handle when the index is not a multiple of 6, happens sometimes with river gauges.
      let forecastIndex = parseInt(Math.ceil(gauge?.isCoastal ? index - 1 : (index / 6) - 1));

      if (forecastIndex > forecasts.forecastData.length - 1) {
        forecastIndex = forecasts.forecastData.length - 1;
      }

      if (forecastIndex < 0) {
        forecastIndex = 0;
      }

      // get the MSL height from the forecast data
      height = forecasts.forecastData[forecastIndex].height;
      if(gauge.isScenario && (gauge.forecastPt || gauge.nwmForecast)){
        setExceededMaxElev(height > gauge.svcMaxElev);
      }
    }

    // get the level from the library, do not snap to the lowest library level if below the lowest level
    const level = await showInundationLayer(gauge.siteId, gauge.isScenario, height, levels, false);

    // show the road and bridge and buildings layers if admin or advanced
    if (isAdmin || isAdvanced) {
      mapManager.showRoadLayer(gauge?.siteId, level, gauge.isScenario);
      mapManager.showBridgeLayer(gauge?.siteId, level, gauge.isScenario);
    }
    mapManager.showFloodBuildingsLayer(gauge?.siteId, level, gauge.isScenario);
  }, [gauge, mapManager, levels, forecasts, setForecastIndex, isAdmin, isAdvanced]);

  useEffect(() => {
    if (!librariesLoading) {
      changeLevel(null);
    }
  }, [librariesLoading, changeLevel]);

  const stageData = useMemo(() => {
    if (!forecasts) {
      return null;
    }

    return forecasts.forecastData.map(f => f.stage);
  }, [forecasts]);

  const rangeData = useMemo(() => {
    if (!forecasts) {
      return null;
    }

    return forecasts.forecastData.map(f => f.range);
  }, [forecasts]);

  if (librariesLoading) {
    return <LoadingDisplay />;
  }

  if (!levels || levels.length === 0) {
    return (
      <div className="pe-3 bg-black bg-opacity-100 px-5" style={{ height: '90px' }}>
        <div className="align-items-center d-flex justify-content-center w-100 h-100">
          No inundation levels are available for {gauge.name}.
        </div>
      </div>
    );
  }

  if (!forecasts || forecasts.forecastData.length === 0) {
    return (
      <div className="pe-3 bg-black bg-opacity-100 px-5" style={{ height: '90px' }}>
        <div className="align-items-center d-flex justify-content-center w-100 h-100">
          Forecasts for {gauge.name} are issued as needed during times of high water, but are not routinely available.
        </div>
      </div>
    );
  }

  return (
    <div className="w-100 pe-3 bg-black bg-opacity-100">
      <div className="d-flex align-items-center">
        <ForecastSliderLegend isCoastal={gauge.isCoastal} />
        <PillButton
          className="p-2"
          onClick={() => changeLevel(Math.max(parseInt(forecastIndex ?? 0) - (gauge.isCoastal ? 1 : 6), 0))}
        >
          <div className="d-flex align-items-center">
            <ChevronLeft />
          </div>
        </PillButton>
        <div className="w-100 overflow-hidden position-relative d-flex flex-column px-2 py-1">
          <MemoizedForecastRangeScale
            className="position-relative"
            style={topStyle}
            min={0}
            max={forecasts.maxForecastTime}
            smallStep={gauge.isCoastal ? 1 : 6}
            largeStep={gauge.isCoastal ? 2 : 6}
            offset={gauge.isCoastal ? 1 : 6}
            values={stageData}
          />
          <div className="position-relative">
            <MemoizedLinearGauge
              className="position-absolute"
              style={linearGaugeStyle}
              ranges={rangeData}
            />
            <RangeSlider
              style={{ zIndex: 1000 }}
              value={forecastIndex ?? 0}
              min={0}
              max={forecasts.maxForecastTime}
              step={gauge.isCoastal ? 1 : 6}
              tooltipPlacement="top"
              onChange={changeEvent => changeLevel(changeEvent.target.value)}
            />
          </div>
          <MemoizedRangeScale
            className="position-relative"
            style={bottomStyle}
            min={0}
            max={forecasts.maxForecastTime}
            smallStep={gauge.isCoastal ? 1 : 6}
            largeStep={gauge.isCoastal ? 2 : 6}
            textPosition="bottom"
            size="lg"
          />
        </div>
        <PillButton
          className="p-2"
          onClick={() => changeLevel(Math.min(parseInt(forecastIndex ?? 0) + (gauge.isCoastal ? 1 : 6), forecasts.maxForecastTime))}
        >
          <div className="d-flex align-items-center">
            <ChevronRight />
          </div>
        </PillButton>
      </div>
      <div className="pb-2 align-items-center d-flex justify-content-center w-100" style={{ fontSize: 14 }}>
        Drag to simulate forecasted flood depth <span
          className="ps-2"
          style={{ fontSize: 12 }}
        >
          (Min. Library {gauge.isCoastal ? 'MSL' : 'Stage'} {gauge.isCoastal ? roundToTenth(levels[0]) : roundToTenth(levels[0] - gauge.gageDatum)} ft)
        </span>
      </div>

    
      {/* { (gauge.isScenario && (gauge.forecastPt || gauge.nwmForecast)) && */}
      {exceededMaxElev &&
      <div className="pb-2 align-items-center d-flex justify-content-center w-100" style={{ fontSize: 14 }}>
      The displayed water elevation exceeds the range of estimated flood impacts available at this site.  The highest available inundation extent is displayed.  Inundation and impacts may be greater.
      </div>
      }


    </div>
  )
}

export default ForecastSlider;