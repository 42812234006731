import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import useConfigData from 'src/lib/hooks/useConfigData';

const useBuildingImpactSummary = (gaugeId, level) => {
  const { data: config } = useConfigData('Map');

  const { data, isLoading } = useQuery(['building-impact-summary', gaugeId, level], ({ queryKey }) => {
    const [, gaugeId, level] = queryKey;
    let query;
    if (level.toString().indexOf('.') != -1) {
      query = `GAGE_ID='${gaugeId}' AND ELEVATION='${level}'`;
    } else {
      query = `GAGE_ID='${gaugeId}' AND ELEVATION='${level}.0'`;
    }


    return queryFeatures({
      url: config?.references.buildingImpactSummary,
      where: query,
      outFields: ['*'],
      returnGeometry: false,
    });
  }, {
    enabled: gaugeId !== undefined && level !== undefined && level !== null 
  });

  const impactSummary = useMemo(() => {
    return data?.features && data?.features.length > 0 ? data.features[0].attributes : null
  }, [data]);
  
  return { impactSummary, isLoading };
};

export default useBuildingImpactSummary;