import React, { useState } from 'react';
import Widget from './Widget';
import useMapStore from 'src/stores/mapStore';
import ImpactContent from './ImpactContent';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import LoadingDisplay from 'src/components/LoadingDisplay';
import ImpactModal from './ImpactModal';
import PropTypes from 'prop-types';

const ImpactWidget = ({ level }) => {
  const gauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails, isLoading } = useGaugeDetails(gauge?.attributes?.siteId);
  const [showImpactModal, setShowImpactModal] = useState(false);

  if (isLoading) {
    return <LoadingDisplay />;
  }

  const handleActionClick = () => {
    setShowImpactModal(!showImpactModal);
  }

  // Only show ImpactModal and more icon if level is not null or undefined
  return (
    <Widget
      title="Impact"
      onActionClick={level != null ? handleActionClick : null} // Pass handler conditionally
    >
      <ImpactContent level={level} />
      {gaugeDetails && level != null &&
        <ImpactModal
          show={showImpactModal}
          handleClose={() => setShowImpactModal(false)}
          level={level}
        />
      }
    </Widget>
  );
}

ImpactWidget.propTypes = {
  level: PropTypes.number
};
export default ImpactWidget;