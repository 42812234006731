import React from 'react';
import { GaugeMarkerColors } from 'src/constants/gaugeMarkerColors';

// eslint-disable-next-line react/prop-types
const BridgeIcon = ({color}) => {
  return (
    <svg width="24px" height="17px" viewBox="0 0 24 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Assets" stroke="none" strokeWidth="1" fill="none">
        <g transform="translate(-246.000000, -553.000000)" fill={color} id="Bridges_White">
          <path d="M246,569.964 L269.016,569.964 L269.016,553.062 L246,553.062 L246,569.964 Z M257.406,559.158 L257.609,559.158 L262.206,559.158 L264.134,556.283 L266.054,556.301 L262.853,560.187 L257.609,560.187 L257.406,560.187 L252.161,560.187 L248.962,556.301 L250.882,556.283 L252.809,559.158 L257.406,559.158 Z M248.962,567.011 L252.161,563.125 L257.406,563.125 L257.609,563.125 L262.853,563.125 L266.054,567.011 L264.134,567.029 L262.206,564.154 L257.609,564.154 L257.406,564.154 L252.809,564.154 L250.882,567.029 L248.962,567.011 Z"></path>
        </g>
      </g>
    </svg>
  )
}

const BridgeLegend = () => {
  const levels = [
    { color: GaugeMarkerColors.RED, label: 'Pressure/Weir' },
    { color: GaugeMarkerColors.YELLOW, label: 'Warning' },
    { color: GaugeMarkerColors.GREEN, label: 'No Flooding' }
  ];

  return (
    levels.map((level) => {
      return (
        <div className="mb-2 d-flex align-items-center d-flex" key={level.label}>
          <div className="me-2 d-flex align-items-center justify-content-center" style={{ backgroundColor: 'black', width: 20, height: 17 }}>
            <BridgeIcon color={level.color} />
          </div>
          <div>{level.label}</div>
        </div>
      )
    })
  );
}

export default BridgeLegend;
