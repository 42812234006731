import React from 'react';
import useMapStore from 'src/stores/mapStore';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useGaugeImages from 'src/lib/hooks/useGaugeImages';
import LoadingDisplay from 'src/components/LoadingDisplay';
import PropTypes from 'prop-types';
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'src/assets/styles/Photos.scss';

const Photos = ({ className, width, height }) => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const { data: gauge } = useGaugeDetails(activeGauge?.attributes?.siteId);
  const [gaugeImages, isLoading] = useGaugeImages(gauge?.siteId);

  if (isLoading) {
    return <LoadingDisplay />;
  }

  if (gaugeImages.length === 0) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <span>No images available.</span>
      </div>
    );
  }

  return (
    <>
      <Gallery withCaption>
        <div className={`d-flex ${className}`}>
          {gaugeImages.map((image) => {
            return (
              <Item key={image.id}
                cropped
                original={image.src}
                thumbnail={image.src}
                width={image.width}
                height={image.height}
                caption={image.title}
              >
                {({ ref, open }) => (
                  <div
                    ref={ref}
                    style={{
                      position: 'relative',
                      width: width,
                      height: height,
                      overflow: 'hidden',
                      padding: '10px',
                      cursor: 'pointer'
                    }}
                    onClick={open}
                  >
                    <img
                      className="photo-style"
                      src={image.src}
                      alt={image.name}
                    />
                    <button
                      className="zoom-button"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" width="20" height="20" viewBox="0 0 512 512">
                        <path d="M304 192v32c0 6.6-5.4 12-12 12h-56v56c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-56h-56c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h56v-56c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v56h56c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z" />
                      </svg>
                    </button>
                  </div>
                )}
              </Item>
            )
          })}
        </div>
      </Gallery>
    </>
  );
}

Photos.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default Photos;