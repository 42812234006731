import React from 'react';
import PropTypes from 'prop-types';
import { flexRender } from '@tanstack/react-table';
import { Table } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import PaginationFooter from 'src/components/PaginationFooter';
import AdminEditUserModal from './AdminEditUserModal';

const UsersTable = ({ table }) => {
  return (
    <>
      <Table size="sm" variant="dark" striped bordered hover>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} style={{ width: header.getSize() }}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? 'd-flex cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <span className="ms-1"><CaretUpFill /></span>,
                        desc: <span className="ms-1"><CaretDownFill /></span>,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={row.getIsSelected() ? 'table-light' : ''}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} >
                  {flexRender(cell.column.columnDef.cell, cell.getContext(), row)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <PaginationFooter
        table={table}
        totalRecordCount={table.getFilteredRowModel().rows.length}
        alwaysDisplay={true}
      />
      <AdminEditUserModal table={table} />
    </>
  )
}
UsersTable.propTypes = {
  table: PropTypes.object.isRequired,
}
export default UsersTable;