import React, { memo } from 'react';
import PropTypes from 'prop-types';

/**
  * A linear gauge component that displays a range of values.
 */
const LinearGauge = ({ className, style, ranges }) => {
  if (!ranges) return null;

  // scale the range values to 0-100
  const getScaledRanges = (ranges) => {
    const min = Math.min(...ranges.map((range) => range.min));
    const max = Math.max(...ranges.map((range) => range.max));

    const scaled = ranges.map((range) => {
      return {
        min: (range.min - min) / (max - min) * 100,
        max: (range.max - min) / (max - min) * 100,
        color: range.color
      }
    });

    return scaled;
  }

  return (
    <div className={`${className}`} style={style}> 
      <div>
        {getScaledRanges(ranges).map((range, index) => {
          return (
            <div
              key={index}
              className="position-absolute"
              style={{
                left: `${range.min}%`,
                width: `${range.max - range.min}%`,
                backgroundColor: range.color,
                height: '100%',
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

LinearGauge.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export const MemoizedLinearGauge = memo(LinearGauge);