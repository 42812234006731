import React, { useState } from 'react';
import TrendContent from './TrendContent';
import SingleAccordion from 'src/components/SingleAccordion';
import useMapStore from 'src/stores/mapStore';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import HydrographModal from './HydrographModal';
import LoadingDisplay from 'src/components/LoadingDisplay';
import useUserGroup from 'src/lib/hooks/useUserGroup';
import { isNwmModel } from 'src/lib/forecastUtils';


const TrendWidgetSm = () => {
  const [showHydrographModal, setShowHydrographModal] = useState(false)
  const gauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails, isLoading } = useGaugeDetails(gauge?.attributes?.siteId);
  const { trend } = gaugeDetails;
  const { isAdmin, isAdvanced } = useUserGroup();
  const useNwmModel = isNwmModel(gaugeDetails, isAdmin, isAdvanced);

  if (isLoading) {
    return <LoadingDisplay />;
  }

  return (
    <>
      <SingleAccordion
        header={`Trend: ${trend}`}
        eventKey="0"
        defaultEventKey={null}
      >
        <TrendContent
          useNwmModel={useNwmModel} 
          modalCallback={() => setShowHydrographModal(true)}
        />
      </SingleAccordion>
      {gaugeDetails &&
        <HydrographModal
          useNwmModel={useNwmModel}
          show={showHydrographModal}
          handleClose={() => setShowHydrographModal(false)}
        />
      }
    </>
  );
}

export default TrendWidgetSm;