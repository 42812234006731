import React from 'react';
import Container from 'react-bootstrap/Container';
import Notifications from '../Notifications';
import WeatherToggle from '../weather/WeatherToggle';
import ToolsToggle from '../ToolsToggle';
import Filters from '../filters/MapGaugeFilters';
import SearchSmBtn from '../search/SearchSmBtn';
import useMapStore from '../../../stores/mapStore';
import styles from '../../../assets/styles/ActionBar.module.scss';

const ActionBarSm = () => {
  const toggleToolsModal = useMapStore(state => state.toggleToolsModal);

  return (
    <div id="actionbarSm" className={styles.actionBarSm}>
      <Container>
        <div className="d-flex flex-column pt-3 gap-3">
          <SearchSmBtn />
          <div className="d-flex justify-content-between align-items-center mt-2">
            <ToolsToggle
              className="h-100"
              action={toggleToolsModal} />
            <WeatherToggle />
            <div className="d-flex">
              <div className="d-flex gap-2">
                <Filters className="h-100" />
                <Notifications className="h-100" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ActionBarSm;