import React from 'react';
import Button from 'react-bootstrap/Button';
import { XLg, ChevronDoubleUp, ChevronDoubleDown, ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import useMapStore from '../../../stores/mapStore';
import useGaugeStore from '../../../stores/gaugeStore';
import { DrawerLevels } from 'src/constants/drawerLevels';

const GaugeDrawerHeader = () => {
  const clearAllSelectedFeatures = useMapStore(state => state.clearAllSelectedFeatures);
  const gauge = useMapStore(state => state.activeGauge);
  const zoomToGaugeLocation = useMapStore(state => state.zoomToGaugeLocation)
  const toggleDrawerExpand = useGaugeStore(state => state.toggleDrawerExpand)
  const drawerLevel = useGaugeStore(state => state.drawerLevel);

  const expandDrawerLevel = () => {
    if (drawerLevel === DrawerLevels.MIN) {
      toggleDrawerExpand(DrawerLevels.HALF);
    }
    if (drawerLevel === DrawerLevels.HALF) {
      toggleDrawerExpand(DrawerLevels.FULL);
    }
  }

  const collapseDrawerLevel = () => {
    if (drawerLevel === DrawerLevels.FULL) {
      toggleDrawerExpand(DrawerLevels.HALF);
    }
    if (drawerLevel === DrawerLevels.HALF) {
      toggleDrawerExpand(DrawerLevels.MIN);
    }
  }

  return (
    <div
      className="d-flex align-items-center justify-content-left p-2 position-relative primary-blue-background"
      style={{ opacity: 1 }}
    >
      <h5 className="text-white m-0 cursor-pointer" onClick={() => zoomToGaugeLocation(gauge)}>
        {gauge?.attributes?.name} - SITE ID: {gauge?.attributes?.siteId}
      </h5>
      <div className="d-flex end-0 h-100 me-0 position-absolute">
        <Button
          variant="link"
          className="d-flex align-items-center px-1 text-white grow"
          title={drawerLevel === DrawerLevels.FULL ? 'Collapse' : 'Expand'}
          disabled={drawerLevel === DrawerLevels.MIN}
          onClick={() => drawerLevel !== DrawerLevels.FULL ? expandDrawerLevel() : collapseDrawerLevel()}
        >
          {drawerLevel !== DrawerLevels.FULL ? <ChevronDoubleUp /> : <ChevronDoubleDown />}
        </Button>

        <Button
          variant="link"
          className="d-flex align-items-center px-1 text-white grow"
          disabled={drawerLevel === DrawerLevels.FULL}
          title={drawerLevel === DrawerLevels.MIN ? 'Show' : 'Minimize'}
          onClick={() => drawerLevel !== DrawerLevels.MIN ? collapseDrawerLevel() : expandDrawerLevel()}
        >
          { drawerLevel !== DrawerLevels.MIN ? <ChevronDown /> : <ChevronUp /> }
        </Button>

        <Button
          variant="link"
          className="d-flex align-items-center px-1 text-white pr-2"
          onClick={() => {
            clearAllSelectedFeatures();
            toggleDrawerExpand(DrawerLevels.HALF)
          }}
        >
          <XLg />
        </Button>
      </div>
    </div>
  )
}
export default GaugeDrawerHeader; 