import React from 'react';
import SingleAccordion from '../../../../components/SingleAccordion';
import BridgeLegend from './BridgeLegend';
import RoadLegend from './RoadLegened';


const TransportationLegendContainer = () => {
  return (
    <>
      <SingleAccordion
        header="Bridges"
        eventKey="bridges"
        defaultEventKey={null}>
        <BridgeLegend />
      </SingleAccordion>
      <SingleAccordion
        header="Road Inundation Levels"
        eventKey="levels"
        defaultEventKey={null}
      >
        <RoadLegend />
      </SingleAccordion>
    </>
  );
}

export default TransportationLegendContainer;