import { Filters } from '../constants/filters';

export const filtersToQuery = (filterGroups, bridgeRoadGaugeIds, aggregation = 'OR') => {
  const query = filterGroups.map((filterGroup) => {
    // handle the guage filter group separately
    if (filterGroup.id === Filters.GAUGE_FILTER_ID && filterGroup.filters) {
      
      // Map the filters to a query string
      const gaugeQuery = filterGroup.filters.map(filter => {
        const { field, operator, value, isDisabled, isActive, displayName } = filter;

        // If the filter is not disabled and is active THEN create (and return) the query
        if (!isDisabled && isActive && displayName !== 'Road/Bridge Data' && displayName !== 'Has NWM Forecast') {
          
          //if the value is a string, wrap it in single quotes
          if (typeof value === 'string' || value instanceof String) {
            return `${field} ${operator} '${value}'`;
          }
          //if the value is a number or bool, return it as is
          else {
            return `${field} ${operator} ${value}`;
          }
        }
        
        if (!isDisabled && isActive && displayName === 'Road/Bridge Data') {
          let otherValue = bridgeRoadGaugeIds.map(x => `'${x}'`).join(',');
          return `${field} ${operator} (${otherValue})`;
        }

        // speacial logic added when the gauge has an NWM forecast
        // and a RFC forecast, only show the gauge if it has an NWM forecast
        if (!isDisabled && isActive && displayName === 'Has NWM Forecast') {
          return `${field} ${operator} ${value} AND forecastCondition = -1`;
        }
        
        //explicitly return null if none of the above are met
        return null;
      })
        .filter(x => { return x !== null })
        .join(` ${aggregation} `);
      
      
      //if the gaugeQuery is not empty, wrap it in parentheses
      if (gaugeQuery) {
        return `(${gaugeQuery}) `;
      }
      //if the gaugeQuery is empty, return null 
      return null;
         
    }
    // handle the owned by filter group
    else if (filterGroup.id === Filters.OWNED_BY_FILTER_ID && filterGroup.filters) {
      let ownedByQuery = null;

      ownedByQuery = filterGroup.filters.map(filter => {
        const { field, operator, value, isDisabled, isActive, displayName } = filter;

        if (!isDisabled && isActive && displayName !== 'Other') {
          return `${field} ${operator} '${value}'`;
        }
        if (!isDisabled && isActive && displayName === 'Other') {
          let otherValue = value.split(',').map(x => `'${x}'`).join(',');
          return `${field} ${operator} (${otherValue})`;
        }
        return null;
      })
        .filter(x => { return x !== null })
        .join(` ${aggregation} `);
      
      ownedByQuery = ownedByQuery ? `(${ownedByQuery})` : null;
      return ownedByQuery;
    }
    else if (filterGroup.id === Filters.STATUS_FILTER_ID && filterGroup.filters) { 
      let statusQuery = filterGroup.filters.map(filter => {
        const { field, operator, value, isDisabled, isActive } = filter;

        if (!isDisabled && isActive) {
          return `${field} ${operator} ${value}`;
        }
        return null;
      })
        .filter(x => { return x !== null })
        .join(` ${aggregation} `);
      
      statusQuery = statusQuery ? `(${statusQuery})` : null;
      return statusQuery;
    }
  })
    .filter(x => { return x !== null })
    .join(` AND `);
  
  return query;  //+ ' AND (bankFull is not null)'; 
  //uncomment out the above line if they ONLY ever want to see gauges that are risk rated
};

export const sleep = async (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const downloadImage = (filename, dataUrl) => {
  const element = document.createElement('a');
  element.setAttribute('href', dataUrl);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}