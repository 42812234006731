import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';


/**
 * list for current condition is passed in
 * then counts are displayed based on trend field/attribute 
 * trends are constant, and map to hard coded string in geojson 
 * 'Rising' 
 * 'Constant'
 * 'Falling'
 */

const OverviewCondition = ({ image, alt, risingImage, altRising, constantImage, altConst, fallingImage, altFalling, conditionGauges, isForecast }) => {
  const risingCountMemo = useMemo(() => {
    return isForecast
      ? conditionGauges?.filter(x => x.attributes?.forecastCondition > x.attributes?.condition).length ?? 0
      : conditionGauges?.filter(x => x.attributes?.trend === 'Rising').length ?? '0';
  }, [conditionGauges, isForecast]);

  const constantCountMemo = useMemo(() => {
    return isForecast
      ? conditionGauges?.filter(x => x.attributes?.forecastCondition === x.attributes?.condition).length ?? 0
      : conditionGauges?.filter(x => x.attributes?.trend === 'Constant').length ?? 0;
  }, [conditionGauges, isForecast]);

  const fallingCountMemo = useMemo(() => {
    return isForecast
      ? conditionGauges?.filter(x => x.attributes?.forecastCondition < x.attributes?.condition).length ?? 0
      : conditionGauges?.filter(x => x.attributes?.trend === 'Falling').length ?? 0;
  }, [conditionGauges, isForecast]);

  return (
    <Container fluid className="d-flex px-3 py-3 align-items-center w-100" style={{ borderBottom: 'solid white 2px' }}>
      <div className="pe-2">
        <img src={image} className="rounded" alt={alt} />
      </div>
      <div className="d-flex-column flex-fill justify-content-center px-2">
        <div className="d-flex align-items-center gap-2">
          <img src={risingImage} alt={altRising} /> {isForecast ? 'To Rise:' : 'Rising:'}
        </div>
        <div>
          <div className="d-flex align-items-center gap-2">
            <img src={constantImage} alt={altConst} /> Constant:
          </div>
        </div>
        <div>
          <div className="d-flex align-items-center gap-2">
            <img src={fallingImage} alt={altFalling} /> {isForecast ? 'To Fall:' : 'Falling:'}
          </div>
        </div>
      </div>
      <div className="d-flex-column">
        <div className="d-flex justify-content-end" >
          {risingCountMemo}
        </div>
        <div className="d-flex justify-content-end">
          {constantCountMemo}
        </div>
        <div className="d-flex justify-content-end">
          {fallingCountMemo}
        </div>
      </div>
    </Container>

  )
};

OverviewCondition.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  risingImage: PropTypes.string.isRequired,
  altRising: PropTypes.string.isRequired,
  constantImage: PropTypes.string.isRequired,
  altConst: PropTypes.string.isRequired,
  fallingImage: PropTypes.string.isRequired,
  altFalling: PropTypes.string.isRequired,
  conditionGauges: PropTypes.array.isRequired,
  isForecast: PropTypes.bool
};

export default OverviewCondition;
