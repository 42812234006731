import React from 'react';
import Container from 'react-bootstrap/Container';

const NotFoundPage = () => {
  return (
    <Container fluid>
      <h1>Not Found</h1>
    </Container>
  );
};

export default NotFoundPage;