import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import useConfigData from 'src/lib/hooks/useConfigData';

const useBuildingDamages = (gaugeId, level) => {
  const { data: config } = useConfigData('Map');

  const { data, isLoading } = useQuery(['building-damages', gaugeId, level], ({ queryKey }) => {
    const [, gaugeId, level] = queryKey;
    
    return queryFeatures({
      url: config?.references.buildingDamages,
      where: `GageID='${gaugeId}' AND USER_FLAG='${level}'`,
      outFields: ['*'],
      orderByFields: ['ST_CST_100 DESC'],
      returnGeometry: false,
      returnDistinctValues: true
    });
  }, {
    enabled: gaugeId !== undefined && level !== undefined && level !== null && config !== undefined
  });
  
  //this conversion from description to occ type comes directly from sproc 
  // PRC_GET_FIMAN_DAMAGES_BY_GAGEID_SCENARIO
  const getOccupancyTypeByDescription = (description) => {
    if (!description.startsWith('RES') && !description.startsWith('GOV') && !description.startsWith('EDU')) {
      return 'Commercial';
    } else if (description.startsWith('RES')) {
      return 'Residential';
    } else if (description.startsWith('GOV') || description.startsWith('EDU')) {
      return 'Public';
    }
    // should never get here
    return 'Unknown'; 
  };

  const buildingDamages = useMemo(() => {
    return data?.features ? data.features.map(x => {
      return {
        buildingId: x.attributes.BLDG_ID,
        floodDepth: x.attributes.DEPTH,
        occupancyType: getOccupancyTypeByDescription(x.attributes.DESCRIPTION),
        gaugeId: x.attributes.GageID,
        estimatedDamage: x.attributes.ST_CST_100, 
        level: x.attributes.USER_FLAG,
        xCord: x.attributes.X_CORD,
        yCord: x.attributes.Y_CORD
      }
    }).filter(x => x.estimatedDamage > 0) : [];
  }, [data]);
  
  return { buildingDamages, isLoading };
};

export default useBuildingDamages;