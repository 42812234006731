import React from 'react';
import PropTypes from 'prop-types';


const SubscriptionsMessage = ({ children }) => {
  return (
    <div className="mt-2 bg-black bg-opacity-75 mt-2 mx-2 p-2 rounded text-center">
      {children}
    </div>
  );
}

SubscriptionsMessage.propTypes = {
  children: PropTypes.node.isRequired
};

export default SubscriptionsMessage;