import React from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryButton from 'src/components/PrimaryButton';
import useUserStore from 'src/stores/userStore';

const DuplicateUserModal = () => {
  const showDuplicateUserModal = useUserStore(state => state.showDuplicateUserModal);
  const handleDuplicateModalClose = useUserStore(state => state.handleDuplicateModalClose);
  const duplicateUserEmail = useUserStore(state => state.duplicateUserEmail);
  return (
    <Modal
      size="md"
      centered
      show={showDuplicateUserModal}
    >
      <Modal.Header>
        <Modal.Title>Duplicate User</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-md-1 pt-md-4">
        <div className="d-flex m-2 justify-content-center align-items-center">
          There is already an active account associated with the email:
        </div>
        <div className="d-flex m-2 justify-content-center align-items-center">
          <b>{duplicateUserEmail}</b>
        </div>
        <div className="d-flex m-2 mt-4 justify-content-center align-items-center">
          Please sign in with the original provider.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex w-100 justify-content-center gap-4">
          <PrimaryButton
            className="rounded-pill flex-grow-1"
            onClick={() => handleDuplicateModalClose()}
          >
            <span className="text-truncate">Okay</span>
          </PrimaryButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DuplicateUserModal; 