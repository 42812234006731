import React from 'react';
import DarkCard from '../../../../components/DarkCard';
import { Tabs, Tab } from 'react-bootstrap';
import useMapStore from '../../../../stores/mapStore';
import OverviewCondition from './OverviewCondition';
//monitor images 
import currentMonitor from '../../../../assets/images/gaugeOverviewImages/current_monitor_overview.png';
import forecastedMonitor from '../../../../assets/images/gaugeOverviewImages/forecasted_monitor.png';
import currentMonitorRising from '../../../../assets/images/gaugeOverviewImages/current_monitor_rising.png';
import currentMonitorConstant from '../../../../assets/images/gaugeOverviewImages/current_monitor_constant.png';
import currentMonitorFalling from '../../../../assets/images/gaugeOverviewImages/current_monitor_falling.png';
//normal images
import currentNormal from '../../../../assets/images/gaugeOverviewImages/current_normal_overview.png';
import forecastedNormal from '../../../../assets/images/gaugeOverviewImages/forecasted_normal.png';
import currentNormalRising from '../../../../assets/images/gaugeOverviewImages/current_normal_rising.png';
import currentNormalConstant from '../../../../assets/images/gaugeOverviewImages/current_normal_constant.png';
import currentNormalFalling from '../../../../assets/images/gaugeOverviewImages/current_normal_falling.png';
//minor flooding images
import currentMinor from '../../../../assets/images/gaugeOverviewImages/current_minor_overview.png';
import forecastedMinor from '../../../../assets/images/gaugeOverviewImages/forecasted_minor.png';
import currentMinorRising from '../../../../assets/images/gaugeOverviewImages/current_minor_rising.png';
import currentMinorConstant from '../../../../assets/images/gaugeOverviewImages/current_minor_constant.png';
import currentMinorFalling from '../../../../assets/images/gaugeOverviewImages/current_minor_falling.png';
//moderate flooding images
import currentModerate from '../../../../assets/images/gaugeOverviewImages/current_moderate_overview.png';
import forecastedModerate from '../../../../assets/images/gaugeOverviewImages/forecasted_moderate.png';
import currentModerateRising from '../../../../assets/images/gaugeOverviewImages/current_moderate_rising.png';
import currentModerateConstant from '../../../../assets/images/gaugeOverviewImages/current_moderate_constant.png';
import currentModerateFalling from '../../../../assets/images/gaugeOverviewImages/current_moderate_falling.png';
//major flooding images
import currentMajor from '../../../../assets/images/gaugeOverviewImages/current_major_overview.png';
import forecastedMajor from '../../../../assets/images/gaugeOverviewImages/forecasted_major.png';
import currentMajorRising from '../../../../assets/images/gaugeOverviewImages/current_major_rising.png';
import currentMajorConstant from '../../../../assets/images/gaugeOverviewImages/current_major_constant.png';
import currentMajorFalling from '../../../../assets/images/gaugeOverviewImages/current_major_falling.png';

/**
 * conditions are ints, and directly correlate to color/usage
 * in-serverice values 0, 1
 * condition values -1, 0, 1, 2, 3, 4
 * -1 = not in service
 * 0 = normal
 * 1 = monitor 
 * 2 = minor flooding 
 * 3 = moderate flooding
 * 4 = major flooding
 */
const GaugesOverview = () => {
  const currentGauges = useMapStore((state) => state.currentGauges);

  return (
    <DarkCard title="Gauge Overview" infoText="Number of gauges by flood stage and trend. Forecasted tab only includes gauges with known forecast information.">
      <Tabs defaultActiveKey="area" justify variant="find-gauges">
        <Tab eventKey="area" title="Current">
          <div className="d-flex-column black-background">
            <OverviewCondition
              image={currentMajor} alt="Major Current Gauge Condition"
              risingImage={currentMajorRising} altRising="Rising Current Gauge Condition"
              constantImage={currentMajorConstant} altConst="Constant Current Gauge Condition"
              fallingImage={currentMajorFalling} altFalling="Falling Current Gauge Condition"
              conditionGauges={currentGauges?.filter(x => x.attributes?.condition === 4)} />
            
            <OverviewCondition
              image={currentModerate} alt="Moderate Current Gauge Condition"
              risingImage={currentModerateRising} altRising="Rising Current Gauge Condition"
              constantImage={currentModerateConstant} altConst="Constant Current Gauge Condition"
              fallingImage={currentModerateFalling} altFalling="Falling Current Gauge Condition"
              conditionGauges={currentGauges?.filter(x => x.attributes?.condition === 3)} />
            
            <OverviewCondition
              image={currentMinor} alt="Minor Current Gauge Condition"
              risingImage={currentMinorRising} altRising="Rising Current Gauge Condition"
              constantImage={currentMinorConstant} altConst="Constant Current Gauge Condition"
              fallingImage={currentMinorFalling} altFalling="Falling Current Gauge Condition"
              conditionGauges={currentGauges?.filter(x => x.attributes?.condition === 2)} />

            <OverviewCondition
              image={currentMonitor} alt="Monitor Current Gauge Condition"
              risingImage={currentMonitorRising} altRising="Rising Current Gauge Condition"
              constantImage={currentMonitorConstant} altConst="Constant Current Gauge Condition"
              fallingImage={currentMonitorFalling} altFalling="Falling Current Gauge Condition"
              conditionGauges={currentGauges?.filter(x => x.attributes?.condition === 1)} />
            
            <OverviewCondition
              image={currentNormal} alt="Normal Current Gauge Condition"
              risingImage={currentNormalRising} altRising="Rising Current Gauge Condition"
              constantImage={currentNormalConstant} altConst="Constant Current Gauge Condition"
              fallingImage={currentNormalFalling} altFalling="Falling Current Gauge Condition"
              conditionGauges={currentGauges?.filter(x => x.attributes?.condition === 0)} />
          </div>
        </Tab>
        <Tab eventKey="location" title="Forecasted">
          <div className="d-flex-column black-background">
            <OverviewCondition
              image={forecastedMajor} alt="Major Current Gauge Condition"
              risingImage={currentMajorRising} altRising="Rising Current Gauge Condition"
              constantImage={currentMajorConstant} altConst="Constant Current Gauge Condition"
              fallingImage={currentMajorFalling} altFalling="Falling Current Gauge Condition"
              conditionGauges={currentGauges?.filter(x => x.attributes?.forecastCondition === 4)}
              isForecast={true} />

            <OverviewCondition
              image={forecastedModerate} alt="Moderate Current Gauge Condition"
              risingImage={currentModerateRising} altRising="Rising Current Gauge Condition"
              constantImage={currentModerateConstant} altConst="Constant Current Gauge Condition"
              fallingImage={currentModerateFalling} altFalling="Falling Current Gauge Condition"
              conditionGauges={currentGauges?.filter(x => x.attributes?.forecastCondition === 3)}
              isForecast={true} />

            <OverviewCondition
              image={forecastedMinor} alt="Minor Current Gauge Condition"
              risingImage={currentMinorRising} altRising="Rising Current Gauge Condition"
              constantImage={currentMinorConstant} altConst="Constant Current Gauge Condition"
              fallingImage={currentMinorFalling} altFalling="Falling Current Gauge Condition"
              conditionGauges={currentGauges?.filter(x => x.attributes?.forecastCondition === 2)}
              isForecast={true} />

            <OverviewCondition
              image={forecastedMonitor} alt="Monitor Current Gauge Condition"
              risingImage={currentMonitorRising} altRising="Rising Current Gauge Condition"
              constantImage={currentMonitorConstant} altConst="Constant Current Gauge Condition"
              fallingImage={currentMonitorFalling} altFalling="Falling Current Gauge Condition"
              conditionGauges={currentGauges?.filter(x => x.attributes?.forecastCondition === 1)}
              isForecast={true} />

            <OverviewCondition
              image={forecastedNormal} alt="Normal Current Gauge Condition"
              risingImage={currentNormalRising} altRising="Rising Current Gauge Condition"
              constantImage={currentNormalConstant} altConst="Constant Current Gauge Condition"
              fallingImage={currentNormalFalling} altFalling="Falling Current Gauge Condition"
              conditionGauges={currentGauges?.filter(x => x.attributes?.forecastCondition === 0)}
              isForecast={true} />
          </div>
        </Tab>
        
      </Tabs>
    </DarkCard>
  );
}

export default GaugesOverview;
