const { queryFeatures } = require('@esri/arcgis-rest-feature-service');

/**
  * Query all features from a feature service.
  * @param {Object} queryParameters - The query parameters to pass to the feature service.
  * @param {number} batchSize - The number of features to retrieve per request.
  * @param {number} offset - The offset to start retrieving features from.
  * @param {Object[]} allFeatures - The features retrieved so far.
  * @returns {Promise<Object[]>} A promise that resolves with all the features.
 */
export const queryAllFeatures = (queryParameters, batchSize, offset = 0, allFeatures = []) => {
  queryParameters.resultOffset = offset;
  queryParameters.resultRecordCount = batchSize;

  return queryFeatures(queryParameters)
    .then(response => {
      allFeatures = allFeatures.concat(response.features);
      if (response.exceededTransferLimit) {
        // if the number of features retrieved exceeds the limit, query the next batch
        return queryAllFeatures(queryParameters, batchSize, offset + batchSize, allFeatures);
      } else {
        // otherwise, resolve the promise with all the features
        return allFeatures;
      }
    })
    .catch(error => {
      console.error('Error querying features:', error);
      return [];
    });
}


