import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { confirm } from 'src/components/ConfirmationModal';

/**
 * A single image in the list of gauge images. Delegates to the parent component
 * for the actual deletion. Reduces the number of props passed from the parent.
 */
const GaugeImage = ({ image, onDelete }) => {
  const deleteImage = async () => {
    await confirm(
      'Are you sure you want to delete this photo?',
      'Delete Photo', 'Continue', 'Cancel',
      async () => await onDelete(image.id)
    );
  };

  return (
    <div
      className="border border-1 border-dark rounded d-flex flex-column justify-content-between text-center bg-dark p-3 photo-attribute"
      style={{ width: 175 }}
    >
      <div>
        <img
          {...image} width={125} height={125} />
        <div className="mt-2">{image.title}</div>
      </div>
      <Button
        variant="red-cancel"
        className="rounded-pill px-4 mt-3"
        onClick={async () => await deleteImage()}
      >
        Delete
      </Button>
    </div>
  );
};

GaugeImage.propTypes = {
  image: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default GaugeImage;