import React from 'react';
import LayersList from './LayersList';


const LayersContainer = () => {
  return (
    <div className="d-flex flex-column dark-gray-background">
      <LayersList />
    </div>
  );
}

export default LayersContainer;
