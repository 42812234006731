import React from 'react';
import SingleAccordion from '../../../../components/SingleAccordion';
import HistoricalTopPeakContent from './HistoricalTopPeakContent';


const HistoricalPeakWidgetSm = () => {
  return (
    <SingleAccordion
      header="Historical Peaks"
      eventKey="0"
      defaultEventKey={null}
    >
      <HistoricalTopPeakContent />
    </SingleAccordion>
  );
}


export default HistoricalPeakWidgetSm;