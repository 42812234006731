import React from 'react';
import GaugesOverview from './GaugesOverview';
import GaugesSearch from './GaugesSearch';

const OverviewContainer = () => {
  return (
    <div className="d-flex flex-column dark-gray-background">
      <GaugesSearch />
      <GaugesOverview />
    </div>
  );
}

export default OverviewContainer;
