import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'src/components/PrimaryButton';
import useMapStore from 'src/stores/mapStore';
import NotificationIconBadge from 'src/components/NotificationIconBadge';


const ToolsToggle = ({ action, className }) => {
  const [showBadge, setShowBadge] = useState(false);
  const activeGroupLayerId = useMapStore(state => state.activeGroupLayerId);
  const selectedGaugeAreaOfInterest = useMapStore((state) => state.selectedGaugeAreaOfInterest);
  const selectedGaugeAreaOfInterestSubCategory = useMapStore((state) => state.selectedGaugeAreaOfInterestSubCategory);
  const hasAreaOfInterestGraphic = useMapStore(state => state.hasAreaOfInterestGraphic);

  useEffect(() => {
    setShowBadge(false);

    // show badge if area of interest is active
    if (hasAreaOfInterestGraphic && selectedGaugeAreaOfInterestSubCategory !== null && selectedGaugeAreaOfInterest !== null) {
      setShowBadge(true);
    }

    // loop over each layer group type to see if it has an active layer
    Object.entries(activeGroupLayerId).forEach(([key, value]) => {
      // if the value does not start with "None" then show the badge.
      // each group of layers has a "None-<group.id>" that is the default layer
      // if the value is not "None-<group.id>" then a layer group has an active layer
      if(!value.startsWith('None')) {
        setShowBadge(true);
      }
    });
  }, [activeGroupLayerId, setShowBadge, selectedGaugeAreaOfInterestSubCategory, selectedGaugeAreaOfInterest, hasAreaOfInterestGraphic])

  return (
    <div className="position-relative">
      {showBadge && <NotificationIconBadge height={12} width={12} />}
      <PrimaryButton
        className={className}
        onClick={() => action()}
      >
        Menu
      </PrimaryButton>
    </div>
  );
}

ToolsToggle.propTypes = {
  className: PropTypes.string,
  action: PropTypes.func,
}


export default ToolsToggle;
