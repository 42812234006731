import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import WebTileLayer from '@arcgis/core/layers/WebTileLayer';
import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer.js';
import TileLayer from '@arcgis/core/layers/TileLayer';
import WMSLayer from '@arcgis/core/layers/WMSLayer';

import { LayerTypes } from '../constants/layerTypes';
import { gaugeRendererCim } from './gaugeRederers';
import { Layers } from '../constants/layers';
import { filtersToQuery } from '../lib/utils';
import GroupLayer from '@arcgis/core/layers/GroupLayer';
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import { BuildingColors } from 'src/constants/buildingColors';
import { roundToTenth } from 'src/lib/mathUtils';
import { getBldgOccupancyType } from './buildingUtils';

/**
 * Class to load map layers based on the config passed in.
 * @see https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-Layer.html
 */
export class LayerService {

  /**
   * Loads a layer based on the config passed in.
   * @param {Object} layer - The layer config.
   * @param {String} layer.type - The type (see `./constants`) of layer to load.
   * @returns {Object} The layer.
   */
  loadLayer(layer) {
    switch (layer.type) {
      case LayerTypes.FEATURE:
        return this.loadFeatureLayer(layer);
      case LayerTypes.WEB_TILE:
        return this.loadWebTileLayer(layer);
      case LayerTypes.VECTOR_TILE:
        return this.loadVectorTileLayer(layer);
      case LayerTypes.TILE:
        return this.loadTileLayer(layer);
      case LayerTypes.GEO_JSON:
        return this.loadGeoJsonLayer(layer);
      case LayerTypes.MAP_IMAGE:
        return this.loadMapImageLayer(layer);
      case LayerTypes.GROUP:
        return this.loadGroupLayer(layer);
      case LayerTypes.WMS:
        return this.loadWmsLayer(layer);
      default:
        return null;
    }
  }

  /**
   * Loads a feature layer based on the config passed in.
   * @param {Object} config - The layer config.
   * @returns {Object} The layer.
   */
  loadFeatureLayer(config) {
    const layer = new FeatureLayer({
      id: config.id,
      url: config.url,
      outFields: config.outFields ?? ['*'],
      visible: config.visible ?? true,
      opacity: config.opacity ?? 1,
      refreshInterval: config.refreshInterval ?? 0,
      maxScale: config.maxScale ?? 0,
      minScale: config.minScale ?? 0,
      legendEnabled: config.legendEnabled ?? false,
      labelingInfo: config.label ? [config.label] : [],
    });

    if (config.renderer) {
      layer.renderer = config.renderer;
    }

    if(config.popupTemplate){
      layer.popupTemplate = config.popupTemplate;
    }

    return layer;
  }

  /**
   * Loads a group layer based on the config passed in. Layers
   *  within a group will be returned and shown as a single layer
   * in the view.
   * @param {Object} config - The layer config.
   * @returns {Object} The layer.
   */
  loadGroupLayer(config) {
    const innerLayers = config?.layers?.map(x => {
      const layer = this.loadLayer(x);
      layer.showpopup = true
      switch (layer.id) {
        case Layers.NCDOT_ASSETS_BUILDINGS:
          layer.popupTemplate = {
            content: feature => {
              const attributes = feature.graphic.attributes;
              const div = document.createElement('div');
              let content = `
              <h2 style="padding-top: 0.5rem; padding-left: 0.5rem; font-size: 1.2em; font-weight: bold;">DOT Building</h2>
              <div style="padding-left: 0.5rem;"><strong>Facility Name</strong>: ${attributes.FacilityName ?? 'Fueling Canopy'}</div>`;
              div.innerHTML = content;
              return div;
            }
          };
          break;
        case Layers.NCDOT_ASSETS_LAND:
          layer.popupTemplate = {
            content: feature => {
              const attributes = feature.graphic.attributes;
              const div = document.createElement('div');
              let content = `
              <h2 style="padding-top: 0.5rem; padding-left: 0.5rem; font-size: 1.2em; font-weight: bold;">DOT Land</h2>
              <div style="padding-left: 0.5rem;"><strong>Name</strong>: ${attributes.Name}</div>`;
              div.innerHTML = content;
              return div;
            }
          };
          break;
      }
      return layer;
    });
    const groupLayer = new GroupLayer({
      id: config.id,
      visible: config.visible ?? true,
      layers: innerLayers
    });
    return groupLayer;
  }


  /**
   * Loads a web tile layer based on the config passed in.
   * @param {Object} config - The layer config.
   * @returns {Object} The layer.
   */
  loadWebTileLayer(config) {
    const layer = new WebTileLayer(config.url, {
      id: config.id,
      subDomains: config.subDomains,
      refreshInterval: config.refreshInterval ?? 0,
      opacity: config.opacity ?? 0,
      visible: config.visible ?? true,
      maxScale: config.maxScale ?? 0,
      minScale: config.minScale ?? 0,
    });

    return layer;
  }

  /**
   * Loads a vector tile layer based on the config passed in.
   * @param {Object} config - The layer config.
   * @returns {Object} The layer.
   */
  loadVectorTileLayer(config) {
    const layer = new VectorTileLayer({
      id: config.id,
      url: config.url,
      visible: config.visible ?? true,
      maxScale: config.maxScale ?? 0,
      minScale: config.minScale ?? 0,
      legendEnabled: config.legendEnabled ?? false,
    });

    return layer;
  }

  /**
   * Loads a tile layer based on the config passed in.
   * @param {Object} config - The layer config.
   * @returns {Object} The layer.
   */
  loadTileLayer(config) {
    const layer = new TileLayer({
      id: config.id,
      url: config.url,
      visible: config.visible ?? true,
      maxScale: config.maxScale ?? 0,
      minScale: config.minScale ?? 0,
      legendEnabled: config.legendEnabled ?? false,
    });

    return layer;
  }

  /**
 * Loads a WMS layer based on the config passed in.
 * @param {Object} config - The layer config.
 * @returns {Object} The layer.
 */
  loadWmsLayer(config) {
    const layer = new WMSLayer({
      id: config.id,
      url: config.url,
      visible: config.visible ?? true,
      maxScale: config.maxScale ?? 0,
      minScale: config.minScale ?? 0,
      sublayers: config.sublayers ?? [],
      legendEnabled: config.legendEnabled ?? false,
    });

    return layer;
  }

  /**
   * Loads a geo json layer based on the config passed in.
   * @param {Object} config - The layer config.
   * @returns {Object} The layer.
   */
  loadGeoJsonLayer(config) {
    const layer = new GeoJSONLayer({
      id: config.id,
      url: config.url,
      outFields: config.outFields ?? ['*'],
      objectIdField: config.objectIdField ?? null,
      refreshInterval: config.refreshInterval ?? 0,
      visible: config.visible ?? true,
      opacity: config.opacity ?? 1,
      maxScale: config.maxScale ?? 0,
      minScale: config.minScale ?? 0,
      legendEnabled: config.legendEnabled ?? false,
    });

    return layer;
  }

  /**
   * Loads a map image layer based on the config passed in.
   * @param {Object} config - The layer config.
   * @returns {Object} The layer.
   */
  loadMapImageLayer(config) {
    const layer = new MapImageLayer({
      id: config.id,
      title: config.title ?? undefined,
      url: config.url,
      refreshInterval: config.refreshInterval ?? 0,
      sublayers: config.sublayers ?? [],
      visible: config.visible ?? true,
      opacity: config.opacity ?? 1,
      legendEnabled: config.legendEnabled ?? false,
    });

    // only override the min/max scale if they are set
    if (config.maxScale) {
      layer.minScale = config.maxScale;
    }

    if (config.minScale) {
      layer.maxScale = config.minScale;
    }
    
    return layer;
  }

  /**
   * Loads a custom time enabled radar layer.
   * @param {Object} config - The layer config.
   * @returns {Array<Object>} The layers.
   */
  loadRadarLayers(config) {
    const layers = config.intervals.map((c) => {
      const layerConfig = {
        type: config.type,
        id: `${config.id}${c.interval}`,
        url: c.url,
        subDomains: config.subDomains,
        maxScale: config.maxScale,
        refreshInterval: config.refreshInterval,
        opacity: config.opacity,
        visible: config.visible,
        legendEnabled: config.legendEnabled ?? false,
      }
      return this.loadLayer(layerConfig);
    });

    return layers;
  }

  /**
   * 
   * @param {Loads the gauge layer using the default filters} config 
   * @param {Object} config - The layer config.
   * @returns {Array<Object>} The layers.
   */
  loadGaugeLayer(config) {
    const layer = this.loadLayer(config);
    layer.renderer = gaugeRendererCim;
    layer.definitionExpression = config.defaultFilters ? filtersToQuery(config.defaultFilters) : null;
    layer.showpopup = true;
    layer.orderBy = [{
      field: 'condition',
      order: 'descending'
    }];
    layer.popupTemplate = {
      title: '{name}',
      outFields: [
        'name',
        'siteId',
        'flowSensorId',
        'isScenario',
        'bankFull',
        'condition',
        'conditionTxt',
        'currentElevationMsl'
      ],
      overwriteActions: true,
      actions: config.popupActions ?? [],
      content: feature => {
        const {
          siteId,
          inService,
          isCoastal,
          isScenario,
          flowSensorId,
          condition,
          conditionTxt,
          hydroAllStage,
          currentElevationMsl
        } = feature.graphic.attributes;

        const div = document.createElement('div');
        let content = `<div><strong>Site ID: ${siteId}</strong></div></br>`;

        if (inService) {
          content += '<ul>'

          if (flowSensorId !== null) {
            content += '<li>Flow Gauge</li>';
          }

          if (isScenario !== 0) {
            content += '<li>Has Scenario</li>';
          }

          content += '</ul>';

          if (condition > 0) {
            content += `<div class="text-orange"><strong>The site is in "${conditionTxt}" and the `;

            if (isCoastal) {
              content += `water elevation is ${roundToTenth(currentElevationMsl)} ft NAVD 88</strong></div>`;
            } else {
              content += `stage is ${roundToTenth(hydroAllStage)} ft</strong></div>`;
            }
          }
        } else {
          content += '<div>Out of Service</div>';
        }

        div.innerHTML = content;

        return div;
      }
    };

    layer.labelingInfo = [{
      symbol: {
        type: 'text',
        color: '#127187',
        haloColor: 'white',
        haloSize: 1.2,
        yoffset: 15,
        xoffset: 15,
        font: {
          size: 11,
          weight: 'bold',
          family: 'Arial'
        }
      },
      labelPlacement: 'above-right',
      labelExpressionInfo: {
        expression: "'Rainfall - last 24 hours: ' + Round($feature.rain24hr * 100) / 100 + ' in'"
      },
      maxScale: 0,
      minScale: 577790.554289,
      where: 'rainCondition = 1'
    }];

    return layer;
  }

  /**
   * Load the reference layers.
   * @param {Object} config - The layer config.
   * @returns {Array<Object>} The layers.
   */
  loadReferenceLayers(config) {
    const groups = config.groups.map((g) => {
      return g.layers.map((l) => {
        return this.loadLayer(l);
      });
    });

    const layers = config.layers.map((l) => {
      return this.loadLayer(l);
    });

    return [...groups.flat(), ...layers];
  }

  /**
   * Load the inundation layer.
   */
  loadInundationLayer(config) {
    return this.loadLayer(config);
  }

  /**
   * Load the buildings layer with estimated flood level.
   */
  loadFloodBuildingsLayer(config) {
    const layer = this.loadLayer(config);
    layer.opacity = 0.8;
    layer.showpopup = true;
    layer.popupTemplate = {
      title: 'Building {NC_FIMAN.DBO.S_BUILDING_FP.BLDG_ID}',
      content: feature => {
        const attributes = feature.graphic.attributes;
        const div = document.createElement('div');
        const USDollar = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumSignificantDigits: 3
        });
        const floodDepth = roundToTenth(attributes['NC_FIMAN.DBO.L_DAMAGE_RESULTS_FL.DEPTH']);
        const floodDepthInfo = floodDepth < 0
          ? `${floodDepth} ft - Negative values indicate the water level is below the building first floor elevation.`
          : `${floodDepth} ft`;
        const lat = attributes['NC_FIMAN.DBO.S_BUILDING_FP.Y_CORD'];
        const lng = attributes['NC_FIMAN.DBO.S_BUILDING_FP.X_CORD'];
        const location = `https://www.google.com/maps/?q=${lat},${lng}`;
        const content =
          `<div class="mb-1"><strong>Building Type</strong>: ${getBldgOccupancyType(attributes['NC_FIMAN.DBO.S_BUILDING_FP.OCCUP_TYPE'])}</div>
         <div class="mb-1"><strong> Flood Depth:</strong> ${floodDepthInfo}</div>
         <div class="mb-1"><strong> Est. Structure Damages: </strong>${USDollar.format(attributes['NC_FIMAN.DBO.L_DAMAGE_RESULTS_FL.ST_CST_100'])}</div>
         <div><a href="${location}" style="color:#FFC336" target="_blank">View on Google Maps</a></div>
         `;
        div.innerHTML = content;
        return div;
      }
    };

    layer.renderer = new ClassBreaksRenderer({
      field: 'NC_FIMAN.DBO.L_DAMAGE_RESULTS_FL.DEPTH',
      defaultSymbol: new SimpleFillSymbol({
        color: BuildingColors.BRIGHT_YELLOW
      }),
      defaultLabel: '< 0',
      classBreakInfos: [
        {
          minValue: 0,
          maxValue: 1,
          symbol: new SimpleFillSymbol({
            color: BuildingColors.YELLOW
          }),
          label: '0 - 1'
        },
        {
          minValue: 1,
          maxValue: 2,
          symbol: new SimpleFillSymbol({
            color: BuildingColors.ORANGE
          }),
          label: '1 - 2'
        },
        {
          minValue: 2,
          maxValue: 3,
          symbol: new SimpleFillSymbol({
            color: BuildingColors.RED
          }),
          label: '2 - 3'
        },
        {
          minValue: 3,
          maxValue: 4,
          symbol: new SimpleFillSymbol({
            color: BuildingColors.FUCHSIA
          }),
          label: '3 - 4'
        },
        {
          minValue: 4,
          maxValue: 5,
          symbol: new SimpleFillSymbol({
            color: BuildingColors.MEDIUM_PURPLE
          }),
          label: '4 - 5'
        },
        {
          minValue: 5,
          maxValue: Infinity,
          symbol: new SimpleFillSymbol({
            color: BuildingColors.DARK_PURPLE
          }),
          label: '> 5'
        }
      ]
    });

    return layer;
  }

  /**
   * Load the road layer.
   */
  loadRoadLayer(roadLayerConfig) {
    const layer = this.loadLayer(roadLayerConfig);
    layer.showpopup = true;
    layer.popupTemplate = {
      title: '{StreetName}',
      outFields: ['*'],
      overwriteActions: false,
      content: feature => {
        const attributes = feature.graphic.attributes;
        const lat = feature.graphic.geometry.extent.center.latitude;
        const lng = feature.graphic.geometry.extent.center.longitude;

        const location = `https://www.google.com/maps/?q=${lat},${lng}`;

        const div = document.createElement('div');
        const content = `<div><strong>Max Depth</strong>: ${attributes.Max_Depth} ft</div>
        <div class="mt-2"><a href="${location}" style="color:#FFC336;" target="_blank">View on Google Maps</a></div>`;
        div.innerHTML = content;

        return div;
      }
    };

    return layer;
  }

  /**
   * Load the bridge layer.
   * uses the default layer renderer
   */
  loadBridgeLayer(bridgeLayerConfig) {
    const layer = this.loadLayer(bridgeLayerConfig);

    layer.showpopup = true;
    layer.popupTemplate = {
      title: 'Bridge No. {Bridge_Number}',
      outFields: ['*'],
      overwriteActions: true,
      actions: [],
      content: feature => {
        const attributes = feature.graphic.attributes;
        const div = document.createElement('div');
        let content = `<div><strong>Road Name</strong>: ${attributes.F_CARRIED}</div>`;
        if (attributes.FREEBOARD !== null) {
          content += `<div><strong>Freeboard:</strong> ${attributes.FREEBOARD.toFixed(1)} ft</div></br>`;
        }

        div.innerHTML = content;

        return div;
      }
    };
    return layer;
  }


  /**
   * Load the default bridge layer.
   * uses the default layer renderer
   */
  loadDefaultBridgeLayer(bridgeLayerConfig) {
    const layer = this.loadLayer(bridgeLayerConfig);

    layer.showpopup = true;
    layer.popupTemplate = {
      title: 'Bridge No. {Bridge_Number}',
      outFields: ['*'],
      overwriteActions: true,
      actions: [],
      content: feature => {
        const attributes = feature.graphic.attributes;
        const div = document.createElement('div');
        let content = `<div><strong>Road Name</strong>: ${attributes.F_CARRIED}</div>`;
        content += `<div><strong>Freeboard:</strong> No Flooding</div></br>`;

        div.innerHTML = content;

        return div;
      }
    };
    return layer;
  }

  /** 
   * Loads an empty graphics layer to add graphics to througout the lifetime of the map 
  */
  loadEmptyGraphicLayer() {
    const layer = new GraphicsLayer({
      id: Layers.GRAPHICS,
      graphics: [],
      visible: true
    });
    return layer;
  }
}