import React from 'react';
import { Container } from 'react-bootstrap';

const AuthorizationError = () => {

  return (
    <Container fluid>
      <h1>You do not have the correct application permissions to access this page. Please navigate back to the home screen.</h1>
    </Container>);
};

export default AuthorizationError;