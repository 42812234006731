import useUserStore from 'src/stores/userStore';
import { CognitoGroups } from 'src/constants/cognitoGroups';
const useUserGroup = () => {
  const cognitoUser = useUserStore(state => state.cognitoUser);
  const groups = cognitoUser?.signInUserSession?.idToken?.payload['cognito:groups'] ?? [];
  
  const isAdmin = groups?.includes(CognitoGroups.ADMIN) ?? false; 
  const isSubscriber = groups?.includes(CognitoGroups.SUBSCRIBER) ?? false;
  const isAdvanced = groups?.includes(CognitoGroups.ADVANCED) ?? false;

  return { isAdmin, isSubscriber, isAdvanced };
}; 
export default useUserGroup; 