import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { confirm } from 'src/components/ConfirmationModal';
import InfoLink from 'src/components/InfoLink';
import PrimaryButton from 'src/components/PrimaryButton';
import useAlertStore from 'src/stores/alertStore';


const SubscriptionActions = () => {
  const [updating, setUpdating] = useState(false);
  const unsubscribeAll = useAlertStore(state => state.unsubscribeAll);

  const handleUnsubscribeAll = async () => {
    setUpdating(true);

    if (await confirm(
      `You are unsubscribing from all alerts. Continue?`,
      'Unsubscribe',
      'Continue',
      'Cancel',
      async () => unsubscribeAll()
    )) {
      toast.success(
        'You have unsubscribed from all alerts.'
      );
    }

    setUpdating(false);
  }

  
  return (
    <div
      className="sticky-top d-flex align-items-center justify-content-end ju px-3 py-2 gap-2"
      style={{ backgroundColor: 'rgba(0,0,0,1)' }}>
      <PrimaryButton
        disabled={updating}
        className="rounded-pill py-0"
        onClick={handleUnsubscribeAll}
      >
        Unsubscribe All
      </PrimaryButton>
      <InfoLink title="" placement="bottom" className="mb-1">
        These are not official alerts declared by any North Carolina government agency and do not 
        require any individual action. These alerts are meant to communicate the momentary water level 
        data reported at a gauge.
      </InfoLink>
    </div>
  );
}

export default SubscriptionActions;