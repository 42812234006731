import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { confirmable, createConfirmation } from 'react-confirm';

const Confirmation = ({
  proceedLabel = 'OK',
  cancelLabel = 'Cancel',
  title = 'Confirmation',
  confirmation,
  show,
  proceed,
  enableEscape = false,
  onProceed
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  return (
    <div className="static-modal">
      <Modal
        animation={true}
        show={show}
        onHide={() => proceed(false)}
        backdrop={enableEscape ? true : 'static'}
        keyboard={enableEscape}
        size="md"
        centered
        scrollable={true}
      >
        <Modal.Header className="p-1">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmation}</Modal.Body>
        <Modal.Footer className="d-flex gap-1">
          <Button
            className="rounded-pill flex-grow-1"
            variant="red-cancel"
            onClick={() => proceed(false)}>{cancelLabel}</Button>
          <Button
            className="btn-green-accept rounded-pill flex-grow-1"
            disabled={isProcessing}
            onClick={async () => {
              if (onProceed) {
                setIsProcessing(true);
                await onProceed();
                setIsProcessing(false);
              }
              proceed(true);
            }}
          >
            {isProcessing && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>}
            {proceedLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Confirmation.propTypes = {
  proceedLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func,
  enableEscape: PropTypes.bool,
  onProceed: PropTypes.func
};

export function confirm(
  confirmation,
  title = 'Confirmation',
  proceedLabel = 'OK',
  cancelLabel = 'Cancel',
  onProceed = null,
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    title,
    proceedLabel,
    cancelLabel,
    onProceed,
    ...options
  });
}