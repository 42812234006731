import React, { Fragment, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { roundToTenth } from 'src/lib/mathUtils';


/**
 * A scale for a range slider. Note that the slider is not included in this component.
 * This is a separate component so that the scale can be reused for different sliders.
 * Not intended to be u
 * @param {string} props.className - the class name
 * @param {Object} props.style - the style object
 * @param {string} props.textPosition - the position of the text (top or bottom)
 * @param {string} props.size - the size of the text (sm or lg)
 * @param {number} props.min - the minimum value
 * @param {number} props.max - the maximum value
 * @param {number} props.offset - the offset to apply to the values
 * @param {number} props.smallStep - the small step value
 * @param {number} props.largeStep - the large step value
  */
const RangeScale = ({
  className,
  style,
  textPosition = 'bottom',
  size = 'lg',
  min,
  max,
  offset = 0,
  smallStep,
  largeStep,
}) => {
  const numTicks = Math.floor((max - min) / smallStep) + 1;

  const tickValues = useMemo(() => {
    return Array.from({ length: numTicks }, (_, index) => min + index * smallStep);
  }, [numTicks, min, smallStep]);

  const getPosition = (tick, index) => {
    if (index === 0) return 'start';
    if (index === numTicks - 1 && tick.scaled > 98) return 'end';
    return 'middle';
  }

  const getScaledValues = useCallback((ticks) => {
    const scaled = ticks.map((tick) => {
      return {
        scaled: (tick - min) / (max - min) * 100,
        value: tick, // not adjusted for offset since we need the original value for % operator
      }
    });

    return scaled;
  }, [min, max]);

  const scaledValues = useMemo(() => getScaledValues(tickValues), [tickValues, getScaledValues]);

  const top = (tick, index) => {
    return (
      <Fragment key={`${index}-top`}>
        {roundToTenth(tick.value-min) % largeStep === 0 &&
          <text 
            style={{ stroke: 'white', fontSize: size === 'sm' ? 11 : 13, fontWeight: 100 }}
            x={`${tick.scaled}%`}
            y="25" textAnchor={getPosition(tick, index)}>
            {roundToTenth(tick.value - offset)}
          </text>
        }
      </Fragment>
    )
  }

  const bottom = (tick, index) => {
    return (
      <Fragment key={`${index}-bottom`}>
        <rect
          style={{ stroke: 'white' }}
          x={`${tick.scaled}%`}
          y="0" width="0.5"
          height="7.5">
        </rect>
        {roundToTenth(tick.value-min) % largeStep === 0 &&
          <text
            style={{ stroke: 'white', fontSize: size === 'sm' ? 11 : 13, fontWeight: 100 }}
            x={`${tick.scaled}%`} y="23"
            textAnchor={getPosition(tick, index)}
          >
            {roundToTenth(tick.value - offset)}
          </text>
        }
      </Fragment>
    )
  }

  return (
    <div className={`${className}`} style={style}>
      <svg
        role="presentation"
        width="100%"
        height="35"
        xmlns="http://www.w3.org/2000/svg"
      >
        {textPosition === 'top' ?
          scaledValues.map((tick, index) => {
            return top(tick, index);
          }
          ) : scaledValues.map((tick, index) => {
            return bottom(tick, index);
          }
          )}
      </svg>
    </div>
  )
};

RangeScale.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  textPosition: PropTypes.oneOf(['top', 'bottom']),
  size: PropTypes.oneOf(['sm', 'lg']),
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  offset: PropTypes.number,
  smallStep: PropTypes.number.isRequired,
  largeStep: PropTypes.number.isRequired,
};

export const MemoizedRangeScale = memo(RangeScale);
