import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Map from '../components/Map';
import ActionBar from '../features/map/bars/ActionBar';
import ActionBarMd from '../features/map/bars/ActionBarMd';
import ActionBarSm from '../features/map/bars/ActionBarSm';
import ToolsSidebar from '../features/map/tools/ToolsSidebar';
import ToolsModal from '../features/map/tools/ToolsModal';
import WelcomeModal from '../features/map/welcome/WelcomeModal';
import SignUpModal from 'src/features/map/auth/SignUpModal';
import RadarTimestamp from '../features/map/weather/RadarTimestamp';
import SubscriptionsSidebar from '../features/map/alerts/SubscriptionsSidebar';
import useMapStore from '../stores/mapStore';
import GaugeDrawer from '../features/map/gauges/GaugeDrawer';
import GaugeModal from '../features/map/gauges/GaugeModal';
import useCustomMediaQuery from '../lib/hooks/useCustomMediaQuery';
import useConfigData from '../lib/hooks/useConfigData';
import styles from '../assets/styles/Gauge.module.scss';
import '@arcgis/core/assets/esri/themes/dark/main.css';
import SignInModal from 'src/features/map/auth/SignInModal';
import useUserGroup from 'src/lib/hooks/useUserGroup';
import { Layers } from 'src/constants/layers';
import { gaugeRendererCim, gaugeRendererCimNwm } from 'src/lib/gaugeRederers';

const MapPage = () => {
  const { isMobile, isTablet, isDesktop } = useCustomMediaQuery();
  const activeGauge = useMapStore((state) => state.activeGauge);
  const showToolsSidebar = useMapStore((state) => state.showToolsSidebar);
  const setActiveGauge = useMapStore((state) => state.setActiveGauge);
  const mapManager = useMapStore(state => state.mapManager);
  const toggleWelcomeModal = useMapStore(state => state.toggleWelcomeModal);
  const showGroupLayer = useMapStore(state => state.showGroupLayer);
  const ncdotAssetsLayerIsReady = useMapStore(state => state.ncdotAssetsLayerIsReady);
  const { isAdmin, isAdvanced } = useUserGroup();


  // get the config data
  const { isLoading, data: config } = useConfigData('Map');
  useEffect(() => {
    // set sessionStorage to false so the welcome modal will show on page load
    if (!window.sessionStorage.getItem('hasVisitedMapPage')) {
      window.sessionStorage.setItem('hasVisitedMapPage', true);
    } else {
      toggleWelcomeModal(false);
    }
  }, [toggleWelcomeModal]);

  useEffect(() => {
    return () => {
      // hide the selected gauge when the user navigates away from the map page
      setActiveGauge(null);
    };
  }, [setActiveGauge]);

  useEffect(() => {
    // toggle active map layers based on role
    // if the user is not admin or advanced, then hide specific layers;
    if (ncdotAssetsLayerIsReady) {
      if (isAdmin || isAdvanced) {
        mapManager?.setLayerVisibility(Layers.NCDOT_ASSET_GROUP, true);
        mapManager?.setLayerVisibility(Layers.LAYER_GROUP_NCDOT_ASSETS, true);
      } else {
        mapManager?.setLayerVisibility(Layers.DEFAULT_BRIDGES, false);
        mapManager?.setLayerVisibility(Layers.BRIDGES, false);
        mapManager?.setLayerVisibility(Layers.ROADS, false);
        mapManager?.setLayerVisibility(Layers.NCDOT_ASSET_GROUP, false);
      }
    }

  }, [isAdmin, isAdvanced, mapManager, showGroupLayer, ncdotAssetsLayerIsReady]);

  useEffect(() => {
    // set the gauge layer renderer based on role
    if (mapManager?.gaugesLayer) {
      const renderer = isAdmin || isAdvanced ? gaugeRendererCimNwm : gaugeRendererCim;
      mapManager.setGaugeLayerRenderer(renderer);
    }
  }, [mapManager, mapManager?.gaugesLayer, isAdmin, isAdvanced]);

  return (
    <>
      {/* Show a spinner while the config data is loading */}
      {isLoading && !config ? (
        <div className="align-items-center d-flex justify-content-center mapContainer w-100">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <>
          {isMobile && <ActionBarSm />}
          {isTablet && <ActionBarMd />}
          {isDesktop && <ActionBar />}
          <div className="mapContainer d-flex">
            <SubscriptionsSidebar
              placement="end"
              backdrop={false}
              className="sidebar"
            />
            {isMobile ? <ToolsModal /> : <ToolsSidebar />}

            <Map>
              {activeGauge && isDesktop && (
                <GaugeDrawer
                  className={`${showToolsSidebar
                    ? styles.gaugeDrawerToolsOpen
                    : styles.gaugeDrawerToolsClosed
                    } ${styles.transform}`}
                />
              )}
              {activeGauge && (isMobile || isTablet) && <GaugeModal />}
            </Map>
          </div>
          <RadarTimestamp />
          <WelcomeModal />
          <SignUpModal />
          <SignInModal />
        </>
      )}
    </>
  );
};

export default MapPage;
