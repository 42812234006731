import React, { useState } from 'react';
import Widget from './Widget';
import FlowContent from './FlowContent';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import LoadingDisplay from 'src/components/LoadingDisplay';
import useMapStore from 'src/stores/mapStore';
import HydrographModal from './HydrographModal';

const FlowWidget = () => {
  const gauge = useMapStore(state => state.activeGauge);
  const [showHydrographModal, setShowHydrographModal] = useState(false);
  const { data: gaugeDetails, isLoading } = useGaugeDetails(gauge?.attributes?.siteId);


  if (isLoading) {
    return <LoadingDisplay />;
  }
  const handleActionClick = () => {
    setShowHydrographModal(!showHydrographModal);
  }

  return (
    <Widget
      title="Flow"
      onActionClick={handleActionClick}
    >
      <FlowContent />
      {gaugeDetails &&
        <HydrographModal
          show={showHydrographModal}
          handleClose={() => setShowHydrographModal(false)}
          isFlow={true}
        />
      }
    </Widget>
  );
}

export default FlowWidget;