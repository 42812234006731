import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { GaugeMarkerColors } from 'src/constants/gaugeMarkerColors';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';
import { convertElevationToStage } from 'src/lib/stageConversionUtils';
import { roundToTenth } from 'src/lib/mathUtils';

const StageLegendModal = ({ gaugeDetails, show, handleClose }) => {

  const { isDesktop } = useCustomMediaQuery();

  const { isCoastal, minor, major, moderate, bankFull, gageDatum } = gaugeDetails;

  // adjust minor,major,moderate,bankfull values to be the correct stage value instead of elevation
  // this reverts the values back to the original stage values AFTER the backend has already converted them to elevation
  // calcuations rely on gageDatum 

  let adjustedNormal = !isCoastal ? convertElevationToStage(bankFull, gageDatum) : bankFull;
  let adjustedMinor = !isCoastal ? convertElevationToStage(minor, gageDatum) : minor;
  let adjustedModerate = !isCoastal ? convertElevationToStage(moderate, gageDatum) : moderate;
  let adjustedMajor = !isCoastal ? convertElevationToStage(major, gageDatum) : major;

  const legendDetails = [
    { color: GaugeMarkerColors.PURPLE, label: 'Major Flooding', value: `${roundToTenth(adjustedMajor)} ft` },
    { color: GaugeMarkerColors.RED, label: 'Moderate Flooding', value: `${roundToTenth(adjustedModerate)} ft` },
    { color: GaugeMarkerColors.ORANGE, label: 'Minor Flooding', value: `${roundToTenth(adjustedMinor)} ft` },
    { color: GaugeMarkerColors.YELLOW, label: 'Monitor', value: `${roundToTenth(adjustedNormal)} ft` },
    { color: GaugeMarkerColors.GREEN, label: 'Normal', value: `<${roundToTenth(adjustedNormal)} ft` }
  ]
  return (
    <Modal
      className={!isDesktop ? 'mobile-modal' : ''}
      size="md"
      centered
      show={show}
      onHide={handleClose}
      animation={true}
      scrollable={true}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{gaugeDetails.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-md-5 pt-md-4">
        <div className="d-flex justify-content-between" style={{ borderBottom: 'solid white 2px' }}>
          <div>CONDITION</div>
          <div>{gaugeDetails.isCoastal ? 'ELEVATION' : 'STAGE'}</div>
        </div>
        {legendDetails.map((detail, index) => {
          return (
            <div key={index} className="d-flex justify-content-between py-2" style={{ borderBottom: 'solid white 1px' }}>
              <div className="d-flex align-items-center">
                <div className="rounded-circle" style={{ width: '15px', height: '15px', backgroundColor: detail.color }}></div>
                <div className="px-3">{detail.label}</div>
              </div>
              <div>{detail.value}</div>
            </div>
          )
        })}
      </Modal.Body>
    </Modal>
  )
}

StageLegendModal.propTypes = {
  gaugeDetails: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default StageLegendModal;