import React from 'react'; 
import { Modal } from 'react-bootstrap';
import useMapStore from '../../../stores/mapStore';
import GaugeContentSm from './GaugeContentSm';


const GaugeModal = () => {
  const gauge = useMapStore(state => state.activeGauge);
  const showGaugeData = useMapStore(state => state.showGaugeData);
  const toggleGauge = useMapStore(state => state.toggleGauge);
  return (
    <Modal
      className="mobile-modal"
      show={showGaugeData}
      onHide={() => toggleGauge(false)}
      animation={true}
      backdrop="static"
      scrollable={true}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          { gauge?.attributes?.name }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <GaugeContentSm /> 
      </Modal.Body>
    </Modal>
  )

}
export default GaugeModal;