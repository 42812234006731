import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SearchResults } from '../../../constants/search';
import SuggestionGroup from './SuggestionGroup';


const SuggestionMenu = ({ suggestions, onSelect, topOffest='35px' }) => {
  const places = useMemo(() => {
    return suggestions.filter((s) => s.type === SearchResults.PLACE);
  }, [suggestions]);

  const gauges = useMemo(() => {
    return suggestions.filter((s) => s.type === SearchResults.GAUGE);
  }, [suggestions]);

  return (
    <ul
      className="dropdown-menu dropdown-menu-dark start-0 end-0 overflow-auto position-absolute rounded shadow-lg show"
      style={{ maxHeight: '450px', zIndex: 1500, top: topOffest }}
    >
      <SuggestionGroup group={gauges} title="Gauges" onSelect={onSelect} />
      <SuggestionGroup group={places} title="Places" onSelect={onSelect} />
    </ul>
  );
};

SuggestionMenu.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  onSelect: PropTypes.func.isRequired,
  topOffest: PropTypes.string,
};

export default SuggestionMenu;

