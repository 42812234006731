import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Gridget from './Gridget';
import useMapStore from 'src/stores/mapStore';
import useBuildingDamageTotals from 'src/lib/hooks/useBuildingDamageTotals';
import useBuildingDamages from 'src/lib/hooks/useBuildingDamages';
import useBuildingImpactSummary from 'src/lib/hooks/useBuildingImpactSummary';
import InfoLink from 'src/components/InfoLink';
import NavItem from 'src/features/map/gauges/NavItem';
import LoadingDisplay from 'src/components/LoadingDisplay';
import styles from 'src/assets/styles/Gauge.module.scss';
import PaginationFooter from 'src/components/PaginationFooter';
import SelectFilter from 'src/features/map/filters/SelectFilter';
import FilterCollapse from 'src/features/map/filters/FilterCollapse';
import { roundToTenth } from 'src/lib/mathUtils';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  getSortedRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getFacetedUniqueValues,
} from '@tanstack/react-table'
import { Table, Card, Button } from 'react-bootstrap';
import { XLg, CaretDownFill, CaretUpFill, Search, FunnelFill } from 'react-bootstrap-icons';
import CenteredCell from './CenteredCell';
import PrimaryButton from 'src/components/PrimaryButton';
import { exportToExcel } from 'src/lib/dataExport';
import { convertElevationToStage } from 'src/lib/stageConversionUtils';
import { formatDate } from 'src/lib/dateUtils';
import { toFilenameSafe } from 'src/lib/stringUtils';


const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0
});

const columnHelper = createColumnHelper();

const buildingDamageColumns = [
  columnHelper.accessor('select', {
    id: 'select',
    header: () => '',
    enableColumnFilter: false,
    cell: () => (
      <CenteredCell><Search /></CenteredCell>
    ),
    size: 20,
    maxSize: 20
  }),
  columnHelper.accessor('estimatedDamage', {
    header: () => 'Estimated Damages',
    cell: info => <span className="d-flex px-4 justify-content-end">{USDollar.format(info.getValue())}</span>,
    enableSorting: true,
    enableColumnFilter: false,
    size: 20,
    maxSize: 20,
  }),
  columnHelper.accessor('floodDepth', {
    header: () => 'Flood Depth',
    cell: info => roundToTenth(info.renderValue()),
    size: 50,
    maxSize: 50,
    enableSorting: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('occupancyType', {
    header: () => 'Occupancy Type',
    cell: info => info.renderValue(),
    size: 50,
    maxSize: 50,
  }),
  columnHelper.accessor('buildingId', {
    header: () => 'Building ID',
    cell: info => info.renderValue(),
    enableColumnFilter: false,
    size: 50,
    maxSize: 50,
  }),
];

const columns = [
  {
    id: 'depthColumn',
    footer: x => x.column.id,
    columns: [
      {
        id: 'depth',
        header: 'Current Flood Depth',
        accessorKey: 'depth',
        cell: info => info.getValue(),
        footer: x => x.column.id,
      }
    ],
  },
  {
    header: <CenteredCell>Total</CenteredCell>,
    id: 'total',
    footer: x => x.column.id,
    columns: [
      {
        accessorKey: 'countTotal',
        cell: info => <CenteredCell>{info.getValue()}</CenteredCell>,
        header: () => <CenteredCell>Count</CenteredCell>,
        footer: x => x.column.id,
      },
      {
        id: 'damagesTotal',
        accessorKey: 'damagesTotal',
        cell: info => <span className="d-flex px-4 justify-content-end">{USDollar.format(info.getValue())}</span>,
        header: () => <CenteredCell>Est. Damages</CenteredCell>,
        footer: x => x.column.id
      },
    ],
  },
  {
    header: <CenteredCell>Residential</CenteredCell>,
    id: 'residential',
    footer: x => x.column.id,
    columns: [
      {
        accessorKey: 'countResidential',
        cell: info => <CenteredCell>{info.getValue()}</CenteredCell>,
        header: () => <CenteredCell>Count</CenteredCell>,
        footer: x => x.column.id,
      },
      {
        accessorKey: 'damagesResidential',
        cell: info => <span className="d-flex px-4 justify-content-end">{USDollar.format(info.getValue())}</span>,
        header: () => <CenteredCell>Est. Damages</CenteredCell>,
        footer: x => x.column.id,
      },
    ],
  },
  {
    header: <span className="d-flex justify-content-center">Commercial</span>,
    id: 'commercial',
    footer: x => x.column.id,
    columns: [
      {
        accessorKey: 'countNonResidential',
        cell: info => <CenteredCell>{info.getValue()}</CenteredCell>,
        header: () => <CenteredCell>Count</CenteredCell>,
        footer: x => x.column.id,
      },
      {
        accessorKey: 'damagesNonResidential',
        cell: info => <span className="d-flex px-4 justify-content-end">{USDollar.format(info.getValue())}</span>,
        header: () => <CenteredCell>Est. Damages</CenteredCell>,
        footer: x => x.column.id,
      },
    ],
  },
  {
    header: <span className="d-flex justify-content-center">Public</span>,
    id: 'public',
    footer: x => x.column.id,
    columns: [
      {
        accessorKey: 'countPublic',
        cell: info => <CenteredCell>{info.getValue()}</CenteredCell>,
        header: () => <CenteredCell>Count</CenteredCell>,
        footer: x => x.column.id,
      },
      {
        accessorKey: 'damagesPublic',
        cell: info => <span className="d-flex px-4 justify-content-end">{USDollar.format(info.getValue())}</span>,
        header: () => <CenteredCell>Est. Damages</CenteredCell>,
        footer: x => x.column.id,
      },
    ],
  },
];


const BuildingsImpactedGridget = ({ level, toggleGridget }) => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const mapManager = useMapStore(state => state.mapManager);
  const { damageTotals, isLoading: isLoadingTotals } = useBuildingDamageTotals(activeGauge?.attributes?.siteId, level);
  const finalIndex = damageTotals?.length - 1;
  const { buildingDamages, isLoading: isLoadingDamages } = useBuildingDamages(activeGauge?.attributes?.siteId, level);
  const { impactSummary, isLoading: isLoadingImpactSummary } = useBuildingImpactSummary(activeGauge?.attributes.siteId, level);
  const isLoading = isLoadingTotals || isLoadingDamages || isLoadingImpactSummary;
  const displayNoDamageAssessed = !isLoading && (impactSummary === null || impactSummary?.BLDG_DAMAGED === 0);

  const [activeTab, setActiveTab] = useState('damages');
  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);

  const [canViewFilters, setCanViewFilters] = useState(true);

  const damageTotalsOptions = {
    data: damageTotals,
    columns,
    getCoreRowModel: getCoreRowModel(),
  };

  const buildingDamageOptions = {
    data: buildingDamages,
    columns: buildingDamageColumns,
    state: { sorting, rowSelection, columnFilters },

    enableRowSelection: true,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnFiltersChange: setColumnFilters,
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
  };

  const damageTotalsTable = useReactTable(damageTotalsOptions);
  const buildingDamagesTable = useReactTable(buildingDamageOptions);

  const selectedRows = buildingDamagesTable.getFilteredSelectedRowModel().flatRows;

  useEffect(() => {
    mapManager.clearFloodBuildingHighlight();

    return () => {
      // clear the highlights when the component unmounts
      mapManager.clearFloodBuildingHighlight();
    }
  }, [mapManager, buildingDamages]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      const row = selectedRows[0];
      const target = [row.original.xCord, row.original.yCord];
      mapManager.zoomToFeatureByGeometry(target, 19);
      mapManager.highlightFloodBuildingFeature(row.original.gaugeId, row.original.buildingId, level);
    }
  }, [selectedRows, mapManager, level]);

  const getHeaderStyle = (header) => {
    if (header?.column?.id === 'total') {
      return 'border-left border-right';
    }
    if (header.id === 'depth' || header.id === 'damagesTotal') {
      return 'border-right';
    }
    return '';
  }

  const getDamagesTableDisplay = () => {
    if (isLoading) {
      return (<LoadingDisplay />);
    }
    else if (displayNoDamageAssessed) {
      return (
        <div className="d-flex flex-fill align-items-center justify-content-center h-100">
          No building impacts reported at this flood stage.
        </div>
      );
    }
    else {
      return (
        <Table size="sm" variant="dark" striped bordered hover>
          <thead>
            {damageTotalsTable.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} colSpan={header.colSpan} className={getHeaderStyle(header)}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {damageTotalsTable.getRowModel().rows.map((row, index) => (
              <tr key={row.id} className={index === finalIndex ? 'border-top' : ''}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} className={cell.column.id === 'depth' || cell.column.id === 'damagesTotal' ? 'border-right' : ''}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )
    }
  }

  const getBuildingsTableDisplay = () => {
    if (isLoading) {
      return (<LoadingDisplay />);
    }
    else if (displayNoDamageAssessed) {
      return (
        <div className="d-flex flex-fill align-items-center justify-content-center h-100">
          No building impacts reported at this flood stage.
        </div>
      );
    }
    else {
      return (
        <>
          <Table size="sm" variant="dark" striped bordered hover>
            <thead>
              {buildingDamagesTable.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id} style={{ width: header.getSize(), minWidth: header.getSize(), maxWidth: header.getSize() }}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <span className="ms-1"><CaretUpFill /></span>,
                            desc: <span className="ms-1"><CaretDownFill /></span>,
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {buildingDamagesTable.getRowModel().rows.map(row => (
                <tr key={row.id} className={row.getIsSelected() ? 'table-light' : ''}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} onClick={() => setRowSelection({ [row.id]: true })}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginationFooter
            table={buildingDamagesTable}
            totalRecordCount={buildingDamagesTable.getFilteredRowModel().rows.length}
            pageSize={5}
          />
        </>
      )
    }
  }

  const handleExport = () => {
    const dataMap = [
      {
        columnName: 'Estimated Damages',
        dataKey: 'estimatedDamage',
        converter: (value) => USDollar.format(value),
      },
      {
        columnName: 'Flood Depth',
        dataKey: 'floodDepth',
        converter: (value) => roundToTenth(value),
      },
      {
        columnName: 'Occupancy Type',
        dataKey: 'occupancyType',
      },
      {
        columnName: 'Building ID',
        dataKey: 'buildingId',
        format : '0',
      },
    ];

    const { name, siteId, isCoastal, gageDatum } = activeGauge?.attributes || {};

    const coastalString = isCoastal ? '' : `and a stage of ${roundToTenth(convertElevationToStage(level, gageDatum))} ft.`;

    const subHeaders = [
      `${name} (${siteId})`,
      `This report was generated using an elevation of ${level} ft. ${coastalString}`
    ];

    const safeName = toFilenameSafe(name).toLowerCase();
    const filename = `${safeName}_buildings-impacted_${formatDate(Date.now(), 'MMddyyy')}.xlsx`;

    exportToExcel(
      'FIMAN Inundation Export',
      subHeaders,
      dataMap,
      buildingDamagesTable.getFilteredRowModel().rows.map(row => row.original),
      filename,
      'Buildings'
    );
  }
  return (
    <Gridget >
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <Card.Title className="m-0">
            <div className="d-flex">
              <span>Buildings Impacted</span>
              <InfoLink
                className="px-2"
                placement="right"
                title="Building Valuation"
              >
                <ul>
                  <li>
                    This table reflects counts of buildings inside the FIMAN inundation extent where structural damages may occur. 
                    Additional buildings may be shown (color coded) in the map indicating buildings inside the inundation extent that may 
                    not sustain structural damages due to elevated first floors or site-specific conditions.</li>
                  <li>
                    Additional buildings may be impacted outside of the inundation extent.
                  </li>
                </ul>
              </InfoLink>
            </div>
          </Card.Title>
          <div className="d-flex m-1">
            <NavItem tabId="damages" tabName="Damages" className={`px-4 ${activeTab === 'damages' ? styles.activeTabItem : styles.tabItem}`} setActiveTab={setActiveTab} />
            <NavItem tabId="buildings" tabName="Buildings" className={`px-4 ${activeTab === 'buildings' ? styles.activeTabItem : styles.tabItem}`} setActiveTab={setActiveTab} />
          </div>
          <div className="d-flex m-1">
            {activeTab === 'buildings' && (
              <Button
                variant="link"
                className="d-flex align-items-center px-1 text-white pr-2"
                onClick={() => setCanViewFilters(!canViewFilters)}
              >
                <FunnelFill />
              </Button>
            )}
            <Button
              variant="link"
              className="d-flex align-items-center px-1 text-white pr-2"
              onClick={() => toggleGridget()}
            >
              <XLg />
            </Button>
          </div>
        </div>
      </Card.Header>
      {activeTab === 'damages' && (
        <Card.Body className="d-flex flex-column justify-content-between">
          {getDamagesTableDisplay()}
        </Card.Body>
      )}
      {activeTab === 'buildings' && (
        <Card.Body className="d-flex flex-column">
          <FilterCollapse isOpen={canViewFilters}>
            <div className="d-flex justify-content-between p-2">
              <PrimaryButton
                className="rounded-pill btn-sm m-1"
                onClick={() => handleExport()}
              >
                Export
              </PrimaryButton>
              <SelectFilter
                className="d-flex align-items-center"
                label="Occupancy Type"
                column={buildingDamagesTable.getColumn('occupancyType')}
                table={buildingDamagesTable}
              />
            </div>
          </FilterCollapse>
          {getBuildingsTableDisplay()}
        </Card.Body>
      )}
    </Gridget>
  )

};

BuildingsImpactedGridget.propTypes = {
  level: PropTypes.number,
  toggleGridget: PropTypes.func.isRequired,
};
export default BuildingsImpactedGridget;