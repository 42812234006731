import React from 'react';
import { Collapse } from 'react-bootstrap';
import ToolsContent from './ToolsContent';
import useMapStore from '../../../stores/mapStore';
import ToolsSidebarHeader from './ToolsSidebarHeader';


const ToolsSidebar = () => {
  const showToolsSidebar = useMapStore(state => state.showToolsSidebar);

  return (
    <>
      <Collapse in={showToolsSidebar} dimension="width" className="layer-sidebar">
        <div> {/* This div is needed to make the collapse work */}
          <div
            className="h-100 d-flex flex-column toolSideBar border-end border-dark dark-gray-background"
          >
            <ToolsSidebarHeader />
            <ToolsContent />
          </div>
        </div>
      </Collapse>
    </>
  );
};



export default ToolsSidebar;
