import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const SelectFilter = ({ column, table, nameFormatter = null, label, className }) => {
  const [value, setValue] = useState(column.getFilterValue());

  const sortedUniqueValues = useMemo(
    () => Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column]
  );
  const selectFilter = column.getFilterValue();

  useEffect(() => {
    setValue(selectFilter ?? 'resetFilters');
  }, [selectFilter]);

  const updateColumntFilters = (value) => {
    setValue(value);
    if (value === 'resetFilters') {
      table.resetColumnFilters(true);
    }
    else {
      column.setFilterValue(value);
    }
  }

  return (
    <div className={className ?? ''}>
      <Form.Label className="m-0 me-2 text-nowrap">{label}</Form.Label>
      <Form.Select value={value} size="sm" onChange={e => updateColumntFilters(e.target.value)}>
        <option value="resetFilters">All</option>
        {sortedUniqueValues.map((value) => (
          <option value={value} key={value}>
            {nameFormatter ? nameFormatter(value) : value}
          </option>
        ))}
      </Form.Select>
    </div>
  )
}

SelectFilter.propTypes = {
  column: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
  nameFormatter: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default SelectFilter;