import { getAttachments, queryFeatures } from '@esri/arcgis-rest-feature-service';
import { useEffect, useState } from 'react';
import useConfigData from 'src/lib/hooks/useConfigData';

const useGaugeImages = (siteId) => {
  const [gaugeImages, setGaugeImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { data: config } = useConfigData('Map');

  useEffect(() => {
    /* Function to fetch and create attachments based on site id.
       If features exist, object id is used to extract attachment
       data to create image objects.
    */
    const createAttachments = async (siteId) => {
      setIsLoading(true);
      const features = await queryFeatures({
        url: config?.arcgisRestUrl,
        where: `SITE_ID = '${siteId}'`,
        outFields: ['OBJECTID'],
        returnGeometry: false
      });

      if (features.features.length === 0) {
        setIsLoading(false);
        return [];
      }

      const objectId = features.features[0].attributes.OBJECTID;
      
      const attachments = await getAttachments({
        url: config?.arcgisRestUrl,
        featureId: objectId
      });

      if (attachments.attachmentInfos.length === 0) {
        setIsLoading(false);
        return [];
      }

      // Image objects made during onload event
      const images = attachments.attachmentInfos.map(async (attachment) => {
        return new Promise((resolve) => {
          const image = new Image();
          image.src = `${config?.arcgisRestUrl}/${objectId}/attachments/${attachment.id}`;
          image.onload = () => {
            resolve({
              id: attachment.id,
              title: attachment.name,
              src: image.src,
              width: image.width,
              height: image.height
            });
          };
        });
      });

      Promise.all(images).then((loadedImages) => {
        setGaugeImages(loadedImages);
        setIsLoading(false);
      });
    };

    createAttachments(siteId);
  }, [config?.arcgisRestUrl, siteId]);

  return [gaugeImages, isLoading];
};

export default useGaugeImages;