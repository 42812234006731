import { useEffect } from 'react';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useLibraryLevels from 'src/lib/hooks/useLibraryLevels';
import useMapStore from 'src/stores/mapStore';
import useUserGroup from 'src/lib/hooks/useUserGroup';


const CurrentInundation = () => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const showInundationLayer = useMapStore(state => state.showInundationLayer);
  const mapManager = useMapStore(state => state.mapManager);
  const { data: gauge } = useGaugeDetails(activeGauge?.attributes?.siteId);
  const { levels, isLoading: librariesLoading } = useLibraryLevels(activeGauge?.attributes?.siteId);
  const { isAdmin, isAdvanced } = useUserGroup();

  useEffect(() => {
    // show the current gauge inundation level, will "hide" if
    // the height is below the lowest level in the library
    if (librariesLoading) {
      return;
    }

    if (!gauge || !gauge.inService || !gauge.currentElevationMsl) {
      return;
    }

    // get the current inundation height snapped to the nearest level
    // do not snap to the lowest inundation layer if the height is below the lowest 
    // level, indicated by the false flag
    const showLayers = async () => {
      const level = await showInundationLayer(gauge.siteId, gauge.isScenario, gauge.currentElevationMsl, levels, false);
      const minLevel = Math.min(...levels);

      // if the current level is below the lowest level, then show the default bridge layer
      // but only if the user is admin or advanced
      if (isAdmin || isAdvanced) {
        if (level < minLevel) {
          mapManager.hideBridgeLayer();
          mapManager.showDefaultBridgeLayer(gauge.siteId);
        } else {
          mapManager.hideDefaultBridgeLayer();
          mapManager.showBridgeLayer(gauge.siteId, level, gauge.isScenario);
        }
        mapManager.showRoadLayer(gauge.siteId, level, gauge.isScenario);

      }
      mapManager.showFloodBuildingsLayer(gauge.siteId, level, gauge.isScenario);
    };

    showLayers();

  }, [gauge, mapManager, librariesLoading, levels, isAdmin, isAdvanced]);

  return null;
}

export default CurrentInundation;