import React from 'react';
import PropTypes from 'prop-types';


const InfoCard = ({ title1, title2, children }) => {
  return (
    <div>
      <h4 className="border-2 border-bottom border-primary pb-3 text-uppercase">
        {title1} <span className="text-primary">{title2}</span>
      </h4>
      {children}
    </div>
  )
}

InfoCard.propTypes = {
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default InfoCard;