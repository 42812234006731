import axios from 'axios';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';

// a place to intercept errors and add toast notifications
axios.interceptors.response.use(async response => {
  return response;
}, error => {
  const { data, status } = error.response;

  switch (status) {
    case 400:
      console.error('400', data);
      // custom handler for when we return error messages we want to display
      if (data.errorMessage) {
        toast.error(data.errorMessage);
      } else {
        toast.error('An error occurred. If this persists, please refresh the page and try again.');
      }
      break;
    case 401:
      console.error('Unauthorized');
      break;
    case 403:
      console.error('Forbidden');
      break;
    case 404:
      console.error('Not found');
      break;
    case 500:
      console.error('500', data);
      toast.error('An error occurred. If this persists, please refresh the page and try again.');
      break;
    default:
      console.error('Unknown error', data);
      toast.error('An error occurred. If this persists, please refresh the page and try again.');
      break;
  }
  return Promise.reject(error);
});

const responseBody = response => response.data;

const requests = {
  get: (url, headers = {}) => axios.get(url, headers).then(responseBody),
  getRaw: (url, headers = {}) => axios.get(url, headers),
  post: (url, body = {}, headers = {}) => axios.post(url, body, headers).then(responseBody),
  postRaw: (url, body = {}, headers = {}) => axios.post(url, body, headers),
  put: (url, body = {}, headers) => axios.put(url, body, headers).then(responseBody),
  delete: (url, headers = {}) => axios.delete(url, headers).then(responseBody),
};

const config = {
  list: () => requests.get('/api/config'),
  get: (id) => requests.get(`/api/config/${id}`,),
};

const gauge = {
  get: (siteId) => requests.get(`/api/gauges/${siteId}`),
  list: async () => requests.get('/api/gauges/getAll', await getAuthHeader()),
  update: async (gauge) => requests.post(`/api/gauges/update`, gauge, await getAuthHeader()),
};

const alert = {
  // all scoped to the current user
  list: async () => requests.get('/api/alerts', await getAuthHeader()),
  listBySite: async (siteId) => requests.get(`/api/alerts/${siteId}`, await getAuthHeader()),
  subscriptions: async () => requests.get('/api/alerts/subscriptions', await getAuthHeader()),
  subscribe: async (site) => requests.post(`/api/alerts/subscribeSite`, site, await getAuthHeader()),
  unsubscribe: async (site) => requests.post(`/api/alerts/unsubscribeSite`, site, await getAuthHeader()),
  unsubscribeAll: async () => requests.post(`/api/alerts/unsubscribeAll`, {}, await getAuthHeader()),
  createSubscription: async (subscription) => requests.post(`/api/alerts/`, subscription, await getAuthHeader()),
  deleteSubscription: async (subscriptionId) => requests.delete(`/api/alerts/${subscriptionId}`, await getAuthHeader()),
  updateSubscription: async (subscription) => requests.put(`/api/alerts/`, subscription, await getAuthHeader()),

  // admin only
  unsubscribeUserAll: async (user) => requests.post(`/api/alerts/unsubscribeUserAll`, user, await getAuthHeader()),
};

const user = {
  list: async () => requests.get('/api/user/getAllUsers', await getAuthHeader()),
  validate: async (user) => requests.post('/api/user/validate', user, await getAuthHeader()),
  update: async (user) => requests.post('/api/user/updateProfile', user, await getAuthHeader()),
  adminUpdate: async (user) => requests.post('/api/user/adminUpdateUser', user, await getAuthHeader()),
  search: async (useSearchModel) => requests.post('/api/user/searchUsers', useSearchModel, await getAuthHeader()),
  delete: async () => requests.delete(`/api/user/deleteUser`, await getAuthHeader()),
  adminDelete: async (username) => requests.delete(`/api/user/adminDeleteUser/${username}`, await getAuthHeader()),
  duplicateUserCheck: async (email) => requests.get(`/api/user/duplicateUserCheck/${email}`, await getAuthHeader()),
  duplicatePhoneCheck: async (phone, username) => requests.get(`/api/user/duplicatePhoneCheck/${phone}/${username}`, await getAuthHeader()),
  toggleRecieveEmails: async (user) => requests.post('/api/user/toggleRecieveEmails', user, await getAuthHeader()),
  smsOptIn: async (user) => requests.post('/api/user/smsOptIn', user, await getAuthHeader()),
  emailOptOut: async (encryptedUsername) => requests.post(`/api/user/emailOptOut?token=${encryptedUsername}`, {}),
};

const report = {
  create: async (formData, useAuth = false) => {
    if (useAuth) {
      return requests.postRaw('/api/reports/create',
        formData,
        {
          ...await getAuthHeader({
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/octet-stream',
          }),
          responseType: 'arraybuffer'
        }
      );
    } else {
      return requests.postRaw('/api/reports/create',
        formData, 
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/octet-stream',
          },
          responseType: 'arraybuffer'
        }
      );
    }
  },
};

const getAuthHeader = async (other = {}) => {
  return {
    headers: {
      'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      ...other
    }
  };
};

// helper function to create KMZ endpoint URL
const createKMZUrl = (siteId, elevation, check = false) => {
  const elevationWhole = Math.floor(elevation);
  const elevationDecimal = Math.floor((elevation - elevationWhole) * 10);
  return `/api/kmz/${check ? 'check/' : ''}${siteId}?elevation=${elevationWhole}.${elevationDecimal}`;
};

const kmz = {
  download: async (siteId, elevation) => {
    const url = createKMZUrl(siteId, elevation);
    return requests.getRaw(url, {
      ...await getAuthHeader({
        'Content-Type': 'application/json',
        'Accept': 'application/octet-stream',
      }),
      responseType: 'arraybuffer'
    });
  },
  checkExists: async (siteId, elevation) => {
    const url = createKMZUrl(siteId, elevation, true);
    try {
      const response = await requests.get(url, await getAuthHeader());
      return response.message === 'File exists.'; // { message: "File exists." } for a 200 response
    } catch (error) {
      console.error('Error during KMZ existence check:', error);
      throw error;
    }
  }
};

const agent = {
  config,
  gauge,
  user,
  alert,
  report,
  kmz
};

export default agent;