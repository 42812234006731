import { React, useEffect, useRef } from 'react';
import useMapStore from 'src/stores/mapStore';
import PropTypes from 'prop-types';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';
import useConfigData from 'src/lib/hooks/useConfigData';


/**
 * Primary map component.  This component is responsible for creating the map
 * and leverages the mapStore to store the map instance.  ArcGIS JS
 * requires a native DOM element to create the map, so this component
 * uses a ref to store the DOM element and then passes it to the mapStore.
 * @see https://developers.arcgis.com/javascript/latest/api-reference/esri-Map.html
 * @component
 */
const Map = ({ children }) => {
  const { isMobile } = useCustomMediaQuery();
  const setMobile = useMapStore((state) => state.setMobile);
  const setContainer = useMapStore((state) => state.setContainer);
  const mapRef = useRef(null);

  const { data: config } = useConfigData('Map');

  useEffect(() => {
    if (config && mapRef.current) {
      setContainer(config, mapRef.current);
    }
  }, [setContainer, config]);

  useEffect(() => {
    setMobile(isMobile);
  }, [isMobile, setMobile]);


  return (
    <div className="w-100 h-100 position-relative" ref={mapRef}>
      {children}
    </div>
  );
};

Map.propTypes = {
  children: PropTypes.node
}

export default Map;