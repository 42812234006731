import React from 'react';
import { Form } from 'react-bootstrap';

const CheckboxColumnDef = ({
  size = 20,
  maxSize = 20
}) => {
  return {
    id: 'select',
    header: '',
    cell: ({ row }) => (
      <Form.Check
        className="d-flex justify-content-center"
        type="checkbox"
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={() => {}} // this is needed to prevent a warning, checkbox is readonly
      />
    ),
    size: size,
    maxSize: maxSize
  };
};

export default CheckboxColumnDef;

