import React from 'react'
import PropTypes from 'prop-types';

const ImpactHouseIcon = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 55 55" version="1.1">
      <title>AF907BCB-9769-4991-937D-970EF3F2D91AB</title>
      <defs>
        <polygon id="path-3" points="0 0 54 0 54 7.42084775 0 7.42084775"/>
        <polygon id="path-3" points="0 0 54 0 54 7.42084775 0 7.42084775"/>
      </defs>
      <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-584.000000, -198.000000)" id="Impact">
          <g transform="translate(584.000000, 198.000000)">
            <polygon id="Fill-1" fill="#FEFEFE" points="27 0 5 21.6391153 7.31759567 24 27 4.65192084 46.6801165 24 49 21.6391153"/>
            <g id="Group-10" transform="translate(0.000000, 8.000000)">
              <g id="Group-4" transform="translate(0.000000, 28.032996)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-3"/>
                </mask>
                <g id="Clip-3"/>
                <path d="M8.83521855,7.42084775 C5.14905368,7.42084775 0.515877848,2.56893382 -0.00046308604,2.015625 L2.01164576,0.120891003 C3.65097034,1.87148573 6.95045837,4.65197881 8.83521855,4.65197881 C10.1202823,4.65197881 11.72256,3.53838668 13.1372879,2.55730969 C14.7117804,1.46696583 16.1959712,0.437067474 17.7287859,0.437067474 C19.2917013,0.437067474 20.9402876,1.47626514 22.686122,2.57358348 C24.0452795,3.42911981 25.5850406,4.40089749 26.4973201,4.40089749 C27.363291,4.40089749 28.8914749,3.52211289 30.2390553,2.7479455 C32.1608624,1.64365268 34.1498169,0.499837803 36.0785703,0.499837803 C37.9540687,0.499837803 39.4822527,1.52043685 40.9618126,2.50383867 C42.2792923,3.38262327 43.5226784,4.21026168 44.8471044,4.21026168 C47.1602192,4.21026168 50.9552093,1.09266869 52.1036627,0 L54,2.01097535 C53.4674511,2.51778763 48.6698797,6.98145545 44.8471044,6.98145545 C42.6914389,6.98145545 40.9618126,5.82834126 39.4359441,4.81239187 C38.2458129,4.01962587 37.1228293,3.27103157 36.0785703,3.27103157 C34.8838083,3.27103157 33.1426048,4.27070718 31.6074746,5.15181661 C29.801439,6.18868945 28.0972824,7.16976644 26.4973201,7.16976644 C24.790848,7.16976644 22.9755508,6.02595156 21.2204547,4.91933391 C20.0071693,4.15679066 18.4998242,3.20826125 17.7287859,3.20826125 C17.0549958,3.20826125 15.8069789,4.07309689 14.7048341,4.83796497 C12.9589997,6.04919983 10.9793069,7.42084775 8.83521855,7.42084775" id="Fill-2" fill="#FEFEFE" mask="url(#mask-2)"/>
              </g>
              <path d="M13.138214,26.0636354 C14.7103911,24.9732915 16.1968973,23.9433932 17.7297121,23.9433932 C19.2926275,23.9433932 20.9412138,24.980266 22.6847327,26.0799092 C22.7240951,26.1031574 22.7657728,26.1287305 22.8051351,26.1543036 L22.8051351,18.8520221 L31.1962541,18.8520221 L31.1962541,25.7079369 C32.8170553,24.8035792 34.467957,24.0061635 36.0794964,24.0061635 C37.9549949,24.0061635 39.4831788,25.0244377 40.9627387,26.0101644 C41.5879049,26.4286332 42.196863,26.8331531 42.8081366,27.1446799 L42.8081366,15.4322016 L27.4383109,0 L27.0006946,0 L26.5630783,0 L11.1932527,15.4322016 L11.1932527,27.3353157 C11.8485194,26.9563689 12.5130479,26.498378 13.138214,26.0636354" id="Fill-5" fill="#FEFEFE"/>
              <g id="Group-9" transform="translate(0.000000, 35.579152)">
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3"/>
                </mask>
                <g id="Clip-8"/>
                <path d="M8.83521855,7.42084775 C5.14905368,7.42084775 0.515877848,2.56893382 -0.00046308604,2.015625 L2.01396119,0.120891003 L1.00674905,1.06942042 L2.01164576,0.120891003 C3.65097034,1.87148573 6.95045837,4.65197881 8.83521855,4.65197881 C10.1202823,4.65197881 11.72256,3.53838668 13.1372879,2.55730969 C14.7117804,1.46696583 16.1959712,0.437067474 17.7287859,0.437067474 C19.2917013,0.437067474 20.9402876,1.47626514 22.686122,2.57358348 C24.0452795,3.42911981 25.5850406,4.40089749 26.4973201,4.40089749 C27.363291,4.40089749 28.8914749,3.52211289 30.2390553,2.7479455 C32.1608624,1.64365268 34.1498169,0.499837803 36.0785703,0.499837803 C37.9540687,0.499837803 39.4822527,1.52043685 40.9618126,2.50616349 C42.2792923,3.38262327 43.5226784,4.21026168 44.8471044,4.21026168 C46.9032064,4.21026168 50.3902443,1.62737889 52.1036627,0 L54,2.01097535 C53.4674511,2.51778763 48.6698797,6.98145545 44.8471044,6.98145545 C42.6914389,6.98145545 40.9618126,5.82834126 39.4359441,4.81239187 C38.2458129,4.01962587 37.1228293,3.27103157 36.0785703,3.27103157 C34.8838083,3.27103157 33.1426048,4.27070718 31.6074746,5.15181661 C29.801439,6.18868945 28.0972824,7.16976644 26.4973201,7.16976644 C24.790848,7.16976644 22.9755508,6.02595156 21.2204547,4.91933391 C20.0071693,4.15679066 18.4998242,3.20826125 17.7287859,3.20826125 C17.0549958,3.20826125 15.8069789,4.07309689 14.7048341,4.83796497 C12.9589997,6.04919983 10.9793069,7.42084775 8.83521855,7.42084775" id="Fill-7" fill="#FEFEFE" mask="url(#mask-4)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

ImpactHouseIcon.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired
};

export default ImpactHouseIcon;