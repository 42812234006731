import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import useUserStore from 'src/stores/userStore';
import PrimaryButton from 'src/components/PrimaryButton';
import { Form, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import UserForm from './UserForm';
import agent from 'src/lib/agent';
import { Auth } from 'aws-amplify';
import { confirm } from 'src/components/ConfirmationModal';
import InfoLink from 'src/components/InfoLink';
import styles from '../../../assets/styles/WeatherToggle.module.scss';

const UserProfileModal = () => {
  const showEditUserModal = useUserStore(state => state.showEditUserModal);
  const toggleEditUserModal = useUserStore(state => state.toggleEditUserModal);
  const editUser = useUserStore(state => state.editUser);
  const clone = _.cloneDeep(editUser);
  const setEditUser = useUserStore(state => state.setEditUser);
  const setUser = useUserStore(state => state.setUser);
  const duplicateUserCheck = useUserStore(state => state.duplicateUserCheck);
  const setCognitoUser = useUserStore(state => state.setCognitoUser);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const deleteUser = useUserStore(state => state.deleteUser);

  const { handleSubmit, control, reset, formState: { errors } } = useForm({ defaultValues: editUser, shouldUnregister: true })

  const handleCancelEdit = () => {
    setEditUser(null);
    setIsEditing(false);
    reset({
      values: clone
    });
    toggleEditUserModal(false);
  }

  const toggleRecieveEmails = async () => {
    setIsSubmitting(true);
    try {
      let updatedUser = { ...editUser, recieveEmails: !editUser.recieveEmails };
      await agent.user.toggleRecieveEmails(updatedUser);
      setEditUser(updatedUser);
      let toastMessage = updatedUser.recieveEmails
        ? 'You have successfully turned on emails. You will now receive email Alerts from gauges you have subscribed to.'
        : 'You have successfully turned off emails. You will no longer receive any email notifications from FIMAN.';

      toast.success(toastMessage);
    }
    catch (error) {
      toast.error('Failed to update email opt in status!');
    }
    finally {
      setIsSubmitting(false);
    }
  }

  const smsOptIn = async () => {
    try {
      await agent.user.smsOptIn(editUser);
      toast.success('SMS Opt in was a success.');
    }
    catch (error) {
      toast.error('Failed to update phone opt in status!');
    }
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      if (await duplicateUserCheck(data.phoneNumber, data.username)) {
        toast.error('Phone number already exists for another account. Please enter a different phone number.');
        return;
      }
      const userResponse = await agent.user.update(
        {
          ...data,
          phoneNumber: data.phoneNumber === '' ? null : data.phoneNumber,
          role: editUser.role,
          username: editUser.username,
          cognitoUserId: editUser.cognitoUserId,
          groups: editUser.groups
        });
      toast.success('Successfully updated user profile!')
      setUser(userResponse);
      const user = await Auth.currentAuthenticatedUser();
      setCognitoUser(user);
      // store user's new information in the state so user profile modal shows the latest details
      setEditUser(userResponse);
      reset({
        values: _.cloneDeep(userResponse)
      });
      setIsEditing(false);
    } catch (error) {
      toast.error('Failed to update user profile!');
    }
    finally {
      setIsSubmitting(false);
    }
  }

  const handleDeleteUser = async () => {
    if (await confirm(
      `Are you sure you want to delete your FIMAN profile? This will unsubscribe you from all Alerts.`,
      'Delete',
      'Continue',
      'Cancel',
      async () => deleteUser()
    )) {
      toast.success(`You have deleted the user "${editUser.name}".`);
    }
  }

  return (
    <Modal
      centered
      show={showEditUserModal}
      onHide={() => handleCancelEdit()}
      animation={true}
      backdrop="static"
      scrollable={true}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          My Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-md-5 pt-md-4">
        <div className="d-flex flex-column dark-gray-background mt-3">
          {isEditing && <UserForm user={editUser} control={control} errors={errors} isEditing={isEditing} />}
          {!isEditing &&
            <>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">Name:</Form.Label>
                <Col sm="8">
                  <Form.Control className="text-white" disabled={true} plaintext={true} readOnly={true} value={editUser?.name ?? ''} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">Email:</Form.Label>
                <Col sm="8">
                  <Form.Control className="text-white" disabled={true} plaintext={true} readOnly={true} value={editUser?.email ?? ''} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">Email Alerts:</Form.Label>
                <Col sm="8" className="d-flex gap-3">
                  <div className="form-check form-switch ">
                    <input
                      className={`form-check-input ${styles.weatherToggle}`}
                      type="checkbox"
                      role="switch"
                      checked={editUser?.recieveEmails ?? false}
                      disabled={isSubmitting}
                      onChange={() => toggleRecieveEmails()}
                    />
                  </div>
                  <InfoLink title="" placement="top" className="mt-1">
                    Toggle Email Alerts On/Off to manage email notifications for your Alert Subscriptions. You can opt out any time from here or from the link in an email alert.
                  </InfoLink>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">Phone Number:</Form.Label>
                <Col sm="8">
                  <Form.Control className="text-white" disabled={true} plaintext={true} readOnly={true} value={editUser?.phoneNumber ?? 'N/A'} />
                </Col>
              </Form.Group>
              {editUser?.phoneNumber &&
                <>
                  <Form.Group as={Row} className="">
                    <Form.Label column sm="4">Text Alerts:</Form.Label>
                    <Col sm="8" className="d-flex gap-3">

                      {editUser?.phoneOptInStatus === 'Confirmed' &&
                        <p className="mt-1 p-1">Confirmed</p>
                      }
                      {editUser?.phoneOptInStatus === 'OptedOut' &&
                        <p className="mt-1 p-1">Opted Out</p>
                      }
                      <InfoLink title="" placement="top" className="mt-2">
                        {editUser?.phoneOptInStatus !== 'Confirmed' &&
                          <span>You must opt in to receive text alerts. </span>
                        }
                        <span>You can opt in and out again twice in a 30 day period.</span>
                      </InfoLink>
                      {(editUser?.phoneOptInStatus === null || editUser?.phoneOptInStatus === 'None') && (
                        <Button className="p-1" variant="link" onClick={() => smsOptIn()}>Phone Opt In</Button>
                      )}
                    </Col>
                  </Form.Group>
                  {editUser?.phoneOptInStatus === 'OptedOut' &&
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="4"></Form.Label>
                      <Col sm="8" className="dflex gap-3">
                        <Button className="p-1" variant="link" onClick={() => smsOptIn()}>Phone Opt In</Button>
                      </Col>
                    </Form.Group>
                  }
                </>
              }
            </>
          }
        </div>
      </Modal.Body>

      <Modal.Footer>
        {isEditing &&
          <div className="d-flex w-100 justify-content-center gap-4">
            <Button
              variant="red-cancel"
              className="rounded-pill px-4"
              disabled={isSubmitting}
              onClick={() => setIsEditing(false)}
            >
              <div className="d-flex align-items-center justify-content-center">
                <span className="text-truncate">Cancel</span>
              </div>
            </Button>
            <PrimaryButton
              className="rounded-pill px-4"
              disabled={isSubmitting}
              onClick={() => handleSubmit(onSubmit)()}>
              <span>Save</span>
            </PrimaryButton>
          </div>
        }
        {!isEditing &&
          <div className="d-flex w-100 justify-content-center gap-4">
            <Button
              variant="red-cancel"
              className="rounded-pill px-4"
              disabled={isSubmitting}
              onClick={() => handleDeleteUser()}
            >
              <div className="d-flex align-items-center justify-content-center">
                <span className="text-truncate">Delete Profile</span>
              </div>
            </Button>
            <PrimaryButton
              className="rounded-pill px-4"
              disabled={isSubmitting}
              onClick={() => setIsEditing(true)}>
              <span>Edit</span>
            </PrimaryButton>
          </div>
        }
      </Modal.Footer>
    </Modal>
  )
}
export default UserProfileModal; 
