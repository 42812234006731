import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import TileInfo from '@arcgis/core/layers/support/TileInfo';
import * as urlUtils from '@arcgis/core/core/urlUtils';
import Graphic from '@arcgis/core/Graphic';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils.js';
import _ from 'lodash';
import { Widgets } from '../constants/widgets';
import Legend from '@arcgis/core/widgets/Legend';
import { LayerService } from './layerService';
import { setupMapWidgets } from './widgets';
import { toggleRadarVisibility } from './weather';
import { Layers } from '../constants/layers';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import { getInundationLevel } from './hydroUtils';
import { geometry } from '../constants/geometry';
import Polygon from '@arcgis/core/geometry/Polygon.js';
import Extent from '@arcgis/core/geometry/Extent';
import IdentifyParameters from '@arcgis/core/rest/support/IdentifyParameters.js';
import * as identify from '@arcgis/core/rest/identify.js';

export class MapManager {
  webmap = null;
  view = null;
  layerService = new LayerService();
  gaugesLayer = null;
  inundationLayer = null;
  roadLayer = null;
  floodBuildingsLayer = null;
  defaultBridgeLayer = null;
  bridgeLayer = null;
  graphicsLayer = null;
  radarLayers = [];
  referenceLayers = [];
  radarAnimationTimer = null;
  gaugesLayerView = null;
  roadsLayerView = null;
  floodBuildingsLayerView = null;
  bridgeLayerView = null;
  roadHighlight = null;
  floodBuildingHighlight = null;
  bridgeHighlight = null;
  isDesktop = true;
  config = null;

  constructor(mapConfig) {
    this.config = mapConfig;

    this.webmap = new ArcGISMap({
      showAttribution: false,
      basemap: Widgets.STREETS_BASEMAP,
    });

    this.loadProxies();

    this.view = new MapView({
      map: this.webmap,
      center: this.config.defaultCenter,
      extent: {
        xmin: this.config.defaultBounds[0],
        ymin: this.config.defaultBounds[1],
        xmax: this.config.defaultBounds[2],
        ymax: this.config.defaultBounds[3],
      },
      constraints: {
        minZoom: this.config.minZoomLevel,
        maxZoom: this.config.maxZoomLevel,
        rotationEnabled: false,
        snapToZoom: false,
        lods: TileInfo.create().lods,
      },
      popup: {
        autoOpenEnabled: false,
        collapseEnabled: false,
        highlightEnabled: true,
        dockEnabled: false,
        dockOptions: {
          buttonEnabled: false,
        },
      },
      ui: {
        components: [],
      },
    });

  }

  /**
   * Sets the container for the map view
   * @param {HTMLElement} container - the container element
   */
  initialize(container) {
    this.view.container = container;
  }

  /**
   * Loads the proxy rules for the map.  This is called after the map is initialized
   * and the view is ready.
   */
  loadProxies() {
    // load proxy rules
    const proxyRules = this.config.proxyRules;

    if (proxyRules) {
      proxyRules.forEach((rule) => {
        urlUtils.addProxyRule(rule);
      });
    }
  }

  /**
   * Loads the widgets for the map.  This is called after the map is initialized
   * and the view is ready.
   */
  loadWidgets() {
    setupMapWidgets(this.view, this.config, this.layerService);
  }

  /**
   * Creates a legend widget
   * @param {HTMLElement} container - the container element
   * @returns {Legend} - the legend widget
   **/
  createLegend(container) {
    const legend = new Legend({
      id: Widgets.LEGEND,
      view: this.view,
    }, container);

    return legend;
  }

  /**
   * Removes a widget from the map
   * @param {string} widgetId - the id of the widget to remove
   * @returns {void}
   **/
  removeWidget(widgetId) {
    const widget = this.view.ui.find(widgetId);

    if (widget) {
      this.view.ui.remove(widget);
    }
  }

  /**
   * Loads the layers for the map.  This is called after the map is initialized
   * and the view is ready.
   * @param {function} onLayerViewReady - callback function to call when the layer view is ready
   * @param {function} onLayerViewUpdated - callback function to call when the layer view is updated
   */
  loadLayers(onLayerViewReady = null, onLayerViewUpdated = null) {
    const _this = this;

    this.view.when(async () => {
      this.graphicsLayer = this.layerService.loadEmptyGraphicLayer();
      this.gaugesLayer = this.layerService.loadGaugeLayer(this.config.layers.gauges);
      this.radarLayers = this.layerService.loadRadarLayers(this.config.layers.weatherRadar);
      this.referenceLayers = this.layerService.loadReferenceLayers(this.config.layers.references);
      this.inundationLayer = this.layerService.loadInundationLayer(this.config.layers.inundation);
      this.roadLayer = this.layerService.loadRoadLayer(this.config.layers.roads);
      this.bridgeLayer = this.layerService.loadBridgeLayer(this.config.layers.bridges);
      this.floodBuildingsLayer = this.layerService.loadFloodBuildingsLayer(this.config.layers.buildings);
      this.defaultBridgeLayer = this.layerService.loadDefaultBridgeLayer(this.config.layers.defaultBridges);

      // watch for gauge layer/view updates
      this.view.whenLayerView(this.gaugesLayer).then(function (layerView) {
        _this.gaugesLayerView = layerView;

        if (onLayerViewReady)
          onLayerViewReady(layerView);

        layerView.watch('updating', (val) => {
          if (!val) {
            if (onLayerViewUpdated)
              onLayerViewUpdated(layerView);
          }
        });
      });

      // watch for each reference layer 
      this.referenceLayers.forEach((layer) => {
        this.view.whenLayerView(layer).then(function (layerView) {
          if (onLayerViewReady)
            onLayerViewReady(layerView);
        });
      });

      // save layer views
      this.view.whenLayerView(this.roadLayer).then(function (layerView) {
        _this.roadsLayerView = layerView;
      });

      this.view.whenLayerView(this.floodBuildingsLayer).then(function (layerView) {
        _this.floodBuildingsLayerView = layerView;
      });

      this.view.whenLayerView(this.defaultBridgeLayer).then(function (layerView) {
        _this.bridgeLayerView = layerView;
      });

      // add layers to map
      this.addLayers([
        this.inundationLayer,
        this.roadLayer,
        this.floodBuildingsLayer,
        ...this.referenceLayers,
        this.graphicsLayer,
        ...this.radarLayers,
        this.defaultBridgeLayer,
        this.bridgeLayer,
        this.gaugesLayer,
      ]);
    });
  }

  setGaugeLayerRenderer(renderer) {
    this.gaugesLayer.renderer = renderer;
  }

  /**
   * Register event pointer move handler
   * @param {function} onHoverHandler
   */
  onViewHover(onHoverHandler) {
    this.view.on('pointer-move', _.debounce((event) => {
      if (!onHoverHandler) return;

      const mapPoint = this.view.toMap(event);

      this.view.hitTest(event).then(async (response) => {
        if (response?.results?.length > 0) {
          const results = response.results.filter(result => result.graphic.layer.popupTemplate);
          
          if (results?.length === 0) {

            onHoverHandler(null, null, mapPoint);
          } else {
            const { graphic, layer } = response?.results?.[0] || {};
            onHoverHandler(graphic, layer, mapPoint);
          }
        } else {
          onHoverHandler(null, null, mapPoint);
        }
      });
    }, 100));
  }

  /**
   * Register event pointer for when popups are closed
   * @param {function} onPopupCloseHandler
   */
  onPopupClose(onPopupCloseHandler) {
    reactiveUtils.when(
      () => {
        const visible = this.view.popup.visible === false;
        return visible;
      },
      () => {
        const layerName = this.view.popup.selectedFeature?.layer?.id;
        onPopupCloseHandler(layerName);
      }
    )
  }

  /**
   * Register event click handler
   * @param {function} onClickHandler
   */
  onViewClick(onClickHandler) {
    this.view.on('click', (event) => {
      if (!onClickHandler) return;

      this.view.hitTest(event)
        .then(async (response) => {
          // this is first graphic in the first layer that was hit
          // TODO: handle multiple layers
          const { graphic, layer } = response?.results?.[0] || {};
          onClickHandler(graphic, layer);
        });
    });
  }

  /**
   * Register event Action click handler
   * @param {function} onActionClickHandler
   */
  onActionClick(onActionClickHandler) {
    this.view.popup.on('trigger-action', (event) => {
      if (!onActionClickHandler) return;
      onActionClickHandler(event.action.id);
    });
  }

  /**
    * Register event view updated handler
    * @param {function} onViewUpdatedHandler
   */
  onViewUpdated(onViewUpdatedHandler) {
    this.view.watch('updating', (val) => {
      if (!val) {
        if (onViewUpdatedHandler)
          onViewUpdatedHandler(this.gaugesLayerView);
      }
    });
  }

  /**
   * Register event handler for when the pointer leaves the map
   * @param {function} onPointerLeaveHandler
   */
  onPointerLeave(onPointerLeaveHandler) {
    this.view.on('pointer-leave', function() {
      if (onPointerLeaveHandler)
        onPointerLeaveHandler();
    });
  }

  /**
   * Register event handler for when the pointer enters the map
   * @param {function} onPointerEnterHandler
   */
  onPointerEnter(onPointerEnterHandler) {
    this.view.on('pointer-enter', function() {
      if (onPointerEnterHandler)
        onPointerEnterHandler();
    });
  }

  /**
   * Set the visibility of a layer
   * 
   * @param {string} layerId - The layer id.
   * @param {boolean} visible - The visibility of the layer.
   */
  setLayerVisibility(layerId, visible) {
    const layer = this.webmap.findLayerById(layerId);
    if (layer) {
      layer.visible = visible;
    }
  }

  /**
   * Get the visibility of a layer
   *  
   * @param {string} layerId - The layer id.
   * @returns {boolean} returns the visibility of the layer.
   */
  getLayerVisibility(layerId) {
    const layer = this.webmap.findLayerById(layerId);
    if (layer) {
      return layer.visible;
    }
    return false;
  }

  /**
   * Set the opacity of a layer
   * 
   * @param {string} layerId - The layer id.
   * @param {number} opacity - The opacity of the layer.
   */
  setLayerOpacity(layerId, opacity) {
    const layer = this.webmap.findLayerById(layerId);
    if (layer) {
      layer.opacity = opacity;
    }
  }

  /**
   * Add a layer to the map
   * 
   * @param {Object} layer - The layer to add.
   */
  addLayer(layer) {
    this.webmap.add(layer);
  }

  /**
   * Add multiple layers to the map
   * 
   * @param {Array} layers - The layers to add.
   */
  addLayers(layers) {
    this.webmap.addMany(layers);
  }

  /**
   * Creates a new graphic to add to the graphicsLayer
   * https://developers.arcgis.com/javascript/latest/api-reference/esri-Graphic.html
   * @param {Object} geomotryObject - The graphic specific geometry object.
   * @param {Object} styleObject - The styling of the graphic. defaults to emtpy with blue border.
   * @returns {Object} returns a new Graphics object
   */
  createGraphic(geomotryObject, styleObject = null) {
    if (!styleObject) {
      styleObject = {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        color: [0, 0, 0, 0],
        outline: {
          color: [11, 58, 141], //primary blue
          width: 1.75
        }
      };
    }

    // Add the geometry and symbol to a new graphic
    return new Graphic({
      geometry: geomotryObject,
      symbol: styleObject
    });
  }

  /**
   * Add a graphic to the graphics layer
   * core assumption here is that there will only be one graphic ever in the layer
   * @param {Object} graphic - The graphic to add.
   */
  addGraphicToLayer(graphic) {
    const layer = this.webmap.findLayerById(Layers.GRAPHICS);
    if (layer) {
      this.clearGraphicsLayer();
      layer.add(graphic);
    }
  }

  /**
   * Clear the graphics layer
   */
  clearGraphicsLayer() {
    const layer = this.webmap.findLayerById(Layers.GRAPHICS);
    if (layer) {
      layer.removeAll();
    }
  }

  /**
   * Helper function to call the locate widget
   */
  locateMe() {
    // use the locate widget to reset view to default extent
    this.view.ui.find(Widgets.LOCATE)?.viewModel?.locate();
  }

  /**
   * Helper function to reset the zoom level to the default extent
   */
  async resetExtent() {
    const bounds = new Extent({
      xmin: this.config.defaultBounds[0],
      ymin: this.config.defaultBounds[1],
      xmax: this.config.defaultBounds[2],
      ymax: this.config.defaultBounds[3],
    });

    return this.view.goTo(bounds);
  }

  /**
   * Zoom to a feature by its geometry. If a zoom is provided, it will zoom to that level. 
   * Otherwise, it will zoom to the extent of the feature, with a 1.5x buffer.
   * 
   * @param {Object} geometry - The geometry of the feature to zoom to.
   * @param {number} zoom - The zoom level to zoom to.
   */
  zoomToFeatureByGeometry(geometry, zoom = undefined) {
    const options = zoom ? { target: geometry, zoom } : { target: geometry };
    if (!zoom) {
      this.view.goTo(geometry, { animate: false }).then(() => {
        this.view.goTo(this.view.extent.expand(1.2));
      });
    } else {
      this.view.goTo(options);
    }
  }

  /**
   * Zoom to a feature by its precise geometry
   * @param {Object} featureLayer - The feature layer to query.
   * @param {number} objectid - The objectid of the feature to zoom to.
   * @param {string} property - The property to query by.
   */
  async zoomToFeatureByPreciseGeometry(featureLayer, objectid, property = 'OBJECTID') {
    if (!featureLayer || !objectid) return;

    // Query the feature layer based on OID, return geom and OID, don't need other attrs
    const result = await featureLayer.queryFeatures({
      where: `${property} = ${objectid}`,
      outFields: [property],
      returnGeometry: true,
    });

    // Unpack the feature from the result if it exists - should only ever be 1 since we are using OID
    const { features } = result;
    const feature = features?.[0];

    // Initiate the zoom - can latch on to the promise if needed
    if (feature)
      this.zoomToFeatureByGeometry(feature.geometry);
  }

  /**
   * Toggle the visibility of the weather radar layers
   * @param {Function} callback - The callback function to call informing the caller of the current timestamp.
   * @returns {boolean} returns true if the radar layers are visible, false otherwise
   */
  toggleRadarLayers(callback) {
    // use helper functions to toggle the radar layers
    const intervals = this.config.layers.weatherRadar.intervals.map((i) => i.interval.toString());

    this.radarAnimationTimer = toggleRadarVisibility(
      intervals,
      this.radarLayers,
      this.radarAnimationTimer,
      callback
    );

    return this.radarAnimationTimer !== null;
  }

  /**
   * Get gauges layer view
   * @returns {Object} returns the gauges layer view
   */
  getGaugeLayerView() {
    return this.gaugesLayerView;
  }

  /**
   * Get gauges layer
   * @returns {Object} returns the gauges layer
   */
  getGaugeLayer() {
    return this.gaugesLayer;
  }

  /**
   * Get radar layers
   * @returns {Array} returns the radar layers
   */
  getRadarLayers() {
    return this.radarLayers;
  }

  /**
   * Get inundation layer
   * @returns {Object} returns the inundation layer
   */
  getInundationLayer() {
    return this.inundationLayer;
  }

  /**
   * Get road layer
   * @returns {Object} returns the road layer
   */
  getRoadLayer() {
    return this.roadLayer;
  }
  /**
   * Get Bridge layer
   * @returns {Object} returns the bridge layer
   */
  getBridgeLayer() {
    return this.bridgeLayer;
  }

  /**
   * Get Flood Buidlings layer
   * @returns {Object} returns the flood buildings layer
   */
  getFloodBuildingsLayer() {
    return this.floodBuildingsLayer;
  }

  getDefaultBridgeLayer() {
    return this.defaultBridgeLayer;
  }
  /**
   * Helper function to query the gauges layer
   * @param {Object} query - The query object.
   * @returns {Array} returns the query results
   * @example
   * queryGaugesLayer({
   *  where: '1=1',
   * outFields: ['*'],
   * returnGeometry: true
   * })
   */
  async queryGaugesLayer(query) {
    const results = await this.gaugesLayer.queryFeatures({
      returnGeometry: true,
      ...query,
    });
    return results.features;
  }

  /**
   * Helper function to query the gauges layer view
   * @param {Object} query - The query object.
   * @returns {Array} returns the query results
   */
  async queryGaugesView(query) {
    const results = await this.gaugesLayerView.queryFeatures({
      returnGeometry: true,
      ...query,
    });
    return results.features;
  }

  /**
   * Helper function to show the popup for a given graphic
   * @param {Object} graphic - The graphic to show the popup for.
   */
  showHoverPopup(graphic, highlight = true,  overridePosition = null) {
    if (!graphic) {
      this.view.popup.close();
    } else {
      if (overridePosition) {
        this.view.popup.open({
          location: overridePosition,
          features: [graphic],
          shouldFocus: true,
          highlightEnabled: highlight,
        });
      } else {
        let location = graphic.geometry;
        if (graphic.geometry?.type === geometry.POLYGON) {
          location = (graphic.geometry instanceof Polygon) ? graphic.geometry.centroid : location
        }

        if (graphic.geometry?.type === geometry.POLYLINE) {
          location = graphic.geometry.extent.center;
        }

        this.view.popup.open({
          location: location,
          features: [graphic],
          shouldFocus: true,
          highlightEnabled: highlight,
        });
      }
    }
  }

  /**
   * Helper function to show the bridge layer for a given site
   *  @param {string} siteId - The site id.
   */
  showDefaultBridgeLayer(siteId) {
    if (!siteId) return;

    const layer = this.getDefaultBridgeLayer();

    layer.definitionExpression = `GAGE_ID='${siteId}'`;
    this.setLayerVisibility(layer.id, true);
  }

  hideDefaultBridgeLayer() {
    const layer = this.getDefaultBridgeLayer();
    layer.definitionExpression = '1=0';
    setTimeout(() => {
      this.setLayerVisibility(layer.id, false);
    }
      , 250);
  }

  /**
   * Helper function to show the bridge layer for a given site
   *  @param {string} siteId - The site id.
   */
  showBridgeLayer(siteId, level, isScenario) {
    if (!siteId || !isScenario) return;

    // have to use toFixed(1) to ensure the level has a decimal place
    // otherwise query will fail for whole numbers with 0 decimal places
    // e.g. 10.0 vs 10,  where 10 fails
    let query;
    if (level.toString().indexOf('.') != -1) {
      query = `GAGE_ID='${siteId}' AND LEVEL_ID='${level}'`;
    } else {
      query = `GAGE_ID='${siteId}' AND LEVEL_ID='${level}.0'`;
    }
    const layer = this.getBridgeLayer();

    layer.definitionExpression = query;
    this.setLayerVisibility(layer.id, true);

  }

  hideBridgeLayer() {
    const layer = this.getBridgeLayer();
    layer.definitionExpression = '1=0';
    setTimeout(() => {
      this.setLayerVisibility(layer.id, false);
    }
      , 250);
  }

  /**
   * function to get a distinct list of gauge ids from the bridge layer
   * the returned list is used to filter the gauges layer
   */
  async getGaugesWithBridgeData() {
    const result = await queryFeatures({
      url: this.config.layers.bridges.featureUrl,
      outFields: ['GAGE_ID'],
      orderByFields: ['GAGE_ID'],
      returnGeometry: false,
      returnDistinctValues: true
    });
    const gaugeIds = result.features.map((f) => f.attributes.GAGE_ID);
    return [...new Set(gaugeIds)];
  }

  /**
   * function to get a distinct list of gauge ids from the road layer
   * the returned list is used to filter the gauges layer
   */
  async getGaugesWithRoadData() {
    const result = await queryFeatures({
      url: this.config.layers.roads.featureUrl,
      outFields: ['GAGE_ID'],
      orderByFields: ['GAGE_ID'],
      returnGeometry: false,
      returnDistinctValues: true
    });
    const gaugeIds = result.features.map((f) => f.attributes.GAGE_ID);
    return [...new Set(gaugeIds)];
  }

  /**
   * Helper function to show the road layer for a given site
   *  @param {string} siteId - The site id.
   */
  showRoadLayer(siteId, level, isScenario) {
    if (!siteId || !isScenario) return;
    let query;
    // if the level has a decimal place, then use it 
    if (level.toString().indexOf('.') != -1) {
      query = `GAGE_ID='${siteId}' AND SCEN_ELEV='${level}'`;
    } else {
      query = `GAGE_ID='${siteId}' AND SCEN_ELEV='${level}.0'`;
    }
    const layer = this.getRoadLayer();
    layer.definitionExpression = query;
    this.setLayerVisibility(layer.id, true);
  }

  hideRoadLayer() {
    const layer = this.getRoadLayer();
    layer.definitionExpression = '1=0';
    // clear the definition expression
    // create a callback to hide the layer to give the map time to update
    // with the new definition expression, otherwise the previous definition
    // expression will still be applied when the layer is shown again
    // hacky, but it works
    setTimeout(() => {
      this.setLayerVisibility(layer.id, false);
    }
      , 250);
  }

  /**
   * Helper function to highlight a road feature.
   * Will enforce that only one feature is highlighted at a time.
   * @param {number} objectId - The object id of the feature to highlight.
   * @returns {void}
   */
  highlightRoadFeature(objectId) {
    if (this.roadHighlight) {
      this.roadHighlight.remove();
      this.roadHighlight = null;
    }

    if (objectId === null) return;

    this.roadHighlight = this.roadsLayerView.highlight(objectId);
  }

  /**
 * Helper function to highlight a flood building feature.
 * Will enforce that only one feature is highlighted at a time.
 * Needs to query the flood building layer to get the object id
 * @param {number} objectId - The object id of the feature to highlight.
 * @returns {void}
 */
  highlightFloodBuildingFeature(gaugeId, buildingId, level) {
    if (this.floodBuildingHighlight) {
      this.floodBuildingHighlight.remove();
      this.floodBuildingHighlight = null;
    }

    if (buildingId === null) return;

    this.floodBuildingsLayer.queryFeatures({
      returnGeometry: false,
      outFields: ['NC_FIMAN.DBO.S_BUILDING_FP.OBJECTID'],
      where: `NC_FIMAN.DBO.L_DAMAGE_RESULTS_FL.GageID='${gaugeId}' AND NC_FIMAN.DBO.S_BUILDING_FP.BLDG_ID='${buildingId}' AND NC_FIMAN.DBO.L_DAMAGE_RESULTS_FL.USER_FLAG='${level}'`,
    }).then((featureSet) => {
      if (featureSet.features.length > 0) {
        const objectId = featureSet.features[0].attributes['NC_FIMAN.DBO.S_BUILDING_FP.OBJECTID'];
        this.floodBuildingHighlight = this.floodBuildingsLayerView.highlight(objectId);
      }
    });
  }

  /**
 * Helper function to highlight a bridge feature.
 * Will enforce that only one feature is highlighted at a time.
 * @param {number} objectId - The object id of the bridge feature to highlight.
 * @returns {void}
 */
  highlightBridgeFeature(objectId) {
    if (this.bridgeHighlight) {
      this.bridgeHighlight.remove();
      this.bridgeHighlight = null;
    }

    if (objectId === null) return;

    this.bridgeHighlight = this.bridgeLayerView.highlight(objectId);
  }

  /**
   * Helper function to clear the road highlight.
   */
  clearRoadHighlight() {
    this.highlightRoadFeature(null);
  }

  /**
   * Helper function to clear the flood building highlight.
   */
  clearFloodBuildingHighlight() {
    this.highlightFloodBuildingFeature(null);
  }

  /**
 * Helper function to clear the bridge highlight.
 */
  clearBridgeHighlight() {
    this.highlightBridgeFeature(null);
  }

  /**
   * Helper function to show inundation layers
   * @param {string} siteId - The site id.
   * @param {boolean} isScenario - Whether or not the site is a scenario.
   * @param {number} elevation - The elevation to show the inundation layer for.
   * @param {Array} levels - The available levels. if not provided, will be queried.
   * @param {boolean} minSnap - Whether or not to snap to the minimum level.
   */
  async showInundationLayer(siteId, isScenario, elevation, levels = null, minSnap = false) {
    if (!isScenario) return;

    // get the levels if they are not provided
    if (!levels) {
      const result = await queryFeatures({
        url: this.config.layers.inundation.featureUrl,
        where: `SITE_ID = '${siteId}'`,
        outFields: ['USER_FLAG'],
        orderByFields: ['USER_FLAG'],
        returnGeometry: false,
      });

      levels = result.features.map((f) => f.attributes.USER_FLAG);
    }

    // get the level from the gauge elevation and show the layer
    const level = getInundationLevel(elevation, levels, minSnap);

    this.showInundationLayerLevelBySiteId(siteId, level);

    return level;
  }

  hideInundationLayer() {
    const layer = this.getInundationLayer();
    const sublayer = layer.findSublayerById(0);
    sublayer.definitionExpression = '1=0';
    // clear the definition expression
    // create a callback to hide the layer to give the map time to update
    // with the new definition expression, otherwise the previous definition
    // expression will still be applied when the layer is shown again
    // hacky, but it works
    setTimeout(() => {
      this.setLayerVisibility(layer.id, false);
    }
      , 250);
  }

  showInundationLayerLevelBySiteId(siteId, level) {
    const query = `SITE_ID='${siteId}' AND USER_FLAG=${level}`;
    const layer = this.getInundationLayer();
    const sublayer = layer.findSublayerById(0);
    sublayer.definitionExpression = query;
    this.setLayerVisibility(layer.id, true);
  }

  // Create and display overlay for buildings with estimated flooding
  showFloodBuildingsLayer(siteId, level, isScenario) {
    if (!siteId || !isScenario) return;
    const query = `GageID='${siteId}' AND USER_FLAG='${level}'`;
    const layer = this.getFloodBuildingsLayer();
    layer.definitionExpression = query;
    this.setLayerVisibility(layer.id, true);
  }

  hideFloodBuildingsLayer() {
    const layer = this.getFloodBuildingsLayer();
    layer.definitionExpression = '1=0';
    setTimeout(() => {
      this.setLayerVisibility(layer.id, false);
    }
      , 250);
  }

  /**
   * Helper function to identify a feature on a layer. The layer is not required to be on the map.
   * Sends a request to the ArcGIS REST map service resource to identify features.
   * @param {Object} layerId - The layers on which to perform the identify operation.
   * @param {Object} mapPoint - The map point to identify.
   * @param {boolean} returnGeometry - Whether or not to return the geometry.
   * @param {number} tolerance - The distance in screen pixels from the specified geometry within which the identify should be performed.
   * @returns {Array} returns the layer and the attribute value
   */
  async identifyFeature(layerUrl, layerId, mapPoint, returnGeometry = false, tolerance = 3) {
    const params = new IdentifyParameters({
      tolerance: tolerance,
      returnGeometry: returnGeometry,
      geometry: mapPoint,
      mapExtent: this.view.extent,
      imageDisplay: this.view.size,
      layerIds: layerId ? [layerId] : [],
    });

    return await identify.identify(layerUrl, params);
  }
}