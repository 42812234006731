import React from 'react';
import useCustomMediaQuery from '../../lib/hooks/useCustomMediaQuery';
import background from '../../assets/images/about_hero.jpg';

const Hero = () => {
  const { isMobile, isTablet } = useCustomMediaQuery();

  const heroStyle = {
    height: isTablet ? '150px' : '300px',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <>
      { !isMobile && <div style={heroStyle}></div> }
      <div className="position-relative overflow-hidden p-3 p-md-5 text-center bg-light">
        <h1 className="fs-3 fw-semibold text-uppercase">
          NC Flood Inundation Mapping and Alert Network (FIMAN)
        </h1>
        <span className="fs-5 fw-lighter p-2">
          North Carolina Emergency Management's Rain, Stream, Weather and Flooding Risk Information Website
        </span>
      </div>
    </>
  );
};

export default Hero;
