
import React, { useState } from 'react';
import useMapStore from 'src/stores/mapStore';
import StageContent from './StageContent';
import StageLegendModal from './StageLegendModal';
import SingleAccordion from 'src/components/SingleAccordion';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';

const StageWidgetSm = () => {
  const gauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails } = useGaugeDetails(gauge?.attributes?.siteId);
  const [showLegendModal, setShowLegendModal] = useState(false) 

  const getTitle = () => {
    return `Stream ${gaugeDetails?.isCoastal ? 'Elevation' : 'Stage'}`;
  }

  const handleClose = () => { 
    setShowLegendModal(false);
  };

  const handleOpen = () => { 
    setShowLegendModal(true);
  };

  return (
    <>
      <SingleAccordion 
        header={getTitle()}
        eventKey="0"
      >
        <StageContent modalCallback={handleOpen} />
      </SingleAccordion>
      {gaugeDetails &&
          <StageLegendModal 
            gaugeDetails={gaugeDetails}
            show={showLegendModal}
            handleClose={() => handleClose()} 
          />
      }
      
    </>

  );
}

export default StageWidgetSm;