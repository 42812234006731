import React, { useState } from 'react';
import FlowContent from './FlowContent';
import SingleAccordion from 'src/components/SingleAccordion';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import LoadingDisplay from 'src/components/LoadingDisplay';
import useMapStore from 'src/stores/mapStore';
import HydrographModal from './HydrographModal';

const FlowWidgetSm = () => {

  const gauge = useMapStore(state => state.activeGauge);
  const [showHydrographModal, setShowHydrographModal] = useState(false); 
  const { data: gaugeDetails, isLoading } = useGaugeDetails(gauge?.attributes?.siteId);
  
 
  if (isLoading) {
    return <LoadingDisplay />;
  }
  return (
    <>
      <SingleAccordion
        header="Flow"
        eventKey="0"
        defaultEventKey={null}
      >
        <FlowContent modalCallback={() => setShowHydrographModal(true)}/>
      </SingleAccordion>
    
      {gaugeDetails &&
        <HydrographModal
          show={showHydrographModal}
          handleClose={() => setShowHydrographModal(false)}
          isFlow={true}
        />
      }
    </>
  );
}

export default FlowWidgetSm;