import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatReportDateTime } from 'src/lib/dateUtils';
import { getGaugeCondition, getLegacySymbolImage, legacyConditionValue } from 'src/lib/conditionUtils';
import Modal from 'react-bootstrap/Modal';
import ReportButton from './ReportButton';
import PillButton from 'src/components/PillButton';
import useMapStore from 'src/stores/mapStore';

const GaugeReports = ({ isScenario, gauge }) => {
  const [showModal, setShowModal] = useState(false);

  const inundationLevel = useMapStore(state => state.inundationLevel);

  const createScenarioQueryParams = () => {
    const condition = getGaugeCondition(inundationLevel, gauge);

    const queryParams = {
      site: gauge.siteId,
      // this does not appear to impact the report, but is required.
      // for historical reports, this should be now + forecastIndex date offset
      dt: formatReportDateTime(new Date()),
      scenario: isScenario,
      elev: inundationLevel,
      cond: condition,
      trend: 'None', // per the spec, this is always None
      symbol: `${legacyConditionValue(condition)}XXX.png`,
      rdt: formatReportDateTime(new Date()),
    }

    return queryParams;
  }

  const createCurrentQueryParams = () => {
    const condition = getGaugeCondition(inundationLevel, gauge);
    const reportDate = formatReportDateTime(new Date());

    // some of the defaults are based on existing fiman report behavior.
    const queryParams = {
      site: gauge.siteId,
      dt: reportDate,
      scenario: isScenario,
      elev: inundationLevel,
      cond: condition,
      forecastPeakDate: gauge.forecastPt ? getForecastDate() : '11/11/1900',
      forecastPeak : gauge.forecastPt ? gauge.forecastPeak : -999,
      trend: gauge.trend,
      symbol: getLegacySymbolImage(gauge),
      rdt: reportDate,
    }

    return queryParams;
  }

  const getForecastDate = () => {
    // If there are no forecasts in the future, then the
    // peak has already passed or the forecast data has not been updated.
    // In that case, we return 'NA'.
    const start = gauge.forecasts.find(f => f.read < 0);
    if (!start) {
      return 'NA';
    }

    let forecastRecord = gauge.forecasts.reduce((acc, current) => {
      return (current.value > acc.value && (current.read && current.read < 0)) ? current : acc;
    }, start);

    let date = new Date(forecastRecord.at);
    
    return formatReportDateTime(date);
  }

  const getModalTitle = () => {
    if (isScenario) {
      return 'Gauge Scenario Reports';
    } else {
      return 'Gauge Reports';
    }
  }

  return (
    <>
      <PillButton
        size="sm"
        variant="random-orange"
        className="d-flex align-items-center justify-content-center m-1 px-2"
        onClick={() => setShowModal(true)}
      >
        Get Report
      </PillButton>

      <Modal
        animation={true}
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        size="md"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex-lg-fill d-flex align-items-center justify-content-center flex-lg-shrink-1 gap-4">
            {isScenario ?
              <>
                <ReportButton
                  type="excel"
                  text="Excel Report"
                  dynamicQueryParams={() => createScenarioQueryParams()}
                  query="name=gauge-scenario&format=excel"
                  useAuth={false}
                />
                <ReportButton
                  type="pdf"
                  text="PDF Report"
                  dynamicQueryParams={() => createScenarioQueryParams()}
                  query="name=gauge-scenario&format=pdf"
                  useAuth={false}
                />
              </> :
              <>
                <ReportButton
                  type="excel"
                  text="Excel Report"
                  dynamicQueryParams={() => createCurrentQueryParams()}
                  query="name=gauge-summary&format=excel"
                  useAuth={false}
                />
                <ReportButton
                  type="pdf"
                  text="PDF Report"
                  dynamicQueryParams={() => createCurrentQueryParams()}
                  query="name=gauge-summary&format=pdf"
                  useAuth={false}
                />
              </>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

GaugeReports.propTypes = {
  isScenario: PropTypes.bool,
  gauge: PropTypes.object,
};

export default GaugeReports;