import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import useUserStore from 'src/stores/userStore';
import PrimaryButton from 'src/components/PrimaryButton';
import { useForm } from 'react-hook-form';
import UserForm from './UserForm';


const AdminEditUserModal = ({ table }) => {
  const showAdminEditUserModal = useUserStore(state => state.showAdminEditUserModal);
  const toggleAdminEditUserModal = useUserStore(state => state.toggleAdminEditUserModal);
  const editUser = useUserStore(state => state.editUser);
  const setEditUser = useUserStore(state => state.setEditUser);
  const isSubmitting = useUserStore(state => state.isSubmitting);
  const handleUpdate = useUserStore(state => state.handleUpdate);
  const [userGroup, setGroup] = useState(editUser?.role ?? '');

  const { handleSubmit, control, reset, formState: { errors } } = useForm({ defaultValues: editUser, shouldUnregister: true })

  useEffect(() => {
    const emptyUser = {
      name: '',
      email: '',
      role: '',
      phoneNumber: '',
    }

    // protection from null or undefined vals for controlled inputs
    const resetValues = editUser ? editUser : emptyUser;
    setGroup(editUser?.role ?? '');
    reset({ ...resetValues })
  }, [editUser, reset, setGroup])


  const handleCancelEdit = () => {
    setEditUser(null);
    reset();
    toggleAdminEditUserModal(false);
  }


  const onSubmit = async (data) => {
    handleUpdate({
      ...data,
      role: userGroup,
      username: editUser.username,
      cognitoUserId: editUser.cognitoUserId,
      groups: [userGroup]
    }, table.options.meta?.updateRowData)
  }

  return (
    <Modal
      centered
      show={showAdminEditUserModal}
      onHide={() => handleCancelEdit()}
      animation={true}
      backdrop="static"
      scrollable={true}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          Editing person data for {editUser?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-md-5 pt-md-4">
        <div className="d-flex flex-column dark-gray-background mt-3">
          <UserForm
            user={editUser}
            control={control}
            errors={errors}
            setGroup={setGroup}
            userGroup={userGroup}
            isManageUser={true} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex w-100 justify-content-center gap-4">
          <Button
            variant="red-cancel"
            className="rounded-pill px-4"
            onClick={() => toggleAdminEditUserModal(false)}
            disabled={isSubmitting}
          >
            <div className="d-flex align-items-center justify-content-center">
              <span className="text-truncate">Cancel</span>
            </div>
          </Button>
          <PrimaryButton
            className="rounded-pill px-4"
            onClick={() => handleSubmit(onSubmit)()}
            disabled={isSubmitting}
          >
            <span>Save</span>
          </PrimaryButton>
        </div>
      </Modal.Footer>
    </Modal>
  )

}

AdminEditUserModal.propTypes = {
  table: PropTypes.object.isRequired,
}

export default AdminEditUserModal;