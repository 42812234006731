import { useCallback, useEffect, useState } from 'react';
import { SearchResults } from '../../../constants/search';
import useDebounceValue from '../../../lib/hooks/useDebounceValue';


/**
 * Custom hook to handle the search functionality
 * @param {object} mapManager
 * @param {string} goecodeUrl
 * @param {string} geocodeToken
 * @param {string} searchExtent
 * @param {number} maxTake
 * @param {string} defaultValue
 * @param {number} delay
 * @returns {object}
 */
const useSearch = (mapManager, goecodeUrl, geocodeToken, searchExtent, maxTake = 3, defaultValue = '', delay = 300) => {
  const [value, setValue] = useState(defaultValue);
  const [suggestions, setSuggestions] = useState([]);
  const debouncedValue = useDebounceValue(value, delay);

  /**
   * Clears the suggestions
   * @returns {void}
   */
  const clearSuggestions = () => {
    setSuggestions([]);
  };

  /**
   * Suggests places based on the search token
   * @param {string} searchToken
   * @returns {Promise}
   */
  const suggestPlaces = useCallback(async (searchToken) => {
    const url = `${goecodeUrl}/suggest?f=pjson&text=${searchToken}&token=${geocodeToken}&maxSuggestions=10&searchExtent=${searchExtent}`;
    const results = await fetch(url)
      .then(response => {
        return response.json();
      });

    if (!results?.suggestions) return Promise.resolve([]);

    const nonCollection = results.suggestions.filter((s) => !s.isCollection);
    return nonCollection.slice(0, maxTake);
  }, [geocodeToken, goecodeUrl, maxTake, searchExtent]);

  /**
   * Fetches the candidates for the search token
   * @param {string} searchToken
   * @param {string} key
   * @returns {Promise}
   */
  const fetchCandidates = useCallback(async (searchToken, key) => {
    const url = `${goecodeUrl}/findAddressCandidates?f=pjson&address=${searchToken}&token=${geocodeToken}&maxLocations=5&outfields=PlaceName,Place_addr&searchExtent=${searchExtent}&magicKey=${key}`;
    const results = await fetch(url)
      .then(response => {
        return response.json();
      });

    return results;
  }, [geocodeToken, goecodeUrl, searchExtent]);

  /**
   * Suggests gauges based on the search token
   * @param {string} searchToken
   * @returns {Promise}
   */
  const suggestGauges = useCallback(async (searchToken) => {
    if (!mapManager) return Promise.resolve([]);

    const results = await mapManager.queryGaugesLayer({
      where: `LOWER(name) LIKE '%${searchToken.toLowerCase()}%' OR LOWER(siteId) LIKE '%${searchToken.toLowerCase()}%'`,
      outFields: ['*'],
      num: maxTake,
      orderByFields: ['siteId ASC'],
    });

    return results;
  }, [mapManager, maxTake]);

  /**
   * Fetches the places and gauges based on the search token
   * @param {string} searchToken
   * @returns {Promise}
   */
  const fetchPlaces = useCallback(async (searchToken) => {
    if (!searchToken) {
      clearSuggestions();
      return;
    }

    const placesPromise = await suggestPlaces(searchToken);
    const gaugesPromise = await suggestGauges(searchToken);
    const [places, gauges] = await Promise.all([placesPromise, gaugesPromise]);

    const suggestions = [];

    // map the suggestions
    if (places?.length > 0) {
      const newPlaces = places.map((f) => {
        const { text, magicKey } = f;
        return {
          key: magicKey,
          name: text,
          address: null,
          location: null,
          type: SearchResults.PLACE,
          meta: f,
        }
      });

      suggestions.push(...newPlaces);
    }

    // map the gauges
    if (gauges?.length > 0) {
      const newGauges = gauges.map((f) => {
        const { attributes } = f;
        const { name, siteId } = attributes;
        return {
          key: siteId,
          name,
          location: f.geometry,
          type: SearchResults.GAUGE,
          meta: f,
        }
      });

      suggestions.push(...newGauges);
    }

    setSuggestions(suggestions);
  }, [suggestPlaces, suggestGauges]);


  useEffect(() => {
    fetchPlaces(debouncedValue);
  }, [debouncedValue, fetchPlaces]);


  return {
    value,
    setValue,
    suggestions,
    clearSuggestions,
    fetchCandidates,
  };
}

export default useSearch;