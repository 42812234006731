import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Widget from './Widget';
import ForecastPeakContent from './ForecastPeakContent';
import useMapStore from 'src/stores/mapStore';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import HydrographModal from './HydrographModal';
import useUserGroup from 'src/lib/hooks/useUserGroup';
import { isNwmModel } from 'src/lib/forecastUtils';


const ForecastPeakWidget = ({ onClick, onActionClick, actionIcon }) => {
  const gauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails } = useGaugeDetails(gauge?.attributes?.siteId);
  const { isAdmin, isAdvanced } = useUserGroup();
  const [showHydrographModal, setShowHydrographModal] = useState(false);


  const handleActionClick = () => {
    if (onActionClick) {
      onActionClick();
    } else {
      setShowHydrographModal(!showHydrographModal);
    }
  }

  const getYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  }

  const useNwmModel = isNwmModel(gaugeDetails, isAdmin, isAdvanced);

  // only admin and advanced users can see NWM forecast, if it exists
  const title = () => {
    return (isAdmin || isAdvanced) && useNwmModel ? 'NWM Forecast' : 'RFC Forecast';
  }

  return (
    <Widget
      title={title()}
      onActionClick={handleActionClick}
      onClick={onClick}
      actionIcon={actionIcon}
    >
      <ForecastPeakContent useNwmModel={useNwmModel} modalCallback={handleActionClick} />
      {gaugeDetails && !onActionClick &&
        <HydrographModal
          useNwmModel={useNwmModel}
          show={showHydrographModal}
          handleClose={() => setShowHydrographModal(false)}
          minDisplayDate={getYesterday()}
        />
      }
    </Widget>
  );
}

ForecastPeakWidget.propTypes = {
  onClick: PropTypes.func,
  onActionClick: PropTypes.func,
  actionIcon: PropTypes.node,
};

export default ForecastPeakWidget;