import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SingleAccordion from 'src/components/SingleAccordion';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';
import Subscription from './Subscription';
import { roundToTenth } from 'src/lib/mathUtils';
import { convertElevationToStage } from 'src/lib/stageConversionUtils';
import _ from 'lodash';


const ConditionRegion = ({ gauge, region, subscriptions }) => {
  const { isMobile } = useCustomMediaQuery();

  const inRegionSubscriptions = useMemo(() => {
    const subs = subscriptions.filter((subscription) => {
      // if coastal, value and region min/max are elevation, 
      // otherwise min/max are stage and value is converted to stage
      const value = !gauge.isCoastal > 0 ? convertElevationToStage(subscription.value, gauge.gageDatum) : subscription.value;
      const roundedValue = roundToTenth(value);
      return roundedValue >= roundToTenth(region.min) && roundedValue < roundToTenth(region.max);
    });

    return _.orderBy(subs, ['value', 'name'], ['desc', 'asc']);

  }, [gauge, region, subscriptions]);

  return (
    <SingleAccordion
      header={<div className="d-flex align-items-center">
        <div
          className="d-flex align-items-center me-2 rounded-circle"
          style={{ backgroundColor: region.color, width: 23, height: 23 }}
        >
        </div>
        {`${region.title} (${inRegionSubscriptions.length})`}
      </div>
      }
      eventKey={region.title}
      defaultEventKey={!isMobile ? region.title : null}
    >
      <div className="flex flex-col">
        {inRegionSubscriptions.length === 0 ?
          <div className="text-center text-muted">No alerts configured at this flood risk rating</div> :
          inRegionSubscriptions.map((subscription, index) => (
            <div key={subscription.id}
            >
              <Subscription
                gauge={gauge}
                subscription={subscription}
              />
              {index < inRegionSubscriptions.length - 1 && <hr className="mt-3 mb-2" />}
            </div>
          ))
        }
      </div>
    </SingleAccordion>
  );
};

ConditionRegion.propTypes = {
  gauge: PropTypes.object.isRequired,
  region: PropTypes.object.isRequired,
  subscriptions: PropTypes.array.isRequired,
};

export default ConditionRegion;