import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import NotificationIconBadge from 'src/components/NotificationIconBadge';


/**
 * A reusable button component that has a yellow background and an icon.
 * @param {Object} props - The props for the component.
 * @param {boolean} props.showBadge - Whether or not to show a badge on the button.
 * @param {string} props.className - A custom class name for the button.
 * @param {ReactNode} props.icon - The icon for the button.
 * @see https://react-bootstrap.github.io/components/buttons/
 * @component
 * @example
 * return (
 *  <PrimaryIconButton className="my-class" icon={<Icon />}>
 *   <div>
 *     Whatever you want to put in the button goes here.
 *   </div>
 *  </PrimaryIconButton>
 * );
 */
const PrimaryIconButton = ({ icon, showBadge = false, className, ...props }) => {
  return (
    <div className="position-relative h-100">
      {showBadge && <NotificationIconBadge height={12} width={12} /> }
      <Button
        className={className}
        variant="yellow-primary"
        {...props}
      >
        <div className="d-flex align-items-center">
          {icon}
        </div>
      </Button>
    </div>
  );
};

PrimaryIconButton.propTypes = {
  icon: PropTypes.node,
  showBadge: PropTypes.bool,
  className: PropTypes.string
};

export default PrimaryIconButton;