import { GaugeMarkerColors } from '../constants/gaugeMarkerColors';
import { convertElevationToStage } from './stageConversionUtils';

export const getColorByCondition = (condition, inService) => {
  // if the gauge is out of service, default the color to gray
  if (!inService) {
    return GaugeMarkerColors.GRAY;
  }
  switch (condition) {
    case -1: return GaugeMarkerColors.BLUE;
    case 0: return GaugeMarkerColors.GREEN;
    case 1: return GaugeMarkerColors.YELLOW;
    case 2: return GaugeMarkerColors.ORANGE;
    case 3: return GaugeMarkerColors.RED;
    case 4: return GaugeMarkerColors.PURPLE;
    default: return GaugeMarkerColors.GRAY;
  }
}

export const getConditionText = (condition, inService) => {
  if (!inService) {
    return 'Out of Service';
  }

  switch (condition) {
    case 0: return 'Normal';
    case 1: return 'Monitor';
    case 2: return 'Minor Flooding';
    case 3: return 'Moderate Flooding';
    case 4: return 'Major Flooding';
    default: return 'Unknown';
  }
}

export const getConditionRanges = (conditions, min = -1000000, max = 1000000) => {
  const ranges = [];

  if (!conditions || conditions.length === 0) {
    return ranges;
  }

  ranges.push({
    id: 'normal',
    min: min,
    max: conditions[0].value,
    color: GaugeMarkerColors.GREEN,
    title: 'Normal',
  });

  for (let i = 0; i < conditions.length; i++) {
    const condition = conditions[i];
    const nextCondition = conditions[i + 1];

    ranges.push({
      id: condition.title,
      min: condition.value,
      max: nextCondition?.value ?? max,
      color: condition.color,
      title: condition.title,
    });
  }

  return ranges.reverse();
}

/**
 * Convert the gauge's elevation into a condition.
 */
export const getGaugeCondition = (elevation, gauge) => {
  if (elevation < gauge.bankFull) {
    return 0;
  } else if (elevation < gauge.minor) {
    return 1;
  } else if (elevation < gauge.moderate) {
    return 2;
  } else if (elevation < gauge.major) {
    return 3;
  } else {
    return 4;
  }
}


export const getLegacySymbolImage = (gauge) => {
  if (gauge.inService) {
    if (gauge.condition != null) {
      var conditionChar = legacyConditionValue(gauge.condition);
      var forecastChar = legacyForecastValue(gauge.forecastCondition);
      var trendChar = legacyTrendValue(gauge.trend);
      var rainChar = legacyRainValue(gauge.rainCondition);

      var iconValue = conditionChar + forecastChar + trendChar + rainChar;
    } else {
      iconValue = 'XXXX';
    }
  } else {
    iconValue = 'XXXX';
  }
  return iconValue + '.png';
}

/**
 * Convert the gauge's condition into a legacy condition value.
 */
export const legacyConditionValue = (conditionValue) => {
  let conditionChar = 'B';

  switch (conditionValue) {
    case 0:
      conditionChar = 'G';
      break;
    case 1:
      conditionChar = 'Y';
      break;
    case 2:
      conditionChar = 'O';
      break;
    case 3:
      conditionChar = 'R';
      break;
    case 4:
      conditionChar = 'P';
      break;
    default:
      conditionChar = 'B';
      break;
  }
  return conditionChar;
}


function legacyForecastValue(forecastValue) {
  let forecastChar = 'X';

  switch (forecastValue) {
    case null:
      forecastChar = 'X';
      break;
    case 0:
      forecastChar = 'G';
      break;
    case 1:
      forecastChar = 'Y';
      break;
    case 2:
      forecastChar = 'O';
      break;
    case 3:
      forecastChar = 'R';
      break;
    case 4:
      forecastChar = 'P';
      break;
    default:
      forecastChar = 'X';
      break;
  }
  return forecastChar;
}

function legacyTrendValue(trendValue) {
  let trendChar = 'X';

  switch (trendValue) {
    case null:
      trendChar = 'X';
      break;
    case 'Constant':
      trendChar = 'C';
      break;
    case 'Falling':
      trendChar = 'F';
      break;
    case 'Rising':
      trendChar = 'R';
      break;
  }
  return trendChar;
}

function legacyRainValue(rainValue) {
  let rainChar = 'X';
  
  switch (rainValue) {
    case 1:
      rainChar = 'R';
      break;
    default:
      rainChar = 'X';
      break;
  }
  return rainChar;
}

/**
 * Convert the gauge's conditions into a list that can be used to render the gauge's condition ranges.
 * If the gauge is coastal, the conditions will be elevations. Otherwise, they will be converted
 * to stage.
 */
export const getGagueConditions = (gauge) => {
  const conditions = [];

  if (gauge.bankFull && gauge.bankFull < gauge.minor) {
    conditions.push({
      title: 'Monitor',
      value: !gauge.isCoastal ? convertElevationToStage(gauge.bankFull, gauge.gageDatum) : gauge.bankFull,
      color: GaugeMarkerColors.YELLOW,
    });
  }

  if (gauge.minor && gauge.minor < gauge.moderate) {
    conditions.push({
      title: 'Minor Flooding',
      value: !gauge.isCoastal ? convertElevationToStage(gauge.minor, gauge.gageDatum) : gauge.minor,
      color: GaugeMarkerColors.ORANGE,
    });
  }

  if (gauge.moderate && gauge.moderate < gauge.major) {
    conditions.push({
      title: 'Moderate Flooding',
      value: !gauge.isCoastal ? convertElevationToStage(gauge.moderate, gauge.gageDatum) : gauge.moderate,
      color: GaugeMarkerColors.RED,
    });
  }

  if (gauge.major) {
    conditions.push({
      title: 'Major Flooding',
      value: !gauge.isCoastal ? convertElevationToStage(gauge.major, gauge.gageDatum) : gauge.major,
      color: GaugeMarkerColors.PURPLE,
    });
  }

  return conditions;
}