import React from 'react';
import { RoadColors } from 'src/constants/roadColors';

// eslint-disable-next-line react/prop-types
const RoadIcon = ({ color }) => {
  return (
    <svg width="45px" height="15px" viewBox="0 0 45 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <polygon id="path-1" points="0 0 44.7937 0 44.7937 13.493 0 13.493"></polygon>
      </defs>
      <g id="Assets" stroke="none" strokeWidth="1" fill="none">
        <g transform="translate(-303.000000, -533.000000)" id="Road_Black">
          <g transform="translate(303.000000, 533.000000)">
            <g id="Clip-8"></g>
            <path d="M30.0767,13.493 C26.4027,13.493 23.5957,10.736 21.1187,8.305 C18.9667,6.19 16.9317,4.193 14.8077,4.193 C12.2747,4.193 6.2337,8.841 2.7527,12.14 L-0.0003,9.238 C1.5917,7.728 9.7867,0.193 14.8077,0.193 C18.5677,0.193 21.4117,2.986 23.9217,5.451 C26.0387,7.529 28.0387,9.493 30.0767,9.493 C33.4967,9.493 38.9197,3.671 41.5347,0 L44.7937,2.319 C43.9807,3.463 36.6587,13.493 30.0767,13.493" id="Fill-7" fill={color} mask="url(#mask-2)"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}


const RoadLegend = () => {
  const levels = [
    { color: RoadColors.PURPLE, label: '> 5 ft' },
    { color: RoadColors.RED, label: '2-5 ft' },
    { color: RoadColors.ORANGE, label: '0.5-2 ft' },
    { color: RoadColors.YELLOW, label: '0-0.5 ft' },
  ];

  return (
    levels.map((level) => {
      return (
        <div className="mb-2 d-flex align-items-center d-flex" key={level.label}>
          <RoadIcon color={level.color} />
          <div className="ms-2">{level.label}</div>
        </div>
      )
    }
    )
  );
}

export default RoadLegend;
