import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import useConfigData from 'src/lib/hooks/useConfigData';

const useGaugeImpact = (siteId, elevation) => {
  const { data: config } = useConfigData('Map');

  const { data, isLoading } = useQuery(['impact', siteId, elevation], ({ queryKey }) => {
    const [, id, elev] = queryKey;

    // query need elevation with two decimal places
    const formattedElev = parseFloat(elev).toFixed(2);
  
    const query = `GageID='${id}' AND Elevation <='${formattedElev}' AND RecordDelete<>'Yes' AND prevdataid=0`;
    return queryFeatures({
      url: config?.references.impact,
      where: query,
      outFields: ['*'],
      orderByFields: ['Elevation DESC'],
      returnGeometry: false,
    });
  }, {
    enabled: config?.references?.impact !== undefined && siteId !== undefined && elevation !== undefined,
  });

  const impacts = useMemo(() => {
    if (data?.features?.length > 0) {
      const feature = data.features[0];
      return {
        id: 0,
        gaugeId: feature.attributes.GageID,
        gaugeName: feature.attributes.GageName,
        elevation: feature.attributes.Elevation,
        roadImpact: feature.attributes.Road_Impact,
        bldgImpact: feature.attributes.Bldg_Impact,
        utilitiesImpact: feature.attributes.Utilities_Impact,
        otherImpact: feature.attributes.Other_Impact
      };
    }
    return null;
  }, [data]);

  return { impacts, isLoading };
};

export default useGaugeImpact;