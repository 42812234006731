import Zoom from '@arcgis/core/widgets/Zoom';
import Home from '@arcgis/core/widgets/Home';
import Locate from '@arcgis/core/widgets/Locate';
import Extent from '@arcgis/core/geometry/Extent';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import GroupLayer from '@arcgis/core/layers/GroupLayer';
import Basemap from '@arcgis/core/Basemap'; 
import Expand from '@arcgis/core/widgets/Expand';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import NCEMBasemap from '../assets/images/ncem_base.jpg';
import { Widgets } from '../constants/widgets';
import { toast } from 'react-toastify';
import useMapStore from 'src/stores/mapStore';



const createZoomWidget = (view) => {
  return new Zoom({
    id: Widgets.ZOOM,
    view: view,
  });
}

const createHomeWidget = (view, bounds) => {
  const extent = new Extent(
    ...bounds,
    view.spatialReference || new SpatialReference({ wkid: 4326 })
  );

  const homeWidget = new Home({
    id: Widgets.HOME,
    view: view,
    viewpoint: {
      targetGeometry: extent,
    },
  });

  homeWidget.goToOverride = function(view, goToParams) {
    const { 
      setSelectedGaugeAreaOfInterest, 
      setSelectedGaugeAreaOfInterestSubCategory, 
      setHasAreaOfInterestGraphic,
      mapManager, 
    } = useMapStore.getState();

    setSelectedGaugeAreaOfInterest(null);
    setSelectedGaugeAreaOfInterestSubCategory(null);
    setHasAreaOfInterestGraphic(false);
    mapManager.clearGraphicsLayer();

    return view.goTo(goToParams.target, goToParams.options);
  };

  return homeWidget;
}

const createLocateWidget = (view, locateZoomScale) => {
  const locateWidget = new Locate({
    id: Widgets.LOCATE,
    view: view,
    scale: locateZoomScale,
    useHeadingEnabled: false,
  });

  locateWidget.on('locate-error', (event) => {
    if (event.error) {
      toast.error('Unable to find your location. Please check your browser settings and try again.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  });

  return locateWidget;
}

const createBasemapGalleryExpandWidget = (view, base1, base2, basemapIds, layerService) => {
  const customBasemap = new Basemap({ 
    baseLayers: [ 
      new GroupLayer({ 
        title: 'Group Layer', 
        layers: [ 
          layerService.loadLayer(base1), 
          layerService.loadLayer(base2)
        ],
        minScale: 18489297.737236, 
        maxScale: 564.248588, 
        id: 'groupLayer', 
        visibilityMode: 'independent',
      }), 
    ], 
    title: 'NCEM Base & Imagery', 
    id: Widgets.CUSTOM_BASEMAP,
    thumbnailUrl: NCEMBasemap
  }); 

  const gallery = new BasemapGallery({
    view: view, 
    source: [customBasemap, basemapIds.map(x => {return Basemap.fromId(x)})].flat(),
  }); 

  // set the default basemap to the custom one.
  view.map.basemap = customBasemap;
  
  return new Expand({
    id: Widgets.BASEMAP_EXPAND,
    view, 
    content: gallery,
    expandIconClass: 'esri-icon-basemap',
    group: 'top-right'
  })
}

export const setupMapWidgets = (view, config, layerService) => {
  view.ui.add(createZoomWidget(view), {
    position: 'top-right'
  });

  view.ui.add(createHomeWidget(view, config.defaultBounds), {
    position: 'top-right'
  });

  view.ui.add(createLocateWidget(view, config.locateZoomScale), 'top-right');
  view.ui.add(createBasemapGalleryExpandWidget(
    view, 
    config.layers.ncemBasemapOuter, 
    config.layers.ncemBasemapInner,
    config.basemapIds,
    layerService
  ), 
  'top-right');
}