import React from 'react'; 


const LoadingDisplay = () => { 
  return (
    <div className="d-flex flex-fill align-items-center justify-content-center h-100">
      <span className="text-muted">Loading...</span>
    </div>
  );
}

export default LoadingDisplay;