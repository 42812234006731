import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card } from 'react-bootstrap';
import ContextAwareToggle from './ContextAwareToggle';

/**
 * Accordion component that only has one item. The default accordion component
 * requires at least two items and only allows one to be open at a time.
 */
const SingleAccordion = ({ header, eventKey, defaultEventKey = eventKey, children }) => {
  return (
    <Accordion key={eventKey} defaultActiveKey={defaultEventKey}>
      <Card className="dark black-border">
        <Card.Header className="d-flex justify-content-between align-items-center py-0">
          <ContextAwareToggle eventKey={eventKey}>
            {header}
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body className="bg-black">
            {children}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

SingleAccordion.propTypes = {
  header: PropTypes.node.isRequired,
  eventKey: PropTypes.string.isRequired,
  defaultEventKey: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SingleAccordion;