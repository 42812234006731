import React, { Fragment, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { roundToTenth } from 'src/lib/mathUtils';


/**
 * A scale for a range slider. Note that the slider is not included in this component.
 * This is a separate component so that the scale can be reused for different sliders.
 * Not intended to be u
 * @param {string} props.className - the class name
 * @param {Object} props.style - the style object
 * @param {number} props.min - the minimum value
 * @param {number} props.max - the maximum value
 * @param {number} props.offset - the offset to apply to the index, 1 or 6 based on the forecast interval
 * @param {number} props.smallStep - the small step value
 * @param {number} props.largeStep - the large step value
 * @param {number[]} props.values - the values to display
  */
const ForecastRangeScale = ({
  className,
  style,
  min,
  max,
  smallStep,
  largeStep,
  values,
  offset = 0,
}) => {
  const numTicks = Math.floor((max - min) / smallStep) + 1;

  const tickValues = useMemo(() => {
    return Array.from({ length: numTicks }, (_, index) => min + index * smallStep);
  }, [numTicks, min, smallStep]);

  const getPosition = (tick, index) => {
    if (index === 0) return 'start';
    if (index === numTicks - 1 && tick.scaled > 98) return 'end';
    return 'middle';
  }

  const getScaledValues = useCallback((ticks) => {
    const scaled = ticks.map((tick) => {
      return {
        scaled: (tick - min) / (max - min) * 100,
        value: tick, // not adjusted for offset since we need the original value for % operator
      }
    });

    return scaled;
  }, [min, max]);

  const scaledValues = useMemo(() => getScaledValues(tickValues), [tickValues, getScaledValues]);

  /**
   * Subtract off 1 from the index to account for the NOW offset applied in the slider.
   * Index 0 is the first value in the array. Slider position 0 is considered NOW. So, when 
   * the slider is at position 0, the value displayed should be the current condition of the gauge. 
   * When the slider is at position 1, the value displayed should be the first value in the array, etc.
   */
  const top = (tick, index) => {
    return (
      <Fragment key={`${index}-top`}>
        {roundToTenth(tick.value - min) % largeStep === 0 &&
          <text
            style={{ stroke: 'white', fontSize: 12, fontWeight: 100 }}
            x={`${tick.scaled}%`}
            y="25" textAnchor={getPosition(tick, index)}>
            {values[(tick.value / offset) - 1] !== undefined ? roundToTenth(values[(tick.value / offset) - 1]) : ''}
          </text>
        }
      </Fragment>
    )
  }

  return (
    <div className={`${className}`} style={style}>
      <svg
        role="presentation"
        width="100%"
        height="35"
        xmlns="http://www.w3.org/2000/svg"
      >
        {
          scaledValues.map((tick, index) => {
            return top(tick, index);
          })
        }
      </svg>
    </div>
  )
};

ForecastRangeScale.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  textPosition: PropTypes.oneOf(['top', 'bottom']),
  size: PropTypes.oneOf(['sm', 'lg']),
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  smallStep: PropTypes.number.isRequired,
  largeStep: PropTypes.number.isRequired,
  offset: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export const MemoizedForecastRangeScale = memo(ForecastRangeScale);
