import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { BellFill } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import PillButton from 'src/components/PillButton';
import useMapStore from 'src/stores/mapStore';
import useUserStore from 'src/stores/userStore';
import useAlertStore from 'src/stores/alertStore';
import { confirm } from 'src/components/ConfirmationModal';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';


const AlertButton = ({ size = 'sm' }) => {
  const [updating, setUpdating] = useState(false);

  const { isMobile } = useCustomMediaQuery(state => state.view);

  const user = useUserStore(state => state.cognitoUser);
  const gauge = useMapStore(state => state.activeGauge);
  const toggleSignInModal = useMapStore(state => state.toggleSignInModal);
  const clearAllSelectedFeatures = useMapStore(state => state.clearAllSelectedFeatures);

  const subscribe = useAlertStore(state => state.subscribeToSite);
  const unsubscribe = useAlertStore(state => state.unsubscribeFromSite);
  const sites = useAlertStore(state => state.alertSubscriptions);
  const showSubscriptionConfiguration = useAlertStore(state => state.showSubscriptionConfiguration);

  const count = useMemo(() => {
    return sites && sites.length > 0 ?
      sites.filter(site => site.attributes.siteId === gauge?.attributes.siteId).length :
      0;
  }, [sites, gauge]);

  const handleAlertClick = async () => {
    if (!user) {
      toggleSignInModal(true);
      return;
    }

    setUpdating(true);

    if (count > 0) {
      if (await confirm(
        `You are unsubscribing from alerts for ${gauge?.attributes.name}. Continue?`,
        'Unsubscribe',
        'Continue',
        'Cancel',
        async () => {
          await unsubscribe(gauge?.attributes.siteId);
        }
      )) {
        toast.success(
          `You have unsubscribed to alerts for "${gauge?.attributes.name}." Manage settings from your Alert Subscriptions list.`
        );
      }
    }

    if (count === 0) {
      await subscribe(gauge?.attributes.siteId);

      if (isMobile) {
        clearAllSelectedFeatures();
      }

      await showSubscriptionConfiguration(gauge?.attributes.siteId);
    }

    setUpdating(false);
  }

  const message = () => {
    return count > 0 ? 'Unsubscribe' : 'Subscribe';
  }

  const title = () => {
    if (!user) {
      return 'Sign up to receive flooding alerts by email or text message.';
    }

    return count > 0 ?
      'Unsubscribe from alerts for this site.' :
      'Subscribe to alerts for this site.';
  }

  return (
    <PillButton
      disabled={updating}
      size={size}
      variant="random-orange"
      className="d-flex align-items-center justify-content-center m-1 px-2"
      title={title()}
      onClick={() => handleAlertClick()}
    >
      { updating && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> }
      <BellFill className="mx-1 mb-1 mt-1" size="17" /> {message()}
    </PillButton>
  );
}

export default AlertButton;

AlertButton.propTypes = {
  size: PropTypes.string
};