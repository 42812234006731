import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import PhotosEditor from './PhotosEditor';


const EditPhotosModal = ({ onHide, showing, gauge }) => {
  const [show, setShow] = useState(false);
  const [selectedGauge, setSelectedGauge] = useState(null);

  useEffect(() => {
    setShow(showing);
    setSelectedGauge(gauge);
  }, [showing, gauge]);

  const handleCancelEdit = () => {
    setShow(false);
    setSelectedGauge(null);
    onHide();
  }

  return (
    <Modal
      centered
      show={show}
      onHide={() => handleCancelEdit()}
      animation={true}
      backdrop="static"
      scrollable={true}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          Gauge Photos
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-md-5 pt-md-4">
        <div className="d-flex flex-column dark-gray-background mt-3">
          <PhotosEditor siteId={selectedGauge?.siteId} />
        </div>
      </Modal.Body>
    </Modal>
  );
}

EditPhotosModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  showing: PropTypes.bool.isRequired,
  gauge: PropTypes.object
};

export default EditPhotosModal;