import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import LoadingDisplay from '../../../components/LoadingDisplay';
import CurrentTabContentSm from './CurrentTabContentSm';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useMapStore from '../../../stores/mapStore';
import { Tabs as TabsConstants } from '../../../constants/tabs';
import PhotosTabContentSm from './widgets/PhotosTabContentSm';
import AlertButton from './widgets/AlertButton';
import useHasGaugeImages from 'src/lib/hooks/useHasGaugeImages';


const GaugeContentSm = () => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const { isLoading } = useGaugeDetails(activeGauge.attributes.siteId);
  const { data: hasImages, isLoading: isImagesLoading } = useHasGaugeImages(activeGauge.attributes.siteId);


  if (isLoading) {
    return <LoadingDisplay />;
  }
  return (
    <>
      <div className="d-grid align-items-center p-1 dark-blue-background">
        <AlertButton size="lg" />
      </div>
      <Tabs
        defaultActiveKey={TabsConstants.CURRENT}
        justify
        variant="sidebar"
        className="position-sticky top-0"
        style={{ zIndex: 100 }}
      >
        <Tab eventKey={TabsConstants.CURRENT} title="Current">
          <CurrentTabContentSm />
        </Tab>
        {!isImagesLoading && hasImages &&
          <Tab eventKey={TabsConstants.PHOTOS} title="Photos">
            <PhotosTabContentSm />
          </Tab>
        }
      </Tabs>
    </>
  );
}

export default GaugeContentSm;