const startRadarAnimation = (intervals, layers, callback) => {
  let currentIndex = intervals.length - 1;
  let previousIndex = currentIndex - 1;

  layers.forEach((layer) => {
    layer.opacity = 0;
    layer.visible = true;
  });
  
  const interval = setInterval(() => {
    previousIndex = currentIndex;
    currentIndex -= 1;
    if (currentIndex < 0) {
      currentIndex = intervals.length - 1;
    }

    layers[currentIndex].opacity = 0.5;
    layers[previousIndex].opacity = 0;

    callback(intervals[currentIndex]);
  }, 500);

  return interval;
}

export function toggleRadarVisibility(intervals, layers, timer, callback) {
  if (timer) {
    clearInterval(timer);
    timer = null;
    layers.forEach((layer) => {
      layer.opacity = 0;
      layer.visible = false;
    });
  }
  else {
    timer = startRadarAnimation(intervals, layers, callback);
  }

  return timer;
}