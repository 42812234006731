
export const hasRfsForecastData = (gaugeDetails) => {
  const { forecastPt, forecastCondition, } = gaugeDetails;

  return forecastPt === true && forecastCondition !== null && forecastCondition !== undefined &&
    forecastCondition > -1 && gaugeDetails?.forecasts.length > 0;
}

export const hasNwmForecastData = (gaugeDetails) => {
  const { nwmForecast, nwmForecastCondition } = gaugeDetails;

  return nwmForecast === true && nwmForecastCondition !== null && nwmForecastCondition !== undefined &&
    nwmForecastCondition > -1 && gaugeDetails?.nwmForecasts.length > 0;
}

export const hasForecastData = (gaugeDetails, isAdmin, isAdvanced) => {
  return hasRfsForecastData(gaugeDetails) || ((isAdmin || isAdvanced) && hasNwmForecastData(gaugeDetails));
}

// only admin and advanced users can see NWM forecast, if it exists
// if the gauge has a RFC forecast, use that instead of NWM
export const isNwmModel = (gaugeDetails, isAdmin, isAdvanced) => {
  return (isAdmin || isAdvanced) && !hasRfsForecastData(gaugeDetails) && hasNwmForecastData(gaugeDetails);
}