import React from 'react';
import { Button, Row } from 'react-bootstrap';

const Contact = () => {
  return (
    <Row className="bg-light p-3 rounded shadow-sm">
      <h5 className="pb-3 text-uppercase">FIMAN Contact</h5>
      <address>
        <div className="fw-bold">NC Floodplain Mapping Program</div>
        <div>200 Park Offices Dr</div>
        <div>Durham, NC 27713</div>
      </address>
      <address>
        <div className="fw-bold">Mailing Address</div>
        <div>4218 Mail Service Center</div>
        <div>Raleigh, NC 27699-4218</div>
      </address>
      <Button href="mailto:fimanhelp@ncdps.gov?subject=FIMAN%20website" variant="yellow-primary" className="mt-3">
        <span className="text-uppercase">Send Email</span>
      </Button>
    </Row>
  );
}

export default Contact;
