import React from 'react';
import GeneralContent from './GeneralContent';
import SingleAccordion from '../../../../components/SingleAccordion';


const GeneralWidgetSm = () => {
  return (
    <SingleAccordion 
      header="General Info"
      eventKey="0"
    >
      <GeneralContent />
    </SingleAccordion>
  );
}

export default GeneralWidgetSm;