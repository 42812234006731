import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';

import agent from '../lib/agent';
import BuildInfo from '../components/BuildInfo';

const HomePage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // using agent that consolidates all api calls
    const testApi2 = async () => {
      const response = await agent.config.list();
      setData(response);
    }

    testApi2();
  }, []);

  return (
    <Container fluid>
      <h1>Home Page</h1>
      <BuildInfo />

      <h4 className="mt-3">Config Info</h4>
      {data.map((config) => (
        <Button variant="yellow-primary" key={config.id}>
          {config.appName}: {config.config }
        </Button>
      ))}
    </Container>
  );
};

export default HomePage;