import React from 'react';
import { GaugeMarkerColors } from 'src/constants/gaugeMarkerColors';


const ForecastLegend = () => {
  const ratings = [
    { color: GaugeMarkerColors.PURPLE, label: 'Major Flooding' },
    { color: GaugeMarkerColors.RED, label: 'Moderate Flooding' },
    { color: GaugeMarkerColors.ORANGE, label: 'Minor Flooding' },
    { color: GaugeMarkerColors.YELLOW, label: 'Monitor' },
    { color: GaugeMarkerColors.GREEN, label: 'Normal' },
  ];

  return (
    ratings.map((rating) => {
      return (
        <div className="mb-2 d-flex align-items-center d-flex" key={rating.label}>
          <div className="me-2 d-flex align-items-center justify-content-center rounded-circle" style={{ backgroundColor: rating.color, width: 24, height: 24 }}>
            <div className="rounded-circle border border-dark" style={{ backgroundColor: GaugeMarkerColors.BLUE, width: 17, height: 17 }}></div>
          </div>
          <div>{rating.label}</div>
        </div>
      )}
    )
  );
}

export default ForecastLegend;
