import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Sliders } from 'react-bootstrap-icons';
import PrimaryIconButton from 'src/components/PrimaryIconButton';
import PrimaryButton from 'src/components/PrimaryButton';
import { Modal, Overlay, Popover, Row, Col, Form } from 'react-bootstrap';
import useMapStore from 'src/stores/mapStore';
import _ from 'lodash';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';
import SingleAccordion from 'src/components/SingleAccordion';
import PermissionFilter from 'src/features/map/auth/PermissionsFilter';

const MapGaugeFilters = ({ className }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [target, setTarget] = useState(null);
  const [showBadge, setShowBadge] = useState(false);
  const ref = useRef(null);
  const currentFilterGroups = useMapStore(state => state.currentGaugeFilters);
  const defaultGaugeFilters = useMapStore(state => state.defaultGaugeFilters);
  const updateCurrentGaugeFilters = useMapStore(state => state.updateCurrentGaugeFilters);
  const { isDesktop } = useCustomMediaQuery();
  const gaugeCount = useMapStore(state => state.gaugeCount);

  useEffect(() => {

    //if the current filters are not the same as the default filters, show the badge
    //using lodash comparison to avoid false positives / comparison on refs
    if (!_.isEqual(currentFilterGroups, defaultGaugeFilters)) {
      setShowBadge(true);
    } else {
      setShowBadge(false);
    }
  }, [currentFilterGroups, defaultGaugeFilters, setShowBadge]);

  const handleClick = (event) => {
    if (isDesktop) {
      setShowOverlay(!showOverlay);
      setTarget(event.target);
    } else {
      setShowModal(!showModal);
    }

  };

  const applyFilter = (filterToUpdate) => {
    //clone the filter so we don't mutate the original
    filterToUpdate = _.cloneDeep(filterToUpdate);
    filterToUpdate.isActive = !filterToUpdate.isActive;


    // replace the updated filter in the current filter groups
    // get new filter group with udpated values from user interaction
    let newFilterGroups = currentFilterGroups.map((filterGroup) => {
      return {
        ...filterGroup,
        filters: filterGroup.filters.map((filter) => {
          if (filter.field === filterToUpdate.field && filter.displayName === filterToUpdate.displayName) {
            return filterToUpdate;
          }
          return filter;
        })
      }
    });

    updateCurrentGaugeFilters(newFilterGroups);
  };

  const modalHeader = (group) => {
    return <div className="d-flex align-items-center">
      <span>{group.name}</span>
    </div>
  };
  return (
    <div ref={ref}>
      <PrimaryIconButton
        onClick={(e) => handleClick(e)}
        className={className}
        showBadge={showBadge}
        icon={<Sliders size="20" />}
      />
      {isDesktop &&
        <Overlay
          show={showOverlay}
          target={target}
          placement="bottom"
          container={ref}
          containerPadding={55}
          className="d-flex flex-column dark-gray-background"
          rootClose="true"
          onHide={() => setShowOverlay(false)}
        >
          <Popover
            id="popover-contained"
            className="dark position-fixed"
            style={{ width: '500px', maxWidth: '500px', marginTop: '20px' }}
          >
            <Popover.Header className="dark d-flex justify-content-between align-items-center py-2">
              Gauge Display
              <PrimaryButton
                className="rounded-pill py-0"
                onClick={() => updateCurrentGaugeFilters()}
              >
                Reset to Default
              </PrimaryButton>
            </Popover.Header>
            <Popover.Body className="bg-black">
              <div className="d-flex">
                {currentFilterGroups?.map((filterGroup) => (
                  <div className="d-flex-column flex-fill justify-content-center px-2" key={filterGroup.id}>
                    <div className="mb-2 text-white">{filterGroup.name}</div>
                    {filterGroup.filters.map((filter, index) => (
                      <PermissionFilter key={index} any={filter.allowedRoles}>
                        <Form.Group as={Row} className="mb-3" controlId={filter.field + '-' + index} >
                          <Col >
                            <Form.Check
                              title={filter.tooltip}
                              type="checkbox"
                              checked={filter.isActive}
                              onChange={() => applyFilter(filter)}
                              label={filter.displayName}
                              disabled={filter.isDisabled}
                              className="text-white" />
                          </Col>
                        </Form.Group>
                      </PermissionFilter>
                    ))}
                  </div>
                ))}
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>
      }
      {!isDesktop &&
        <Modal
          show={showModal}
          className="mobile-modal"
          onHide={() => setShowModal(false)}
          centered
          animation={true}
          placement="bottom"
          backdrop="static"
          scrollable={true}>
          <Modal.Header closeButton closeVariant="white" onClick={() => setShowModal(false)} className="py-2 px-3">
            <Modal.Title>
              GAUGE DISPLAY
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 m-0">
            {currentFilterGroups?.map((filterGroup) => (
              <SingleAccordion
                key={filterGroup.id}
                title={filterGroup.name}
                header={modalHeader(filterGroup)}
                eventKey={filterGroup.id}
              >
                {filterGroup.filters.map((filter, index) => (
                  <PermissionFilter key={index} any={filter.allowedRoles}>
                    <Form.Group className="mb-3" controlId={filter.field + '-' + index} >
                      <Form.Check
                        title={filter.tooltip}
                        type="checkbox"
                        checked={filter.isActive}
                        onChange={() => applyFilter(filter)}
                        label={filter.displayName}
                        disabled={filter.isDisabled}
                        className="text-white" />
                    </Form.Group>
                  </PermissionFilter>
                ))}
              </SingleAccordion>
            ))}

          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex flex-column w-100 text-light-gray text-truncate align-items-center">
              Showing {gaugeCount} Gauges
            </div>
            <div className="d-flex flex-column w-100">
              <PrimaryButton className="rounded-pill px-4" onClick={() => updateCurrentGaugeFilters()}>
                <span>Reset to Default</span>
              </PrimaryButton>
            </div>
          </Modal.Footer>
        </Modal>
      }
    </div>
  );
}
MapGaugeFilters.propTypes = {
  className: PropTypes.string
};

export default MapGaugeFilters;