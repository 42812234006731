import React from 'react';
import ReportButton from './ReportButton';
import useMapStore from 'src/stores/mapStore';
import { confirm } from 'src/components/ConfirmationModal';
import { sleep } from 'src/lib/utils';


const BriefSummaryButton = () => {
  const mapManager = useMapStore((state) => state.mapManager);
  const gaugeCount = useMapStore(state => state.gaugeCount);
  const showWeather = useMapStore((state) => state.showWeather);
  const toggleWeatherAnimation = useMapStore((state) => state.toggleWeatherAnimation);
  const toggleToolsSidebar = useMapStore((state) => state.toggleToolsSidebar);
  const clearAllSelectedFeatures = useMapStore((state) => state.clearAllSelectedFeatures);

  const resetViewForScreenshot = async () => {
    // turn on the weather layer
    if (!showWeather) {
      await toggleWeatherAnimation();
    }

    // hide tool sidebar
    await toggleToolsSidebar(false);
    await sleep(1000);

    // deselect active gauge
    clearAllSelectedFeatures();

    // zoom to extent
    await mapManager.resetExtent();
  }

  const takeScreenshot = async () => {
    if (!mapManager) return null;

    const screenshot = await mapManager.view.takeScreenshot({ format: 'png', });

    return screenshot;
  }

  const handleDynamicQueryParams = async () => {
    let result = null;

    if (await confirm('If you continue, the map view will be reset for the report. Continue?',
      'Reset View',
      'Continue',
      'Cancel'
    )) {
      await resetViewForScreenshot();

      await sleep(1250); // wait for the view to update

      const screenshot = await takeScreenshot();

      // remove the data:image/png;base64, prefix
      const base64 = screenshot.dataUrl.replace('data:image/png;base64,', '')

      result = {
        map: base64,
        gaugeCount: gaugeCount,
      }
    }

    return result;
  }

  return (
    <ReportButton
      type="word"
      text="Word Report"
      query="name=flood-brief&format=word"
      dynamicQueryParams={handleDynamicQueryParams}
    />
  )
}

export default BriefSummaryButton;