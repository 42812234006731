import PropTypes from 'prop-types';
import { addAttachment } from '@esri/arcgis-rest-feature-service';
import React, { useRef, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';


const ImageUploader = ({ gaugeFeatureId, editUrl, onImageUploaded }) => {
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      setImagePreviewUrl(URL.createObjectURL(event.target.files[0]));
    }
  };

  const onImageUpload = async (image) => {
    setIsLoading(true);

    try {
      const result = await addAttachment({
        url: editUrl,
        featureId: gaugeFeatureId,
        attachment: image
      });

      if (result.addAttachmentResult.success === false) {
        toast.error('Error uploading image');
        return;
      }

      toast.success('Photo uploaded');
      onImageUploaded();
      reset();
    } catch (error) {
      toast.error('Error uploading image');
    } finally {
      setIsLoading(false);
    }
  };

  const reset = () => {
    setImage(null);
    setImagePreviewUrl(null);
    inputRef.current.value = null;
  };

  return (
    <div className="mt-2">
      <Form.Label>Upload Photo (.jpg, .jpeg, .png, .gif)</Form.Label>

      <Card className="border border-dark">
        <Card.Body className="bg-dark">
          {image &&
            <img className="mb-2" src={imagePreviewUrl} width={128} height={128} />}
          <div className="d-flex flex-column">
            <input ref={inputRef} className="form-control" type="file" accept="image/*" onChange={onImageChange} />
            {image && <div className="d-flex gap-2">
              <Button variant="red-cancel" className="rounded-pill px-4 mt-3 flex-grow-1" onClick={() => reset()}>
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                className="rounded-pill px-4 mt-3 flex-grow-1 btn-green-accept"
                onClick={() => onImageUpload(image)}
              >
                {isLoading && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>}
                Upload
              </Button>
            </div>}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

ImageUploader.propTypes = {
  gaugeFeatureId: PropTypes.any,
  editUrl: PropTypes.string.isRequired,
  onImageUploaded: PropTypes.func.isRequired
};

export default ImageUploader;