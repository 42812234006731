import React from 'react';
import Button from 'react-bootstrap/Button';
import { XLg } from 'react-bootstrap-icons';
import useMapStore from '../../../stores/mapStore';

const ToolsSidebarHeader = () => {
  const toggleToolsSidebar = useMapStore(state => state.toggleToolsSidebar);

  return (
    <div
      className="d-flex align-items-center p-4 justify-content-center position-relative primary-blue-background"
    >
      <h5 className="text-white m-0">FIMAN TOOLS</h5>
      <div
        className="d-flex end-0 h-100 me-0 position-absolute"
      >
        <Button
          variant="link"
          className="d-flex align-items-center px-3 text-white bold"
          onClick={() => toggleToolsSidebar(false)}
        >
          <XLg size="20" />
        </Button>
      </div>
    </div>
  );
}

export default ToolsSidebarHeader;