import * as ExcelJS from 'exceljs';


const mapDataToArray = (dataMap, dataRow) => {
  const mappedData = [];

  dataMap.forEach((map) => {
    const value = dataRow[map.dataKey];
    mappedData.push(map.converter ? map.converter(value) : value);
  });

  return mappedData;
};

const mapDataToStyle = (dataMap, dataRow) => {
  const mappedData = [];

  dataMap.forEach((map) => {
    const value = dataRow[map.dataKey];
    mappedData.push(map.styleProvider ? map.styleProvider(value) : undefined);
  });

  return mappedData;
}

/**
 * Exports data to an excel file.
 * that is being exported from the drawer tables.
 * @param {string} headers - The header of the table
 * @param {string[]} subHeaders - The sub-headers of the table
 * @param {object[]} dataMap - The mapping of the data to the columns
 * @param {object[]} data - The data to export
 * @param {string} fileName - The name of the file to export
 * @param {string} [sheetName='Sheet 1'] - The name of the sheet to export
 */
export const exportToExcel = (header, subHeaders, dataMap, data, fileName, sheetName = 'Sheet 1') => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);

  // config columns width
  worksheet.columns = dataMap.map((map) => ({
    width: map.width ?? 20,
  }));

  // add the main header
  worksheet.addRow([header]);
  worksheet.getRow(1).font = {
    size: 16,
    bold: true
  };

  // add sub-header rows
  subHeaders.forEach((sub, index) => {
    worksheet.addRow([sub]);
    worksheet.getRow(2 + index).font = { // rows index starts at 1
      size: 12,
      bold: true
    };
  });

  // add the column names
  const offset = subHeaders.length + 1; // 1 for the main header

  const columns = dataMap.map((map) => map.columnName);
  worksheet.addRow(columns);

  // format the column names row
  worksheet.getRow(offset + 1).font = {
    bold: true,
  };

  // format the columns format
  dataMap.forEach((map, index) => {
    if(map.format)
      worksheet.getColumn(index + 1).numFmt = map.format;
  });

  // format the column names background
  columns.forEach((header, index) => {
    worksheet.getRow(offset + 1).getCell(index + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'bfbfbf' },
    };
  });

  // add the data
  data.forEach((dataRow, rowIndex) => {
    worksheet.addRow(mapDataToArray(dataMap, dataRow));

    // format the row background
    const styles = mapDataToStyle(dataMap, dataRow);
    styles.forEach((style, index) => {
      if (style) {
        worksheet.getRow(offset + 2 + rowIndex).getCell(index + 1).fill = style;
      }
    });
  });

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  });
}