import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRight } from 'react-bootstrap-icons';
import GeneralWidget from './widgets/GeneralWidget';
import StageWidget from './widgets/StageWidget';
import WeatherWidget from './widgets/WeatherWidget';
import TrendWidget from './widgets/TrendWidget';
import ForecastPeakWidget from './widgets/ForecastPeakWidget';
import FlowWidget from './widgets/FlowWidget';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useMapStore from 'src/stores/mapStore';
import CurrentInundation from './CurrentInundation';
import LoadingDisplay from 'src/components/LoadingDisplay';
import useHistoricPeakEvents from 'src/lib/hooks/useHistoricPeakEvents';
import HistoricalTopPeakWidget from './widgets/HistoricalPeakWidget';
import HistoricalTopPeakContent from './widgets/HistoricalTopPeakContent';
import useUserGroup from 'src/lib/hooks/useUserGroup';
import { hasForecastData } from 'src/lib/forecastUtils';


const CurrentTabContent = ({ onWidgetClicked }) => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const { data: gauge, isLoading } = useGaugeDetails(activeGauge?.attributes?.siteId);
  const { events, isLoading: isHistoryLoading } = useHistoricPeakEvents(activeGauge?.attributes.siteId);
  const { isAdmin, isAdvanced } = useUserGroup();

  if (isLoading || isHistoryLoading) {
    return <LoadingDisplay />
  }

  const { inService, currentFlow, isScenario } = gauge;

  const hasWeatherData = () => {
    return gauge?.rainCondition === 1;
  }

  const hasPeakEvents = () => {
    return events?.length > 0;
  }

  return (
    <div className="d-flex flex-wrap gap-2 p-2">
      {isScenario && <CurrentInundation />}
      <GeneralWidget />
      {inService && (
        <>
          <StageWidget />
          {hasWeatherData() && <WeatherWidget />}
          <TrendWidget />
          {hasForecastData(gauge, isAdmin, isAdvanced) && (
            isScenario ?
              <ForecastPeakWidget
                onClick={() => onWidgetClicked('ForecastPeak')}
                onActionClick={() => onWidgetClicked('ForecastPeak')}
                actionIcon={<ChevronRight size={12} />}
              /> :
              // has a forecast, but no scenario
              <ForecastPeakWidget />
          )
          }
          {currentFlow !== null && <FlowWidget />}
          {hasPeakEvents() && (
            <HistoricalTopPeakWidget
              onClick={() => onWidgetClicked('HistoricalPeak')}
              onActionClick={() => onWidgetClicked('HistoricalPeak')}
              actionIcon={<ChevronRight size={12} />}>
              <HistoricalTopPeakContent />
            </HistoricalTopPeakWidget>
          )}
        </>
      )}
    </div>
  );
};

CurrentTabContent.propTypes = {
  onWidgetClicked: PropTypes.func.isRequired
};

export default CurrentTabContent;