import React, { useMemo } from 'react';
import useConfigData from 'src/lib/hooks/useConfigData';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';
import LayerGroup from './LayerGroup';
import PermissionFilter from 'src/features/map/auth/PermissionsFilter';

const LayersList = () => {
  const { data: config } = useConfigData('Map');
  const { isMobile } = useCustomMediaQuery();


  const groups = useMemo(() => {
    if (!config) return [];

    return !isMobile ?
      config.layers.references.groups :
      config.layers.references.groups.filter((group) => group.mobile);
  }, [config, isMobile]);

  return (
    <>
      {
        groups.map((group) => (
          <PermissionFilter key={group.name} any={group.allowedRoles}>
            <LayerGroup key={group.name} group={group} />
          </PermissionFilter>
        ))
      }
    </>
  );
}

export default LayersList;
