import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import PrimaryButton from 'src/components/PrimaryButton';
import ZoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';


/**
 * Base hydrograph component.  params drive data display, as well as distinct lables and plugins
 * @param {bool} isFullGraphDisplay - bool for if the graph is a full display, or should hide lables, tooltips, and zoom features
 * @param {dateTime} minDisplayDate - the initial min display date of the graph.
 * @param {objectArray} historicalData - historical formatted data.
 * @param {objectArray} forecastedData - forecasted formatted data. empty array if no forecasted data.
 * @param {object} scaleYOptions - options for the Y axis.
 * @param {object} tooltipCallbackOptions - tooltip options and details.
 * @param {object} conditionBackgroundPlugin - custom plugin for background of graph.
 * @param {object} backgroundOptions - options for the background plugin.
 * @param {function} exportFunction - function to call when the export button is clicked.
*/
const Hydrograph = ({
  isFullGraphDisplay = true,
  minDisplayDate,
  historicalData,
  forecastedData,
  scaleYOptions,
  tooltipCallbackOptions,
  conditionBackgroundPlugin,
  backgroundOptions,
  exportFunction = null
}) => {
  const chartRef = useRef();

  useEffect(() => {
    // set the background options for the condition background plugin
    // this is needed since the plugin is not a react component, and does not update on its own
    // this is a way to pass in new options when a gauge is changed
    if (conditionBackgroundPlugin) {
      chartRef.current.options.plugins.conditionBackground.adjustedMinor = backgroundOptions.adjustedMinor;
      chartRef.current.options.plugins.conditionBackground.adjustedModerate = backgroundOptions.adjustedModerate;
      chartRef.current.options.plugins.conditionBackground.adjustedMajor = backgroundOptions.adjustedMajor;
      chartRef.current.options.plugins.conditionBackground.adjustedNormal = backgroundOptions.adjustedNormal;
      chartRef.current.options.plugins.conditionBackground.showText = backgroundOptions.showText;

      chartRef.current.update();
    }
  }, [backgroundOptions, conditionBackgroundPlugin]);


  // top level configuration for chart js
  //   const config = {
  //   type: 'line'
  //   data: {}
  //   options: {}
  //   plugins: []
  // }

  // the above gets deconstructed in the react-chartjs-2 component
  // <Line data={data} options={options} plugins={[plugin1, plugin2]} />

  //register the chart with all of the outside components that will eventually be rendered 
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    ZoomPlugin,
    TimeScale,
    Filler
  );

  const getMinDate = () => {
    if (minDisplayDate) {
      return minDisplayDate
    }

    let minDate = new Date();
    if (forecastedData.length > 0) {
      minDate.setDate(minDate.getDate() - 6);
    } else {
      minDate.setDate(minDate.getDate() - 3);
    }

    return minDate;
  };

  const getMinZoomDate = () => {
    let minDate = new Date();
    // 1 day is the max zoom out. this makes it so the fully 30 days are actually visible
    // but still allows for a little bit of padding on the side
    minDate.setDate(minDate.getDate() - 31);

    return minDate;
  };

  const getMaxDate = () => {
    let maxDate = new Date();
    if (forecastedData.length > 0) {
      // ensure the forecast data is valid 
      const lastForecastDate = new Date(forecastedData[forecastedData.length - 1].dataAt);
      if (lastForecastDate > maxDate) {
        maxDate = lastForecastDate;
      }
    } else {
      maxDate.setDate(maxDate.getDate());
    }

    return maxDate;
  };

  // dynamically create the labels based on date
  const labels = historicalData.map(d => d.dataAt);

  // top level options for the chart
  const options = {
    responsive: true,
    animation: false,
    //sets background color of chart
    customCanvasBackgroundColor: {
      color: '#FFFFFF',
    },
    //sets hover over interaction
    interaction: {
      axis: 'x',
      mode: 'nearest',
      intersect: false
    },
    //way to set options for specific plugins
    plugins: {
      //handle zoom options
      zoom: {
        pan: {
          // pan options and/or events
          enabled: isFullGraphDisplay,
          mode: 'x',
        },
        zoom: {
          // zoom options and/or events
          wheel: {
            enabled: isFullGraphDisplay,
          },
          pinch: {
            enabled: isFullGraphDisplay
          },
          mode: 'x',
        },
        limits: {
          x: { min: getMinZoomDate(), max: getMaxDate() },
        }
      },
      tooltip: {
        enabled: isFullGraphDisplay,
        usePointStyle: false,
        displayColors: false,
        titleAlign: 'center',
        yAlign: 'bottom',
        bodyFont: {
          size: 12
        },
        titleFont: {
          size: 15
        },
      },
      //handle background options
      conditionBackground: {
        adjustedMinor: 0,
        adjustedModerate: 0,
        adjustedMajor: 0,
        adjustedNormal: 0,
        showText: false,
      }
    },
    //sets the values each axis will use
    parsing: {
      xAxisKey: 'dataAt',
      yAxisKey: 'dataValue'
    },
    //manages axises and their options
    scales: {
      x: {
        type: 'time',
        min: getMinDate(),
        max: getMaxDate(),
        ticks: {
          display: isFullGraphDisplay,
          autoSkip: false,
          maxRotation: 0,
          major: {
            enabled: true
          },
          font: (context) => {
            if (context.tick && context.tick.major) {
              return {
                weight: 'bold',
              };
            }
          }
        }
      }
    },
  };

  options.scales.y = scaleYOptions;
  options.plugins.tooltip.callbacks = tooltipCallbackOptions;

  // find last date of historical data and trim forecast data to prevent overlapping
  const latestHistoricalDate = historicalData[historicalData.length - 1]?.dataAt ? new Date(historicalData[historicalData.length - 1].dataAt) : null;
  const filteredForecastedData = latestHistoricalDate ? forecastedData.filter(forecast => new Date(forecast.dataAt) > latestHistoricalDate) : forecastedData;

  // data object sent to the react-charjs-2 component
  const chartData = {
    labels: labels,
    datasets: [
      {
        //ensure data is sorted ascending by date
        data: historicalData.sort((a, b) => a.dataAt - b.dataAt),
        fill: {
          target: {
            value: -5
          },
          below: 'rgba(211, 211, 211, 0.1)', // Area will be gray below the origin
        },
        borderColor: '#1455C1', //primary blue - color of point border 
        pointStyle: 'circle',
        pointRadius: 1,
        pointHoverRadius: 15,
        cubicInterpolationMode: 'monotone', // type of line
        tension: 0.1, //controls how bendy the line is
      },
      {
        data: filteredForecastedData.sort((a, b) => a.dataAt - b.dataAt),
        fill: {
          target: {
            value: -5
          },
          below: 'rgba(211, 211, 211, 0.4)',
        },
        backgroundColor: 'rgba(211, 211, 211, 0.4)',
        borderColor: '#1455C1', //primary blue - color of point border 
        pointStyle: 'circle',
        pointRadius: 1,
        pointHoverRadius: 15,
        cubicInterpolationMode: 'monotone', // type of line
        tension: 0.1, //controls how bendy the line is
        //sets the line to dashed for every value in the future
        borderDash: [5, 5],
      }
    ]
  };

  const resetZoom = () => {
    let priorDate = getMinDate();
    let forwardDate = getMaxDate();

    //not sure why here, but the zoom will NOT reset if the dates do not have the + in front of them
    //found deep in source code docs here, in nextWeek() func: https://github.com/chartjs/chartjs-plugin-zoom/blob/master/docs/scripts/utils.js
    chartRef.current.zoomScale('x', { min: +priorDate, max: +forwardDate }, 'none');
    chartRef.current.update();
  }

  const showAllZoom = () => {
    let priorDate = getMinZoomDate();
    let forwardDate = getMaxDate();

    //not sure why here, but the zoom will NOT reset if the dates do not have the + in front of them
    //found deep in source code docs here, in nextWeek() func: https://github.com/chartjs/chartjs-plugin-zoom/blob/master/docs/scripts/utils.js
    chartRef.current.zoomScale('x', { min: +priorDate, max: +forwardDate }, 'none');
    chartRef.current.update();
  }

  // add the custom plugin to the chart
  const plugins = [];
  if (conditionBackgroundPlugin) {
    plugins.push(conditionBackgroundPlugin);
  }

  return (
    <>
      <Line ref={chartRef} data={chartData} options={options} plugins={plugins} />
      {isFullGraphDisplay &&
        <div className="d-flex justify-content-center px-2">
          <PrimaryButton size="md" className="rounded-pill px-3 m-2" onClick={() => { showAllZoom(); }}>Show All</PrimaryButton>
          <PrimaryButton size="md" className="rounded-pill px-3 m-2" onClick={() => { resetZoom(); }}>Reset</PrimaryButton>
          {exportFunction &&
            <PrimaryButton size="md" className="rounded-pill px-3 m-2" onClick={() => { exportFunction(); }}>Export</PrimaryButton>
          }
        </div>
      }
    </>
  );
}

Hydrograph.propTypes = {
  isFullGraphDisplay: PropTypes.bool,
  minDisplayDate: PropTypes.instanceOf(Date),
  historicalData: PropTypes.arrayOf(PropTypes.object).isRequired,
  forecastedData: PropTypes.arrayOf(PropTypes.object).isRequired,
  scaleYOptions: PropTypes.object.isRequired,
  tooltipCallbackOptions: PropTypes.object.isRequired,
  conditionBackgroundPlugin: PropTypes.object,
  backgroundOptions: PropTypes.object,
  exportFunction: PropTypes.func
};
export default Hydrograph;