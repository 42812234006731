import React, { useEffect, useState } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { Table } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill, Search } from 'react-bootstrap-icons';
import useMapStore from 'src/stores/mapStore';
import { roundToTenth } from 'src/lib/mathUtils';
import LoadingDisplay from 'src/components/LoadingDisplay';
import PaginationFooter from 'src/components/PaginationFooter';
import PrimaryButton from 'src/components/PrimaryButton';
import CenteredCell from './CenteredCell';
import useBridgesImpacted from 'src/lib/hooks/useBridgesImpacted';
import { exportToExcel } from 'src/lib/dataExport';
import { convertElevationToStage } from 'src/lib/stageConversionUtils';
import { formatDate } from 'src/lib/dateUtils';
import { toFilenameSafe } from 'src/lib/stringUtils';
import InfoLink from 'src/components/InfoLink';


const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('select', {
    header: () => '',
    cell: () => <div className="text-center"><Search /></div>,
    enableSorting: false,
    size: 25,
    maxSize: 25,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('streetName', {
    header: () => <div className="text-center">Road Name</div>,
    cell: info => <CenteredCell>{info.getValue()}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: false,
    size: 150,
  }),
  columnHelper.accessor('bridgeNumber', {
    header: () => <div className="text-center">Bridge Number</div>,
    cell: info => <CenteredCell>{info.getValue()}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('category', {
    header: () => 'Alert',
    cell: info => <CenteredCell>{info.getValue()}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('floodSource', {
    header: () => <div className="text-center">Flood Source</div>,
    cell: info => <CenteredCell>{info.getValue()}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('roadElevation', {
    header: () => <div className="text-center">Road Elevation (ft)</div>,
    cell: info => <CenteredCell>{roundToTenth(info.getValue())}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('lowChordElevation', {
    header: () => <div className="text-center">Low Chord Elevation (ft)</div>,
    cell: info => <CenteredCell>{roundToTenth(info.getValue())}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: false,
    size: 200,
  }),
  columnHelper.accessor('wsel', {
    header: () =>
      <div className="text-center d-flex align-items-center gap-1">
        <InfoLink>Elevation of the water's surface at this bridge, in feet (NAVD 88)</InfoLink>
        Current/Scenario WSEL
      </div>,
    cell: info => <CenteredCell>{roundToTenth(info.getValue())}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: true,
    size: 250,
  }),
  columnHelper.accessor('freeboard', {
    header: () => <div className="text-center">Freeboard (ft)</div>,
    cell: info => <CenteredCell>{roundToTenth(info.getValue())}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: true,
  }),
];

const BridgesTable = () => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const inundationLevel = useMapStore(state => state.inundationLevel);
  const mapManager = useMapStore(state => state.mapManager);

  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});

  const { impactedBridgesElevated: entries, isLoading } = useBridgesImpacted(activeGauge?.attributes?.siteId, inundationLevel);

  const options = {
    data: entries,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
    enableFilters: true,
    enableColumnFilters: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
  };

  const table = useReactTable(options);
  const selectedRows = table.getFilteredSelectedRowModel().flatRows;

  useEffect(() => {
    return () => {
      mapManager.clearBridgeHighlight();
    }
  }, [mapManager]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      const row = selectedRows[0];
      mapManager.zoomToFeatureByGeometry(row?.original?.center, 19);
      mapManager.highlightBridgeFeature(row?.original?.objectId);
    }
  }, [selectedRows, mapManager]);

  const handleExport = () => {
    const dataMap = [
      {
        columnName: 'Road Name',
        dataKey: 'streetName',
      },
      {
        columnName: 'Bridge Number',
        dataKey: 'bridgeNumber',
        format: '0',
      },
      {
        columnName: 'Alert',
        dataKey: 'category',
      },
      {
        columnName: 'Flood Source',
        dataKey: 'floodSource',
      },
      {
        columnName: 'Road Elevation (ft)',
        dataKey: 'roadElevation',
        converter: value => roundToTenth(value),
      },
      {
        columnName: 'Low Chord Elevation (ft)',
        dataKey: 'lowChordElevation',
        converter: value => roundToTenth(value),
      },
      {
        columnName: 'Current/Scenario WSEL',
        dataKey: 'wsel',
        converter: value => roundToTenth(value),
      },
      {
        columnName: 'Freeboard (ft)',
        dataKey: 'freeboard',
        converter: value => roundToTenth(value),
      },
    ];

    const { name, siteId, isCoastal, gageDatum } = activeGauge?.attributes || {};

    const coastalString = isCoastal ? '' : `and a stage of ${roundToTenth(convertElevationToStage(inundationLevel, gageDatum))} ft.`;

    const subHeaders = [
      `${name} (${siteId})`,
      `This report was generated using an elevation of ${inundationLevel} ft. ${coastalString}`
    ];

    const safeName = toFilenameSafe(name).toLowerCase();
    const filename = `${safeName}_bridges-impacted_${formatDate(Date.now(), 'MMddyyy')}.xlsx`;

    exportToExcel(
      'FIMAN Inundation Export',
      subHeaders,
      dataMap,
      table.getFilteredRowModel().rows.map(row => row.original),
      filename,
      'Bridges'
    );
  }

  if (isLoading) {
    return (<LoadingDisplay />);
  }

  if (!entries || entries.length === 0) {
    return (<div className="d-flex flex-fill align-items-center justify-content-center h-100">
      No bridge impacts at this flood stage.
    </div>);
  }

  return (
    <>
      <div className="d-flex p-2">
        <PrimaryButton
          className="rounded-pill btn-sm m-1"
          onClick={() => handleExport()}
        >
          Export
        </PrimaryButton>
      </div>
      <Table size="sm" variant="dark" striped bordered hover>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} style={{ width: header.getSize() }}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? 'd-flex justify-content-center cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <span className="ms-1"><CaretUpFill /></span>,
                        desc: <span className="ms-1"><CaretDownFill /></span>,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={row.getIsSelected() ? 'table-light' : ''}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} onClick={() => setRowSelection({ [row.id]: true })}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <PaginationFooter
        table={table}
        totalRecordCount={table.getFilteredRowModel().rows.length}
        pageSize={5}
      />
    </>
  )
}

export default BridgesTable;