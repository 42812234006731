import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import useConfigData from 'src/lib/hooks/useConfigData';

const useBridgesImpacted = (gaugeId, level) => {
  const { data: config } = useConfigData('Map');

  const { data, isLoading, isError } = useQuery(['bridges-impacted', gaugeId, level], ({ queryKey }) => {
    const [, gaugeId, level] = queryKey;

    const adaptedLevel = level.toString().indexOf('.') != -1 ? level : `${level}.0`;
    
    return queryFeatures({
      url: config?.layers.bridges.featureUrl,
      where: `GAGE_ID='${gaugeId}' AND LEVEL_ID='${adaptedLevel}'`,
      outFields: ['OBJECTID', 'FRBRD_CAT', 'F_CARRIED', 'Bridge_Number', 'FTR_INTRSC', 'DECK_EL', 'FREEBOARD', 'LOC_WSEL', 'X_CORD', 'Y_CORD'],
      orderByFields: ['FREEBOARD asc', 'F_CARRIED'],
      returnGeometry: false,
    });
  }, {
    enabled: gaugeId !== undefined && level !== undefined && level !== null && config !== undefined
  });
  
  const impactedBridges = useMemo(() => {
    return data?.features ? data.features.map(xf => {
      return {
        objectId: xf.attributes.OBJECTID,
        streetName: xf.attributes.F_CARRIED,
        category: xf.attributes.FRBRD_CAT,
        bridgeNumber: xf.attributes.Bridge_Number,
        floodSource: xf.attributes.FTR_INTRSC,
        roadElevation: xf.attributes.DECK_EL,
        freeboard: xf.attributes.FREEBOARD,
        wsel: xf.attributes.LOC_WSEL,
        lowChordElevation: xf.attributes.LOC_WSEL + xf.attributes.FREEBOARD,
        center: [xf.attributes.X_CORD, xf.attributes.Y_CORD],
      }
    }) : [];
  }, [data]);

  const impactedBridgesElevated = useMemo(() => {
    return impactedBridges.filter(xf => xf.category !== 'Normal');
  }, [impactedBridges]);

  const impactedByCategory = useMemo(() => {
    return impactedBridges.reduce((acc, curr) => {
      if (!acc[curr.category]) {
        acc[curr.category] = [];
      }
      acc[curr.category].push(curr);
      return acc;
    }, {});
  }, [impactedBridges]);

  return { impactedBridges, impactedBridgesElevated, impactedByCategory, isLoading, isError };
};

export default useBridgesImpacted;