import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import useConfigData from './useConfigData';


const useRoadsAffectedTotals = (gaugeId, level) => {
  const { data: config } = useConfigData('Map');

  const { data, isLoading } = useQuery(['road-damage-totals', gaugeId, level], ({ queryKey }) => {
    const [, gaugeId, level] = queryKey;

    const adaptedLevel = level.toString().indexOf('.') != -1 ? level : `${level}.0`;
  
    // handle 1.0 vs 1. data is not consistent in the database
    return queryFeatures({
      url: config?.references.roadsDamageTotals,
      where: `GAGE_ID='${gaugeId}' AND (LEVEL_ID='${adaptedLevel}' OR LEVEL_ID='${level}')`,
      outFields: ['*'],
      returnGeometry: false,
    });
  }, {
    enabled: gaugeId !== undefined && level !== undefined && level !== null
  });

  const entries = useMemo(() => {
    const rows = data?.features ? data.features
      .map(entry => {
        return {
          depthCat: entry.attributes.Depth_Cat,
          totalMiles: entry.attributes.Tot_Miles,
          intCount: entry.attributes.Int_Count,
          intMiles: entry.attributes.Int_Miles,
          usCount: entry.attributes.US_Count,
          usMiles: entry.attributes.US_Miles,
          ncCount: entry.attributes.NC_Count,
          ncMiles: entry.attributes.NC_Miles,
          localMiles: entry.attributes.Local_Miles,
          totalCount: entry.attributes.Tot_Count,
          localCount: entry.attributes.Local_Count,
        }
      }) : [];

    // remove the total row from the list and add it to the end
    const totalRow = rows.filter(e => e.depthCat === 'Total')[0];
    const nonTotal = rows.filter(e => e.depthCat !== 'Total');

    nonTotal.sort((a, b) => {
      if (a.depthCat >  b.depthCat) {
        return -1;
      } else if (a.depthCat <  b.depthCat) {
        return 1;
      } else {
        return 0;
      }
    });

    if (totalRow) {
      nonTotal.push(totalRow);
    }
    return nonTotal;
  }, [data]);

  const totalEntry = useMemo(() => {
    return entries.length > 0 ? entries.filter(e => e.depthCat === 'Total')[0] : null;
  }, [entries]);

  return { entries, totalEntry, isLoading };
};

export default useRoadsAffectedTotals;