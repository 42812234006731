import React, { useState, useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import PropTypes from 'prop-types';
import { Table, Card, Button } from 'react-bootstrap';
import { XLg, CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import useHistoricPeakEvents from 'src/lib/hooks/useHistoricPeakEvents';
import useMapStore from 'src/stores/mapStore';
import Gridget from './Gridget';
import LoadingDisplay from 'src/components/LoadingDisplay';
import { formatDate } from 'src/lib/dateUtils';
import CheckboxColumnDef from 'src/components/CheckboxColumnDef';
import PaginationFooter from 'src/components/PaginationFooter';
import { roundToTenth } from 'src/lib/mathUtils';
import InfoLink from 'src/components/InfoLink';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('name', {
    header: () => 'Historic Event Name',
    cell: info => info.renderValue(),
    enableSorting: true,
    size: 100,
    maxSize: 100,
  }),
  columnHelper.accessor('date', {
    header: () => 'Date',
    cell: info => formatDate(info.getValue()),
    enableSorting: true,
    size: 100,
    maxSize: 100,
  }),
  columnHelper.accessor('stage', {
    header: () => 'Peak Stage',
    cell: info => roundToTenth(info.getValue()),
    enableSorting: true,
    size: 50,
    maxSize: 50,
  }),
  columnHelper.accessor('', {
    header: () => '',
    cell: info => info.renderValue(),
    id: 'blank',
  }),
];

const HistoricalPeaksGridget = ({ onRowSelectChange, onClose, initialEventId }) => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const { events, isLoading } = useHistoricPeakEvents(activeGauge?.attributes.siteId);

  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [lastSelectedId, setLastSelectedId] = useState(null);

  const columnsMemo = useMemo(() => {
    return [CheckboxColumnDef({}), ...columns];
  }, []);

  const options = {
    data: events,
    columns: columnsMemo,
    state: {
      sorting,
      rowSelection,
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
  };

  const table = useReactTable(options);
  const filteredSelectedRows = table.getFilteredSelectedRowModel().flatRows;

  useEffect(() => {
    if (filteredSelectedRows.length > 0) {
      const row = filteredSelectedRows[0];

      // Only call onRowSelectChange if the row is different than the last selected row
      if (row.original.id !== lastSelectedId) {
        if (onRowSelectChange) {
          onRowSelectChange(row.original);
        }
        setLastSelectedId(row.original.id);
      }
    }
  }, [filteredSelectedRows, onRowSelectChange, lastSelectedId]);

  useEffect(() => {
    const index = events.findIndex((event) => event.id === initialEventId);
    if (index !== -1) {
      table.setRowSelection({ [index]: true });
    }
  }, [events, initialEventId, table]);

  if (isLoading) {
    return <LoadingDisplay />;
  }

  return (
    <Gridget>
      <Card.Header>
        <div className="d-flex align-items-center justify-content-between">
          <Card.Title className="m-0 py-2">
            <div className="d-flex align-items-center">
              Historical Peaks
              <InfoLink
                className="px-2"
                placement="right"
                title="Roads Impacted"
              >
                <ul>
                  <li>Select an event in the grid to view flood inundation and impacts on the map.</li>
                  <li>Historic flood elevations obtained from USGS, NWS, NCEM and other sources.</li>
                  <li>Historic floodplain mapping shown may be rounded to the nearest FIMAN inundation mapping available.</li>
                  <li>Road and Bridge impacts are based on current road elevations and bridge data and may not reflect transporation assets at the time of the event.</li>
                </ul>
              </InfoLink>
            </div>
          </Card.Title>
          <Button
            variant="link"
            className="d-flex align-items-center px-1 text-white pr-2 grow"
            onClick={onClose}
          >
            <XLg />
          </Button>
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-column justify-content-between">
        <Table size="sm" variant="dark" striped bordered hover>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} style={{ width: header.getSize() }}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <span className="ms-1"><CaretUpFill /></span>,
                            desc: <span className="ms-1"><CaretDownFill /></span>,
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className={row.getIsSelected() ? 'table-light' : ''}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} onClick={() => setRowSelection({ [row.id]: true })}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
        <PaginationFooter
          table={table}
          totalRecordCount={events.length}
          pageSize={5}
        />
      </Card.Body>
    </Gridget>
  )
};

HistoricalPeaksGridget.propTypes = {
  onRowSelectChange: PropTypes.func,
  onClose: PropTypes.func,
  initialEventId: PropTypes.number,
};


export default HistoricalPeaksGridget;