import React from 'react';
import Container from 'react-bootstrap/Container';
import Notifications from '../Notifications';
import ShowingCount from '../ShowingCount';
import Reports from '../reports/Reports';
import WeatherToggle from '../weather/WeatherToggle';
import Filters from '../filters/MapGaugeFilters';
import ToolsToggle from '../ToolsToggle';
import useMapStore from '../../../stores/mapStore';
import styles from '../../../assets/styles/ActionBar.module.scss';
import Search from '../search/Search';
import PermissionFilter from '../auth/PermissionsFilter';
import { CognitoGroups } from 'src/constants/cognitoGroups';


const ActionBar = () => {
  const toggleToolsSidebar = useMapStore(state => state.toggleToolsSidebar);

  return (
    <div className={`d-flex align-items-center ${styles.actionBar}`}>
      <Container fluid className="mx-md-3">
        <div className="d-flex justify-items-between align-items-center">
          <div className="d-flex flex-fill gap-3">
            <ToolsToggle
              className="rounded-pill py-1"
              action={toggleToolsSidebar}
            />
            <WeatherToggle />
          </div>
          <div className="d-flex flex-fill justify-content-center">
            <ShowingCount />
          </div>
          <div className="flex-lg-fill d-flex align-items-center justify-content-lg-end flex-lg-shrink-1 gap-2">
            <Search />
            <PermissionFilter any={[CognitoGroups.ADMIN, CognitoGroups.ADVANCED]}>
              <Reports className="rounded-pill py-1" />
            </PermissionFilter>
            <Filters className="rounded-pill" />
            <Notifications className="rounded-pill" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ActionBar;