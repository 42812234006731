import React from 'react';
import PropTypes from 'prop-types';


const NameValueText = ({ label, value, className }) => {
  return (
    <div className={`d-flex ${className}`}>
      <span className="fw-bold me-2 text-nowrap">{label}:</span>
      <span className="text-truncate" title={value}>{value}</span>
    </div>
  );
}

NameValueText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired, 
  className: PropTypes.string
};

export default NameValueText;