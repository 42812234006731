import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { convertElevationToStage, convertStageToElevation } from 'src/lib/stageConversionUtils';
import { HydrographColors } from 'src/constants/hydrographColors';
import { format } from 'date-fns'
import { Paramcodes } from 'src/constants/paramcodes';
import { roundToTenth } from 'src/lib/mathUtils';
import Hydrograph from 'src/components/Hydrograph';
import { exportHydrographData } from 'src/lib/hydroUtils';

const StageHydrograph = ({ gaugeDetails, useNwmModel = false, isFullGraphDisplay = true, minDisplayDate }) => {

  const { isCoastal, minor, major, moderate, bankFull, historical, forecasts, nwmForecasts, gageDatum } = gaugeDetails;

  // adjust minor,major,moderate,bankfull values to be the correct stage value instead of elevation
  // this reverts the values back to the original stage values AFTER the backend has already converted them to elevation
  // calcuations rely on gageDatum 

  const adjustedNormal = !isCoastal ? convertElevationToStage(bankFull, gageDatum) : bankFull;
  const adjustedMinor = !isCoastal ? convertElevationToStage(minor, gageDatum) : minor;
  const adjustedModerate = !isCoastal ? convertElevationToStage(moderate, gageDatum) : moderate;
  const adjustedMajor = !isCoastal ? convertElevationToStage(major, gageDatum) : major;

  const historicalData = useMemo(() => {
    let filteredHistorical = historical.filter(h => Paramcodes.STAGE_CODES.includes(h.code));
    return [...filteredHistorical].map(d => {
      return {
        ...d,
        dataAt: new Date(d.at),
        dataValue: !isCoastal ? convertElevationToStage(d.value, gageDatum) : d.value
      }
    });
  }, [historical, isCoastal, gageDatum]);

  const forecastedData = useMemo(() => {
    if (useNwmModel) {
      return nwmForecasts.map(d => {
        return {
          ...d,
          dataAt: new Date(d.at),
          dataValue: d.value
        }
      });
    } else {
      const filtered = forecasts?.filter(f => Paramcodes.STAGE_CODES.includes(f.code)) ?? [];
      return [...filtered].map(d => {
        return {
          ...d,
          dataAt: new Date(d.at),
          dataValue: !isCoastal ? convertElevationToStage(d.value, gageDatum) : d.value
        }
      });
    }
  }, [forecasts, nwmForecasts, useNwmModel, isCoastal, gageDatum]);


  // custom plugin that generates the background color based on the condition
  const ConditionBackgroundPlugin = {
    id: 'conditionBackground',
    beforeDraw: (chart, args, opts) => {
      const { ctx, chartArea: { left, top, right, bottom }, scales: { y } } = chart;

      const adjustedMinor = opts.adjustedMinor;
      const adjustedModerate = opts.adjustedModerate;
      const adjustedMajor = opts.adjustedMajor;
      const adjustedNormal = opts.adjustedNormal;
      const showText = opts.showText;

      const minorY = y.getPixelForValue(adjustedMinor);
      const moderateY = y.getPixelForValue(adjustedModerate);
      const majorY = y.getPixelForValue(adjustedMajor);
      const monitorY = y.getPixelForValue(adjustedNormal);

      ctx.save();
      ctx.fillStyle = HydrographColors.LIGHT_GREEN;
      ctx.fillRect(left, bottom, right, monitorY - bottom);

      ctx.fillStyle = HydrographColors.LIGHTER_YELLOW;
      ctx.fillRect(left, monitorY, right, minorY - monitorY);

      ctx.fillStyle = HydrographColors.LIGHT_ORANGE;
      ctx.fillRect(left, minorY, right, moderateY - minorY);

      ctx.fillStyle = HydrographColors.LIGHT_RED;
      ctx.fillRect(left, moderateY, right, majorY - moderateY);

      ctx.fillStyle = HydrographColors.LIGHT_PURPLE;
      ctx.fillRect(left, majorY, right, top - majorY);

      ctx.font = '11px Arial';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'left';
      const yOffset = 4;
      const xOffset = 10;

      if (showText) {
        ctx.fillText(`Normal <${roundToTenth(adjustedNormal)} ft`, left + xOffset, ((bottom + monitorY) / 2) + yOffset);
        if (adjustedNormal < adjustedMinor) ctx.fillText(`Monitor ${roundToTenth(adjustedNormal)} ft`, left + xOffset, ((minorY + monitorY) / 2) + yOffset);
        if (adjustedMinor < adjustedModerate) ctx.fillText(`Minor ${roundToTenth(adjustedMinor)} ft`, left + xOffset, ((moderateY + minorY) / 2) + yOffset);
        if (adjustedModerate < adjustedMajor) ctx.fillText(`Moderate ${roundToTenth(adjustedModerate)} ft`, left + xOffset, ((majorY + moderateY) / 2) + yOffset);
        ctx.fillText(`Major ${roundToTenth(adjustedMajor)} ft`, left + xOffset, ((top + majorY) / 2) + yOffset);
      }

      ctx.restore();
    }
  };

  let minDataPont = [...historicalData].sort((a, b) => a.dataValue - b.dataValue)[0]?.dataValue;
  let maxDataPoint = [...historicalData].sort((a, b) => b.dataValue - a.dataValue)[0]?.dataValue;

  if(forecastedData.length > 0){
    const minforecast = [...forecastedData].sort((a, b) => a.dataValue - b.dataValue)[0]?.dataValue;
    const maxforecast = [...forecastedData].sort((a, b) => b.dataValue - a.dataValue)[0]?.dataValue;

    minDataPont = minDataPont < minforecast ? minDataPont : minforecast;
    maxDataPoint = maxDataPoint > maxforecast ? maxDataPoint : maxforecast;
  }

  let maxYOption = adjustedMajor > Math.ceil(maxDataPoint)
    ? adjustedMajor
    : Math.ceil(maxDataPoint);


  maxYOption = maxYOption + 1; // add 1 to the max value to give some padding on the top of the chart
  maxYOption = Math.ceil(maxYOption);

  const tooltipCallbackOptions = {
    //adds Forecast Header to tooltip if the date is in the future
    beforeTitle: (context) => {
      if (!context[0]?.parsed.x) {
        return;
      }
      const comparisonDate = new Date(context[0].parsed.x);
      if (comparisonDate > new Date()) {
        return 'Forecast';
      }
    },
    title: () => { return null }, //removes the title from the tooltip,
    label: (context) => {
      if (isCoastal) {
        return `Elevation: ${roundToTenth(context.parsed.y)} ft NAVD 88`;
      } else {
        return `Stage: ${roundToTenth(context.parsed.y)} ft`;
      }
    },
    afterLabel: (context) => {
      if (isCoastal) {
        return null;
      } else {
        return `Elevation: ${roundToTenth(convertStageToElevation(context.parsed.y, gageDatum))} ft NAVD 88`;
      }
    },
    afterBody: (context) => {
      if (!context[0]?.parsed.x) {
        return;
      }
      const date = new Date(context[0]?.parsed.x);

      return `Date: ${format(date, 'MMM dd, p')}`;
    },
    // more tooltip options will likely needed to go here 
    // formatting for the date and value will need to be added here
  };
  const scaleYOptions = {
    title: {
      display: isFullGraphDisplay,
      text: isCoastal ? 'Elevation (ft NAVD 88)' : 'Stage (ft)'
    },
    ticks: {
      display: isFullGraphDisplay,
      precision: 0
    },
    min: Math.floor(minDataPont) - 1,
    max: maxYOption,
  };

  const backgroundOptions = {
    adjustedMinor,
    adjustedModerate,
    adjustedMajor,
    adjustedNormal,
    showText: isFullGraphDisplay
  };

  return (
    <Hydrograph
      isFullGraphDisplay={isFullGraphDisplay}
      minDisplayDate={minDisplayDate}
      historicalData={historicalData}
      forecastedData={forecastedData}
      scaleYOptions={scaleYOptions}
      tooltipCallbackOptions={tooltipCallbackOptions}
      conditionBackgroundPlugin={ConditionBackgroundPlugin}
      backgroundOptions={backgroundOptions}
      exportFunction={() => exportHydrographData(gaugeDetails, historicalData, forecastedData)}
    />
  );
}

StageHydrograph.propTypes = {
  gaugeDetails: PropTypes.object.isRequired,
  useNwmModel: PropTypes.bool,
  chartRef: PropTypes.object,
  isFullGraphDisplay: PropTypes.bool,
  minDisplayDate: PropTypes.instanceOf(Date),
};

export default StageHydrograph;