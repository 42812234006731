import React from 'react';
import PropTypes from 'prop-types';
import SubscriptionList from './SubscriptionList';
import SubscriptionActions from './SubscriptionActions';
import SubscriptionsMessage from './SubscriptionsMessage';


const SubscriptionsContainer = ({ sites }) => {
  return (
    <>
      {!sites || sites.length === 0 ?
        <SubscriptionsMessage>
          No alert subscriptions. To subscribe, select a gauge on the map and use the
          Subscribe button in the bottom drawer.
        </SubscriptionsMessage>
        :
        <>
          {sites.length > 0 && <SubscriptionActions />}
          <SubscriptionList sites={sites} />
        </>
      }
    </>
  );
};

SubscriptionsContainer.propTypes = {
  sites: PropTypes.array.isRequired
};

export default SubscriptionsContainer;