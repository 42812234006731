import React from 'react';
import PropTypes from 'prop-types';
import useMapStore from '../../stores/mapStore';


const ShowingCount = ({ className }) => {
  const gaugeCount = useMapStore(state => state.gaugeCount);

  return (
    <div className={`text-light-gray text-truncate ${className}`}>
      Showing {gaugeCount} Gauges
    </div>
  );
}

ShowingCount.propTypes = {
  className: PropTypes.string
};

export default ShowingCount;