import React from 'react';
import SingleAccordion from 'src/components/SingleAccordion';
import AssetsLegend from './AssetsLegend';

const AssetLegendContainer = () => {
  return (
    <>
      <SingleAccordion
        header="NCDOT Assets"
        eventKey="assets"
        defaultEventKey={null}
      >
        <AssetsLegend />
      </SingleAccordion>
    </>
  )
}
export default AssetLegendContainer; 1