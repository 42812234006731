import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import agent from 'src/lib/agent';
import { FileEarmarkSpreadsheet, FilePdf, FileWord } from 'react-bootstrap-icons';


const ReportButton = ({ text, type, query, dynamicQueryParams, useAuth = true }) => {
  const [loading, setLoading] = useState(false);


  const downloadFile = async (formData) => {
    try {
      // download report file
      const response = await agent.report.create(formData, useAuth);

      // Get filename from content-disposition
      const filename = response.headers.get('content-disposition')
        .split(';')
        .find(n => n.includes('filename='))
        .replace('filename=', '')
        .trim();

      // create a blob url for the file
      const url = URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      toast.success('Report downloaded successfully.');
    } catch (err) {
      toast.error('Error downloading report.');
    }
  };

  const handleDownload = async () => {
    if (!query) {
      return;
    }

    // convert the query string to an object
    const params = new URLSearchParams(query);
    const formData = new FormData();

    // Append each query parameter to the FormData object
    for (const [key, value] of params) {
      formData.append(key, value);
    }

    // add dynamic query params to the form data
    if (dynamicQueryParams) {
      const dynamicParams = await dynamicQueryParams();
      if (!dynamicParams) {
        return;
      }

      for (const [key, value] of Object.entries(dynamicParams)) {
        formData.append(key, value);
      }
    }

    try {
      setLoading(true);
      await downloadFile(formData);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="action-button px-md-4 d-flex flex-column align-items-center">
      <button
        className="grow d-flex align-items-center p-0 text-black border-0 bg-transparent"
        disabled={loading}
        onClick={async () => { await handleDownload(); }}
      >
        <div
          className="d-flex align-items-center justify-content-center mb-2 rounded-circle bg-primary"
          style={{ width: '65px', height: '65px' }}
        >
          {loading ? <span
            className="spinner-border"
            aria-hidden="true"
          ></span> :
            <>
              {type === 'excel' && <FileEarmarkSpreadsheet size="30" />}
              {type === 'pdf' && <FilePdf size="30" />}
              {type === 'word' && <FileWord size="30" />}
            </>}
        </div>
      </button>
      <div
        className="fw-bold text-center p-1 text-yellow"
        style={{ fontSize: '12px', lineHeight: '17px' }}>
        {text}
      </div>
    </div>
  );
};

ReportButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['excel', 'pdf', 'word']).isRequired,
  query: PropTypes.string.isRequired,
  dynamicQueryParams: PropTypes.func,
  useAuth: PropTypes.bool
};

export default ReportButton;