import React from 'react';
import { Modal } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import PrimaryButton from '../../../components/PrimaryButton';

const WelcomeModalBodySm = ({ handleFindMe, handleExplore, handleSignIn, handleSignUp }) => {
  return (
    <Modal.Body className="p-md-5 pt-md-4">
      <p>
        The Flood Inundation Mapping and Alert Network, or FIMAN, is North Carolina’s resource
        for flood awareness and forecasting. Users can see current and forecasted water levels, and
        sign up to receive email or text alerts when waters are rising nearby.
      </p>
      <p>
          Start getting the information you need by zooming to your location, exploring the map,
          or signing up for flood alerts!
      </p>
      <div className="d-grid gap-3 mt-2">
        <PrimaryButton 
          className="rounded-pill"
          onClick={handleFindMe}>
          FIND MY LOCATION
        </PrimaryButton>
        <PrimaryButton  
          className="rounded-pill"
          onClick={handleExplore}>
          EXPLORE THE MAP
        </PrimaryButton >
        <PrimaryButton  
          className="rounded-pill"
          onClick={handleSignUp}>
          SIGN UP
        </PrimaryButton >
        <PrimaryButton  
          className="rounded-pill"
          onClick={handleSignIn}>
          SIGN IN
        </PrimaryButton >
      </div>
    </Modal.Body>
  )
}; 

WelcomeModalBodySm.propTypes = {
  handleFindMe: PropTypes.func.isRequired,
  handleExplore: PropTypes.func.isRequired,
  handleSignIn: PropTypes.func.isRequired,
  handleSignUp: PropTypes.func.isRequired,
};
export default WelcomeModalBodySm;