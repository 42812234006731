/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DarkCard from 'src/components/DarkCard';
import { GearFill, Search } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import { getColorByCondition, getConditionText } from 'src/lib/conditionUtils';
import useMapStore from 'src/stores/mapStore';
import ArrowIcon from 'src/components/ArrowIcon';
import OutOfServiceIcon from 'src/components/OutOfServiceIcon';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';
import useAlertStore from 'src/stores/alertStore';


const ConditionRingIcon = ({
  conditionColor,
  trend = 0,
  forecastColor = '',
  hasForecast = false,
  inService = true
}) => {
  const getRotation = (trend) => {
    if (trend === 'Rising') return 90;
    if (trend === 'Falling') return -90;
    return 0;
  }

  const rotation = getRotation(trend);

  const forecastStyle = {
    backgroundColor: hasForecast ? forecastColor : 'transparent',
    width: 31,
    height: 31,
    border: hasForecast ? '2px solid black' : 'none',
    color: 'black'
  };

  return (
    <div className="me-2 rounded-circle d-flex align-items-center justify-content-center" style={forecastStyle}>
      <div className="rounded-circle d-flex align-items-center justify-content-center border border-dark border-1" style={{ backgroundColor: conditionColor, width: 20, height: 20, transform: `rotate(${rotation}deg)` }}>
        {inService ? rotation !== 0 && <ArrowIcon height="11px" width="11px" /> : <OutOfServiceIcon height="11px" width="11px" />}
      </div>
    </div>
  )
}

const Condition = ({ site }) => {
  const { condition, trend, inService, forecastCondition } = site.attributes;

  const getCurrentConditionText = (condition, trend, inService) => {
    const conditionText = getConditionText(condition, inService);

    if (!inService) {
      return conditionText;
    }

    return `${conditionText} : ${trend}`;
  }

  return (
    <div className="d-flex align-items-center">
      <ConditionRingIcon
        inService={inService}
        trend={trend}
        hasForecast={forecastCondition > -1}
        forecastColor={getColorByCondition(forecastCondition, inService)}
        conditionColor={getColorByCondition(condition, inService)} />
      <div className="ms-6" style={{ fontSize: 16 }}>
        {getCurrentConditionText(condition, trend, inService)}
      </div>
    </div>
  );
}

const SubscriptionItem = ({ site, onUnsubscribe }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const showGaugeDetails = useMapStore(state => state.showGaugeDetails);
  const showGaugeDetailsMobile = useMapStore(state => state.showGaugeDetailsMobile);
  const toggleNotifications = useMapStore(state => state.toggleNotifications);

  const showSubscriptionConfiguration = useAlertStore(state => state.showSubscriptionConfiguration);

  const { isMobile } = useCustomMediaQuery();

  const onSelected = async () => {
    if (!isMobile) {
      showGaugeDetails(site);
    } else {
      await showGaugeDetailsMobile(site);
      toggleNotifications(false);
    }
  }

  return (
    <DarkCard key={alert.id} title={`Gauge: ${site.attributes.name}`} className="alert-item border-dark mb-0">
      <div className="d-flex flex-column">
        <Condition site={site} />
        <div className="d-flex flex-column mt-2 text-light-gray">
          Last Updated: {site.attributes.lastUpdate}
        </div>
        <div className="align-content-center border-top border-secondary d-flex justify-content-between mt-2 pt-2">
          <Button
            variant="link"
            disabled={isUpdating}
            className="text-yellow text-decoration-none p-0"
            onClick={async () => {
              setIsUpdating(true);
              await onUnsubscribe(site.attributes.siteId);
              setIsUpdating(false);
            }}
          >
            {isUpdating && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>}
            Unsubscribe
          </Button>
          <div className="d-flex gap-3 text-yellow">
            <Button
              variant="link"
              className="text-yellow p-0"
              onClick={async () => {
                await onSelected();
                await showSubscriptionConfiguration(site.attributes.siteId);
              }}
            >
              <GearFill size="25" />
            </Button>
            <Button
              variant="link"
              disabled={isUpdating}
              title="Zoom to Gauge Location"
              className="text-yellow p-0"
              onClick={async () => await onSelected()}
            >
              <Search size="25" />
            </Button>
          </div>
        </div>
      </div>
    </DarkCard>
  );
}

SubscriptionItem.propTypes = {
  site: PropTypes.object.isRequired,
  onUnsubscribe: PropTypes.func.isRequired
};

export default SubscriptionItem;