import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { Search as SearchIcon, XLg } from 'react-bootstrap-icons';
import useMapStore from '../../../stores/mapStore';
import useSearch from './useSearch';
import useClickOutside from '../../../lib/hooks/useClickOutside';
import { SearchResults } from '../../../constants/search';
import SuggestionMenu from './SuggestionsMenu';

import styles from '../../../assets/styles/Search.module.scss';
import useConfigData from '../../../lib/hooks/useConfigData';

const Search = ({ size = 'sm' }) => {
  const searchInputStyle = size === 'sm' ? styles.searchInputSm : styles.searchInputLg;

  const ref = useClickOutside(() => handleHide());

  const { data: config } = useConfigData('Map');

  const mapManager = useMapStore((state) => state.mapManager);
  const clearAllSelectedFeatures = useMapStore((state) => state.clearAllSelectedFeatures);
  const showGaugeDetails = useMapStore((state) => state.showGaugeDetails);

  const { value, setValue, suggestions, clearSuggestions, fetchCandidates } = useSearch(
    mapManager,
    config?.geocoder.url,
    config?.geocoder.token,
    config?.geocoder.searchExtent, 
    config?.geocoder.maxSuggestions,
    '', 
    300,
  );

  const handleHide = () => {
    if(value === '') return;
    setValue('', false);
    clearSuggestions();
  }

  const handleSelect = async (suggestion) => {
    if (suggestion.type === SearchResults.GAUGE) {
      showGaugeDetails(suggestion.meta);
    } else {
      await handlePlaceSelect(suggestion);
    }

    // clear the search input and suggestions
    handleHide();
  }

  const handlePlaceSelect = async (suggestion) => {
    const { name, key } = suggestion;

    // get the details of the place
    const results = await fetchCandidates(name, key);

    if (results.candidates.length > 0) {
      const { location } = results.candidates[0];
      const target = [location.x, location.y];

      // hide anything selected and zoom to the location
      clearAllSelectedFeatures();
      mapManager.showHoverPopup(null);
      mapManager.zoomToFeatureByGeometry(target, 13);
    }
  }

  return (
    <div
      ref={ref}
      className="d-flex align-items-center justify-content-evenly rounded-pill ps-2 pe-3 bg-white h-100 position-relative">
      <SearchIcon
        className={styles.searchIcon}
        size={18}
      />
      <input
        className={`flex-grow-1 ps-2 ${searchInputStyle}`}
        type="text"
        placeholder="Search by Location or Gauge"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />

      <div style={{ width: '30px', marginRight: '-5px' }}>
        {value ?
          <Button
            variant="link"
            size="sm"
            className="d-flex align-items-center px-3 text-dark"
            onClick={() => setValue('')}
          >
            <XLg />
          </Button> : null
        }
      </div>

      {suggestions.length > 0 ?
        <SuggestionMenu       
          suggestions={suggestions} 
          onSelect={handleSelect}
        />
        : null
      }
    </div>
  );
};

Search.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg'])
};


export default Search;