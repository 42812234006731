import React from 'react'; 
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';

const NotificationIconBadge = ({ className, height, width, offset = -2, children }) => {

  return (
    <Badge
      pill
      className={` ${className} position-absolute p-0`}
      bg="danger"
      style={
        {
          width: width,
          height: height,
          top: offset, right: offset,
          border: '1px solid #fff',
        }}
    >
      {children || <>&nbsp;</>}
    </Badge>
  )
}; 

NotificationIconBadge.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  offset: PropTypes.number,
  children: PropTypes.node,
};

export default NotificationIconBadge;