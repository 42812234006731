import { useMemo } from 'react';
import { useQuery } from 'react-query';
import agent from '../agent';

const useUsers = () => {
  const { data, isLoading } = useQuery('users', () => {
    return agent.user.list();
  }, {
    enabled: true,
    staleTime: Infinity
  });
 
  const entries = useMemo(() => {
    return data?.map(entry => {
      return {
        ...entry,
        phoneNumber: entry.phoneNumber ?? '',
      }
    }) || [];
  }, [data]);

  return { entries, isLoading }
}; 

export default useUsers;