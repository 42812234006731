import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, Button, Form, Table } from 'react-bootstrap';
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  flexRender
} from '@tanstack/react-table';
const columnHelper = createColumnHelper();
import { exportToExcel } from 'src/lib/dataExport';
import { formatDate } from 'src/lib/dateUtils';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import PaginationFooter from 'src/components/PaginationFooter';
import useAdminGauges from 'src/lib/hooks/useAdminGauges';
import PrimaryButton from 'src/components/PrimaryButton';
import LoadingDisplay from 'src/components/LoadingDisplay';
import CenteredCell from '../../map/gauges/gridgets/CenteredCell';
import EditPhotosModal from '../photos/EditPhotosModal';
import { confirm } from 'src/components/ConfirmationModal';
import { toast } from 'react-toastify';
import agent from 'src/lib/agent';
import useGaugeStore from 'src/stores/gaugeStore';
import GaugeEditModal from './GaugeEditModal';

const ManageGauges = () => {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const [showEditPhotos, setShowEditPhotos] = useState(false);
  const [gauge, setGauge] = useState(null);
  const [gaugeIndex, setGaugeIndex] = useState(null);
  const { data: adminGauges, isLoading } = useAdminGauges();
  const [ownerOptions, setOwnerOptions] = useState([]);

  const [data, setData] = useState(adminGauges);
  const toggleEditGaugeModal = useGaugeStore(state => state.toggleEditGaugeModal);

  useEffect(() => {
    setData(adminGauges);
  }, [adminGauges]);

  useEffect(() => {
    const allOwners = data?.map(x => { return x.owner });
    let uniqueOwners = [...new Set(allOwners)].sort();
    uniqueOwners.push('Custom');
    setOwnerOptions(uniqueOwners);
  }, [data]);

  const handleGaugePhotos = (row) => {
    const gauge = row.original;
    setGauge(gauge);
    setShowEditPhotos(true);
  }

  const handleEditGauge = (row) => {
    const gauge = row.original;
    setGaugeIndex(row.index);
    setGauge(gauge);
    toggleEditGaugeModal(gauge);
  }

  const getNullableBoolTextValue = (value) => {
    if (value === null) return '';
    if (!value) return 'No';
    if (value) return 'Yes';
    return '';
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnsMemo = useMemo(() => {
    return [
      columnHelper.accessor('select', {
        header: () => '',
        cell: (info) => {
          return (
            <CenteredCell>
              <Button className="p-1" variant="link" onClick={() => handleEditGauge(info.row)}>Edit</Button> |
              {info.row?.original?.inService
                ? < Button className="p-1" variant="link" onClick={() => handleDeactivate(info.row)}>Deactivate</Button>
                : < Button className="p-1" variant="link" onClick={() => handleActivate(info.row)}>Activate</Button>
              } |
              <Button
                className="p-1"
                variant="link"
                onClick={() => handleGaugePhotos(info.row)}
              >
                Photos
              </Button>
            </CenteredCell >
          )
        },
        enableSorting: true,
        enableColumnFilter: false,
        size: 250,
      }),
      columnHelper.accessor('siteId', {
        header: () => 'Site ID',
        cell: info => info.getValue(),
        size: 50,
        enableSorting: true,
        enableGlobalFilter: true,
      }),
      columnHelper.accessor('isCoastal', {
        header: () => 'Is Coastal',
        cell: info => getNullableBoolTextValue(info.getValue()),
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('name', {
        header: () => 'Gauge Name',
        cell: info => <div style={{ whiteSpace: 'nowrap' }}>{info.getValue()}</div>,
        size: 1500,
        enableSorting: true,
        enableGlobalFilter: true,
      }),
      columnHelper.accessor('county', {
        header: () => 'County',
        cell: info => info.getValue(),
        size: 50,
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('owner', {
        header: () => 'Owner',
        cell: info => info.getValue(),
        size: 100,
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('forecastPt', {
        header: () => 'RFC Forecast',
        cell: info => getNullableBoolTextValue(info.getValue()),
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('nwmForecast', {
        header: () => 'NWM Forecast',
        cell: info => getNullableBoolTextValue(info.getValue()),
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('forecastId', {
        header: () => 'Forecast ID',
        cell: info => info.getValue(),
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('rainCondition', {
        header: () => 'Rain',
        cell: info => getNullableBoolTextValue(info.getValue()),
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('gageDatum', {
        header: () => 'Gauge Datum (ft) NAVD88',
        cell: info => formatInitialNumbers(info.getValue()),
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('bankFull', {
        header: () => 'Monitor (ft)',
        cell: info => formatInitialNumbers(info.getValue()),
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('minor', {
        header: () => 'Minor (ft)',
        cell: info => formatInitialNumbers(info.getValue()),
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('moderate', {
        header: () => 'Moderate (ft)',
        cell: info => formatInitialNumbers(info.getValue()),
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('major', {
        header: () => 'Major (ft)',
        cell: info => formatInitialNumbers(info.getValue()),
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('isScenario', {
        header: () => 'Has Library',
        cell: info => getNullableBoolTextValue(info.getValue()),
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('svcMinElev', {
        header: () => 'Library Min Elev (ft)',
        cell: info => formatInitialNumbers(info.getValue()),
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('svcMaxElev', {
        header: () => 'Library Max Elev (ft)',
        cell: info => formatInitialNumbers(info.getValue()),
        enableSorting: true,
        enableGlobalFilter: false,
      }),
    ];
  });

  const formatInitialNumbers = (value) => {
    if (!value) return '';
    const parsedNumber = parseFloat(value);
    if (isNaN(parsedNumber)) return '';
    return parsedNumber;
  }

  const options = {
    data: data ?? [],
    columns: columnsMemo,
    state: {
      sorting,
      columnFilters,
      globalFilter
    },
    enableFilters: true,
    enableColumnFilters: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    meta: {
      updateRowData: (rowIndex, value) => {
        const dataClone = [...data];
        dataClone[rowIndex] = value;
        setData(dataClone)
      },
    },
  };

  const table = useReactTable(options);

  const reset = useCallback(() => {
    if (!table) return;

    table.resetColumnFilters(true);
    table.resetGlobalFilter(true);
    setSorting([]);
  }, [table]);

  useEffect(() => {
    reset();
    return () => {
      reset();
    }
  }, [adminGauges, table, reset]);

  const updateGauge = async (index, gauge) => {
    let response = await agent.gauge.update(gauge);
    table.options.meta?.updateRowData(index, response);
    setGauge(null);
    setGaugeIndex(null);
  }
  const handleDeactivate = async (row) => {
    const gauge = row.original;
    gauge.inService = false;

    if (await confirm(
      `Are you sure you want to deactivate ${gauge.name}?`,
      'Deactivate Gauge',
      'Continue',
      'Cancel',
      async () => updateGauge(row.index, gauge)
    )) {
      toast.success(`You have successfully deactivated the gauge. This change will be reflected in the Map after the next ETL.`);
    }
  }

  const handleActivate = async (row) => {
    const gauge = row.original;
    gauge.inService = true;
    if (await confirm(
      `Are you sure you want to activate ${gauge.name}?`,
      'Activate Gauge',
      'Continue',
      'Cancel',
      async () => updateGauge(row.index, gauge)
    )) {
      toast.success(`You have successfully activated the gauge. This change will be reflected in the Map after the next ETL.`);
    }

  }

  const handleExport = () => {
    const dataMap = [
      {
        columnName: 'Site ID',
        dataKey: 'siteId',
      },
      {
        columnName: 'In Service',
        dataKey: 'inService',
        converter: value => getNullableBoolTextValue(value)
      },
      {
        columnName: 'Is Coastal',
        dataKey: 'isCoastal',
        converter: value => getNullableBoolTextValue(value)
      },
      {
        columnName: 'Gauge Name',
        dataKey: 'name',
      },
      {
        columnName: 'County',
        dataKey: 'county',
      },
      {
        columnName: 'Owner',
        dataKey: 'owner',
      },
      {
        columnName: 'RFC Forecast',
        dataKey: 'forecastPt',
        converter: value => getNullableBoolTextValue(value)
      },
      {
        columnName: 'Forecast ID',
        dataKey: 'forecastId',
      },
      {
        columnName: 'Rain',
        dataKey: 'rainCondition',
        converter: value => getNullableBoolTextValue(value)
      },
      {
        columnName: 'Gauge Datum',
        dataKey: 'gageDatum',
      },
      {
        columnName: 'Monitor (ft)',
        dataKey: 'bankFull',
      },
      {
        columnName: 'Minor (ft)',
        dataKey: 'minor',
      },
      {
        columnName: 'Moderate (ft)',
        dataKey: 'moderate',
      },
      {
        columnName: 'Major (ft)',
        dataKey: 'major',
      },
      {
        columnName: 'Has Library',
        dataKey: 'isScenario',
        converter: value => getNullableBoolTextValue(value)
      },
      {
        columnName: 'Library Min Elev (ft)',
        dataKey: 'svcMinElev',
      },
      {
        columnName: 'Library Max Elev (ft)',
        dataKey: 'svcMaxElev',
      }

    ];

    const subHeaders = [
      `This report was generated from the Admin Manage Gauges Table`
    ];

    const filename = `manage_gauges_${formatDate(Date.now(), 'MMddyyy')}.xlsx`;

    exportToExcel(
      'FIMAN Gauges Export',
      subHeaders,
      dataMap,
      table.getFilteredRowModel().rows.map(row => row.original),
      filename,
      'Gauges'
    );
  }

  const validateGaugeName = (name, formValues) => {
    let existingGauges = [...adminGauges];
    return !existingGauges.some(g => g.name === name && g.siteId !== formValues.siteId);
  }

  if (isLoading) {
    return (<LoadingDisplay />);
  }



  return (
    <>
      <Card className="admin-table">
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <Card.Title className="m-0">
              Manage Gauges
            </Card.Title>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-between p-1 pb-2">
            <div className="d-flex gap-1">
              <PrimaryButton className="rounded-pill btn-sm m-1" onClick={() => handleExport()}>
                Export
              </PrimaryButton>
              <PrimaryButton
                className="rounded-pill btn-sm m-1"
                onClick={() => reset()}
              >
                Reset
              </PrimaryButton>
            </div>
            <div className="d-flex gap-1 w-50">
              <Form.Control
                value={globalFilter ?? ''}
                size="sm"
                id="globalFilterID"
                name="somethingCustomThatwonttriggerautofill-1234"
                onChange={e => setGlobalFilter(e.target.value)}
                className="d-flex align-items-center w-100"
                placeholder="Search by Gauge Name or Site ID"
              />
            </div>
          </div>
          <div className="scrollable-table">
            <Table size="sm" variant="dark" striped bordered hover>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} style={{ width: header.getSize() }}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'd-flex cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <span className="ms-1"><CaretUpFill /></span>,
                              desc: <span className="ms-1"><CaretDownFill /></span>,
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className={row.getIsSelected() ? 'table-light' : ''}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id} >
                        {flexRender(cell.column.columnDef.cell, cell.getContext(), row)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <PaginationFooter
            table={table}
            totalRecordCount={table.getFilteredRowModel().rows.length}
            alwaysDisplay={true}
          />
        </Card.Body>
      </Card>
      <EditPhotosModal
        onHide={() => {
          setShowEditPhotos(false);
          setGauge(null);
        }}
        showing={showEditPhotos}
        gauge={gauge} />
      {gauge != null && (
        <GaugeEditModal
          onHide={() => {
            setGauge(null);
            setGaugeIndex(null);
          }}
          gauge={gauge}
          ownerOptions={ownerOptions}
          updateRowData={table.options.meta?.updateRowData}
          gaugeIndex={gaugeIndex}
          validateGaugeName={validateGaugeName}
        />
      )}

    </>
  );
}

export default ManageGauges;