import React from 'react';
import PropTypes from 'prop-types';
import Widget from './Widget';


const HistoricalPeakWidget = ({ onClick, onActionClick, actionIcon, children }) => {
  return (
    <Widget
      title="Historical Peaks"
      onClick={onClick}
      onActionClick={onActionClick}
      actionIcon={actionIcon}
    >
      {children}
    </Widget>
  );
}

HistoricalPeakWidget.propTypes = {
  onClick: PropTypes.func,
  onActionClick: PropTypes.func,
  actionIcon: PropTypes.node,
  children: PropTypes.node,
};


export default HistoricalPeakWidget;