import { useQuery } from 'react-query';
import useConfigData from './useConfigData';
import { useMemo } from 'react';
import { queryAllFeatures } from '../queries';


const routeClassToName = (routeClass) => {
  switch (routeClass) {
    case '1':
      return 'Interstate';
    case '2':
      return 'US Highway';
    case '3':
      return 'NC Highway';
    case '4':
    case '5':
      return 'SR Route';
    case '80':
      return 'Ramp';
    default:
      return 'Unknown';
  }
}

const useRoadsAffected = (gaugeId, level) => {
  const { data: config } = useConfigData('Map');

  const { data, isLoading } = useQuery(['road-segments', gaugeId, level], ({ queryKey }) => {
    const [, gaugeId, level] = queryKey;

    const adaptedLevel = level.toString().indexOf('.') != -1 ? level : `${level}.0`;
  
    return queryAllFeatures({
      url: config?.references.roadsInundation,
      where: `GAGE_ID='${gaugeId}' AND (SCEN_ELEV='${adaptedLevel}' OR SCEN_ELEV='${level}')`,
      outFields: ['OBJECTID', 'StreetName', 'FEET', 'Depth_Cat', 'Max_Depth', 'AADT_2016', 'LONG', 'LAT', 'RouteClass'],
      orderByFields: ['RouteClass','Max_Depth desc', 'StreetName'],
      returnGeometry: false,
    }, 1000);
  }, {
    enabled: gaugeId !== undefined && level !== undefined && level !== null
  });

  const entries = useMemo(() => {
    const rows = data ? data
      .map(entry => {
        return {
          objectId: entry.attributes.OBJECTID,
          streetName: entry.attributes.StreetName,
          length: entry.attributes.FEET,
          depthCat: entry.attributes.Depth_Cat,
          maxDepth: entry.attributes.Max_Depth,
          aadt: entry.attributes.AADT_2016,
          center: [entry.attributes.LONG, entry.attributes.LAT],
          routeClass: routeClassToName(entry.attributes.RouteClass),
        }
      }) : [];
    return rows;
  }, [data]);

  return { entries, isLoading };
};

export default useRoadsAffected;