import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import PrimaryIconButton from '../../../../components/PrimaryIconButton';
import { ThreeDots } from 'react-bootstrap-icons';

const Widget = ({ title, className, onClick, onActionClick, actionIcon = <ThreeDots size="15" />, children }) => {
  return (
    <Card
      className={`widget ${className} ${onClick !== undefined ? 'cursor-pointer' : ''}`}
      onClick={onClick}
    >
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          {title}
          {onActionClick &&
            <PrimaryIconButton
              onClick={onActionClick}
              className="rounded-pill py-0 px-0 d-flex align-items-center justify-content-center grow"
              style={{ width: '19px', height: '19px' }}
              icon={actionIcon}
            />}
        </div>
      </Card.Header>
      <Card.Body>
        {children}
      </Card.Body>
    </Card>
  );
};

Widget.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  actionIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
  onActionClick: PropTypes.func
};

export default Widget;