import React from 'react'; 
import PropTypes from 'prop-types';
import Collapse from 'react-bootstrap/Collapse';
import GaugeDrawerHeader from './GaugeDrawerHeader';
import GaugeContent from './GaugeContent';
import { DrawerLevels } from 'src/constants/drawerLevels';
import styles from '../../../assets/styles/Gauge.module.scss';
import useMapStore from '../../../stores/mapStore';
import useGaugeStore from '../../../stores/gaugeStore';
import { Tabs } from 'src/constants/tabs';


const GaugeDrawer = ({ className }) => {
  const showGaugeData = useMapStore(state => state.showGaugeData); 
  const drawerLevel = useGaugeStore(state => state.drawerLevel);
  const activeTab = useGaugeStore(state => state.activeTab);

  const doesActiveTabHaveSlider = 
    activeTab === Tabs.SCENARIO || 
    activeTab === Tabs.FORECAST ||
    activeTab === Tabs.HISTORY;

  // for future implementation - we will need checks on if the table widget is open or not and have a class for slider with widget
  // i guess we will also need a height for tab with table widget with no slider...
  
  //uhhhhh I hate this. there has to be a better way to do this.
  const getDrawerHeightClassName = () => {
    switch (drawerLevel) {
      case DrawerLevels.MIN:
        return styles.gaugeCardMinimizedHeight;
      
      case DrawerLevels.FULL:
        return styles.gaugeCardHeightActive;
      
      case DrawerLevels.HALF: {
        //if (doesActiveTabHaveSlider && (showScenarioBuildingGridget))
        //  return styles.gaugeSliderGridgetCardHeight;
        if (doesActiveTabHaveSlider)
          return styles.gaugeSliderCardHeight;
        else
          return styles.gaugeCardHeight;
      }
      default:
        return styles.gaugeCardHeight;
    }
  } 

  return (
    <>
      <Collapse in={showGaugeData} className={className}>
        <div>
          {drawerLevel && (
            <div>
              <div
                className={`${getDrawerHeightClassName()} ${styles.transform} d-flex flex-column border-end border-dark dark-gray-with-opacity`}
              >
                <GaugeDrawerHeader />
                <GaugeContent />
              </div>
            </div>
          )}       
        </div>
      </Collapse>
    </>
  )
}

GaugeDrawer.propTypes = {
  className: PropTypes.string.isRequired
};

export default GaugeDrawer; 