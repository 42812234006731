import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';

import App from './App';
import { HashRouter as Router } from 'react-router-dom';

import './assets/styles/index.scss';
import './assets/styles/App.scss';

// Configure Amplify in index file or root file
async function configureAmplify() {
  try {
    // Get AWS Exports from public folder - the committed version is used for local development
    // this file will be replaced by the build process with the correct values for the environment
    const response = await fetch(`${window.location.origin}/awsExports.json`);
    const awsExports = await response.json();

    Amplify.configure({
      Auth: {
        region: awsExports.REGION,
        userPoolId: awsExports.USER_POOL_ID,
        userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
        oauth: {
          domain: awsExports.DOMAIN,
          scope: ['email', 'profile', 'openid'],
          redirectSignIn: awsExports.REDIRECT_SIGNIN,
          redirectSignOut: awsExports.REDIRECT_SIGNOUT,
          responseType: 'code', // 'token' for Implicit grant, 'code' for Authorization code grant,
        }
      }
    });
    appengGoogleScriptTags();
  } catch (error) {
    console.error(`Failed to import awsExports THIS IS A BIG DILL 🥒, users won't be able to login, this file should be added as part of the build: ${error}`);
    appengGoogleScriptTags();
  }
}

async function appengGoogleScriptTags() {
  try {
    const response = await fetch(`${window.location.origin}/googleAnalyticsToken.json`);
    const tokenConfig = await response.json();
    let scriptOne = document.createElement('script'); 
    scriptOne.async = true; 
    scriptOne.src = `https://www.googletagmanager.com/gtag/js?id=${tokenConfig.TOKEN}`; 
    document.head.appendChild(scriptOne); 

    let scriptTwo = document.createElement('script'); 
    scriptTwo.type = 'text/javascript'; 
    scriptTwo.innerHTML = `window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments); } gtag("js", new Date()); gtag("config", "${tokenConfig.TOKEN}");`;
    document.head.appendChild(scriptTwo); 
    mainRender()
  } catch (error) {
    console.error('Failed to import googleAnalyticsTokens. Not such a big deal.')
    mainRender()
  }

}

function mainRender() {
  // must have this because of facebooke redirects.  the #_=_ is appeneded by fb
  // causes LOTS of issues, have to remove it at SOURCE before the hash router is actually created 
  // otherwise more state issues are caused 
  if (window.location.hash === '#_=_')
    window.location.hash = '';
  
  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    <Router>
      <App />
    </Router>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

configureAmplify();
