import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { confirm } from 'src/components/ConfirmationModal';
import { roundToTenth } from 'src/lib/mathUtils';
import { convertElevationToStage } from 'src/lib/stageConversionUtils';
import useAlertStore from 'src/stores/alertStore';
import { Pencil, Trash } from 'react-bootstrap-icons';
import { Button, Form } from 'react-bootstrap';
import { AlertEvents } from 'src/constants/alertEvents';
import styles from '../../../assets/styles/WeatherToggle.module.scss';


const Subscription = ({ gauge, subscription }) => {
  const [sub, setSub] = useState(subscription);
  const [events, setEvents] = useState(subscription.events);
  const [updating, setUpdating] = useState(false);

  const deleteSubscription = useAlertStore(state => state.deleteSubscription);
  const updateSubscription = useAlertStore(state => state.updateSubscription);

  const toggleShowEditModal = useAlertStore(state => state.toggleShowEditModal);
  const setEditAlert = useAlertStore(state => state.setEditAlert);

  const didMount = useRef(false);

  const onToggle = (e) => {
    setSub({ ...sub, enabled: e.target.checked });
  };

  useEffect(() => {
    // Return early, if this is the first render so we are not updating the subscription
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    const onChange = async () => {
      const newSubscription = {
        ...sub,
        events: [...events],
      };
      setUpdating(true);
      await updateSubscription(newSubscription);
      setUpdating(false);
    };

    onChange();

  }, [sub, events, updateSubscription, gauge.siteId]);

  const onDelete = async () => {
    await confirm(
      `Are you sure you want to delete this alert?`,
      'Delete Alert',
      'Continue',
      'Cancel',
      async () => await deleteSubscription(subscription.id)
    );
  };

  const elevationText = () => {
    if (gauge.isCoastal) {
      return `${roundToTenth(sub.value)} ft NAVD 88`;
    } else {
      return `${roundToTenth(convertElevationToStage(sub.value, gauge.gageDatum))} ft (${roundToTenth(sub.value)} ft NAVD 88)`;
    }
  };

  return (
    <div
      className={`d-flex flex-column p-1 ps-2 rounded-2 ${updating ? 'dark-gray-with-opacity' : ''}`}
      style={{ margin: -5 }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-3">
          <div className="form-check form-switch">
            <input
              className={`form-check-input ${styles.weatherToggle}`}
              type="checkbox"
              role="switch"
              title="Enable/Disable Subscription"
              checked={sub.enabled}
              onChange={async (e) => {
                onToggle(e);
              }}
            />
          </div>
          <div className="ml-2">{sub.name}: {elevationText()}</div>
        </div>
        <div className="d-flex align-items-center gap-1">
          <Button
            className="grow p-2"
            title="Delete"
            variant="link"
            onClick={async () => {
              await onDelete();
            }}
          >
            <Trash size="18" />
          </Button>
          <Button
            className="grow p-2"
            title="Edit"
            variant="link"
            onClick={() => {
              setEditAlert({
                ...sub,
                events: [...events],
              });
              toggleShowEditModal(true);
            }}
          >
            <Pencil size="18" />
          </Button>
        </div>
      </div>
      {sub.enabled && <div>
        <div className="align-content-lg-center d-flex d-lg-flex flex-column flex-lg-row gap-2 gap-lg-4" style={{ marginTop: 10 }}>
          {events.map((event) => (
            <div key={`${subscription.id}-${event.alertEventId}`} className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                label={AlertEvents.getEventById(event.alertEventId)}
                id={`${subscription.id}-${event.alertEventId}`}
                checked={event.enabled}
                onChange={async (evt) => {
                  setEvents(events.map((e) => {
                    if (e.alertEventId === event.alertEventId) {
                      return { ...e, enabled: evt.target.checked };
                    }
                    return e;
                  }));
                }}
              />
            </div>
          ))}
        </div>
      </div>}
    </div>
  );
};

Subscription.propTypes = {
  gauge: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
};

export default Subscription;