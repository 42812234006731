import React, { useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import useMapStore from '../../../stores/mapStore';
import SubscriptionsContainer from './SubscriptionsContainer';
import useUserStore from 'src/stores/userStore';
import SubscriptionsMessage from './SubscriptionsMessage';
import useAlertStore from 'src/stores/alertStore';


const SubscriptionsSidebar = ({ ...props }) => {
  const toggleNotifications = useMapStore(state => state.toggleNotifications);
  const showNotifications = useMapStore(state => state.showNotifications);

  const user = useUserStore(state => state.cognitoUser);

  const sites = useAlertStore(state => state.alertSubscriptions);
  const isLoading = useAlertStore(state => state.loadingAlertSubscriptions);

  useEffect(() => {
    return () => {
      toggleNotifications(false);
    }
  }, [toggleNotifications]);

  const message = () => {
    if (!user) {
      return 'Create or sign into a FIMAN account to subscribe to customized alerts.';
    }

    if (isLoading) {
      return 'Loading alert subscriptions...';
    }
  }

  const loadingWithNoSubscriptions = () => {
    return isLoading && sites?.length === 0;
  }

  return (
    <Offcanvas
      show={showNotifications}
      enforceFocus={false}
      onHide={() => toggleNotifications(false)}
      {...props}
    >
      <Offcanvas.Header closeButton closeVariant="white" style={{ zIndex: 10 }}>
        <Offcanvas.Title>Alert Subscriptions ({sites ? `${sites.length}` : 0})</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {!user || loadingWithNoSubscriptions() ?
          <SubscriptionsMessage>
            {message()}
          </SubscriptionsMessage>
          :
          <SubscriptionsContainer sites={sites} />
        }
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default SubscriptionsSidebar;