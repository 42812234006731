import { useMediaQuery } from 'react-responsive';

const useCustomMediaQuery = () => {
  return {
    isMobile: useMediaQuery({ maxWidth: 576 }), 
    isTablet: useMediaQuery({ minWidth: 577, maxWidth: 991 }), 
    isDesktop: useMediaQuery({ minWidth: 992 }) 
  }
}; 

export default useCustomMediaQuery;