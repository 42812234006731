import React from 'react';
import styles from '../../../assets/styles/WeatherToggle.module.scss';
import useMapStore from '../../../stores/mapStore';


const WeatherToggle = () => {
  const showWeather = useMapStore(state => state.showWeather);
  const toggleWeatherAnimation = useMapStore(state => state.toggleWeatherAnimation);    
    
  return (
    <div className="d-flex align-items-center gap-2">
      <div className="form-check form-switch">
        <input
          className={`form-check-input ${styles.weatherToggle}`}
          type="checkbox"
          role="switch"
          checked={showWeather}
          onChange={() => {
            toggleWeatherAnimation();
          }}
        />
      </div>
      <div className="text-light-gray text-truncate">
        Weather Radar
      </div>
    </div>
  );
};

export default WeatherToggle;