import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';


/**
 * A reusable button component that has a yellow background and a title.
 * @param {Object} props - The props for the component.
 * @param {string} props.className - A custom class name for the button.
 * @param {ReactNode} props.children - The content for the button.
 * @see https://react-bootstrap.github.io/components/buttons/
 * @component
 * @example
 * return (
 * <PrimaryButton className="my-class">
 *  <div>
 *   Whatever you want to put in the button goes here.
 *  </div>
 * </PrimaryButton>
 * );
 */
const PrimaryButton = ({ className, children, ...props }) => {
  return (
    <Button
      variant="yellow-primary"
      className={className}
      {...props}
    >
      <div className="d-flex align-items-center justify-content-center">
        <span className="text-truncate">{children}</span>
      </div>
    </Button>
  );
}

PrimaryButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default PrimaryButton;