import React from 'react'; 
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';


/**
 * Tanstack/react-table pagination component 
 * MUST be used with a table that has a getPaginationRowModel import
 * @param {object} table - context of the table object generated by useReactTable hook
 * @param {number} totalRecordCount - total number of records in the table
 * @param {bool} alwaysDisplay - if true, always display the pagination footer, even if there is only one page
 */
const PaginationFooter = ({ table, totalRecordCount, alwaysDisplay= false }) => {
  const getRecordCountFooter = () => {
    const { pageIndex, pageSize } = table.getState().pagination;
    const start = pageIndex * pageSize + 1;
    const end = (pageIndex + 1) * pageSize;
    return `${start} - ${end > totalRecordCount ? totalRecordCount : end} of ${totalRecordCount}`;
  }

  return (
    <>
      {/* do we need to dynamically display??? */}
      {(table.getPageCount() > 1 || alwaysDisplay) && ( 
        <div className="d-flex justify-content-between align-items-center rounded-bottom p-1 primary-blue-background">
          <Pagination size="sm" className="justify-content-center p-0 m-0">
            <Pagination.First
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            />
            <Pagination.Prev
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            />
            <Pagination.Next
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            />
            <Pagination.Last
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            />
          </Pagination>
      
          <span className="d-flex justify-items-center px-2">
            <div>{`Page ${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()}`}</div>
          </span>
          <span className="d-flex justify-items-center px-2">
            <div>{`${getRecordCountFooter()}`}</div>
          </span>
        </div>
      )} 
    </>
  )
}

PaginationFooter.propTypes = {
  table: PropTypes.object.isRequired, 
  totalRecordCount: PropTypes.number.isRequired, 
  alwaysDisplay: PropTypes.bool
};

export default PaginationFooter;