/** 
 * Class to hold the layer names for the map. These are used when 
 * initializing the map and can be used throught the app to reference
 * a specific layer.  These names can be arbitrary as they are purely 
 * for the developer to 'talk' to the app about a conceptual layer.
 */
export class Layers {
  static GAUGES = 'gauges';
  static INUNDATINO = 'inundation';
  static ROADS = 'roads';
  static BRIDGES = 'bridges';
  static DEFAULT_BRIDGES = 'defaultBridges';
  static FLOOD_BUILDINGS = 'flood-buildings'

  static GRAPHICS = 'graphics';
  static WEATHER_RADAR = 'nexrad';
  static NCEM_BASEMAP_OUTER = 'ncem_custom_outer';
  static NCEM_BASEMAP_INNER = 'ncem_custom_inner';

  // boundary layers
  static COUNTIES = 'counties';
  static NCEM_BRANCHES = 'ncem_branches';
  static NCDOT_DIVISIONS = 'ncdot_divisions';
  static RIVER_BASINS = 'river_basins';

  // forecast layers
  static ESTIMATED_RAINFALL_24H = 'estimated_rainfall_24h';
  static ESTIMATED_RAINFALL_48H = 'estimated_rainfall_48h';
  static ESTIMATED_RAINFALL_72H = 'estimated_rainfall_72h';
  static ESTIMATED_RAINFALL_120H = 'estimated_rainfall_120h';
  static ESTIMATED_RAINFALL_168H = 'estimated_rainfall_168h';

  // forecasted layers
  static FORCASTED_RAINFALL_24H = 'forecasted_rainfall_24h';
  static FORCASTED_RAINFALL_48H = 'forecasted_rainfall_48h';
  static FORCASTED_RAINFALL_72H = 'forecasted_rainfall_72h';
  static FORCASTED_RAINFALL_120H = 'forecasted_rainfall_120h';
  static FORCASTED_RAINFALL_168H = 'forecasted_rainfall_168h';

  // NCDOT asset layers
  static NCDOT_ASSETS_BUILDINGS = 'buildings';
  static NCDOT_ASSETS_LAND = 'land';
  static NCDOT_ASSET_GROUP = 'asset-group';

  // Layer groups
  static LAYER_GROUP_WEATHER = 'weather';
  static LAYER_GROUP_BOUNDARY = 'boundaries-map-service';
  static LAYER_GROUP_NCDOT_ASSETS = 'assets';

  // query layers
  static COUNTY = 'county';
  static RIVER_BASIN = 'riverBasin';
  static NCEM_BRANCH = 'ncemBranch';
  static NCEM_DIVISION = 'ncemDivision';
}