import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import RangeSlider from 'react-bootstrap-range-slider';
import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useLibraryLevels from 'src/lib/hooks/useLibraryLevels';
import { getInundationLevel } from 'src/lib/hydroUtils';
import { GaugeMarkerColors } from 'src/constants/gaugeMarkerColors';
import useMapStore from 'src/stores/mapStore';
import { MemoizedRangeScale } from 'src/components/RangeScale';
import { MemoizedLinearGauge } from 'src/components/LinearGauge';
import ScenarioSliderLegend from './ScenarioSliderLegend';
import PillButton from 'src/components/PillButton';
import LoadingDisplay from 'src/components/LoadingDisplay';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import useUserGroup from 'src/lib/hooks/useUserGroup';

// styles for the scale, defined here so they are not recreated on every render
// which causes the scale to rerender due to the object reference changing
const topStyle = { paddingLeft: '9px', paddingRight: '10px', marginBottom: '-13px' };
const bottomStyle = { paddingLeft: '10px', paddingRight: '10px', marginTop: '-13px' };
const linearGaugeStyle = { top: '15px', height: '7px', left: '9px', right: '9px', zIndex: 1000 };


/**
 * The slider used to change the inundation level based on the 
 * available inundation lib for the gauge. The selected MSL level is returned
 * in the onScenarioLevelChange callback.
 */
const ScenarioSlider = ({ initialLevel, onScenarioLevelChange }) => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const mapManager = useMapStore(state => state.mapManager);
  const showInundationLayer = useMapStore(state => state.showInundationLayer);
  const { isAdmin, isAdvanced } = useUserGroup();
  const { data: gauge } = useGaugeDetails(activeGauge?.attributes?.siteId);
  const { levels, isLoading: librariesLoading } = useLibraryLevels(activeGauge?.attributes?.siteId);
  const [scenarioHeight, setScenarioHeight] = useState(0);

  const changeLevel = useCallback(async (height) => {
    // need to keep track of the height since the current level may not be in the levels list
    // and this will reduce the slider snapping to the nearest level
    setScenarioHeight(height);

    // snap the height to the nearest level including the lowest level
    const level = await showInundationLayer(gauge.siteId, gauge.isScenario, height, levels, true);

    // update the road, bridge, and buildings layers
    if (isAdmin || isAdvanced) {
      mapManager.showRoadLayer(gauge?.siteId, level, gauge.isScenario);
      mapManager.showBridgeLayer(gauge?.siteId, level, gauge.isScenario);
    }
    mapManager.showFloodBuildingsLayer(gauge?.siteId, level, gauge.isScenario);

    // pass the level to the callback
    if (onScenarioLevelChange) {
      onScenarioLevelChange(level);
    }
  }, [
    levels,
    mapManager,
    onScenarioLevelChange,
    setScenarioHeight,
    showInundationLayer,
    gauge?.siteId,
    gauge?.isScenario,
    isAdmin,
    isAdvanced
  ]);

  const handleSliderChange = useCallback(async (height) => {
    await changeLevel(height);
  }, [changeLevel]);

  useEffect(() => {
    if (!initialLevel) {
      return;
    }

    if (gauge && !librariesLoading && levels) {
      // get the inundation level for the initial level, snapping to the lowest level if needed
      const level = getInundationLevel(initialLevel ?? 0, levels, true);
      changeLevel(level);
    }
  }, [gauge, librariesLoading, levels, initialLevel, changeLevel]);

  // convert the condition levels to ranges
  const ranges = useMemo(() => {
    if (!gauge || !levels) {
      return [];
    }

    const conditions = [];
    if (gauge.bankFull) {
      conditions.push({
        value: gauge.bankFull,
        color: GaugeMarkerColors.YELLOW,
      });
    }

    if (gauge.minor) {
      conditions.push({
        value: gauge.minor,
        color: GaugeMarkerColors.ORANGE,
      });
    }

    if (gauge.moderate) {
      conditions.push({
        value: gauge.moderate,
        color: GaugeMarkerColors.RED,
      });
    }

    if (gauge.major) {
      conditions.push({
        value: gauge.major,
        color: GaugeMarkerColors.PURPLE,
      });
    }

    const ranges = [];

    if (gauge.svcMinElev < conditions[0].value) {
      ranges.push({
        min: gauge.svcMinElev,
        max: conditions[0].value,
        color: GaugeMarkerColors.GREEN,
      });
    }

    // loop through the conditions and create ranges
    for (let i = 0; i < conditions.length; i++) {
      const condition = conditions[i];
      const nextCondition = conditions[i + 1];

      ranges.push({
        // special case for svcMinElev being higher than the first condition
        min: condition.value < gauge.svcMinElev ? gauge.svcMinElev : condition.value,
        max: nextCondition?.value ?? levels[levels.length - 1],
        color: condition.color,
      });
    }

    return ranges;
  }, [gauge, levels]);

  const getLargeStepSize = () => {
    let size = 1;

    if (levels.length >= 35) {
      size = 2;
    }

    if (levels.length >= 70) {
      size = 4;
    }

    if (gauge.svcMaxElev > 3000) {
      size *= 2;
    }

    return size;
  };

  if ((librariesLoading || !levels)) {
    return <LoadingDisplay />;
  }

  if (levels.length === 0) {
    return (
      <div className="w-100 px-5">
        <div className="align-items-center d-flex justify-content-center w-100">
          No inundation levels available
        </div>
      </div>
    );
  }

  return (
    <div className="w-100 pe-3 bg-black bg-opacity-100 sticky-top">
      <div className="d-flex align-items-center" style={{ marginTop: gauge.isCoastal ? -20 : 0 }}>
        <ScenarioSliderLegend showElevation={!gauge.isCoastal} />
        <PillButton
          className="p-2"
          onClick={() => changeLevel(Math.max(
            parseFloat(scenarioHeight) - parseFloat(gauge.srvInt),
            gauge.svcMinElev
          ))}
        >
          <div className="d-flex align-items-center">
            <ChevronLeft />
          </div>
        </PillButton>
        <div className="w-100 overflow-hidden position-relative d-flex flex-column px-2 py-1">
          {!gauge.isCoastal ? (
            <MemoizedRangeScale
              className="position-relative"
              style={topStyle}
              textPosition="top"
              min={gauge.svcMinElev}
              max={gauge.svcMaxElev}
              offset={gauge.gageDatum}
              smallStep={gauge.srvInt * 1}
              largeStep={getLargeStepSize()}
              size="sm"
            />) : <div className="position-relative" style={{ height: 25 }} />}
          <div className="position-relative">
            <MemoizedLinearGauge
              className="position-absolute"
              style={linearGaugeStyle}
              ranges={ranges}
            />
            <RangeSlider
              style={{ zIndex: 1000 }}
              value={scenarioHeight}
              min={gauge.svcMinElev}
              max={gauge.svcMaxElev}
              step={gauge.srvInt * 1}
              tooltipPlacement={gauge.isCoastal ? 'bottom' : 'top'}
              onChange={changeEvent => handleSliderChange(changeEvent.target.value)}
            />
          </div>
          <MemoizedRangeScale
            className="position-relative"
            style={bottomStyle}
            min={gauge.svcMinElev}
            max={gauge.svcMaxElev}
            smallStep={gauge.srvInt * 1}
            largeStep={getLargeStepSize()}
            textPosition="bottom"
            size="lg"
          />
        </div>
        <PillButton
          className="p-2"
          onClick={() => changeLevel(Math.min(
            parseFloat(scenarioHeight) + parseFloat(gauge.srvInt),
            gauge.svcMaxElev
          ))}
        >
          <div className="d-flex align-items-center">
            <ChevronRight />
          </div>
        </PillButton>
      </div>
      <div
        className="pb-2 align-items-center d-flex justify-content-center w-100"
        style={{ marginTop: -5, fontSize: 14 }}
      >
        Drag to simulate flood severity
      </div>
    </div>
  )
}

ScenarioSlider.propTypes = {
  initialLevel: PropTypes.number,
  onScenarioLevelChange: PropTypes.func,
};

export default ScenarioSlider;