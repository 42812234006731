import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Gridget from './Gridget';
import InfoLink from 'src/components/InfoLink';
import NavItem from 'src/features/map/gauges/NavItem';
import styles from 'src/assets/styles/Gauge.module.scss';
import { Card, Button } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import RoadsImpactedTable from './RoadsImpactedTable';
import RoadsSegmentTable from './RoadsSegmentTable';


const RoadsImpactedGridget = ({ toggleGridget }) => {
  const [activeTab, setActiveTab] = useState('impact');

  return (
    <Gridget>
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <Card.Title className="m-0">
            <div className="d-flex">
              <span>Roads Impacted</span>
              <InfoLink
                className="px-2"
                placement="right"
                title="Roads Impacted"
              >
                <ul>
                  <li>Roadway classification and flood inundation values determined by using NCDOT LRS datasets overlaid on NCEM QL1 and QL2 raster datasets. All lengths are in miles.</li>
                  <li>Some roadways may be inundated that do not appear in this application.</li>
                  <li>Information should be evaluated with ground conditions before road closures or other emergency response actions.</li>
                </ul>
              </InfoLink>
            </div>
          </Card.Title>
          <div className="d-flex m-1">
            <NavItem tabId="impact" tabName="Impact" className={`px-4 ${activeTab === 'impact' ? styles.activeTabItem : styles.tabItem}`} setActiveTab={setActiveTab} />
            <NavItem tabId="segments" tabName="Road Segments" className={`px-4 ${activeTab === 'segments' ? styles.activeTabItem : styles.tabItem}`} setActiveTab={setActiveTab} />
          </div>
          <div className="d-flex m-1">
            <Button
              variant="link"
              className="d-flex align-items-center px-1 text-white pr-2"
              onClick={() => toggleGridget()}
            >
              <XLg />
            </Button>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-column">
        {activeTab === 'impact' && (
          <RoadsImpactedTable />
        )}
        {activeTab === 'segments' && (
          <RoadsSegmentTable />
        )}
      </Card.Body>
    </Gridget>
  )
};

RoadsImpactedGridget.propTypes = {
  toggleGridget: PropTypes.func.isRequired,
};

export default RoadsImpactedGridget;