import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import useVH from 'react-viewport-height';
import AppRoutes from './AppRoutes';
import ErrorBoundary from './ErrorBoundary';
import { Auth } from 'aws-amplify';
import 'react-toastify/dist/ReactToastify.css';
import useUserStore from './stores/userStore';
import { useIdleTimer } from 'react-idle-timer';

const App = () => {
  // help with calculating vh on mobile devices
  useVH();
  const setCognitoUser = useUserStore(state => state.setCognitoUser);
  const validateUser = useUserStore(state => state.validateUser);
  const cognitoUser = useUserStore(state => state.cognitoUser);
  const setTokenPayload = useUserStore(state => state.setTokenPayload);
  const signOut = useUserStore(state => state.signOut);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: true,
        structuralSharing: false,
        retry: false,
        retryCount: 0,
        staleTime: 1000 * 60 * 15, // 15 minutes
      },
    },
  });

  useEffect(() => {
    const checkUser = async () => {
      try {
        const validatedUser = await Auth.currentAuthenticatedUser();
        if (validatedUser !== null && cognitoUser === null) {
          setCognitoUser(validatedUser);
          setTokenPayload(validatedUser.signInUserSession.idToken.payload);
          validateUser();
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (cognitoUser === null) {
      checkUser();
    }
  }, [cognitoUser, setCognitoUser, setTokenPayload, validateUser]);

  const onIdle = () => {
    signOut();
  }

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 60, // 60 minutes,
    startOnMount: true,
  });

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <AppRoutes />
          <ToastContainer
            autoClose={4000}
            hideProgressBar={false}
            position={toast.POSITION.TOP_CENTER}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
            progress={undefined}
            theme={'dark'}
          />
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
