import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useUserGroup from 'src/lib/hooks/useUserGroup.js';
import { CognitoGroups } from 'src/constants/cognitoGroups.js';

/**
 * @description Component that checks if the user has the required permissions
 * Should set ONE of the following properties permission, any, OR all
 * @param {string} [props.group] A single required permission
 * @param {array<string>} [props.any] Passes if ANY of the permissions are met
 * @param {boolean} [props.isRoute] If true, the component will render a Navigate component instead of null if the user does not have the required permissions
 * @param {React.ReactNode} [props.children] - The children to render if the user has the required permissions
 * @returns {React.ReactElement} - The children to render if the user has the required permissions OR null if the user does not have the required permissions
 */
const PermissionFilter = ({ group, any, all, isRoute, children }) => {

  const { isAdmin, isSubscriber, isAdvanced } = useUserGroup();


  // If the permissionFilter is a route, return a Navigate component to the root.
  const returned = isRoute === true ? (<Navigate to="/" />) : null;

  const hasPermission = (group) => {
    switch (group) {
      case CognitoGroups.ADMIN:
        return isAdmin;
      case CognitoGroups.SUBSCRIBER:
        return isSubscriber;
      case CognitoGroups.ADVANCED:
        return isAdvanced;
      case CognitoGroups.ALL:
        return true;
      default:
        return false;
    }
  }

  const hasAnyPermissions = (groups) => {
    return groups.some(group => hasPermission(group));
  }

  const hasAllPermissions = (groups) => {
    return groups.every(group => hasPermission(group));
  }

  // Check if the user has the singular permission
  if (hasPermission(group)) {
    return children;
  } else if (any) {
    // Check if the user has any of the permissions
    if (hasAnyPermissions(any)) {
      return children;
    }
  } else if (all) {
    // Check if the user has all of the permissions
    return hasAllPermissions(all) ? children : returned;
  }
  // User has failed all the checks, return a null component (nothing rendered) or a Navigate component to the root.
  return returned;
};

PermissionFilter.propTypes = {
  group: PropTypes.string,
  any: PropTypes.array,
  all: PropTypes.array,
  isRoute: PropTypes.bool,
  children: PropTypes.node,
};

PermissionFilter.defaultProps = {
  group: null,
  any: null,
  all: null,
  isRoute: false,
};

export default PermissionFilter;