import React from 'react';
import PropTypes from 'prop-types';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useMapStore from 'src/stores/mapStore';
import NameValueText from 'src/components/NameValueText';
import ArrowIcon from 'src/components/ArrowIcon';
import LoadingDisplay from 'src/components/LoadingDisplay';
import PrimaryButton from 'src/components/PrimaryButton';
import { roundToTenth } from 'src/lib/mathUtils';
import { getColorByCondition } from 'src/lib/conditionUtils';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';

const StageContent = ({ modalCallback }) => {
  const { isDesktop } = useCustomMediaQuery();
  const activeGauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails, isLoading } = useGaugeDetails(activeGauge?.attributes?.siteId);

  if (isLoading) {
    return <LoadingDisplay />;
  }

  const { isCoastal, hydroAllStage, currentElevationMsl, trend, condition, inService } = gaugeDetails;
  
  const getRotation = () => { 
    // if the gauge is out of service, don't show the arrow
    if (!inService) return 0;
    
    if (trend === 'Rising') return 90;
    if (trend === 'Falling') return -90;
    return 0;
  }
  
  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div className="me-2 rounded-circle d-flex align-items-center justify-content-center" style={{ backgroundColor: getColorByCondition(condition, inService), width: 20, height: 20, transform: `rotate(${getRotation()}deg)` }}>
          { getRotation() !== 0 && <ArrowIcon height="13px" width="13px"/> }
        </div>   
      </div>
      {isCoastal && 
        <>
          <NameValueText label="Water Elevation" value={`${roundToTenth(currentElevationMsl)} ft`} className="justify-content-center align-items-center"/>
          <div className="d-flex justify-content-center align-items-center">
            <span className="fw-bold me-2 ">NAVD 88</span>
          </div>
        </>
      }
      {!isCoastal && 
        <>
          <NameValueText label="Stage" value={`${roundToTenth(hydroAllStage)} ft`} className="justify-content-center align-items-center" />
          <span className="d-flex justify-content-center align-items-center">{roundToTenth(currentElevationMsl)} ft NAVD 88</span>
        </>
      }
      {!isDesktop && 
        <div className="d-flex flex-column mt-3">
          <PrimaryButton size="sm" className="rounded-pill py-1" onClick={() => modalCallback()}>
            <span>View Details</span>
          </PrimaryButton>
        </div>
      }
    </>
  );
}

StageContent.propTypes = {
  modalCallback: PropTypes.func
};

export default StageContent;
