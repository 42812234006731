import React, { useState } from 'react';
import { Tab, Card, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Help = ({ navItems }) => {
  const [activeKey, setKey] = useState('home');
  return (
    <>
      <Tab.Container transition={false}>
        <div className="d-flex end-0 h-100 me-0">
          {/* sidebar navigation */}
          <Card className="admin-sidebar">
            <Card.Header className="primary-blue-background">
              <div className="d-flex justify-content-between align-items-center">
                <Card.Title className="m-0">
                  Help Options
                </Card.Title>
              </div>
            </Card.Header>
            <Card.Body>
              <Nav variant="pills" className="flex-column">
                {navItems.map((helpItem) =>
                  <Nav.Item className="border-0" key={helpItem.key}>
                    <Nav.Link active={activeKey === helpItem.key} onClick={() => setKey(helpItem.key)}>
                      {helpItem.title}
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </Card.Body>
          </Card>
          {/* help tabs */}
          <Tab.Content>
            {navItems.map((helpItem) =>
              <Tab.Pane active={activeKey === helpItem.key} key={helpItem.key + 'tab'}>
                <Card className="admin-table">
                  <Card.Header className="primary-blue-background">
                    <div className="d-flex justify-content-between align-items-center">
                      <Card.Title className="m-0">
                        {helpItem.title}
                      </Card.Title>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {helpItem.details}
                  </Card.Body>
                </Card>
              </Tab.Pane>
            )}
          </Tab.Content>
        </div>
      </Tab.Container>
    </>
  );
};

Help.propTypes = {
  navItems: PropTypes.array.isRequired,
};


export default Help;