import React, { useState, useMemo } from 'react';
import {
  createColumnHelper,
  getFilteredRowModel,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import PrimaryButton from 'src/components/PrimaryButton';
import { formatDateTime } from 'src/lib/dateUtils';
import CenteredCell from 'src/features//map/gauges/gridgets/CenteredCell';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import agent from 'src/lib/agent';
import LoadingDisplay from 'src/components/LoadingDisplay';
import InfoLink from 'src/components/InfoLink';
import UsersTable from './UsersTable';
import useAlertStore from 'src/stores/alertStore';
import useUserStore from 'src/stores/userStore';
const columnHelper = createColumnHelper();
import { confirm } from 'src/components/ConfirmationModal';

const UserSearch = () => {
  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [searchField, setSearchField] = useState('name');
  const [isLoading, setIsLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const unsubscribeUserAll = useAlertStore(state => state.unsubscribeUserAll);

  const adminDeleteUser = useUserStore(state => state.adminDeleteUser);
  const handleEditUser = useUserStore(state => state.handleEditUser);
  const handleExport = useUserStore(state => state.handleExport);

  const fieldOptions = [
    { value: 'name', label: 'Name' },
    { value: 'email', label: 'Email' },
    { value: 'phone_number', label: 'Phone Number' },
  ]

  const usubscribeUser = async (row) => {
    const user = table.getFilteredRowModel().rows[row.index].original;

    if (await confirm(
      `You are unsubscribing user "${user.name}" from all gauge alerts. This action cannot be undone. Continue?`,
      ' Unsubscribe User',
      'Continue',
      'Cancel',
      async () => unsubscribeUserAll(user.username)
    )) {
      toast.success(`You have unsubscribed the user "${user.name}" from all alerts.`);
    }
  }


  const handleDeleteUser = async (row) => {
    const user = table.getFilteredRowModel().rows[row.index].original;
    if (await confirm(
      `Are you sure you want to delete the FIMAN profile for ${user.name}?}`,
      'Delete User',
      'Continue',
      'Cancel',
      async () => adminDeleteUser(user.username, row.index, table.options.meta?.deleteRow)
    )) {
      toast.success(`You have deleted the user "${user.name}".`);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnsMemo = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: info => info.getValue(),
        enableSorting: true,
        enableGlobalFilter: true,
        size: 250,
      }),
      columnHelper.accessor('email', {
        header: () => 'Email',
        cell: info => info.getValue() ? info.getValue() : 'N/A',
        enableSorting: true,
        enableGlobalFilter: true,
      }),
      columnHelper.accessor('role', {
        header: () => 'Role',
        cell: info => info.getValue() ? info.getValue() : 'N/A',
        enableSorting: true,
        enableColumnFilter: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('phoneNumber', {
        header: () => 'Phone Number',
        cell: info => info.getValue(),
        enableSorting: false,
        enableGlobalFilter: true,
      }),
      columnHelper.accessor('lastLogin', {
        header: () => 'Last Login',
        cell: info => {
          if (!info.getValue()) return '';
          const date = new Date(info.getValue());
          return formatDateTime(date);
        },
        enableSorting: true,
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('select', {
        header: () => '',
        cell: (info) => (
          <CenteredCell>
            <Button className="p-1" variant="link" onClick={() => handleDeleteUser(info.row)}>Delete</Button> |
            <Button className="p-1" variant="link" onClick={() => handleEditUser(info.row.index, table.getFilteredRowModel().rows[info.row.index].original)}>Edit</Button> |
            <Button className="p-1" variant="link" onClick={() => usubscribeUser(info.row)}>Unsub</Button>
          </CenteredCell>
        ),
        enableSorting: true,
        enableColumnFilter: false,
        size: 250,
      }),
    ];
  });

  const options = {
    data: data ?? [],
    columns: columnsMemo,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    meta: {
      updateRowData: (rowIndex, value) => {
        const dataClone = [...data];
        dataClone[rowIndex] = value;
        setData(dataClone)
      },
      resetTableData: (results) => {
        setData(results);
      },
      deleteRow: (rowIndex) => {
        const dataClone = [...data];
        dataClone.splice(rowIndex, 1);
        setData(dataClone)
      }
    },
  };
  const table = useReactTable(options);

  const applySearch = async () => {
    if (filterValue && filterValue.length > 1) {
      setIsLoading(true);
      try {
        const searchModel = {
          filterField: searchField,
          filterValue: filterValue
        };
        let users = await agent.user.search(searchModel);
        setData(users.map(x => { return ({ ...x, phoneNumber: x.phoneNumber ?? '' }) }) ?? []);
        setIsLoading(false);
        setHasSearched(true);
      }

      catch {
        toast.error(`An error occurred while searching.`);
        setIsLoading(false);
      }

    } else {
      toast.warning(`Please enter at least 2 characters to search.`);
    }
  }

  const resetSearch = () => {
    setFilterValue('');
    setSearchField('name');
    setHasSearched(false);
    table.meta?.resetTableData([]);
    setData([])
  }

  return (
    <>
      <div className="d-flex justify-content-between p-1">
        <div className="d-flex gap-1">

          <PrimaryButton
            className="rounded-pill btn-sm m-1"
            disabled={isLoading || data?.length === 0}
            onClick={() => handleExport(table)}
          >
            Export
          </PrimaryButton>
          <PrimaryButton
            className="rounded-pill btn-sm m-1"
            disabled={isLoading}
            onClick={() => resetSearch()}
          >
            Reset
          </PrimaryButton>
        </div>
        <div className="d-flex gap-2 w-50">
          <InfoLink title="" placement="top" className="mb-1">
            Search results are limited to 100 records. If you do not find the record you
            are looking for, please refine your search.
          </InfoLink>
          <div className="d-flex align-items-center w-100">
            <InputGroup size="sm">
              <InputGroup.Text>Field</InputGroup.Text>
              <Form.Select value={searchField} size="sm" onChange={e => setSearchField(e.target.value)}>
                {fieldOptions.map((fieldOption) => (
                  <option value={fieldOption.value} key={fieldOption.value}>
                    {fieldOption.label}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </div>
          <Form.Control
            value={filterValue ?? ''}
            size="sm"
            id="globalFilterID"
            name="somethingCustomThatwonttriggerautofill-1234"
            onChange={e => setFilterValue(e.target.value)}
            className="d-flex align-items-center w-100"
            placeholder="Search field starts with..."
          />
          <PrimaryButton
            className="rounded-pill btn-sm m-1"
            disabled={isLoading}
            onClick={() => applySearch()}
          >
            Apply
          </PrimaryButton>
        </div>
      </div>
      {isLoading ? <LoadingDisplay /> :
        <>
          {/* user has searched and there is data - display table */}
          {hasSearched && data && data.length > 0 && (
            <UsersTable table={table} />
          )}

          {/* user has searched and there is NO data - display message */}
          {hasSearched && (!data || data.length === 0) && (
            <div className="d-flex flex-fill align-items-center justify-content-center h-100 mt-5">
              There are no users matching your search parameters.
            </div>
          )}

          {/* user has not searched - display message */}
          {!hasSearched && (
            <div className="d-flex flex-fill align-items-center justify-content-center h-100 mt-5">
              Apply search parameters to view users.
            </div>
          )}
        </>
      }

    </>
  );
}
export default UserSearch;