import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const LayerItem = ({ layer, group, isActive, handleClick }) => {
  return (
    <Form.Check
      className="mb-2"
      type="radio"
      id={layer.id}
      label={layer.name}
      name={group.id}
      onChange={() => handleClick(layer.id)}
      checked={isActive}
      title={layer.description}
    />

  );
};

LayerItem.propTypes = {
  layer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};


export default LayerItem;