import React from 'react'; 
import PropTypes from 'prop-types';


const NavItem = ({ tabId, tabName, setActiveTab, className }) => {
  return (
    <div 
      className={className}
      onClick={() => setActiveTab(tabId)}
    >
      { tabName }
    </div>
  ); 
}

NavItem.propTypes = {
  tabId: PropTypes.string.isRequired,
  tabName: PropTypes.string.isRequired, 
  className: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
}

export default NavItem;
