import React from 'react'; 
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';

const Gridget = ({ children }) => {
  return (
    <Card className={`gridget`}>
      {children}
    </Card>
  );
};

Gridget.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Gridget;