import React from 'react';
import { Outlet } from 'react-router-dom';
import UserProfileModal from 'src/features/admin/users/UserProfileModal';
import NavBar from './NavBar';
import Footer from './Footer';
import SignInModal from 'src/features/map/auth/SignInModal';
import EditNameModal from 'src/features/map/auth/EditNameModal';
import DuplicateUserModal from 'src/features/map/auth/DuplicateUserModal';

const AppLayoutDefault = () => {
  return (
    <div className="position-relative">
      <NavBar />
      <main className="mainContainer">
        <Outlet />
      </main>
      <Footer />
      <SignInModal />
      <UserProfileModal />
      <EditNameModal />
      <DuplicateUserModal />
    </div>
  );
}

export default AppLayoutDefault;