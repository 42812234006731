import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import useGaugeImpact from 'src/lib/hooks/useGaugeImpact';
import useMapStore from 'src/stores/mapStore';
import SingleAccordion from 'src/components/SingleAccordion';
import LoadingDisplay from 'src/components/LoadingDisplay';
import { convertElevationToStage } from 'src/lib/stageConversionUtils';
import { roundToTenth } from 'src/lib/mathUtils';

const ImpactModal = ({ show, handleClose, level }) => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const { impacts, isLoading } = useGaugeImpact(activeGauge?.attributes?.siteId, level);

  const getImpacts = () => {
    return impacts ? (
      <>
        {impacts.roadImpact &&
          <SingleAccordion
            header="Road Impact"
            eventKey="0"
            defaultEventKey={null}
          >
            <div>{impacts.roadImpact}</div>
          </SingleAccordion>
        }
        {impacts.bldgImpact &&
          <SingleAccordion
            header="Building Impact"
            eventKey="1"
            defaultEventKey={null}
          >
            <div>{impacts.bldgImpact}</div>
          </SingleAccordion>
        }
        {impacts.utilitiesImpact &&
          <SingleAccordion
            header="Utilities Impact"
            eventKey="2"
            defaultEventKey={null}
          >
            <div>{impacts.utilitiesImpact}</div>
          </SingleAccordion>
        }
        {impacts.otherImpact &&
          <SingleAccordion
            header="Other Impact"
            eventKey="3"
            defaultEventKey={null}
          >
            <div>{impacts.otherImpact}</div>

          </SingleAccordion>
        }
      </>
    ) : (
      <div className="d-flex m-2 justify-content-center align-items-center">No impact data available for the selected elevation.</div>
    );
  }

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      animation={true}
      scrollable={true}
    >
      <Modal.Header closeVariant="white" closeButton>
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          <Modal.Title>{activeGauge.attributes?.name}</Modal.Title>
          {activeGauge?.attributes?.isCoastal === 1 && 
            <h5 className="text-white mt-2">{level ? `Flood Impact at ${roundToTenth(level)} ft NAVD 88` : 'Impact unavailable.'}</h5>
          }
          {activeGauge?.attributes?.isCoastal === 0 && 
            <h5 className="text-white mt-2">{level ? `Flood Impact at ${roundToTenth(convertElevationToStage(level,activeGauge.attributes.gageDatum) )} ft` : 'Impact unavailable.'}</h5>
          }
        </div>
      </Modal.Header>
      <Modal.Body className="p-md-5 pt-md-4">
        {isLoading || !activeGauge ? <LoadingDisplay /> : getImpacts()}
      </Modal.Body>
    </Modal>
  );
}

ImpactModal.propTypes = {
  show: PropTypes.bool.isRequired,
  level: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ImpactModal;