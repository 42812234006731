import React from 'react';
import useMapStore from 'src/stores/mapStore';
import NameValueText from 'src/components/NameValueText';
import LoadingDisplay from 'src/components/LoadingDisplay';
import { roundToTenth } from 'src/lib/mathUtils';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';

const GeneralContent = () => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails, isLoading } = useGaugeDetails(activeGauge?.attributes?.siteId);

  if(isLoading) {
    return <LoadingDisplay />
  }

  const { owner, lastUpdate, gageDatum, siteId } = gaugeDetails;

  // dev note: will need custom func to format this date to an actual date time
  // currently, lastUpdate is a string formatted like this: 
  // May 25 2021  9:00AM
  // conventional formatters wont work
  return (
    <>
      <NameValueText label="Site ID" value={siteId} />
      <NameValueText label="Last updated" value={lastUpdate} />
      <NameValueText label="Gauge datum" value={`${roundToTenth(gageDatum)} ft NAVD 88`} />
      <NameValueText label="Owner" value={owner} />
    </>
  );
}


export default GeneralContent;
