import React from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import UserSearch from './UserSearch';
import AdminAdvancedUsers from './AdminAdvancedUsers';

const ManageUsers = () => {
  return (
    <Card className="admin-table">
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <Card.Title className="m-0">
            Manage Users
          </Card.Title>
        </div>
      </Card.Header>
      <Card.Body>
        <Tabs
          defaultActiveKey="admin"
          id="uncontrolled"
          className="mb-3"
        >
          <Tab eventKey="admin" title="Admin/Advanced">
            <AdminAdvancedUsers />
          </Tab>
          <Tab eventKey="search" title="User Search">
            <UserSearch />
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>

  );
}

export default ManageUsers;