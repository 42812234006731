import React, { useState } from 'react';
import ScenarioSlider from './scenario/ScenarioSlider';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useHasBridgesImpacted from 'src/lib/hooks/useHasBridgesImpacted';
import useHasRoadsAffected from 'src/lib/hooks/useHasRoadsAffected';
import useMapStore from 'src/stores/mapStore';
import LoadingDisplay from 'src/components/LoadingDisplay';
import BuildingsImpactedWidget from './widgets/BuildingsImpactedWidget';
import BuildingsImpactedGridget from './gridgets/BuildingsImpactedGridget';
import ImpactWidget from './widgets/ImpactWidget';
import { GridgetIds } from 'src/constants/gridgetIds';
import RoadsAffectedWidget from './widgets/RoadsAffectedWidget';
import RoadsImpactedGridget from './gridgets/RoadsImpactedGridget';
import BridgesImpactedWidget from './widgets/BridgesImpactedWidget';
import BridgesImpactedGridget from './gridgets/BridgesImpactedGridget';
import PermissionFilter from 'src/features/map/auth/PermissionsFilter';
import { CognitoGroups } from 'src/constants/cognitoGroups';

const ScenarioTabContent = () => {
  const activeGauge = useMapStore(state => state.activeGauge);

  // Used to maintain state of the forecast slider value so
  // that it can be persited when the user switches tabs
  const scenarioLevel = useMapStore(state => state.scenarioLevel);
  const setScenarioLevel = useMapStore(state => state.setScenarioLevel);

  // Current inundation level shown on the map
  const inundationLevel = useMapStore(state => state.inundationLevel);

  const { hasBridgesImpacted, isLoading: isLoadingBridgeImpactedCount } = useHasBridgesImpacted(activeGauge?.attributes?.siteId);
  const { hasRoadsAffected, isLoading: isLoadingRoadsAffectedCount } = useHasRoadsAffected(activeGauge?.attributes?.siteId);
  const activeScenarioTabGridget = useMapStore(state => state.activeScenarioTabGridget);
  const toggleActiveScenarioTabGridget = useMapStore(state => state.toggleActiveScenarioTabGridget);

  const { isLoading: isGaugeLoading } = useGaugeDetails(activeGauge?.attributes?.siteId);

  // Set the initial level to the scenario level if it exists
  const [initialLevel] = useState(scenarioLevel ?? activeGauge?.attributes.currentElevationMsl);

  if (isGaugeLoading) {
    return (
      <LoadingDisplay />
    );
  }

  return (
    <div className="d-flex flex-column w-100">
      <ScenarioSlider initialLevel={initialLevel} onScenarioLevelChange={setScenarioLevel} />
      {activeScenarioTabGridget === GridgetIds.BUILDINGS_IMPACTED &&
        <div className="p-2 w-100">
          <BuildingsImpactedGridget
            level={inundationLevel ?? activeGauge?.attributes.currentElevationMsl}
            toggleGridget={() => toggleActiveScenarioTabGridget(GridgetIds.BUILDINGS_IMPACTED)}
          />
        </div>
      }

      {activeScenarioTabGridget === GridgetIds.ROADS_AFFECTED &&
        <div className="p-2 w-100">
          <RoadsImpactedGridget toggleGridget={() => toggleActiveScenarioTabGridget(GridgetIds.ROADS_AFFECTED)} />
        </div>
      }

      {activeScenarioTabGridget === GridgetIds.BRIDGES_IMPACTED &&
        <div className="p-2 w-100">
          <BridgesImpactedGridget toggleGridget={() => toggleActiveScenarioTabGridget(GridgetIds.BRIDGES_IMPACTED)} />
        </div>
      }

      <div className={`d-flex flex-wrap gap-2 p-2`}>
        <BuildingsImpactedWidget
          level={inundationLevel ?? activeGauge?.attributes.currentElevationMsl}
          onShowGridgetClicked={() => toggleActiveScenarioTabGridget(GridgetIds.BUILDINGS_IMPACTED)}
        />
        <PermissionFilter any={[CognitoGroups.ADMIN, CognitoGroups.ADVANCED]}>
          {!isLoadingRoadsAffectedCount && hasRoadsAffected && <RoadsAffectedWidget onActionClicked={() => toggleActiveScenarioTabGridget(GridgetIds.ROADS_AFFECTED)} />}
          {!isLoadingBridgeImpactedCount && hasBridgesImpacted && <BridgesImpactedWidget onActionClicked={() => toggleActiveScenarioTabGridget(GridgetIds.BRIDGES_IMPACTED)} />}
        </PermissionFilter>
        <ImpactWidget level={inundationLevel ?? activeGauge?.attributes.currentElevationMsl} />
      </div>
    </div>
  );
};

export default ScenarioTabContent;