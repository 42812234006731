import React from 'react';
import PropTypes from 'prop-types';


const StageIndicator = ({ value, min, max, style={} }) => {
  let position = (value - min) / (max - min) * 100;

  if(!position) return null;

  if (position < 0) {
    position = 0;
  }

  if (position > 100) {
    position = 100;
  }

  position -= 1.25;

  return (
    <div className="w-100 position-relative" style={style}>
      <div
        className="position-absolute rounded-circle"
        style={{
          left: `${position}%`,
          width: '15px',
          height: '15px',
          backgroundColor: '#296ee0'
        }}
      />
    </div>
  );
}

StageIndicator.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  style: PropTypes.object,
};

export default StageIndicator;