import React from 'react';
import { GaugeMarkerColors } from 'src/constants/gaugeMarkerColors';
import ArrowIcon from 'src/components/ArrowIcon';


const TrendLegend = () => {
  const ratings = [
    { color: GaugeMarkerColors.BLUE, label: 'Rising', rotatation: 90 },
    { color: GaugeMarkerColors.BLUE, label: 'Constant', rotatation: 0 },
    { color: GaugeMarkerColors.BLUE, label: 'Falling', rotatation: -90 },
  ];

  return (
    ratings.map((rating) => {
      return (
        <div className="mb-2 d-flex align-items-center d-flex" key={rating.label}>
          <div 
            className="me-2 rounded-circle d-flex align-items-center justify-content-center" 
            style={{ backgroundColor: rating.color, width: 17, height: 17, transform: `rotate(${rating.rotatation}deg)` }}
          >
            { rating.rotatation !== 0 && <ArrowIcon height="11px" width="11px"/> }
          </div>
          <div>{rating.label}</div>
        </div>
      )
    })
  );
}

export default TrendLegend;
