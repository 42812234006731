import React from 'react';
import PropTypes from 'prop-types';
import ImpactHouseIcon from 'src/components/ImpactHouseIcon';
import useMapStore from 'src/stores/mapStore';
import { roundToTenth } from 'src/lib/mathUtils';
import { convertElevationToStage } from 'src/lib/stageConversionUtils';

const ImpactContent = ({level}) => {
  const activeGauge = useMapStore(state => state.activeGauge);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      {activeGauge?.attributes?.isCoastal === 1 &&
        <p className="mb-3 text-center">{level ? `Flood Impact at ${roundToTenth(level)} ft NAVD 88` : 'Impact unavailable.'}</p>
      }
  
      {activeGauge?.attributes?.isCoastal === 0 &&
        <p className="mb-3 text-center">{level ? `Flood Impact at ${roundToTenth(convertElevationToStage(level, activeGauge.attributes.gageDatum) )} ft` : 'Impact unavailable.'}</p>
      }
      <ImpactHouseIcon width="60px" height= "60px"/>
    </div>
  );
}

ImpactContent.propTypes = {
  level: PropTypes.number
};

export default ImpactContent;