import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import InfoLink from './InfoLink';


/**
 * A reusable card component that has a dark background and a title.
 * @param {Object} props - The props for the component.
 * @param {string} props.title - The title for the card.
 * @param {ReactNode} props.children - The content for the card.
 * @see https://react-bootstrap.github.io/components/cards/
 * @component 
 * @example
 * return (
 *  <DarkCard title="Custom Title">
 *   <div>
 *     Whatever you want to put in the card goes here.
 *   </div>
 * </DarkCard>
 * );
 */
const DarkCard = ({ title, className, children, infoText }) => {
  return (
    <Card className={`dark ${className}`}>
      <Card.Body>
        <Card.Title>
          <div className="d-flex">
            <span className="">{title}</span> {infoText && <InfoLink className="px-2" title="Overview Info">{infoText}</InfoLink>}
          </div>
        </Card.Title>
        {children}
      </Card.Body>
    </Card>
  );
}

DarkCard.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node, 
  infoText: PropTypes.string
};

export default DarkCard;