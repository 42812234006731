import React from 'react';
import PropTypes from 'prop-types';
import Widget from './Widget';
import useMapStore from 'src/stores/mapStore';
import NameValueText from 'src/components/NameValueText';
import useBuildingImpactSummary from 'src/lib/hooks/useBuildingImpactSummary';
import LoadingDisplay from 'src/components/LoadingDisplay';

const BuildingsImpactedWidget = ({ level, onShowGridgetClicked }) => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const { impactSummary, isLoading } = useBuildingImpactSummary(activeGauge?.attributes.siteId, level);

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 10
  });

  const widgetBody = () => {
    if (impactSummary === null || impactSummary?.BLDG_DAMAGED === 0) {
      return (
        <div className="d-flex justify-content-center align-items-center h-100">
            No Buildings Impacted
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <NameValueText
            label="Buildings Damaged"
            value={`${impactSummary?.BLDG_DAMAGED ?? 0}`}
            className="d-flex justify-content-center align-items-center"
          />
          <h4 className="d-flex justify-content-center align-items-center py-1">{USDollar.format(impactSummary?.TOTAL_DAMAGED)}</h4>
        </div>
      )
    }
  }

  return (
    <Widget
      title="Buildings Impacted"
      onActionClick={onShowGridgetClicked}
    >
      {isLoading ? (<LoadingDisplay />) : (widgetBody())}
    </Widget>
  );
}

BuildingsImpactedWidget.propTypes = {
  onShowGridgetClicked: PropTypes.func.isRequired,
  level: PropTypes.number
};

export default BuildingsImpactedWidget;