import React from 'react';

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    console.error('There was an error on the page. Please refresh the browser to reload the application.  The error:', error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className="d-flex align-items-center justify-content-center p-2">
            <h2 className="p-3">There was an error on the page. </h2>
          </div>
          <div className="d-flex align-items-center justify-content-center p-2">
            <h4 className="p-3">Please refresh the browser to reload the application.</h4>
          </div>

        </>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;