import { useQuery } from 'react-query';
import agent from '../agent';

const useGaugeDetails = (siteId) => {
  return useQuery(['gauge', siteId], ({ queryKey }) => {
    const [, id] = queryKey;
    return agent.gauge.get(id);
  }, {
    enabled: siteId !== undefined && siteId !== null,
    staleTime: 300000 // 5 minutes
  });
}

export default useGaugeDetails;
