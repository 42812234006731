import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Hero from '../features/about/Hero';
import InfoCard from '../features/about/InfoCard.jsx';
import ImageCard from '../features/about/ImageCard';

import aboutTopImage from '../assets/images/about_top_image.png';
import aboutMiddleImage from '../assets/images/about_how.png';
import aboutBottomImage from '../assets/images/about_bottom_image.png';
import aboutTransportationImage from '../assets/images/about_transportation_image.png';
import Contact from '../features/about/Contact';

const AboutPage = () => {
  return (
    <>
      <Container fluid className="p-0">
        <Hero />
      </Container>
      <Container className="mt-4 mt-lg-5">
        <Row>
          <Col xs={12} lg={8}>
            <Row>
              <Col md={12} className="mb-5 d-block d-lg-none">
                <Contact />
              </Col>
              <Col md={6}>
                <InfoCard title1="FIMAN" title2="Mission">
                  <p>
                    To provide real-time and forecast riverine and coastal water level and rain data, rainfall products, flood inundation maps, flooding impacts, and alerts in real-time to support risk-based decisions regarding flooding.
                  </p>
                </InfoCard>
              </Col>
              <Col md={6} className="mt-4 mt-md-0">
                <InfoCard title1="FIMAN" title2="Goals">
                  <ul>
                    <li>Gather and distribute reliable and accurate riverine and coastal water level and rain data.</li>
                    <li>Provide real-time flood inundation maps and alerts.</li>
                    <li>Provide better information about flooding risks and impacts.</li>
                    <li>Help mitigate the loss of lives and property including transportation infrastructure.</li>
                  </ul>
                </InfoCard>
              </Col>
            </Row>
            <Row className="my-4 my-md-5">
              <Col md={12} className="">
                <ImageCard
                  title="REAL-TIME DATA"
                  image={aboutTopImage}
                  alt="real-time data"
                >
                  This web site provides real-time riverine and coastal water level and rainfall data from over 600 gauges across North Carolina. Gauge forecast data for approximately 50 sites available from the National Weather Service (NWS) are also integrated into the application.  Some sites may be stage gauges only, while others may have rainfall data available. Many of these gauges are managed by North Carolina Emergency Management (NCEM), while some are operated by local government agencies and private organizations. Additionally, gauges from the U.S. Geological Survey (USGS) and the National Oceanographic and Atmospheric Organization (NOAA) are included in this network.
                </ImageCard>
                <ImageCard
                  title="GAUGE NETWORK"
                  image={aboutMiddleImage}
                  alt="gauge network"
                  className="mt-4"
                >
                  FIMAN collects and communicates real-time data from water elevation gauges placed at strategic points along waterways across the state. These gauges communicate via satellite, cellular data, or radio signals to a central data clearinghouse. The data are then organized into a database, which is displayed on the site. NCEM staff monitor the gauges and repair any that become damaged. Users can sign up for text or email alerts, to be notified as water levels rise.
                </ImageCard>
                <ImageCard
                  title="FLOODING EXTENT AND IMPACT"
                  image={aboutBottomImage}
                  alt="flooding extent and impact"
                  className="mt-4"
                >
                  FIMAN also contains inundation maps at selected sites across the state. Flooding risk information is provided for areas with inundation maps. For each incremental rise in flood waters, buildings, roads, and infrastructure that would be impacted are identified. Information on water depth in each building affected along with estimated damage costs are displayed.
                </ImageCard>
                <ImageCard
                  title="TRANSPORTATION LAYERS (FIMAN-T)"
                  image={aboutTransportationImage}
                  alt="transportation layers"
                  className="mt-4"
                >
                  The website also shows flooding impacts to roads, bridges, and other NC Department of Transportation (NCDOT) assets. These transportation layers in the FIMAN provide visualization and metrics for roadway inundation, bridge hydraulic performance and identifies potentially impacted NCDOT assets. This data is available for NCID users and will enhance NCDOT’s response during storm events by generating data and reports for use in disaster response and planning. For more information about the real-time traffic and travel information including road/bridge closures, please refer to DriveNC.                </ImageCard>
              </Col>
            </Row>
            <Container style={{paddingBottom:15}}>
              <a href="https://www.nc.gov/privacy">North Carolina Government Website Privacy Policy</a>
            </Container>
          </Col>
          <Col xs={12} lg={4} className="d-none d-lg-block ps-lg-5">
            <Contact />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutPage;
