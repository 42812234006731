import React from 'react';
import PropTypes from 'prop-types';
import SubscriptionItem from './SubscriptionItem';
import useAlertStore from 'src/stores/alertStore';
import { toast } from 'react-toastify';
import { confirm } from 'src/components/ConfirmationModal';


const SubscriptionList = ({ sites }) => {
  const unsubscribe = useAlertStore(state => state.unsubscribeFromSite);

  const handleUnsubscribe = async (site) => {
    if (await confirm(
      `You are unsubscribing from alerts for ${site?.attributes.name}. Continue?`,
      'Unsubscribe',
      'Continue',
      'Cancel',
      async () => unsubscribe(site?.attributes.siteId)
    )) {
      toast.success(
        `Successfully unsubscribed from alerts for "${site?.attributes.name}."`
      );
    }
  }

  return (
    <div className="d-flex flex-column gap-0">
      {
        sites.map((site) => (
          <SubscriptionItem
            key={site.uid}
            site={site} 
            onUnsubscribe={() => handleUnsubscribe(site)}
          />
        ))
      }
    </div>
  );
};

SubscriptionList.propTypes = {
  sites: PropTypes.array.isRequired
};

export default SubscriptionList;