import React from 'react';
import WeatherContent from './WeatherContent';
import SingleAccordion from '../../../../components/SingleAccordion';


const WeatherWidgetSm = () => {
  return (
    <SingleAccordion 
      header="Weather"
      eventKey="0"
    >
      <WeatherContent />
    </SingleAccordion>
  );
}

export default WeatherWidgetSm;