import { useQuery } from 'react-query';
import agent from '../agent';

const useAdminGauges = () => { 

  return useQuery(['admin-gauges'], () => {
    return agent.gauge.list();
  }, {
    enabled: true,
    staleTime: 300000 // 5 minutes in milliseconds
  });
};

export default useAdminGauges;