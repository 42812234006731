import React from 'react';
import useMapStore from '../../../../stores/mapStore';
import LoadingDisplay from 'src/components/LoadingDisplay';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import NameValueText from 'src/components/NameValueText';
import { roundToHundredth } from 'src/lib/mathUtils';


const WeatherContent = () => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails, isLoading } = useGaugeDetails(activeGauge?.attributes?.siteId);

  if(isLoading) {
    return <LoadingDisplay />
  }

  const { rain1hr, rain6hr, rain12hr, rain24hr } = gaugeDetails;

  const valueFormatter = (value) => {
    return value === null ? 'N/A' : `${roundToHundredth(value)} in`;
  }

  return (
    <>
      <h6>Accumulated Rainfall</h6>
      { rain1hr !== null && <NameValueText label="1 hour" value={valueFormatter(rain1hr)} /> }
      { rain6hr !== null && <NameValueText label="6 hour" value={valueFormatter(rain6hr)} /> }
      { rain12hr !== null && <NameValueText label="12 hour" value={valueFormatter(rain12hr)} /> }
      { rain24hr !== null && <NameValueText label="24 hour" value={valueFormatter(rain24hr)} /> }
    </>
  );
}


export default WeatherContent;
