import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Search as SearchIcon, XLg } from 'react-bootstrap-icons';
import SuggestionMenu from './SuggestionsMenu';

import useMapStore from '../../../stores/mapStore';
import useSearch from './useSearch';

import { SearchResults } from '../../../constants/search';
import styles from '../../../assets/styles/Search.module.scss';
import useConfigData from '../../../lib/hooks/useConfigData';


const SearchModal = ({ show, onHide }) => {
  const searchInputStyle = styles.searchInputLg;

  const inputRef = useRef(null);

  const { data: config } = useConfigData('Map');
  const clearAllSelectedFeatures = useMapStore((state) => state.clearAllSelectedFeatures);
  const mapManager = useMapStore((state) => state.mapManager);

  const { value, setValue, suggestions, clearSuggestions, fetchCandidates } = useSearch(
    mapManager,
    config?.geocoder.url,
    config?.geocoder.token,
    config?.geocoder.searchExtent, 
    config?.geocoder.maxSuggestionsMobile,
    '', 
    300,
  );

  useEffect(() => {
    if (show) {
      inputRef.current.focus();
    }
  }, [show]);

  const handleSelect = async (suggestion) => {
    let target = null;

    if (suggestion.type === SearchResults.GAUGE) {
      target = handleGaugeSelect(suggestion);
    } else {
      target = await handlePlaceSelect(suggestion);
    }

    if (target) {
      // zoom to the selected location
      mapManager.zoomToFeatureByGeometry(target, 13);

      // hide/show the hover popup
      if (suggestion.type === SearchResults.GAUGE) {
        mapManager.showHoverPopup(suggestion.meta);
      } else {
        mapManager.showHoverPopup(null);
      }

      // hide the details panel
      clearAllSelectedFeatures();
    }

    // clear the search input and suggestions
    setValue('', false);
    clearSuggestions();

    // hide the modal
    onHide();
  }

  const handleGaugeSelect = (suggestion) => {
    const { location } = suggestion;
    return [location.longitude, location.latitude];
  }

  const handlePlaceSelect = async (suggestion) => {
    const { name, key } = suggestion;

    // get the details of the place
    const results = await fetchCandidates(name, key);

    if (results.candidates.length > 0) {
      const { location } = results.candidates[0];
      return [location.x, location.y];
    }

    return null;
  }

  const handleHide = () => {
    setValue('', false);
    clearSuggestions();
    onHide();
  }

  return (
    <Modal show={show} fullscreen={true} onHide={handleHide}>
      <Modal.Header closeButton closeVariant="white" className="py-2 px-3">
        <Modal.Title>
          SEARCH
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 m-0">
        <div className="px-2 py-3 position-sticky border-0 top-0 start-0 end-0 dark-gray-background" style={{ zIndex: 2000 }}>
          <div
            className="d-flex align-items-center justify-content-evenly rounded-pill ps-2 pe-3 bg-white position-relative"
            style={{ height: '40px' }}
          >
            <SearchIcon
              className={styles.searchIcon}
              size={18}
            />
            <input
              ref={inputRef}
              className={`flex-grow-1 ps-2 ${searchInputStyle}`}
              type="text"
              placeholder="Search by Location or Gauge"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />

            <div style={{ width: '30px', marginRight: '-5px' }}>
              {value ?
                <Button
                  variant="link"
                  size="sm"
                  className="d-flex align-items-center px-3 text-dark"
                  onClick={() => setValue('')}
                >
                  <XLg />
                </Button> : null
              }
            </div>
          </div>
        </div>
        {suggestions.length > 0 ?
          <div className="position-relative">
            <SuggestionMenu
              suggestions={suggestions}
              onSelect={handleSelect}
              topOffest="0px"
            />
          </div>
          : null
        }
      </Modal.Body>
    </Modal>
  );
};

SearchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};


export default SearchModal;