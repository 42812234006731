import React from 'react';
import Widget from './Widget';
import WeatherContent from './WeatherContent';


const WeatherWidget = () => {
  return (
    <Widget title="Weather">
      <WeatherContent />
    </Widget>
  );
}

export default WeatherWidget;