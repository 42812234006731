import React, { useMemo } from 'react';
import WeatherLegend from './WeatherLegend';
import useCustomMediaQuery from '../../../../lib/hooks/useCustomMediaQuery';
import useMapStore from '../../../../stores/mapStore';
import { Layers } from '../../../../constants/layers';
import GaugeLegendContainer from './GaugeLegendContainer';
import TransportationLegendContainer from './TransportationLegendContainer';
import PermissionFilter from '../../auth/PermissionsFilter';
import { CognitoGroups } from 'src/constants/cognitoGroups';
import AssetLegendContainer from './AssetLegendContainer';

const LegendContainer = () => {
  const { isMobile } = useCustomMediaQuery();

  const activeGroupLayerId = useMapStore((state) => state.activeGroupLayerId);

  const isWeatherLayerSelected = useMemo(() => {
    return activeGroupLayerId[Layers.LAYER_GROUP_WEATHER] && !activeGroupLayerId[Layers.LAYER_GROUP_WEATHER].startsWith('None');
  }, [activeGroupLayerId]);

  return (
    <div className="d-flex flex-column dark-gray-background">
      <GaugeLegendContainer />
      <PermissionFilter any={[CognitoGroups.ADMIN, CognitoGroups.ADVANCED]}>
        <TransportationLegendContainer />
        {!isMobile && isWeatherLayerSelected && <WeatherLegend />}
        <AssetLegendContainer />
      </PermissionFilter>
    </div>
  );
}

export default LegendContainer;
