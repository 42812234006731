import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import useConfigData from 'src/lib/hooks/useConfigData';

const useBuildingDamageTotals = (gaugeId, level) => {
  const { data: config } = useConfigData('Map');

  const { data, isLoading } = useQuery(['building-damage-totals', gaugeId, level], ({ queryKey }) => {
    const [, gaugeId, level] = queryKey;
    let query;
    if (level.toString().indexOf('.') != -1) {
      query = `GAGE_ID='${gaugeId}' AND ELEVATION='${level}'`;
    } else {
      query = `GAGE_ID='${gaugeId}' AND ELEVATION='${level}.0'`;
    }


    return queryFeatures({
      url: config?.references.buildingDamageTotals,
      where: query,
      outFields: ['*'],
      returnGeometry: false,
    });
  }, {
    enabled: gaugeId !== undefined && level !== undefined && level !== null 
  });

  const getCustomSortedData = (data) => {
    let reverseSorted = data.reverse();
    let totalRow = data.slice(0, 1); 
    data.shift(); 
    reverseSorted.push(totalRow[0]); 
    return reverseSorted;
  }; 

  const damageTotals = useMemo(() => {
    return data?.features ? getCustomSortedData(data.features.map(x => {
      return {
        countNonResidential: x.attributes.COUNT_NONRESIDENTIAL,
        countPublic: x.attributes.COUNT_PUBLIC,
        countResidential: x.attributes.COUNT_RESIDENTIAL,
        countTotal: x.attributes.COUNT_TOTAL,
        damagesNonResidential: x.attributes.DAMAGES_NONRESIDENTIAL,
        damagesPublic: x.attributes.DAMAGES_PUBLIC,
        damagesResidential: x.attributes.DAMAGES_RESIDENTIAL,
        damagesTotal: x.attributes.DAMAGES_TOTAL,
        depth: x.attributes.DEPTH,
        elevation: x.attributes.ELEVATION,
        gaugeId: x.attributes.GAGE_ID
      }
    })) : [];
  }, [data]);

  return { damageTotals, isLoading };
};

export default useBuildingDamageTotals;