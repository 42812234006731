import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import useConfigData from 'src/lib/hooks/useConfigData';

const useHistoricPeakEvents = (siteId) => {
  const { data: config } = useConfigData('Map');

  const { data, isLoading } = useQuery(['historical-peaks', siteId], ({ queryKey }) => {
    const [, id] = queryKey;

    return queryFeatures({
      url: config?.references.historicalPeaks,
      where: `GAGEID='${id}'`,
      outFields: ['*'],
      orderByFields: ['PEAKSTAGE DESC'],
      returnGeometry: false,
    });
  }, {
    enabled: config?.references?.historicalPeaks !== undefined  && siteId !== undefined,
  });

  const events = useMemo(() => {
    return data?.features ? data.features.map((feature, index) => {
      return {
        id: index,
        gaugeId: feature.attributes.GAGEID,
        name: feature.attributes.EVENTNAME,
        date: feature.attributes.EVENTDATE,
        stage: feature.attributes.PEAKSTAGE,
      };
    }) : []
  }, [data]);
  
  return { events, isLoading };
};

export default useHistoricPeakEvents;