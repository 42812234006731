import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Info } from 'react-bootstrap-icons';
import PrimaryIconButton from './PrimaryIconButton';
import { Overlay, Popover } from 'react-bootstrap';
import useClickOutside from '../lib/hooks/useClickOutside';

/**
 * InfoLink component
 * @param {string} title - title of popover
 * @param {string} links - optional links to display in popover
 * @param {string} placement - placement of popover
 * @param {string} className - optional class name
 * @param {node} children - children to render
 */
const InfoLink = ({ title, links, placement='top', className, children }) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const popoverRef = useClickOutside(() => setShow(false));
  const ref = useRef(null);

  const handleClick = (e) => {
    e.stopPropagation();
    setShow(!show);
    setTarget(e.target);
  };

  return (
    <div ref={popoverRef} className={className}>
      <div ref={ref}>
        <PrimaryIconButton
          className="p-0 m-0"
          icon={<Info />}
          onClick={(e) => handleClick(e)}
        />
        <Overlay
          show={show}
          target={target}
          placement={placement}
          container={ref}
        >
          <Popover className="position-fixed">
            <Popover.Header as="h3">{title}</Popover.Header>
            <Popover.Body>
              <div>
                {<div>{children}</div>}
                {links && links.map((link) => (
                  <div key={link.text}>
                    <a className="text-dark-blue" href={link.url} target="_blank" rel="noreferrer">
                      {link.text}
                    </a>
                  </div>
                ))}
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </div>
  )
}

InfoLink.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
}

export default InfoLink;
