import React from 'react'
import PropTypes from 'prop-types';

const OutOfServiceIcon = ({ height, width }) => {
  return (
    <svg height={height} width={width} viewBox="0 0 7.07106781 7.07106781" xmlns="http://www.w3.org/2000/svg">
      <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-219.000000, -440.000000)" fill="#000000" id="Out_Of_Service_X">
          <g transform="translate(219.000000, 440.000000)">
            <rect id="Rectangle-Copy-2" transform="translate(3.535534, 3.535534) rotate(45.000000) translate(-3.535534, -3.535534) " x="3.03553391" y="-0.964466094" width="1" height="9"></rect>
            <rect id="Rectangle-Copy-3" transform="translate(3.535534, 3.535534) scale(-1, 1) rotate(45.000000) translate(-3.535534, -3.535534) " x="3.03553391" y="-0.964466094" width="1" height="9"></rect>
          </g>
        </g>
      </g>
    </svg>
  )
}

OutOfServiceIcon.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired
};

export default OutOfServiceIcon;