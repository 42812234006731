import { useQuery } from 'react-query'; 
import { useMemo } from 'react';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import useConfigData from 'src/lib/hooks/useConfigData';

const useCounties = () => {
  const { data: config } = useConfigData('Map');
  const { data, isLoading } = useQuery(['counties'], () => {
    const countyConfig = config.findGaugeLayers.find(x => x.name === 'County');
    return queryFeatures(countyConfig.queryFeatures);
  }, {
    enabled: config !== null && config !== undefined,
  });

  const counties = useMemo(() => {
    if (data?.features?.length > 0) {
      return data.features.map(x => x.attributes.County);
    }
    return [];
  }, [data]);

  return { counties, isLoading };

}; 

export default useCounties;