import { useQuery } from 'react-query';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import { useMemo } from 'react';
import useConfigData from './useConfigData';


const useLibraryLevels = (siteId) => {
  const { data: config } = useConfigData('Map');

  const {data, isLoading} = useQuery(['inundation-levels', siteId], ({ queryKey }) => {
    const [, id] = queryKey;

    return queryFeatures({
      url: config?.layers.inundation.featureUrl,
      where: `SITE_ID = '${id}'`,
      outFields: ['USER_FLAG'],
      orderByFields: ['USER_FLAG'],
      returnGeometry: false,
    });
  }, {
    enabled: config !== undefined && siteId !== undefined,
  });

  const levels = useMemo(() => {
    return data && data.features ? data.features.map((feature) => feature.attributes.USER_FLAG) : []
  }, [data]);

  return {levels, isLoading};
}

export default useLibraryLevels;
