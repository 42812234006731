import React from 'react';
import 'photoswipe/dist/photoswipe.css'
import Photos from 'src/components/Photos';

const PhotosTabContent = () => {
  return (
    <Photos className="d-flex" width="270px" height="175px" />
  );
}

export default PhotosTabContent;