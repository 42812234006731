import React from 'react';
import PropTypes from 'prop-types';
import Gridget from './Gridget';
import { Card, Button } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import BridgesTable from './BridgesTable';


const BridgesImpactedGridget = ({ toggleGridget }) => {
  return (
    <Gridget>
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <Card.Title className="m-0">
            Bridges Impacted
          </Card.Title>
          <div className="d-flex m-1">
            <Button
              variant="link"
              className="d-flex align-items-center px-1 text-white pr-2"
              onClick={() => toggleGridget()}
            >
              <XLg />
            </Button>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-column">
        <BridgesTable />
      </Card.Body>
    </Gridget>
  )
};

BridgesImpactedGridget.propTypes = {
  toggleGridget: PropTypes.func.isRequired,
};

export default BridgesImpactedGridget;