import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Form, Col, Row } from 'react-bootstrap';
import { CognitoGroups } from 'src/constants/cognitoGroups';
import PermissionFilter from '../../map/auth/PermissionsFilter';
/**
 * A reusable form for editing users.
 * @param {Object} user - The user object to be edited.
 * @param {Object} control - The control object from react-hook-form.
 * @param {Object} errors - the error object from the reac-hook-form.
 * @param {bool} isManageUser - bool option that indicates the form is being accessed from the Admin Manage Users Page.
  * @component
 */

const UserForm = ({ user, control, errors, isManageUser = false, userGroup, setGroup }) => {
  const groupOptions = CognitoGroups.getGroups();
  return (
    <Form>
      <Form.Group as={Row} className="mb-3" controlId="formName">
        <Form.Label column sm="4">Name</Form.Label>
        <Col sm="8">
          <Controller control={control} name="name"
            defaultValue={user?.name ?? ''}
            rules={{ required: true }}
            render={({
              field: { value, ref } }) => (
              <Form.Control className="text-white" disabled={true} plaintext={true} readOnly={true} value={value} ref={ref} />)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formEmail">
        <Form.Label column sm="4">Email</Form.Label>
        <Col sm="8">
          <Controller control={control} name="email"
            defaultValue={user?.email ?? ''}
            rules={{ required: true }}
            render={({
              field: { value, ref } }) => (
              <Form.Control className="text-white" disabled={true} plaintext={true} readOnly={true} value={value} ref={ref} />)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="4">Phone Number:</Form.Label>
        <Col sm="8">
          <Controller control={control} name="phoneNumber"
            defaultValue={user?.phoneNumber ?? ''}
            rules={{
              required: false, pattern: /^\d{3}-\d{3}-\d{4}$/
            }}
            render={({ field }) => (
              <>
                <Form.Control {...field}
                  isInvalid={errors.phoneNumber}
                  placeholder="Enter Phone Number"
                />
                <Form.Text className="text-muted">Format must be 123-456-7899</Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber?.type === 'pattern' && 'Phone Number is the incorrect format.'}
                </Form.Control.Feedback>
              </>)}
          />
        </Col>
      </Form.Group>
      {isManageUser && (
        <PermissionFilter group={CognitoGroups.ADMIN}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="4">
              Role:
            </Form.Label>
            <Col sm="8">
              <Form.Select
                value={userGroup}
                size="sm"
                onChange={e => setGroup(e.target.value)}>
                {groupOptions.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
        </PermissionFilter>
      )}
    </Form>
  )
}

UserForm.propTypes = {
  user: PropTypes.object,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isManageUser: PropTypes.bool,
  userGroup: PropTypes.string,
  setGroup: PropTypes.func
};

export default UserForm;