import React from 'react';
import SingleAccordion from '../../../../components/SingleAccordion';
import RiskRatingLegend from './RiskRatingLegend';
import ForecastLegend from './ForecastLegend';
import TrendLegend from './TrendLegend';
import BuildingsLegend from './BuildingsLegend'
import useCustomMediaQuery from '../../../../lib/hooks/useCustomMediaQuery';
import PermissionFilter from '../../auth/PermissionsFilter';
import { CognitoGroups } from 'src/constants/cognitoGroups';
import NwmForecastLegend from './NwmForecastLegend';

const GaugeLegendContainer = () => {
  const { isMobile } = useCustomMediaQuery();
  return (
    <>
      <SingleAccordion
        header="Current Risk Rating"
        eventKey="risk"
        defaultEventKey={!isMobile ? 'risk' : null}>
        <RiskRatingLegend />
      </SingleAccordion>
      <SingleAccordion
        header="RFC Forecast Peak Condition"
        eventKey="forecast"
        defaultEventKey={!isMobile ? 'forecast' : null}
      >
        <ForecastLegend />
      </SingleAccordion>
      <PermissionFilter any={[CognitoGroups.ADMIN, CognitoGroups.ADVANCED]}>
        <SingleAccordion
          header="NWM Forecast Peak Condition"
          eventKey="forecast"
          defaultEventKey={!isMobile ? 'forecast' : null}
        >
          <NwmForecastLegend />
        </SingleAccordion>
      </PermissionFilter>
      <SingleAccordion
        header="Trend"
        eventKey="trend"
        defaultEventKey={!isMobile ? 'trend' : null}
      >
        <TrendLegend />
      </SingleAccordion>
      <SingleAccordion
        header="Buildings"
        eventKey="buildings"
        defaultEventKey={!isMobile ? 'building' : null}
      >
        <BuildingsLegend />
      </SingleAccordion>
    </>
  );
}

export default GaugeLegendContainer;