import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Modal, Form } from 'react-bootstrap';
import PrimaryButton from 'src/components/PrimaryButton';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import useUserStore from 'src/stores/userStore';

const EditNameModal = () => {
  const showNameEditModal = useUserStore(state => state.showNameEditModal);
  const toggleNameEditModal = useUserStore(state => state.toggleNameEditModal);
  const validateUser = useUserStore(state => state.validateUser);
  const deleteUser = useUserStore(state => state.deleteUser);
  const setCognitoUser = useUserStore(state => state.setCognitoUser);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const { handleSubmit, control, setValue, formState: { errors } } = useForm({ mode: 'onChange' });

  useEffect(() => {
    setValue('firstName', firstName);
    setValue('lastName', lastName);
  }, [firstName, lastName, setValue])

  const onSubmit = async (data) => {
    setIsSubmitting(true)
    try {
      let name = data.firstName + ' ' + data.lastName;
      await validateUser(name);
      const validatedUser = await Auth.currentAuthenticatedUser();
      setCognitoUser(validatedUser);
      toast.success('Successfully updated user profile!')
      toggleNameEditModal(false);
      setIsSubmitting(false);
    }
    catch (error) {
      toast.error(`There was an error updating user profile. Please contact support.`);
    }
  }

  const cancelProfile = async () => {
    try {
      await deleteUser();
      setFirstName('');
      setLastName('');
      toggleNameEditModal(false);
    } catch (error) {
      toast.error(`There was an error cancelling profile. Please contact support.`);
    }

  }

  return (
    <Modal
      size="md"
      centered
      show={showNameEditModal}
    >
      <Modal.Header>
        <Modal.Title>Create Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-md-5 pt-md-4">
        <div className="d-flex m-2 justify-content-center align-items-center">
          Please enter your name to create a profile in FIMAN. This will not be able to be changed later.
        </div>
        <div className="p-3 mt-2">
          <Form id="nameForm">
            <Form.Group className="mb-3 required" controlId="forFirstName">
              <Form.Label>First Name</Form.Label>
              <Controller
                control={control}
                name="firstName"
                defaultValue={firstName ?? ''}
                rules={{ required: true, maxLength: 100 }}
                render={({ field }) => <>
                  <Form.Control
                    disabled={isSubmitting}
                    isInvalid={errors.firstName}
                    type="text"
                    placeholder="Enter First Name"
                    {...field}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName?.type === 'maxLength' && 'First name cannot exceed 100 characters.'}
                    {errors.firstName?.type === 'required' && 'First name is required.'}
                  </Form.Control.Feedback>
                </>
                }
              />
            </Form.Group>
            <Form.Group className="mb-3 required" controlId="forLastName">
              <Form.Label>Last Name</Form.Label>
              <Controller
                control={control}
                name="lastName"
                defaultValue={lastName ?? ''}
                rules={{ required: true, maxLength: 100 }}
                render={({ field }) => <>
                  <Form.Control
                    disabled={isSubmitting}
                    isInvalid={errors.lastName}
                    type="text"
                    placeholder="Enter Last Name"
                    {...field}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName?.type === 'maxLength' && 'Last name cannot exceed 100 characters.'}
                    {errors.lastName?.type === 'required' && 'Last name is required.'}
                  </Form.Control.Feedback>
                </>
                }
              />
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex w-100 justify-content-center gap-4">
          <PrimaryButton
            variant="red-cancel"
            disabled={isSubmitting}
            className="rounded-pill flex-grow-1"
            onClick={() => cancelProfile()}
          >
            <span className="text-truncate">Cancel</span>
          </PrimaryButton>
          <PrimaryButton
            className="rounded-pill flex-grow-1"
            disabled={isSubmitting}
            onClick={() => handleSubmit(onSubmit)()}>
            {isSubmitting && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>}
            Save
          </PrimaryButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditNameModal; 