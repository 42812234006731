import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BellFill } from 'react-bootstrap-icons';
import PrimaryIconButton from 'src/components/PrimaryIconButton';
import useMapStore from 'src/stores/mapStore';
import useUserStore from 'src/stores/userStore';
import useAlertStore from 'src/stores/alertStore';
import SubscriptionsConfigureModal from './alerts/SubscriptionsConfigureModal';
import NotificationIconBadge from 'src/components/NotificationIconBadge';

const MAX_SUBSCRIPTIONS = 99;

const Notifications = ({ className }) => {
  const showNotifications = useMapStore(state => state.showNotifications);
  const toggleNotifications = useMapStore(state => state.toggleNotifications);
  const gaugeLayerIsReady = useMapStore(state => state.gaugeLayerIsReady);
  const mapManager = useMapStore(state => state.mapManager);

  const user = useUserStore(state => state.cognitoUser);
  const loadAlertSubscriptions = useAlertStore(state => state.loadAlertSubscriptions);
  const alertSubscriptionsInitialized = useAlertStore(state => state.alertSubscriptionsInitialized);
  const alertSubscriptions = useAlertStore(state => state.alertSubscriptions);

  // Load alert subscriptions when the user is logged in and the gauge layer is ready
  // only when the notification panel is shown.
  useEffect(() => {
    const asyncLoadAlertSubscriptions = async () => {
      const openOrFirstTime = !alertSubscriptionsInitialized || showNotifications;

      if (user && mapManager && gaugeLayerIsReady && openOrFirstTime) {
        await loadAlertSubscriptions();
      }
    };

    asyncLoadAlertSubscriptions();

    const interval = setInterval(asyncLoadAlertSubscriptions, 1000 * 60 * 5); // 5 minutes

    return () => {
      clearInterval(interval);
    }
  }, [user, loadAlertSubscriptions, mapManager, gaugeLayerIsReady, showNotifications, alertSubscriptionsInitialized]);

  useEffect(() => {
    const actionbarSm = document.querySelector('#actionbarSm');
    const actionbarMd = document.querySelector('#actionbarMd');

    if (actionbarSm || actionbarMd) {      
      var observer = new MutationObserver(() => {
        updatePosition();
      });
    
      observer.observe(document.body, { attributes: true, childList: true, subtree: true });
    }
  }, []);

  const updatePosition = () => {
    const actionbars = ['actionbarSm', 'actionbarMd'];

    actionbars.forEach(actionbar => {
      const actionbarEl = document.getElementById(actionbar);

      if (actionbarEl) {
        const rect = actionbarEl.getBoundingClientRect();
        const sidebar = document.querySelector('.sidebar');
        if (sidebar) {
          sidebar.style.top = `${rect.bottom}px`;
        }
      }
    });
  }

  const subscriptionCountText = () => {
    if (alertSubscriptions.length > MAX_SUBSCRIPTIONS) {
      return `${MAX_SUBSCRIPTIONS}+`;
    }

    return alertSubscriptions.length;
  }

  const badgeWidth = () => {
    if (alertSubscriptions.length > MAX_SUBSCRIPTIONS) {
      return 25;
    }

    return 19;
  }

  return (
    <div className="position-relative">
      <PrimaryIconButton
        className={className}
        icon={<BellFill size="20" />}
        onClick={() => toggleNotifications(!showNotifications)}
      />
      {alertSubscriptions.length > 0 &&
        <NotificationIconBadge
          className="align-items-center d-flex justify-content-center"
          offset={-6} height={19} width={badgeWidth()}
        >
          <span style={{ fontSize: 9, lineHeight: 10 }}>
            {subscriptionCountText()}
          </span>
        </NotificationIconBadge>}
      <SubscriptionsConfigureModal />
    </div>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;