import React from 'react'; 
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap'

const FilterCollapse = ({ children, isOpen }) => {

  return (
    <Collapse in={isOpen}>
      {/* must have div wrapper or collapse won't close fully */}
      <div>
        {children}
      </div>
    </Collapse>
  )

}
FilterCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default FilterCollapse;