import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import 'chartjs-adapter-date-fns';
import StageHydrograph from 'src/features/map/gauges/widgets/StageHydrograph';
import useMapStore from 'src/stores/mapStore';
import FlowHydrograph from 'src/features/map/gauges/widgets/FlowHydrograph';
import SingleAccordion from 'src/components/SingleAccordion';
import LoadingDisplay from 'src/components/LoadingDisplay';

const HydrographModal = ({ show, handleClose, minDisplayDate, isFlow = false, useNwmModel = false }) => {
  const gauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails, isLoading } = useGaugeDetails(gauge?.attributes?.siteId);
  const { isDesktop } = useCustomMediaQuery();
  const { isCoastal } = gaugeDetails;

  if (isLoading) {
    return <LoadingDisplay />;
  }


  const getModalTitle = () => {
    if (isFlow) return gaugeDetails.name + ' - Flow';
    if (isCoastal) return gaugeDetails.name + ' - Water Surface Elevation';
    return gaugeDetails.name + ' - Stage';
  }
  return (
    <Modal
      className={!isDesktop ? 'mobile-modal' : ''}
      size="lg"
      centered
      fullscreen={!isDesktop}
      show={show}
      onHide={handleClose}
      animation={true}
      scrollable={true}
    >
      <Modal.Header closeVariant="white" closeButton>
        <Modal.Title>{getModalTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-md-5 pt-md-4">
        <SingleAccordion
          header="Instructions"
          eventKey="0"
          defaultEventKey={null}
        >
          <div className="d-flex">
            <ul className="mb-0">
              {useNwmModel ? (
                <li>
                  Forecast data at this site are derived from the NOAA National Water Model (NWM) medium range blend ensemble dataset. 
                  Please visit the following site for additional information about the NOAA NWM: <a href="https://water.noaa.gov/about/nwm" target="_blank" rel="noreferrer">https://water.noaa.gov/about/nwm</a>.
                </li>
              ) : (
                <li>This hydrograph displays {isFlow ? 'river flow' : 'water elevations'} over a selected period of time, including forecast if available.</li>
              )}
              <li>Scroll on any section of the graph to zoom to a shorter time period.</li>
              {useNwmModel ? (
                <li>Click and drag on any section of the graph to pan across up to 30 days past and the remaining forecast, or use the Show All button</li>
              ) : (
                <li>Click and drag on any section of the graph to pan across up to 30 days of the past {isFlow ? 'flow' : 'trend'} or use the Show All button.</li>
              )}
            </ul>
          </div>
        </SingleAccordion>

        <div className="white-background mt-3">
          {isFlow &&
            <FlowHydrograph
              gaugeDetails={gaugeDetails}
              minDisplayDate={minDisplayDate}
            />
          }
          {!isFlow &&
            <StageHydrograph
              useNwmModel={useNwmModel}
              gaugeDetails={gaugeDetails}
              minDisplayDate={minDisplayDate}
            />
          }

        </div>

      </Modal.Body>
    </Modal>
  );
}

HydrographModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  minDisplayDate: PropTypes.instanceOf(Date),
  isFlow: PropTypes.bool,
  useNwmModel: PropTypes.bool
};

export default HydrographModal; 