import React from 'react'; 
import useMapStore from 'src/stores/mapStore';
import { Modal } from 'react-bootstrap';
import AuthModalBody from './AuthModalBody';

const SignUpModal = () => {
  const showSignUpModal = useMapStore(state => state.showSignUpModal);
  const toggleSignUpModal = useMapStore(state => state.toggleSignUpModal);
  return (
    <Modal
      size="md"
      centered
      show={showSignUpModal}
      onHide={() => toggleSignUpModal(false)}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Sign Up</Modal.Title>
      </Modal.Header>
      <AuthModalBody isSignIn={false} />
    </Modal>
  );
}; 

export default SignUpModal; 