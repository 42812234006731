import React from 'react';
import useMapStore from '../../../../stores/mapStore';
import useHistoricPeakEvents from 'src/lib/hooks/useHistoricPeakEvents';
import LoadingDisplay from 'src/components/LoadingDisplay';
import NameValueText from 'src/components/NameValueText';
import { roundToTenth } from 'src/lib/mathUtils';
import { formatDate } from 'src/lib/dateUtils';

/**
 * Loads the historical Top peak event for the active gauge and displays the name, date, and peak stage.
 */
const HistoricalTopPeakContent = () => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const { events, isLoading } = useHistoricPeakEvents(activeGauge?.attributes.siteId);

  if(isLoading) {
    return (
      <LoadingDisplay />
    );
  }

  return (
    <>
      {events.length > 0 ? (
        <div className="d-flex flex-column">
          <NameValueText label="Event" value={events[0].name} />
          <NameValueText label="Date" value={formatDate(events[0].date)} />
          <NameValueText label="Peak Stage" value={`${roundToTenth(events[0].stage)} ft`} />
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center h-100">
          <span className="text-muted">No historical peaks</span>
        </div>
      )}
    </>
  );
}


export default HistoricalTopPeakContent;
