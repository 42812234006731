import React, { useMemo } from 'react';
import useMapStore from 'src/stores/mapStore';


const RadarTimestamp = () => {
  const radarTimestamp = useMapStore(state => state.radarTimestamp);
  const showWeather = useMapStore(state => state.showWeather);

  const radarTimestampFormatted = useMemo(() => {
    if (radarTimestamp) {
      const minutes =  parseInt(radarTimestamp);
      const timeMessage = minutes === 0 ? 'Current' : `${minutes} minutes ago`;
      return timeMessage;
    }
    return '';
  }, [radarTimestamp]);

  return (
    showWeather && 
    <div 
      className="bg-black bg-opacity-25 bottom-0 end-0 fw-semibold position-absolute px-2 py-1 rounded-0 text-bg-primary"
      style={{ width: '240px' }}
    >
      { `Radar Time: ${radarTimestampFormatted}` }
    </div>
  );
}

export default RadarTimestamp;