import React from 'react';
import PropTypes from 'prop-types';
import { SearchResults } from '../../../constants/search';

const SuggestionGroup = ({ group, title, onSelect }) => {
  if (group.length === 0) {
    return null;
  }

  return (
    <>
      <li className="dropdown-header">{title}</li>
      {group.map((suggestion) => {
        const { name, key, type } = suggestion;
        const display = type === SearchResults.GAUGE ? `${key} - ${name}` : name;
        return (
          <li
            key={key}
            onClick={() => onSelect(suggestion)}
            className="p-1 cursor-pointer dropdown-item text-truncate px-3"
            title={display}
          >
            {display}
          </li>
        );
      })
      }
    </>
  );
};

SuggestionGroup.propTypes = {
  group: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SuggestionGroup;
