import React from 'react';
import PropTypes from 'prop-types';
import Widget from './Widget';
import useBridgesImpacted from 'src/lib/hooks/useBridgesImpacted';
import useMapStore from 'src/stores/mapStore';
import LoadingDisplay from 'src/components/LoadingDisplay';
import NameValueText from 'src/components/NameValueText';


// eslint-disable-next-line react/prop-types
const WidgetBody = ({ categories }) => {
  const CATEGORY_LUT = {
    'Pressure/Weir': 'Pressure/Weir',
    'Warning': 'Warning',
    'Normal': 'No Flooding',
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100">
      {Object.keys(categories).length > 0 ?
        (Object.keys(CATEGORY_LUT).map((category) => (
          // eslint-disable-next-line react/prop-types
          categories[category] ? <NameValueText key={category} label={CATEGORY_LUT[category]} value={categories[category].length?.toString()} /> : null
        ))) :
        (<div>No Bridges Impacted</div>)
      }
    </div>
  );
}

const BridgesImpactedWidget = ({ onActionClicked }) => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const inundationLevel = useMapStore(state => state.inundationLevel);
  const { impactedByCategory, isLoading, isError } = useBridgesImpacted(activeGauge?.attributes?.siteId, inundationLevel);

  return (
    <Widget title="Bridges Impacted" onActionClick={onActionClicked}>
      {isLoading && <LoadingDisplay />}
      {isError &&
        <div
          className="d-flex flex-column justify-content-center align-items-center h-100">
          Error loading bridges impacted
        </div>
      }
      {!isLoading && !isError && <WidgetBody categories={impactedByCategory} />}
    </Widget>
  );
}

BridgesImpactedWidget.propTypes = {
  onActionClicked: PropTypes.func
};

export default BridgesImpactedWidget;