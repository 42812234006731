import React from 'react';
import NavItem from './NavItem';
import { ZoomIn } from 'react-bootstrap-icons';
import useMapStore from 'src/stores/mapStore';
import useGaugeStore from 'src/stores/gaugeStore';
import { Tabs } from 'src/constants/tabs';
import styles from 'src/assets/styles/Gauge.module.scss';
import PillButton from 'src/components/PillButton';
import LoadingDisplay from 'src/components/LoadingDisplay';
import CurrentTabContent from './CurrentTabContent';
import HistoryTabContent from './HistoryTabContent';
import ForecastTabContent from './ForecastTabContent';
import ScenarioTabContent from './ScenarioTabContent';
import { DrawerLevels } from 'src/constants/drawerLevels';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import PhotosTabContent from './widgets/PhotosTabContent';
import useHistoricPeakEvents from 'src/lib/hooks/useHistoricPeakEvents';
import AlertButton from './widgets/AlertButton';
import useHasGaugeImages from 'src/lib/hooks/useHasGaugeImages';
import PermissionFilter from 'src/features/map/auth/PermissionsFilter';
import { CognitoGroups } from 'src/constants/cognitoGroups';
import GaugeReports from '../reports/GaugeReports';
import KMZDownloads from '../../../components/KMZDownloads';
import useUserGroup from 'src/lib/hooks/useUserGroup';
import { hasForecastData } from 'src/lib/forecastUtils';

const GaugeContent = () => {
  const activeTab = useGaugeStore(state => state.activeTab);
  const setActiveTab = useGaugeStore(state => state.setActiveTab);
  const zoomToGaugeLocation = useMapStore(state => state.zoomToGaugeLocation);
  const activeGauge = useMapStore(state => state.activeGauge);
  const drawerLevel = useGaugeStore(state => state.drawerLevel);
  const { isAdmin, isAdvanced } = useUserGroup();

  const { data: gauge, isLoading, isError } = useGaugeDetails(activeGauge.attributes.siteId);
  const { events: historicalEvents, isLoading: isHistoryLoading } = useHistoricPeakEvents(activeGauge?.attributes.siteId);
  const { data: hasImages, isLoading: isImagesLoading } = useHasGaugeImages(activeGauge?.attributes.siteId);

  /**
   * Add the logic at content level to handle the widget click event
   * in the event that we need to do something with coordinating the
   * widgets, tabs, etc.
   */
  const handleWidgetClicked = (name) => {
    switch (name) {
      case 'ForecastPeak':
        setActiveTab(Tabs.FORECAST);
        break;
      case 'HistoricalPeak':
        setActiveTab(Tabs.HISTORY);
        break;
      default:
        break;
    }
  }
  
  if (drawerLevel === DrawerLevels.MIN) {
    return (<div />);
  }

  if (isLoading) {
    return <LoadingDisplay />;
  }

  if (isError) {
    return <div>Error loading gauge data</div>;
  }

  const { inService, isScenario } = gauge;
  

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between p-2 position-relative dark-blue-background">
        <div className="d-flex m-1">
          <NavItem tabId={Tabs.CURRENT} tabName="Current" className={`px-4 ${activeTab === Tabs.CURRENT ? styles.activeTabItem : styles.tabItem}`} setActiveTab={setActiveTab} />
          {inService &&
            <>
              {isScenario &&
                <NavItem tabId={Tabs.SCENARIO} tabName="Scenario" className={`px-4 ${activeTab === Tabs.SCENARIO ? styles.activeTabItem : styles.tabItem}`} setActiveTab={setActiveTab} />
              }

              {isScenario && hasForecastData(gauge, isAdmin, isAdvanced) &&
                <NavItem tabId={Tabs.FORECAST} tabName="Forecast" className={`px-4 ${activeTab === Tabs.FORECAST ? styles.activeTabItem : styles.tabItem}`} setActiveTab={setActiveTab} />
              }

              {!isHistoryLoading && historicalEvents.length > 0 &&
                <NavItem tabId={Tabs.HISTORY} tabName="History" className={`px-4 ${activeTab === Tabs.HISTORY ? styles.activeTabItem : styles.tabItem}`} setActiveTab={setActiveTab} />
              }
            </>
          }

          {!isImagesLoading && hasImages &&
            <NavItem tabId={Tabs.PHOTOS} tabName="Photos" className={`px-4 ${activeTab === Tabs.PHOTOS ? styles.activeTabItem : styles.tabItem}`} setActiveTab={setActiveTab} />
          }
        </div>
        <div className="d-flex">
          <AlertButton />
          <PillButton
            size="sm"
            variant="random-orange"
            title="Zoom to Gauge Location"
            className="d-flex justify-content-center m-1 px-2"
            onClick={() => zoomToGaugeLocation(activeGauge)}
          >
            <ZoomIn className="mx-1 mb-1 mt-1" size="17" />
          </PillButton>
          <GaugeReports isScenario={activeTab !== Tabs.CURRENT} gauge={gauge} />
          {/* <PillButton size="sm" variant="random-orange" className="d-flex align-items-center justify-content-center m-1 px-2">Get Report</PillButton> */}
          <PermissionFilter any={[CognitoGroups.ADMIN, CognitoGroups.ADVANCED]}>
            <KMZDownloads activeGauge={activeGauge} activeTab={activeTab}/>
          </PermissionFilter>
        </div>
      </div>

      {((gauge.currentElevationMsl > gauge.svcMaxElev) && gauge.svcMaxElev != null && activeTab === Tabs.CURRENT ) &&
      <div className="d-flex flex-wrap justify-content-between px-3 py-0  position-relative dark-blue-background text-white"><i>The current water elevation exceeds the range of estimated flood impacts available at this site.  The highest available inundation extent is currently displayed.  Actual inundation and impacts may be greater.</i></div>
      }


      <div className="d-flex flex-grow-1 flex-column text-white p-0 mb-2 position-relative" style={{ overflowY: 'auto' }}>
        {activeTab === Tabs.CURRENT && <CurrentTabContent onWidgetClicked={(widget) => handleWidgetClicked(widget)} />}
        {activeTab === Tabs.SCENARIO && <ScenarioTabContent />}
        {activeTab === Tabs.HISTORY && <HistoryTabContent />}
        {activeTab === Tabs.FORECAST && <ForecastTabContent />}
        {activeTab === Tabs.PHOTOS && <PhotosTabContent />}
      </div>
    </>
  );
}

export default GaugeContent;