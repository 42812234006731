import React, { useState } from 'react';
import ForecastPeakContent from './ForecastPeakContent';
import SingleAccordion from 'src/components/SingleAccordion';
import useMapStore from 'src/stores/mapStore';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import HydrographModal from './HydrographModal';
import { isNwmModel } from 'src/lib/forecastUtils';
import useUserGroup from 'src/lib/hooks/useUserGroup';


const ForecastPeakWidgetSm = () => {
  const [showHydrographModal, setShowHydrographModal] = useState(false)
  const gauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails } = useGaugeDetails(gauge?.attributes?.siteId);
  const { isAdmin, isAdvanced } = useUserGroup();

  const getYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  }

  const useNwmModel = isNwmModel(gaugeDetails, isAdmin, isAdvanced);

  // only admin and advanced users can see NWM forecast, if it exists
  const title = () => {
    return (isAdmin || isAdvanced) && useNwmModel ? 'NWM Forecast' : 'RFC Forecast';
  }

  return (
    <>
      <SingleAccordion
        header={title()}
        eventKey="0"
        defaultEventKey={null}
      >
        <ForecastPeakContent useNwmModel={useNwmModel} modalCallback={() => setShowHydrographModal(true)} />
      </SingleAccordion>
      {gaugeDetails &&
        <HydrographModal
          useNwmModel={useNwmModel}
          show={showHydrographModal}
          handleClose={() => setShowHydrographModal(false)}
          minDisplayDate={getYesterday()}
        />
      }
    </>
  );
}

export default ForecastPeakWidgetSm;