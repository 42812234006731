import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';


const ImageCard = ({ title, image, alt, className, children }) => {
  return (
    <Row className={`bg-light py-3 px-2 rounded shadow-sm ${className}`}>
      <Col className="mt-4 mt-md-0" xs={{order: 'last'}} md={{span: 5, order: 'first'}} lg={4}>
        <img src={image} className="rounded" alt={alt} style={{ width: '100%' }} />
      </Col>
      <Col className="mt-0">
        <h5 className="mb-3 text-uppercase">{title}</h5>
        <p className="mb-0">
          {children}
        </p>
      </Col>
    </Row>
  );
};

ImageCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default ImageCard;