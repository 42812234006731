import React, { useCallback, useEffect, useState } from 'react';
import { 
  createColumnHelper, 
  flexRender, 
  getCoreRowModel, 
  getFacetedRowModel, 
  getFacetedUniqueValues, 
  getFilteredRowModel, 
  getPaginationRowModel, 
  getSortedRowModel, 
  useReactTable } from '@tanstack/react-table';
import { Table } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill, Search } from 'react-bootstrap-icons';
import useMapStore from 'src/stores/mapStore';
import useRoadsAffected from 'src/lib/hooks/useRoadsAffected';
import { roundToTenth } from 'src/lib/mathUtils';
import LoadingDisplay from 'src/components/LoadingDisplay';
import PaginationFooter from 'src/components/PaginationFooter';
import SelectFilter from '../../filters/SelectFilter';
import PrimaryButton from 'src/components/PrimaryButton';
import CenteredCell from './CenteredCell';
import { exportToExcel } from 'src/lib/dataExport';
import { convertElevationToStage } from 'src/lib/stageConversionUtils';
import { formatDate } from 'src/lib/dateUtils';
import { toFilenameSafe } from 'src/lib/stringUtils';


const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('select', {
    header: () => '',
    cell: () => <div className="text-center"><Search /></div>,
    enableSorting: false,
    size: 25,
    maxSize: 25,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('streetName', {
    header: () => 'Road Name',
    cell: info => <CenteredCell>{info.getValue()}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: false,
    size: 250,
  }),
  columnHelper.accessor('aadt', {
    header: () => 'AADT',
    cell: info => <CenteredCell>{info.getValue() ? info.getValue() : 'N/A'}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('maxDepth', {
    header: () => 'Max Depth (ft)',
    cell: info => <CenteredCell>{roundToTenth(info.getValue())}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('length', {
    header: () => 'Length (ft)',
    cell: info => <CenteredCell>{roundToTenth(info.getValue())}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('depthCat', {
    header: () => 'Range',
    cell: info => <CenteredCell>{info.getValue()}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('routeClass', {
    header: () => 'Road Type',
    cell: info => <CenteredCell>{info.getValue() === 'SR Route' ? 'Local' : info.getValue()}</CenteredCell>,
    enableSorting: true,
    enableColumnFilter: true,
  }),
];

const RoadsSegmentTable = () => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const inundationLevel = useMapStore(state => state.inundationLevel);
  const mapManager = useMapStore(state => state.mapManager);

  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);

  const { entries, isLoading } = useRoadsAffected(activeGauge?.attributes?.siteId, inundationLevel);

  const options = {
    data: entries,
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
    enableFilters: true,
    enableColumnFilters: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
  };

  const table = useReactTable(options);
  const selectedRows = table.getFilteredSelectedRowModel().flatRows;

  const reset = useCallback(() => {
    if (!table) return;

    table.resetColumnFilters(true);
    setSorting([]);
    setRowSelection({});
    mapManager.clearRoadHighlight();
  }, [table, mapManager]);

  useEffect(() => {
    reset();
    return () => {
      reset();
    }
  }, [entries, table, reset]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      const row = selectedRows[0];
      mapManager.zoomToFeatureByGeometry(row?.original?.center, 19);
      mapManager.highlightRoadFeature(row?.original?.objectId);
    }
  }, [selectedRows, mapManager]);

  const handleExport = () => {
    const dataMap = [
      {
        columnName: 'Road Name',
        dataKey: 'streetName',
      },
      {
        columnName: 'AADT',
        dataKey: 'aadt',
        converter: value => value ? value : 'N/A',
        format: '0'
      },
      {
        columnName: 'Max Depth (ft)',
        dataKey: 'maxDepth',
        converter: value => roundToTenth(value),
      },
      {
        columnName: 'Length (ft)',
        dataKey: 'length',
        converter: value => roundToTenth(value),
      },
      {
        columnName: 'Range',
        dataKey: 'depthCat',
      },
      {
        columnName: 'Road Type',
        dataKey: 'routeClass',
        converter: value => value === 'SR Route' ? 'Local' : value,
      },
    ];

    const { name, siteId, isCoastal, gageDatum } = activeGauge?.attributes || {};

    const coastalString = isCoastal ? '' : `and a stage of ${roundToTenth(convertElevationToStage(inundationLevel, gageDatum))} ft.`;

    const subHeaders = [
      `${name} (${siteId})`,
      `This report was generated using an elevation of ${inundationLevel} ft. ${coastalString}`
    ];  

    const safeName = toFilenameSafe(name).toLowerCase();
    const filename = `${safeName}_roads-impacted_${formatDate(Date.now(), 'MMddyyy')}.xlsx`;

    exportToExcel(
      'FIMAN Inundation Export',
      subHeaders,
      dataMap, 
      table.getFilteredRowModel().rows.map(row => row.original), 
      filename, 
      'Road Segments'
    );
  }

  if (isLoading) {
    return (<LoadingDisplay />);
  }

  if (!entries || entries.length === 0) {
    return (<div className="d-flex flex-fill align-items-center justify-content-center h-100">
      No road impacts reported at this flood stage.
    </div>);
  }

  return (
    <>
      <div className="d-flex justify-content-between p-2">
        <div className="d-flex gap-1">
          <PrimaryButton
            className="rounded-pill btn-sm m-1"
            onClick={() => handleExport()}
          >
              Export
          </PrimaryButton>
          <PrimaryButton
            className="rounded-pill btn-sm m-1"
            onClick={() => reset()}
          >
            Reset
          </PrimaryButton>
        </div>
        <SelectFilter
          className="d-flex align-items-center"
          label="Road Type"
          column={table.getColumn('routeClass')}
          table={table}
          nameFormatter={value => value === 'SR Route' ? 'Local' : value}
        />
      </div>
      <Table size="sm" variant="dark" striped bordered hover>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} style={{ width: header.getSize() }}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? 'd-flex justify-content-center cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <span className="ms-1"><CaretUpFill /></span>,
                        desc: <span className="ms-1"><CaretDownFill /></span>,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={row.getIsSelected() ? 'table-light' : ''}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} onClick={() => setRowSelection({ [row.id]: true })}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <PaginationFooter
        table={table}
        totalRecordCount={table.getFilteredRowModel().rows.length}
        pageSize={5}
      />
    </>
  )
}

export default RoadsSegmentTable;