import { GaugeMarkerColors } from 'src/constants/gaugeMarkerColors';
import { formatDate, formatDateTime } from './dateUtils';
import { exportToExcel } from './dataExport';
import { toFilenameSafe } from './stringUtils';
import { convertStageToElevation } from './stageConversionUtils';
import { roundToTenth } from './mathUtils';


/**
 * Helper function to get the inundation height for a given gauge
 * @param height - the height to check
 * @param levels - the levels to check against
 * @param lockMin - whether or not to lock to the min height
 * @param lockMax - whether or not to lock to the max height
 * @returns 
 */
export const getInundationLevel = (height, levels, lockMin = false, lockMax = true) => {
  if (!levels || levels.length === 0) return height;

  const min = Math.min(...levels);
  const max = Math.max(...levels);

  if (height < min) {
    return lockMin ? min : height;
  }

  if (height > max) {
    return lockMax ? max : height;
  }

  // find the level equal to or just above the height
  const level = levels.find((level) => level >= height);

  return level;
}

/**
 * Helper function to get the inundation color for a given gauge
 * @param height - the height to check
 * @returns the color for the inundation level
 */
const getConditionColor = (gauge, height) => {
  const { bankFull, minor, moderate, major, } = gauge;

  if (!bankFull) {
    return GaugeMarkerColors.GRAY;
  }

  let color = GaugeMarkerColors.GRAY;

  if (height < bankFull) {
    color = GaugeMarkerColors.GREEN;
  } else if (height < minor) {
    color = GaugeMarkerColors.YELLOW;
  } else if (height < moderate) {
    color = GaugeMarkerColors.ORANGE;
  } else if (height < major) {
    color = GaugeMarkerColors.RED;
  } else {
    color = GaugeMarkerColors.PURPLE;
  }

  return color;
}

const getConditionName = (gauge, height) => {
  const { bankFull, minor, moderate, major, } = gauge;

  if (!bankFull) {
    return 'Unknown';
  }

  let name = 'Unknown';

  if (height < bankFull) {
    name = 'Normal';
  } else if (height < minor) {
    name = 'Monitor';
  } else if (height < moderate) {
    name = 'Minor Flooding';
  } else if (height < major) {
    name = 'Moderate Flooding';
  } else {
    name = 'Major Flooding';
  }

  return name;
}

/**
 * Helper function to get forecast range data for a given gauge
 * Coastal forecast data has a 1 hour interval and data is in MSL
 * @param gauge - the gauge
 * @param data - the data, MSL
*/
export const getCoastalForecastData = (gauge, data) => {
  // coastal forecast hour intervals
  let start = 0;
  let end = 1;
  const forecastData = [];

  // the last data point is in the past, so nothing to forecast
  if (data[data.length - 1].read > 0) {
    return null;
  }

  for (let i = data[0].update; i < data.length; i++) {
    forecastData.push({
      height: data[i].value,
      stage: data[i].value,
      date: data[i].at,
      range: {
        min: start,
        max: end,
        color: getConditionColor(gauge, data[i].value),
      }
    });

    start = end;
    end += 1;
  }

  return {
    forecastData,
    maxForecastTime: data.length - data[data.length - 1].update
  };
}

/**
 * Helper function to get forecast range data for a given gauge
 * River forecast data has a 6 hour interval and data is in MSL.
 * Uses the gauge datum to convert to stage.
 * @param gauge - the gauge
 * @param data - the data, MSL
*/
export const getRiverForecastData = (gauge, data) => {
  // river forecast 6 hour intervals
  let start = 0;
  let end = 6;
  const forecastData = [];

  // the last data point is in the past, so nothing to forecast
  if (data[data.length - 1].read > 0) {
    return null;
  }

  for (let i = Math.floor(data[0].update / 6); i < data.length; i++) {
    forecastData.push({
      height: data[i].value,
      stage: data[i].value - gauge.gageDatum,
      date: data[i].at,
      range: {
        min: start,
        max: end,
        color: getConditionColor(gauge, data[i].value),
      }
    });

    start = end;
    end += 6;
  }

  return {
    forecastData,
    maxForecastTime: data.length * 6 - data[data.length - 1].update
  };
}

export const getNwmForecastData = (gauge, data) => {
  let start = 0;
  let end = 1;
  const forecastData = [];

  // the last data point is in the past, so nothing to forecast
  if (data[data.length - 1].read > 0) {
    return null;
  }

  for (let i = data[0].update; i < data.length; i++) {
    forecastData.push({
      height: data[i].value,
      stage: data[i].value,
      date: data[i].at,
      range: {
        min: start,
        max: end,
        color: getConditionColor(gauge, data[i].value + gauge.gageDatum),
      }
    });

    start = end;
    end += 1;
  }

  return {
    forecastData,
    maxForecastTime: data.length - data[data.length - 1].update
  };
}

export const exportHydrographData = (gauge, historicalData, forecastData) => {
  const safeName = toFilenameSafe(gauge.name).toLowerCase();
  const filename = `${safeName}_hydrograph-${formatDate(Date.now(), 'MMddyyy')}.xlsx`;

  const dataMap = [
    {
      columnName: 'Date/Time',
      dataKey: 'dataAt',
      converter: value => value ? formatDateTime(value) : 'N/A',
    }
  ];

  if (!gauge.isCoastal) {
    dataMap.push({
      columnName: 'Stage (ft)',
      dataKey: 'value',
      converter: value => value != null ? roundToTenth(value) : 'N/A',
    });
  }

  // when coastal, the data is in MSL, otherwise it's in stage
  dataMap.push({
    columnName: 'Elevation (ft NAVD 88)',
    dataKey: 'value',
    converter:  (value) => value != null ? roundToTenth(!gauge.isCoastal ? convertStageToElevation(value, gauge.gageDatum) : value) : 'N/A',
  });

  // calulations are done in MSL
  dataMap.push({
    columnName: 'Risk Rating',
    dataKey: 'value',
    converter: value => value != null ? getConditionName(gauge, roundToTenth((!gauge.isCoastal ? convertStageToElevation(value, gauge.gageDatum) : value))) : 'N/A',
    styleProvider: (value) => {
      if(value == null) return null;
      const color = getConditionColor(gauge, roundToTenth((!gauge.isCoastal ? convertStageToElevation(value, gauge.gageDatum) : value)));
      return {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { 
          argb: `FF${color.substring(1)}`
        },
      };
    }
  });

  dataMap.push({
    columnName: 'Source',
    dataKey: 'source',
  });

  const subHeaders = [
    `${gauge.name} (${gauge.siteId})`,
  ];

  // find last date of historical data and trim forecast data to prevent overlapping
  const latestHistoricalDate = historicalData[historicalData.length - 1]?.dataAt ? new Date(historicalData[historicalData.length - 1].dataAt) : null;
  const filteredForecastData = latestHistoricalDate ? forecastData.filter(forecast => new Date(forecast.dataAt) > latestHistoricalDate) : forecastData;

  const data = [
    ...historicalData.map((data) => {
      return {
        dataAt: data.dataAt,
        value: data.dataValue,
        source: 'Historical'
      };
    }),

    ...filteredForecastData.map((data) => {
      return {
        dataAt: data.dataAt,
        value: data.dataValue,
        source: 'Forecast'
      };
    })
  ];

  exportToExcel(
    'Hydrograph Data Export',
    subHeaders,
    dataMap,
    data,
    filename,
    'Hydrograph data'
  );
}

