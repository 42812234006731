import React, { useState } from 'react';
import Widget from './Widget';
import useMapStore from 'src/stores/mapStore';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import TrendContent from './TrendContent';
import HydrographModal from './HydrographModal';
import LoadingDisplay from 'src/components/LoadingDisplay';
import useUserGroup from 'src/lib/hooks/useUserGroup';
import { isNwmModel } from 'src/lib/forecastUtils';

const TrendWidget = () => {
  const gauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails, isLoading } = useGaugeDetails(gauge?.attributes?.siteId);
  const [showHydrographModal, setShowHydrographModal] = useState(false);
  const { isAdmin, isAdvanced } = useUserGroup();

  const useNwmModel = isNwmModel(gaugeDetails, isAdmin, isAdvanced);

  if (isLoading) {
    return <LoadingDisplay />;
  }

  const handleActionClick = () => {
    setShowHydrographModal(!showHydrographModal);
  }

  const { trend } = gaugeDetails;

  return (
    <Widget title={`Trend: ${trend}`} onActionClick={handleActionClick}>
      <TrendContent useNwmModel={useNwmModel} />
      {gaugeDetails &&
        <HydrographModal
          useNwmModel={useNwmModel}
          show={showHydrographModal}
          handleClose={() => setShowHydrographModal(false)}
        />
      }

    </Widget>
  );
}

export default TrendWidget;