import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getAttachments, queryFeatures } from '@esri/arcgis-rest-feature-service';
import LoadingDisplay from 'src/components/LoadingDisplay';
import useConfigData from 'src/lib/hooks/useConfigData';
import GaugeImageList from './GaugeImageList';
import ImageUploader from './ImageUploader';


const getGaugeFeatureId = async (siteId, url) => {
  const features = await queryFeatures({
    url: url,
    where: `SITE_ID = '${siteId}'`,
    outFields: ['OBJECTID'],
    returnGeometry: false
  });

  if (features.features.length === 0) {
    return null;
  }

  const objectId = features.features[0].attributes.OBJECTID;

  return objectId;
}

const loadGaugeImages = async (gaugeFeatureId, url) => {
  const attachments = await getAttachments({
    url: url,
    featureId: gaugeFeatureId
  });

  if (attachments.attachmentInfos.length === 0) {
    return [];
  }

  const loaders = attachments.attachmentInfos.map(async (attachment) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = `${url}/${gaugeFeatureId}/attachments/${attachment.id}`;
      image.onload = () => {
        resolve({
          id: attachment.id,
          title: attachment.name,
          src: image.src,
          width: image.width,
          height: image.height
        });
      };
    });
  });

  const images = await Promise.all(loaders);

  return images;
}

const PhotosEditor = ({ siteId }) => {
  const { data: config } = useConfigData('Map');
  const [images, setImages] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const objectIdRef = useRef(null);

  useEffect(() => {
    const load = async () => {
      const gaugeFeatureId = await getGaugeFeatureId(siteId, config?.arcgisRestUrl);
      const attributeImages = await loadGaugeImages(gaugeFeatureId, config?.arcgisRestUrl);
      
      // save the featureId for the attachment calls
      objectIdRef.current = gaugeFeatureId;

      setImages(attributeImages);
      setIsloading(false);
    }

    if (siteId) {
      setIsloading(true);
      load();
    }
  }, [siteId, config]);

  const reloadImages = async () => {
    const attributeImages = await loadGaugeImages(objectIdRef.current, config?.arcgisRestUrl);
    setImages(attributeImages);
  }

  if (isLoading) {
    return (
      <LoadingDisplay />
    );
  }

  return (
    <>
      <GaugeImageList
        images={images}
        gaugeFeatureId={objectIdRef.current}
        editUrl={config?.layers.gauges.editFeatureUrl}
        onImagesUpdated={async () => {
          await reloadImages();
        }}
      />
      <ImageUploader
        gaugeFeatureId={objectIdRef.current}
        editUrl={config?.layers.gauges.editFeatureUrl}
        onImageUploaded={async () => {
          await reloadImages();
        }}
      />
    </>
  )
}

PhotosEditor.propTypes = {
  siteId: PropTypes.string,
};

export default PhotosEditor;