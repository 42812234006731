import React from 'react';
import PropTypes from 'prop-types';
import useUserStore from 'src/stores/userStore';
import useMapStore from 'src/stores/mapStore';
import { Modal, Button } from 'react-bootstrap';
import { FcGoogle } from 'react-icons/fc';
import { Facebook, Apple } from 'react-bootstrap-icons';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

const AuthModalBody = ({ isSignIn }) => {
  const cognitoUser = useUserStore(state => state.cognitoUser);
  const signIn = useUserStore(state => state.signIn);
  const toggleSignInModal = useMapStore(state => state.toggleSignInModal);
  const toggleSignUpModal = useMapStore(state => state.toggleSignUpModal)
  const buttonText = isSignIn ? 'Sign In' : 'Sign Up';

  if (cognitoUser) {
    return (
      <Modal.Body className="p-md-5 pt-md-4">
        <p className="d-flex align-items-center justify-content-center">
          It looks like you're already signed in as {cognitoUser.signInUserSession.idToken.payload.name}.
        </p>
      </Modal.Body>
    )
  }

  const handleSingUpSwitch = () => {
    toggleSignInModal(false);
    toggleSignUpModal(true);
  }
  return (
    <Modal.Body className="p-md-5 pt-md-4">
      <div className="d-flex flex-column dark-gray-background mt-3">
        <Button
          variant="white-primary"
          className="rounded-pill mt-2"
          onClick={() => signIn(CognitoHostedUIIdentityProvider.Google)}
        >
          <div className="d-flex align-items-center justify-content-center">
            <FcGoogle className="me-2" />
            <span className="text-truncate">{buttonText} With Google</span>
          </div>
        </Button>
        <Button
          variant="white-primary"
          className="rounded-pill mt-2"
          onClick={() => signIn(CognitoHostedUIIdentityProvider.Facebook)}
        >
          <div className="d-flex align-items-center justify-content-center">
            <Facebook className="me-2" />
            <span className="text-truncate">{buttonText} With Facebook</span>
          </div>
        </Button>
        <Button
          variant="white-primary"
          className="rounded-pill mt-2"
          onClick={() => signIn(CognitoHostedUIIdentityProvider.Apple)}
        >
          <div className="d-flex align-items-center justify-content-center">
            <Apple className="me-2" />
            <span className="text-truncate">{buttonText} With Apple</span>
          </div>
        </Button>
        <Button
          variant="white-primary"
          className="rounded-pill mt-3"
          onClick={() => signIn('azure-ad')}
        >
          <div className="d-flex align-items-center justify-content-center">
            <span className="text-truncate">Government Employee {buttonText}</span>
          </div>
        </Button>
      </div>

      {isSignIn && (
        <>
          <hr />
          <p className="d-flex align-items-center justify-content-center">
            Don't have an account yet? Sign up <Button className="p-1" variant="link" onClick={() => handleSingUpSwitch()}>here!</Button>
          </p>
        </>
      )}
    </Modal.Body>
  );
};

AuthModalBody.propTypes = {
  isSignIn: PropTypes.bool.isRequired
};
export default AuthModalBody;