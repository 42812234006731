import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';
import AdminSidebar from 'src/features/admin/AdminSidebar';
import ManageUsers from 'src/features/admin/users/ManageUsers';
import ManageGauges from 'src/features/admin/gauges/ManageGauges';


const AdminPage = () => {
  const [key, setKey] = useState('users');

  return (
    <>
      <Tab.Container transition={false}>
        <div className="d-flex end-0 h-100 me-0">
          <AdminSidebar setKey={setKey} activeKey={key} />
          <Tab.Content>
            <Tab.Pane active={key === 'users'}>
              <ManageUsers />
            </Tab.Pane>
            <Tab.Pane active={key === 'gauges'}>
              <ManageGauges />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </>
  );
};

export default AdminPage;