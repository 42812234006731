import React from 'react';
import { Container } from 'react-bootstrap';

const DesktopOnlyDisplay = () => {

  return (
    <Container fluid>
      <h1>This feature is available only on Desktop devices.</h1>
    </Container>);
};

export default DesktopOnlyDisplay;