import { GaugeMarkerColors } from 'src/constants/gaugeMarkerColors';

/**
 * Helper function to create a size expression.
 * @param {Number} size - The size to use for the smallest scale.
 * @returns {Object} The size expression.
*/
const createSizeExpression = (size) => {
  return {
    type: 'size',
    valueExpression: '$view.scale',
    stops: [
      { size: size, value: 1155581 },
      { size: 4, value: 4622324 }
    ]
  };
};

/**
  * Helper function to create a simple marker symbol.
  * @param {Number} value - The value to use for the unique value renderer.
 */
const createSvgSimpleMarkerSymbol = (value, url, size = 5) => {
  const image = `${process.env.PUBLIC_URL}/img/${url}`;
  return {
    value: value,
    symbol: {
      type: 'picture-marker',
      size,
      url: image,
    },
  };
};

/**
 * Helper function to create a simple marker symbol.
 * @param {Number} value - The value to use for the unique value renderer.
 * @param {String} color - The color to use for the symbol.
 * @param {Number} size - The size to use for the symbol.
 * @param {String} outlineColor - The outline color to use for the symbol.
 * @param {Number} outlineWidth - The outline width to use for the symbol.
 * @returns {Object} The simple marker symbol.
 */
const createSimpleMarkerSymbol = (value, color, size = 5, outlineColor = 'black', outlineWidth = 0.5) => {
  return {
    value: value,
    symbol: {
      type: 'simple-marker',
      color,
      size,
      outline: {
        width: outlineWidth,
        color: outlineColor,
      },
    },
  };
};

/**
 * The default gauge renderer. 
 * in-serverice values 0, 1
 * condition values -1, 0, 1, 2, 3, 4
 */
export const gaugeRenderer = {
  type: 'unique-value',
  // field: 'condition',
  valueExpression: "When($feature.inService == 1, $feature.condition, 'other')",
  defaultSymbol: {
    color: GaugeMarkerColors.GRAY,
    size: 5,
    type: 'simple-marker',
    outline: {
      width: 0.5,
      color: [50, 50, 50, 0.6],
    },
  },
  uniqueValueInfos: [
    createSimpleMarkerSymbol(-1, GaugeMarkerColors.BLUE),
    createSimpleMarkerSymbol(0, GaugeMarkerColors.GREEN),
    createSimpleMarkerSymbol(1, GaugeMarkerColors.YELLOW),
    createSimpleMarkerSymbol(2, GaugeMarkerColors.ORANGE),
    createSimpleMarkerSymbol(3, GaugeMarkerColors.RED),
    createSimpleMarkerSymbol(4, GaugeMarkerColors.PURPLE),
  ],
  visualVariables: [
    createSizeExpression(11),
  ]
};

/**
 * Example of a gauge renderer using SVG symbols.
 * @returns {Array} The array of unique value infos.
 */
const createSymbols = () => {
  const images = [
    'Minor_Falling.svg',
    'Monitor_Falling.svg',
    'Normal_Rising_Forcast_Major.svg',
    'Normal_Rising.svg',
    'Minor_Falling.svg',
  ];

  const symbols = images.map((image, index) => {
    return createSvgSimpleMarkerSymbol(index, image);
  });

  return symbols;
}

/**
 * The gauge renderer using SVG symbols.
 * @returns {Object} The gauge renderer.
*/
export const gaugeRendererSvg = {
  type: 'unique-value',
  field: 'condition',
  defaultSymbol: {
    color: 'black',
    size: 5,
    type: 'simple-marker',
    outline: {
      width: 0.5,
      color: [50, 50, 50, 0.6],
    },
  },
  uniqueValueInfos: createSymbols(),
  visualVariables: [
    createSizeExpression(20),
  ]
};

/**
 * The gauge renderer using CIM symbols.
 * @returns {Object} The gauge renderer.
*/
export const gaugeRendererCim = {
  type: 'simple',
  symbol: {
    type: 'cim',
    data: {
      'type': 'CIMSymbolReference',
      'symbol': {
        'type': 'CIMPointSymbol',
        'symbolLayers': [
          {
            'type': 'CIMVectorMarker',
            'primitiveName': 'OutOfService',
            'enable': true,
            'anchorPointUnits': 'Relative',
            'dominantSizeAxis3D': 'Z',
            'size': 6,
            'billboardMode3D': 'FaceNearPlane',
            'frame': {
              'xmin': 0,
              'ymin': 0,
              'xmax': 7.07,
              'ymax': 7.07
            },
            'markerGraphics': [
              {
                'type': 'CIMMarkerGraphic',
                'geometry': {
                  'rings': [
                    [
                      [
                        0,
                        0.71
                      ],
                      [
                        0.71,
                        0
                      ],
                      [
                        7.07,
                        6.36
                      ],
                      [
                        6.36,
                        7.07
                      ],
                      [
                        0,
                        0.71
                      ]
                    ]
                  ]
                },
                'symbol': {
                  'type': 'CIMPolygonSymbol',
                  'symbolLayers': [
                    {
                      'type': 'CIMSolidFill',
                      'enable': true,
                      'color': [
                        0,
                        0,
                        0,
                        255
                      ]
                    }
                  ]
                }
              },
              {
                'type': 'CIMMarkerGraphic',
                'geometry': {
                  'rings': [
                    [
                      [
                        7.07,
                        0.71
                      ],
                      [
                        6.36,
                        0
                      ],
                      [
                        0,
                        6.36
                      ],
                      [
                        0.71,
                        7.07
                      ],
                      [
                        7.07,
                        0.71
                      ]
                    ]
                  ]
                },
                'symbol': {
                  'type': 'CIMPolygonSymbol',
                  'symbolLayers': [
                    {
                      'type': 'CIMSolidFill',
                      'enable': true,
                      'color': [
                        0,
                        0,
                        0,
                        255
                      ]
                    }
                  ]
                }
              }
            ],
            'scaleSymbolsProportionally': true,
            'respectFrame': true,
            'clippingPath': {
              'type': 'CIMClippingPath',
              'clippingType': 'Intersect',
              'path': {
                'rings': [
                  [
                    [
                      0,
                      0
                    ],
                    [
                      7.07,
                      0
                    ],
                    [
                      7.07,
                      7.07
                    ],
                    [
                      0,
                      7.07
                    ],
                    [
                      0,
                      0
                    ]
                  ]
                ]
              }
            }
          },
          {
            'type': 'CIMVectorMarker',
            'primitiveName': 'Raindrop',
            'enable': true,
            'anchorPointUnits': 'Relative',
            'dominantSizeAxis3D': 'Z',
            'size': 10,
            'billboardMode3D': 'FaceNearPlane',
            'frame': {
              'xmin': 0,
              'ymin': 0,
              'xmax': 11,
              'ymax': 16
            },
            'markerGraphics': [
              {
                'type': 'CIMMarkerGraphic',
                'geometry': {
                  'rings': [
                    [
                      [
                        5.5,
                        16
                      ],
                      [
                        3.09,
                        12.25
                      ],
                      [
                        1.37,
                        9
                      ],
                      [
                        0.77,
                        7.56
                      ],
                      [
                        0.34,
                        6.25
                      ],
                      [
                        0.09,
                        5.06
                      ],
                      [
                        0,
                        4
                      ],
                      [
                        0.09,
                        3.06
                      ],
                      [
                        0.34,
                        2.25
                      ],
                      [
                        0.77,
                        1.56
                      ],
                      [
                        1.37,
                        1
                      ],
                      [
                        2.15,
                        0.56
                      ],
                      [
                        3.09,
                        0.25
                      ],
                      [
                        4.21,
                        0.06
                      ],
                      [
                        5.5,
                        0
                      ],
                      [
                        6.79,
                        0.06
                      ],
                      [
                        7.91,
                        0.25
                      ],
                      [
                        8.85,
                        0.56
                      ],
                      [
                        9.62,
                        1
                      ],
                      [
                        10.23,
                        1.56
                      ],
                      [
                        10.66,
                        2.25
                      ],
                      [
                        10.91,
                        3.06
                      ],
                      [
                        11,
                        4
                      ],
                      [
                        10.91,
                        5.06
                      ],
                      [
                        10.66,
                        6.25
                      ],
                      [
                        10.23,
                        7.56
                      ],
                      [
                        9.62,
                        9
                      ],
                      [
                        7.91,
                        12.25
                      ],
                      [
                        5.5,
                        16
                      ]
                    ]
                  ]
                },
                'symbol': {
                  'type': 'CIMPolygonSymbol',
                  'symbolLayers': [
                    {
                      'type': 'CIMSolidStroke',
                      'enable': true,
                      'capStyle': 'Butt',
                      'joinStyle': 'Miter',
                      'lineStyle3D': 'Strip',
                      'miterLimit': 4,
                      'width': 4,
                      'color': [
                        255,
                        255,
                        255,
                        255
                      ]
                    },
                    {
                      'type': 'CIMSolidFill',
                      'enable': true,
                      'color': [
                        87,
                        239,
                        255,
                        255
                      ]
                    }
                  ]
                }
              },
              {
                'type': 'CIMMarkerGraphic',
                'geometry': {
                  'rings': [
                    [
                      [
                        5.5,
                        16
                      ],
                      [
                        3.09,
                        12.25
                      ],
                      [
                        1.37,
                        9
                      ],
                      [
                        0.77,
                        7.56
                      ],
                      [
                        0.34,
                        6.25
                      ],
                      [
                        0.09,
                        5.06
                      ],
                      [
                        0,
                        4
                      ],
                      [
                        0.09,
                        3.06
                      ],
                      [
                        0.34,
                        2.25
                      ],
                      [
                        0.77,
                        1.56
                      ],
                      [
                        1.37,
                        1
                      ],
                      [
                        2.15,
                        0.56
                      ],
                      [
                        3.09,
                        0.25
                      ],
                      [
                        4.21,
                        0.06
                      ],
                      [
                        5.5,
                        0
                      ],
                      [
                        6.79,
                        0.06
                      ],
                      [
                        7.91,
                        0.25
                      ],
                      [
                        8.85,
                        0.56
                      ],
                      [
                        9.62,
                        1
                      ],
                      [
                        10.23,
                        1.56
                      ],
                      [
                        10.66,
                        2.25
                      ],
                      [
                        10.91,
                        3.06
                      ],
                      [
                        11,
                        4
                      ],
                      [
                        10.91,
                        5.06
                      ],
                      [
                        10.66,
                        6.25
                      ],
                      [
                        10.23,
                        7.56
                      ],
                      [
                        9.62,
                        9
                      ],
                      [
                        7.91,
                        12.25
                      ],
                      [
                        5.5,
                        16
                      ]
                    ]
                  ]
                },
                'symbol': {
                  'type': 'CIMPolygonSymbol',
                  'symbolLayers': [
                    {
                      'type': 'CIMSolidStroke',
                      'enable': true,
                      'capStyle': 'Butt',
                      'joinStyle': 'Miter',
                      'lineStyle3D': 'Strip',
                      'miterLimit': 4,
                      'width': 1.25,
                      'color': [
                        0,
                        0,
                        0,
                        255
                      ]
                    },
                    {
                      'type': 'CIMSolidFill',
                      'enable': true,
                      'color': [
                        87,
                        239,
                        255,
                        255
                      ]
                    }
                  ]
                }
              },
              {
                'type': 'CIMMarkerGraphic',
                'geometry': {
                  'rings': [
                    [
                      [
                        4.5,
                        2.33
                      ],
                      [
                        3.36,
                        2.81
                      ],
                      [
                        2.55,
                        3.3
                      ],
                      [
                        2.06,
                        3.8
                      ],
                      [
                        1.94,
                        4.06
                      ],
                      [
                        1.9,
                        4.32
                      ],
                      [
                        2.06,
                        5.17
                      ],
                      [
                        2.55,
                        6.67
                      ],
                      [
                        3.36,
                        8.82
                      ],
                      [
                        4.5,
                        11.62
                      ],
                      [
                        3.78,
                        4.99
                      ],
                      [
                        4.5,
                        2.33
                      ]
                    ]
                  ]
                },
                'symbol': {
                  'type': 'CIMPolygonSymbol',
                  'symbolLayers': [
                    {
                      'type': 'CIMSolidFill',
                      'enable': true,
                      'color': [
                        50,
                        65,
                        174,
                        255
                      ]
                    }
                  ]
                }
              }
            ],
            'scaleSymbolsProportionally': true,
            'respectFrame': true,
            'clippingPath': {
              'type': 'CIMClippingPath',
              'clippingType': 'Intersect',
              'path': {
                'rings': [
                  [
                    [
                      0,
                      0
                    ],
                    [
                      11,
                      0
                    ],
                    [
                      11,
                      16
                    ],
                    [
                      0,
                      16
                    ],
                    [
                      0,
                      0
                    ]
                  ]
                ]
              }
            },
            'offsetY': 7,
            'offsetX': 7
          },
          {
            'type': 'CIMVectorMarker',
            'primitiveName': 'TrendArrow',
            'enable': true,
            'anchorPointUnits': 'Relative',
            'dominantSizeAxis3D': 'Y',
            'size': 7,
            'billboardMode3D': 'FaceNearPlane',
            'frame': {
              'xmin': 0,
              'ymin': 0,
              'xmax': 800,
              'ymax': 800
            },
            'markerGraphics': [
              {
                'type': 'CIMMarkerGraphic',
                'geometry': {
                  'rings': [
                    [
                      [
                        404,
                        83
                      ],
                      [
                        430,
                        66
                      ],
                      [
                        461,
                        61
                      ],
                      [
                        493,
                        69
                      ],
                      [
                        520,
                        87
                      ],
                      [
                        774,
                        341
                      ],
                      [
                        793,
                        368
                      ],
                      [
                        800,
                        400
                      ],
                      [
                        795,
                        431
                      ],
                      [
                        778,
                        457
                      ],
                      [
                        775,
                        459
                      ],
                      [
                        772,
                        463
                      ],
                      [
                        522,
                        714
                      ],
                      [
                        495,
                        732
                      ],
                      [
                        463,
                        739
                      ],
                      [
                        432,
                        733
                      ],
                      [
                        407,
                        716
                      ],
                      [
                        390,
                        690
                      ],
                      [
                        384,
                        660
                      ],
                      [
                        391,
                        628
                      ],
                      [
                        409,
                        601
                      ],
                      [
                        532,
                        479
                      ],
                      [
                        79,
                        479
                      ],
                      [
                        49,
                        473
                      ],
                      [
                        23,
                        456
                      ],
                      [
                        6,
                        430
                      ],
                      [
                        0,
                        400
                      ],
                      [
                        6,
                        370
                      ],
                      [
                        23,
                        344
                      ],
                      [
                        49,
                        327
                      ],
                      [
                        79,
                        321
                      ],
                      [
                        530,
                        321
                      ],
                      [
                        408,
                        199
                      ],
                      [
                        389,
                        172
                      ],
                      [
                        382,
                        140
                      ],
                      [
                        387,
                        109
                      ],
                      [
                        404,
                        83
                      ]
                    ]
                  ]
                },
                'symbol': {
                  'type': 'CIMPolygonSymbol',
                  'symbolLayers': [
                    {
                      'type': 'CIMSolidFill',
                      'primitiveName': 'TrendArrowFill',
                      'enable': true,
                      'color': [
                        0,
                        0,
                        0,
                        255
                      ]
                    }
                  ]
                }
              }
            ],
            'scaleSymbolsProportionally': true,
            'respectFrame': true,
            'colorLocked': true,
            'rotateClockwise': false,
            'rotation': 90
          },
          {
            'type': 'CIMVectorMarker',
            'enable': true,
            'primitiveName': 'Condition',
            'anchorPoint': {
              'x': 0,
              'y': 0
            },
            'anchorPointUnits': 'Relative',
            'size': 12,
            'frame': {
              'xmin': 0,
              'ymin': 0,
              'xmax': 17,
              'ymax': 17
            },
            'markerGraphics': [
              {
                'type': 'CIMMarkerGraphic',
                'geometry': {
                  'rings': [
                    [
                      [
                        8.5,
                        0
                      ],
                      [
                        7.02,
                        0.13
                      ],
                      [
                        5.59,
                        0.51
                      ],
                      [
                        4.25,
                        1.14
                      ],
                      [
                        3.04,
                        1.99
                      ],
                      [
                        1.99,
                        3.04
                      ],
                      [
                        1.14,
                        4.25
                      ],
                      [
                        0.51,
                        5.59
                      ],
                      [
                        0.13,
                        7.02
                      ],
                      [
                        0,
                        8.5
                      ],
                      [
                        0.13,
                        9.98
                      ],
                      [
                        0.51,
                        11.41
                      ],
                      [
                        1.14,
                        12.75
                      ],
                      [
                        1.99,
                        13.96
                      ],
                      [
                        3.04,
                        15.01
                      ],
                      [
                        4.25,
                        15.86
                      ],
                      [
                        5.59,
                        16.49
                      ],
                      [
                        7.02,
                        16.87
                      ],
                      [
                        8.5,
                        17
                      ],
                      [
                        9.98,
                        16.87
                      ],
                      [
                        11.41,
                        16.49
                      ],
                      [
                        12.75,
                        15.86
                      ],
                      [
                        13.96,
                        15.01
                      ],
                      [
                        15.01,
                        13.96
                      ],
                      [
                        15.86,
                        12.75
                      ],
                      [
                        16.49,
                        11.41
                      ],
                      [
                        16.87,
                        9.98
                      ],
                      [
                        17,
                        8.5
                      ],
                      [
                        16.87,
                        7.02
                      ],
                      [
                        16.49,
                        5.59
                      ],
                      [
                        15.86,
                        4.25
                      ],
                      [
                        15.01,
                        3.04
                      ],
                      [
                        13.96,
                        1.99
                      ],
                      [
                        12.75,
                        1.14
                      ],
                      [
                        11.41,
                        0.51
                      ],
                      [
                        9.98,
                        0.13
                      ],
                      [
                        8.5,
                        0
                      ]
                    ]
                  ]
                },
                'symbol': {
                  'type': 'CIMPolygonSymbol',
                  'symbolLayers': [
                    {
                      'type': 'CIMSolidStroke',
                      'enable': true,
                      'capStyle': 'Round',
                      'joinStyle': 'Round',
                      'lineStyle3D': 'Strip',
                      'miterLimit': 10,
                      'width': 0.5,
                      'color': [
                        0,
                        0,
                        0,
                        255
                      ]
                    },
                    {
                      'type': 'CIMSolidFill',
                      'primitiveName': 'Condition',
                      'enable': true,
                      'color': [
                        176,
                        69,
                        69,
                        255
                      ]
                    }
                  ]
                }
              }
            ],
            'scaleSymbolsProportionally': true,
            'respectFrame': true
          },
          {
            'type': 'CIMVectorMarker',
            'primitiveName': 'Forecast',
            'enable': true,
            'anchorPoint': {
              'x': 0,
              'y': 0
            },
            'anchorPointUnits': 'Relative',
            'size': 16,
            'frame': {
              'xmin': 0,
              'ymin': 0,
              'xmax': 17,
              'ymax': 17
            },
            'markerGraphics': [
              {
                'type': 'CIMMarkerGraphic',
                'geometry': {
                  'rings': [
                    [
                      [
                        8.5,
                        0
                      ],
                      [
                        7.02,
                        0.13
                      ],
                      [
                        5.59,
                        0.51
                      ],
                      [
                        4.25,
                        1.14
                      ],
                      [
                        3.04,
                        1.99
                      ],
                      [
                        1.99,
                        3.04
                      ],
                      [
                        1.14,
                        4.25
                      ],
                      [
                        0.51,
                        5.59
                      ],
                      [
                        0.13,
                        7.02
                      ],
                      [
                        0,
                        8.5
                      ],
                      [
                        0.13,
                        9.98
                      ],
                      [
                        0.51,
                        11.41
                      ],
                      [
                        1.14,
                        12.75
                      ],
                      [
                        1.99,
                        13.96
                      ],
                      [
                        3.04,
                        15.01
                      ],
                      [
                        4.25,
                        15.86
                      ],
                      [
                        5.59,
                        16.49
                      ],
                      [
                        7.02,
                        16.87
                      ],
                      [
                        8.5,
                        17
                      ],
                      [
                        9.98,
                        16.87
                      ],
                      [
                        11.41,
                        16.49
                      ],
                      [
                        12.75,
                        15.86
                      ],
                      [
                        13.96,
                        15.01
                      ],
                      [
                        15.01,
                        13.96
                      ],
                      [
                        15.86,
                        12.75
                      ],
                      [
                        16.49,
                        11.41
                      ],
                      [
                        16.87,
                        9.98
                      ],
                      [
                        17,
                        8.5
                      ],
                      [
                        16.87,
                        7.02
                      ],
                      [
                        16.49,
                        5.59
                      ],
                      [
                        15.86,
                        4.25
                      ],
                      [
                        15.01,
                        3.04
                      ],
                      [
                        13.96,
                        1.99
                      ],
                      [
                        12.75,
                        1.14
                      ],
                      [
                        11.41,
                        0.51
                      ],
                      [
                        9.98,
                        0.13
                      ],
                      [
                        8.5,
                        0
                      ]
                    ]
                  ]
                },
                'symbol': {
                  'type': 'CIMPolygonSymbol',
                  'symbolLayers': [
                    {
                      'type': 'CIMSolidStroke',
                      'enable': true,
                      'capStyle': 'Round',
                      'joinStyle': 'Round',
                      'lineStyle3D': 'Strip',
                      'miterLimit': 10,
                      'width': 0.5,
                      'color': [
                        0,
                        0,
                        0,
                        255
                      ],
                      'effects': []
                    },
                    {
                      'type': 'CIMSolidFill',
                      'primitiveName': 'ForecastRing',
                      'enable': true,
                      'color': [
                        69,
                        146,
                        84,
                        255
                      ],
                      'effects': []
                    }
                  ]
                }
              }
            ],
            'scaleSymbolsProportionally': true,
            'respectFrame': true
          },
        ]
      },
      primitiveOverrides: [{
        type: 'CIMPrimitiveOverride',
        primitiveName: 'TrendArrow',
        propertyName: 'Rotation',
        valueExpressionInfo: {
          type: 'CIMExpressionInfo',
          expression: `
            var trend = $feature.trend;
            if (trend == 'Rising') {
              return 90;
            } else if (trend == 'Falling') {
              return 270;
            }
          `,
          returnType: 'Default'
        }
      }, {
        type: 'CIMPrimitiveOverride',
        primitiveName: 'TrendArrowFill',
        propertyName: 'Color',
        valueExpressionInfo: {
          type: 'CIMExpressionInfo',
          expression: `
            var trend = $feature.trend;
            var inService = $feature.InService;
            if (trend == 'Constant' || inService == 0) {
              return [0, 0, 0, 0];
            } 

            return [0, 0, 0, 255];
          `,
          returnType: 'Default'
        }
      }, {
        type: 'CIMPrimitiveOverride',
        primitiveName: 'Forecast',
        propertyName: 'Size',
        valueExpressionInfo: {
          type: 'CIMExpressionInfo',
          expression: `
            if($feature.forecastPt == 0) {
              return 1;
            }

            var forcast = $feature.forecastCondition;
            var inService = $feature.InService;
            
            if(inService == 0) {
              return 1;
            }

            return IIF(forcast == -1, 1, 18);
          `,
          returnType: 'Default'
        }
      }, {
        type: 'CIMPrimitiveOverride',
        primitiveName: 'Condition',
        propertyName: 'Color',
        valueExpressionInfo: {
          type: 'CIMExpressionInfo',
          expression: `
            var condition = $feature.Condition;
            var inService = $feature.InService;
            if(inService == 0) {
              return '${GaugeMarkerColors.GRAY}';
            } else if(condition == 0) {
              return '${GaugeMarkerColors.GREEN}';
            } else if(condition == 1) {
              return '${GaugeMarkerColors.YELLOW}';
            } else if(condition == 2) {
              return '${GaugeMarkerColors.ORANGE}';
            } else if(condition == 3) {
              return '${GaugeMarkerColors.RED}';
            } else if(condition == 4) {
              return '${GaugeMarkerColors.PURPLE}';
            } else {
              return '${GaugeMarkerColors.BLUE}';
            }
          `,
          returnType: 'Default'
        }
      }, {
        type: 'CIMPrimitiveOverride',
        primitiveName: 'ForecastRing',
        propertyName: 'Color',
        valueExpressionInfo: {
          type: 'CIMExpressionInfo',
          expression: `
            var condition = $feature.forecastCondition;
            if(condition == 0) {
              return '${GaugeMarkerColors.GREEN}';
            } else if(condition == 1) {
              return '${GaugeMarkerColors.YELLOW}';
            } else if(condition == 2) {
              return '${GaugeMarkerColors.ORANGE}';
            } else if(condition == 3) {
              return '${GaugeMarkerColors.RED}';
            } else if(condition == 4) {
              return '${GaugeMarkerColors.PURPLE}';
            } else {
              return '${GaugeMarkerColors.BLUE}';
            }
          `,
          returnType: 'Default'
        }
      }, {
        type: 'CIMPrimitiveOverride',
        primitiveName: 'Raindrop',
        propertyName: 'Size',
        valueExpressionInfo: {
          type: 'CIMExpressionInfo',
          expression: `
            var rainCondition = $feature.rainCondition;
            // size 0 does not work on android but 0.1 does?
            return IIF(rainCondition == 1, 10, 0.1);
          `,
          returnType: 'Default'
        }
      },
      {
        type: 'CIMPrimitiveOverride',
        primitiveName: 'OutOfService',
        propertyName: 'Size',
        valueExpressionInfo: {
          type: 'CIMExpressionInfo',
          expression: `
            var inService = $feature.InService;
            // size 0 does not work on android but 0.1 does?
            return IIF(inService == 0, 6, 0.1);
          `,
          returnType: 'Default'
        }
      }]
    },
  },
};

export const gaugeRendererCimNwm = {
  ...gaugeRendererCim,
  symbol: {
    ...gaugeRendererCim.symbol,
    data: {
      ...gaugeRendererCim.symbol.data,
      symbol: {
        ...gaugeRendererCim.symbol.data.symbol,
        symbolLayers: [
          ...gaugeRendererCim.symbol.data.symbol.symbolLayers,
          {
            'type': 'CIMVectorMarker',
            'primitiveName': 'NWMForecast',
            'enable': true,
            'anchorPointUnits': 'Relative',
            'dominantSizeAxis3D': 'Y',
            'size': 26,
            'billboardMode3D': 'FaceNearPlane',
            'frame': {
              'xmin': 0,
              'ymin': 0,
              'xmax': 17,
              'ymax': 17
            },
            'markerGraphics': [
              {
                'type': 'CIMMarkerGraphic',
                'geometry': {
                  'rings': [
                    [
                      [
                        17,
                        8.5
                      ],
                      [
                        8.5,
                        0
                      ],
                      [
                        0,
                        8.5
                      ],
                      [
                        8.5,
                        17
                      ],
                      [
                        17,
                        8.5
                      ]
                    ]
                  ]
                },
                'symbol': {
                  'type': 'CIMPolygonSymbol',

                  'symbolLayers': [
                    {
                      'type': 'CIMSolidStroke',
                      'enable': true,
                      'capStyle': 'Round',
                      'joinStyle': 'Round',
                      'lineStyle3D': 'Strip',
                      'miterLimit': 10,
                      'width': 0.5,
                      'color': [
                        0,
                        0,
                        0,
                        255
                      ]
                    },
                    {
                      'type': 'CIMSolidFill',
                      'primitiveName': 'NWMForecastRing',
                      'enable': true,
                      'color': [
                        51,
                        51,
                        51,
                        255
                      ]
                    }
                  ]
                }
              }
            ],
            'scaleSymbolsProportionally': true,
            'respectFrame': true
          }
        ]
      },
      primitiveOverrides: [
        ...gaugeRendererCim.symbol.data.primitiveOverrides,
        {
          type: 'CIMPrimitiveOverride',
          primitiveName: 'NWMForecast',
          propertyName: 'Size',
          valueExpressionInfo: {
            type: 'CIMExpressionInfo',
            expression: `
            var nwmForecast = $feature.nwmForecastCondition;
            var rfcForecast = $feature.forecastPt;
            var inService = $feature.InService;

            if($feature.nwmForecast == null || $feature.nwmForecast == 0) {
              return 1;
            }

            if(inService == 0) {
              return 1;
            }

            if(rfcForecast == 1) {
              return 1;
            }

            return IIF(nwmForecast == -1, 1, 21);
          `,
            returnType: 'Default'
          }
        },
        {
          type: 'CIMPrimitiveOverride',
          primitiveName: 'NWMForecastRing',
          propertyName: 'Color',
          valueExpressionInfo: {
            type: 'CIMExpressionInfo',
            expression: `
            var condition = $feature.nwmForecastCondition;
          
            if(condition == 0) {
              return '${GaugeMarkerColors.GREEN}';
            } else if(condition == 1) {
              return '${GaugeMarkerColors.YELLOW}';
            } else if(condition == 2) {
              return '${GaugeMarkerColors.ORANGE}';
            } else if(condition == 3) {
              return '${GaugeMarkerColors.RED}';
            } else if(condition == 4) {
              return '${GaugeMarkerColors.PURPLE}';
            } else {
              return '${GaugeMarkerColors.BLUE}';
            }
          `,
            returnType: 'Default'
          }
        }]
    }
  }
};