import React from 'react';
import Container from 'react-bootstrap/Container';
import Notifications from '../Notifications';
import WeatherToggle from '../weather/WeatherToggle';
import ShowingCount from '../ShowingCount';
import Reports from '../reports/Reports';
import Filters from '../filters/MapGaugeFilters';
import ToolsToggle from '../ToolsToggle';
import useMapStore from '../../../stores/mapStore';
import styles from '../../../assets/styles/ActionBar.module.scss';
import Search from '../search/Search';
import PermissionFilter from '../auth/PermissionsFilter';
import { CognitoGroups } from 'src/constants/cognitoGroups';

const ActionBarMd = () => {
  const toggleToolsSidebar = useMapStore(state => state.toggleToolsSidebar);

  return (
    <div id="actionbarMd" className={styles.actionBarMd}>
      <Container fluid>
        <div className="d-flex flex-column pt-3 gap-3">
          <Search size="lg" />
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div className="d-flex gap-2">
              <ToolsToggle action={toggleToolsSidebar} className="h-100" />
              <WeatherToggle />
            </div>
            <ShowingCount />
            <div className="d-flex">
              <div className="d-flex gap-2 h-100">
                <PermissionFilter any={[CognitoGroups.ADMIN, CognitoGroups.ADVANCED]}>
                  <Reports className="h-100" />
                </PermissionFilter>
                <Filters className="h-100" />
                <Notifications className="h-100" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ActionBarMd;