
export class AlertEvents {
  static RISE_ABOVE = 'Rises Above';
  static FALL_BELOW = 'Falls Below' ;
  static FORECAST_RISE_ABOVE = 'Forecast to Rise';
  static FORECAST_FALL_BELOW = 'Forecast to Fall' ;

  static getEventById(id) {
    switch (id) {
      case 1:
        return AlertEvents.RISE_ABOVE;
      case 2:
        return AlertEvents.FALL_BELOW;
      case 3:
        return AlertEvents.FORECAST_RISE_ABOVE;
      case 4:
        return AlertEvents.FORECAST_FALL_BELOW;
      default:
        return null;
    }
  }

  static getEventIdByName(name) {
    switch (name) {
      case AlertEvents.RISE_ABOVE:
        return 1;
      case AlertEvents.FALL_BELOW:
        return 2;
      case AlertEvents.FORECAST_RISE_ABOVE:
        return 3;
      case AlertEvents.FORECAST_FALL_BELOW:
        return 4;
      default:
        return null;
    }
  }

  static getEvents() {
    return [
      AlertEvents.RISE_ABOVE,
      AlertEvents.FALL_BELOW,
      AlertEvents.FORECAST_RISE_ABOVE,
      AlertEvents.FORECAST_FALL_BELOW
    ];
  }
}