import React from 'react';
import { Outlet } from 'react-router-dom';
import UserProfileModal from 'src/features/admin/users/UserProfileModal';
import NavBar from './NavBar';
import EditNameModal from 'src/features/map/auth/EditNameModal';
import DuplicateUserModal from 'src/features/map/auth/DuplicateUserModal';

const AppLayout = () => {
  return (
    <>
      <NavBar />
      <main>
        <Outlet />
      </main>
      <UserProfileModal />
      <EditNameModal />
      <DuplicateUserModal />
    </>
  );
}

export default AppLayout;