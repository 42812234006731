import React, { useState } from 'react';
import { Search as SearchIcon } from 'react-bootstrap-icons';
import styles from '../../../assets/styles/Search.module.scss';
import SearchModal from './SearchModal';

const SearchSmBtn = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  return (
    <>
      <button
        onClick={handleShow}
        className="d-flex align-items-center rounded-pill ps-2 pe-3 bg-white w-100 position-relative border-0"
        style={{ height: '40px' }}
      >
        <SearchIcon
          className={styles.searchIcon}
          size={18}
        />
        <span className="text-muted text-truncate ps-2">
          Search by Location or Gauge
        </span>
      </button>

      <SearchModal show={show} onHide={() => setShow(false)} />
    </>
  );
};

export default SearchSmBtn;