import React from 'react';
import TrendWidget from './widgets/TrendWidget';
import ForecastPeakWidget from './widgets/ForecastPeakWidget';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useHasBridgesImpacted from 'src/lib/hooks/useHasBridgesImpacted';
import useHasRoadsAffected from 'src/lib/hooks/useHasRoadsAffected';
import useMapStore from 'src/stores/mapStore';
import LoadingDisplay from 'src/components/LoadingDisplay';
import ForecastSlider from './forecast/ForecastSlider';
import BuildingsImpactedWidget from './widgets/BuildingsImpactedWidget';
import ImpactWidget from './widgets/ImpactWidget';
import BuildingsImpactedGridget from './gridgets/BuildingsImpactedGridget';
import { GridgetIds } from 'src/constants/gridgetIds';
import RoadsAffectedWidget from './widgets/RoadsAffectedWidget';
import RoadsImpactedGridget from './gridgets/RoadsImpactedGridget';
import BridgesImpactedWidget from './widgets/BridgesImpactedWidget';
import BridgesImpactedGridget from './gridgets/BridgesImpactedGridget';
import PermissionFilter from 'src/features/map/auth/PermissionsFilter';
import { CognitoGroups } from 'src/constants/cognitoGroups';
import useUserGroup from 'src/lib/hooks/useUserGroup';
import NwmForecastSlider from './forecast/NwmForecastSlider';
import { hasForecastData, isNwmModel } from 'src/lib/forecastUtils';


const ForecastTabContent = () => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const { data: gauge, isLoading } = useGaugeDetails(activeGauge?.attributes?.siteId);
  const inundationLevel = useMapStore(state => state.inundationLevel);
  const activeForecastTabGridget = useMapStore(state => state.activeForecastTabGridget);
  const toggleActiveForecastTabGridget = useMapStore(state => state.toggleActiveForecastTabGridget);
  const { isAdmin, isAdvanced } = useUserGroup();
  const { hasBridgesImpacted, isLoading: isLoadingBridgeImpactedCount } = useHasBridgesImpacted(activeGauge?.attributes?.siteId);
  const { hasRoadsAffected, isLoading: isLoadingRoadsAffectedCount } = useHasRoadsAffected(activeGauge?.attributes?.siteId);

  if (isLoading) {
    return <LoadingDisplay />
  }

  const { inService } = gauge;

  return (
    <div className="d-flex flex-column w-100">
      { isNwmModel(gauge, isAdmin, isAdvanced) ? <NwmForecastSlider /> : <ForecastSlider /> }
      {activeForecastTabGridget === GridgetIds.BUILDINGS_IMPACTED &&
        <div className="p-2 w-100">
          <BuildingsImpactedGridget
            level={inundationLevel ?? activeGauge?.attributes.currentElevationMsl}
            toggleGridget={() => toggleActiveForecastTabGridget(GridgetIds.BUILDINGS_IMPACTED)}
          />
        </div>
      }

      {activeForecastTabGridget === GridgetIds.ROADS_AFFECTED &&
        <div className="p-2 w-100">
          <RoadsImpactedGridget toggleGridget={() => toggleActiveForecastTabGridget(GridgetIds.ROADS_AFFECTED)} />
        </div>
      }

      {activeForecastTabGridget === GridgetIds.BRIDGES_IMPACTED &&
        <div className="p-2 w-100">
          <BridgesImpactedGridget toggleGridget={() => toggleActiveForecastTabGridget(GridgetIds.BRIDGES_IMPACTED)} />
        </div>
      }

      <div className={`d-flex flex-wrap gap-2 p-2`}>
        {inService && <TrendWidget />}
        {hasForecastData(gauge, isAdmin, isAdvanced) && inService && 
          <ForecastPeakWidget />
        }
        <BuildingsImpactedWidget
          level={inundationLevel ?? activeGauge?.attributes.currentElevationMsl}
          onShowGridgetClicked={() => toggleActiveForecastTabGridget(GridgetIds.BUILDINGS_IMPACTED)}
        />
        <PermissionFilter any={[CognitoGroups.ADMIN, CognitoGroups.ADVANCED]}>
          {!isLoadingRoadsAffectedCount && hasRoadsAffected && <RoadsAffectedWidget onActionClicked={() => toggleActiveForecastTabGridget(GridgetIds.ROADS_AFFECTED)} />}
          {!isLoadingBridgeImpactedCount && hasBridgesImpacted && <BridgesImpactedWidget onActionClicked={() => toggleActiveForecastTabGridget(GridgetIds.BRIDGES_IMPACTED)} />}
        </PermissionFilter>
        <ImpactWidget level={inundationLevel ?? activeGauge?.attributes.currentElevationMsl} />
      </div>
    </div>
  );
};

export default ForecastTabContent;