import { useMemo } from 'react';
import { useQueries } from 'react-query';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import useConfigData from './useConfigData';

// class route types
const routeClasses = [1, 2, 3, [4, 5]];

const useRoadsAffectedSummary = (gaugeId, level) => {
  const { data: config } = useConfigData('Map');

  const getCounts = (gaugeId, level, routeClass) => {
    const adaptedLevel = level.toString().indexOf('.') != -1 ? level : `${level}.0`;

    const routeQuery = Array.isArray(routeClass) ? 
      `RouteClass IN (${routeClass[0]}, ${routeClass[1]})` :
      `RouteClass='${routeClass}'`;

    const query = `GAGE_ID='${gaugeId}' AND SCEN_ELEV='${adaptedLevel}' AND ${routeQuery}`;

    return queryFeatures({
      url: config?.references.roadsInundation,
      where: query,
      outFields: ['StreetName'],
      groupByFieldsForStatistics: ['StreetName'],
      returnCountOnly: true,
      returnDistinctValues: true,
      returnGeometry: false,
    });
  }

  const queries = routeClasses.map((routeClass) => {
    return {
      queryKey: ['road-damage-summary', gaugeId, level, routeClass],
      queryFn: () => getCounts(gaugeId, level, routeClass),
      enabled: gaugeId !== undefined && level !== undefined && level !== null
    }}
  );

  // Need to use useQueries instead of useQuery because we need to make multiple queries
  // The needed data was produced dynamically in a stored procedure in the old app so the 
  // data is not available in a single query
  const results = useQueries(queries, {
    enabled: gaugeId !== undefined && level !== undefined && level !== null
  });

  const isLoading = results.some(result => result.isLoading);

  const data = useMemo(() => {
    const totals = {
      intRoutes: 0,
      usRoutes: 0,
      ncRoutes: 0,
      localRoutes: 0,
      total: 0,
    };

    if (results && !isLoading) {
      const counts = results.map(result => result?.data?.count);

      totals.intRoutes = counts[0];
      totals.usRoutes = counts[1];
      totals.ncRoutes = counts[2];
      totals.localRoutes = counts[3];
      totals.total = counts.reduce((acc, count) => acc + count, 0);
    }

    return totals;

  }, [results, isLoading]);

  return { data, isLoading };
};

export default useRoadsAffectedSummary;