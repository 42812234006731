import React from 'react';
import { Modal } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import ActionButton from './ActionButton';
import FindMe from '../../../assets/images/find-me.png';
import Explore from '../../../assets/images/explore.png';
import SignIn from '../../../assets/images/sign-in.png';
import SignUp from '../../../assets/images/sign-up.png';

const WelcomeModalBody = ({handleFindMe, handleExplore, handleSignIn, handleSignUp}) => {
  return (
    <Modal.Body className="p-md-5 pt-md-4">
      <p>
          The Flood Inundation Mapping and Alert Network, or FIMAN, is North Carolina’s
          resource for flood awareness and forecasting. FIMAN displays current and forecasted
          water levels from hundreds of locations throughout our state’s streams and coasts.
          At some sites, users can see which building structures, bridges*, and roadways* may be impacted by rising waters and make
          decisions about flood preparedness and response. Users can also sign up to receive
          email or text alerts when waters are rising nearby. FIMAN also provides information on roadway and bridge inundations for current and forecasted conditions at select locations. However, for real-time traffic and travel updates, including road and bridge closures, please visit <a href="https://drivenc.gov/">DriveNC</a>.
      </p>
      <p>
          Start getting the information you need by zooming to your location, exploring the map,
          or signing up for flood alerts!
      </p>
      <p>
      *For official real-time traffic and travel updates, including road and bridge closures, please visit <a href="https://drivenc.gov/">DriveNC</a>
      </p>
      <div className="d-flex justify-content-between">
        <ActionButton icon={FindMe} text="Find Me" description="Zoom to your location." onClick={handleFindMe} />
        <ActionButton icon={Explore} text="Explore" description="Explore the map on your own." onClick={handleExplore} />
        <ActionButton icon={SignUp} text="Sign Up" description="Get flood alerts and updates." onClick={handleSignUp} />
        <ActionButton icon={SignIn} text="Sign In" description="View alerts and updates." onClick={handleSignIn} />
      </div>
    </Modal.Body>
  )
}; 
WelcomeModalBody.propTypes = {
  handleFindMe: PropTypes.func.isRequired,
  handleExplore: PropTypes.func.isRequired,
  handleSignIn: PropTypes.func.isRequired,
  handleSignUp: PropTypes.func.isRequired,
};
export default WelcomeModalBody;