import React from 'react';
import 'photoswipe/dist/photoswipe.css'
import Photos from 'src/components/Photos';

const PhotosTabContentSm = () => {
  return (
    <Photos className= "d-flex flex-column" height="244px" />
  );
}

export default PhotosTabContentSm;