import React, { useEffect, useRef } from 'react';
import useMapStore from '../../../../stores/mapStore';
import { Widgets } from '../../../../constants/widgets';
import SingleAccordion from '../../../../components/SingleAccordion';


const WeatherLegend = () => {
  const mapManager = useMapStore((state) => state.mapManager);
  const ref = useRef(null);

  const eventKey = 'layer-legend';

  useEffect(() => {
    if(!ref.current || !mapManager) return;
    mapManager.createLegend(ref.current);

    return () => {
      mapManager.removeWidget(Widgets.LEGEND);
    }
  }, [mapManager, ref]);

  return (
    <SingleAccordion header="NOAA Weather Overlays" eventKey={eventKey}>
      <div ref={ref} className="layer-legend"></div>
    </SingleAccordion>
  );
}

export default WeatherLegend;