import React from 'react';
import { Routes, Route } from 'react-router-dom';
import useCustomMediaQuery from './lib/hooks/useCustomMediaQuery';
import AdminPage from './pages/AdminPage';
import MapPage from './pages/MapPage';
import AppLayout from './layouts/AppLayout';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import AboutPage from './pages/AboutPage';
import HelpPage from './pages/HelpPage';
import AppLayoutDefault from './layouts/AppLayoutDefault';
import PermissionFilter from './features/map/auth/PermissionsFilter';
import { CognitoGroups } from './constants/cognitoGroups';
import useUserStore from './stores/userStore';
import AuthorizationError from './components/AuthorizationError';
import DesktopOnlyDisplay from './components/DesktopOnlyDisplay';
import UnsubscribePage from './pages/UnsubscribePage';

const AppRoutes = () => {
  const user = useUserStore(state => state.cognitoUser);
  const { isDesktop } = useCustomMediaQuery();
  return (
    <Routes>
      <Route path="/unsubscribe" element={<UnsubscribePage />} />
      <Route path="/" element={<AppLayout />}>
        <Route index element={<MapPage />} />
      </Route>
      <Route element={<AppLayoutDefault />}>
        {/* this is a temporary solution to lock down state issues for route and route protection. 
            should only allow admin route to be accessed if user is logged in and is an admin and is on desktop
            created new default page -- DesktopOnlyDisplay -- to display when user is logged in and on mobile or tablet
        */}
        {user && isDesktop && (
          <Route path="/admin" element={
            <PermissionFilter group={CognitoGroups.ADMIN} isRoute={true} >
              <AdminPage />
            </PermissionFilter>
          } />
        )}
        {!user && isDesktop && (<Route path="/admin" element={<AuthorizationError />} />)}
        {user && !isDesktop && (<Route path="/admin" element={<DesktopOnlyDisplay />} />)}
        <Route path="/help" element={<HelpPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>

    </Routes >
  );
}

export default AppRoutes;


