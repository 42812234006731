import React from 'react';
import Widget from './Widget';
import GeneralContent from './GeneralContent';


const GeneralWidget = () => {
  return (
    <Widget title="General Info">
      <GeneralContent />
    </Widget>
  );
}

export default GeneralWidget;