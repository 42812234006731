import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PillButton from 'src/components/PillButton';
import useMapStore from 'src/stores/mapStore';
import { Tabs } from 'src/constants/tabs';
import useLibraryLevels from 'src/lib/hooks/useLibraryLevels';
import agent from 'src/lib/agent';
import GoogleEarth from 'src/assets/images/google_earth.png';
import { Modal, Button, Spinner } from 'react-bootstrap';
import PrimaryButton from 'src/components/PrimaryButton';

const KMZDownloads = ({ activeGauge, activeTab }) => {
  const [showKMZModal, setShowKMZModal] = useState(false);
  const [hasCurrentLevelKMZFile, setHasCurrentLevelKMZFile] = useState(false);
  const inundationLevel = useMapStore(state => state.inundationLevel);
  const scenarioLevel = useMapStore(state => state.scenarioLevel);
  const KMZFileLevel = activeTab === Tabs.SCENARIO ? scenarioLevel : inundationLevel;
  const { levels } = useLibraryLevels(activeGauge?.attributes?.siteId);
  const [isDownloading, setIsDownloading] = useState(false);
  const hasInundationLevels = levels.length > 0;
  const siteId = activeGauge.attributes.siteId

  // check if files exists when the Download KMZ button in drawer is clicked
  const handleDownloadKMZClick = async () => {
    try {
      const fileExists = await agent.kmz.checkExists(siteId, KMZFileLevel);
      if (fileExists) {
        setHasCurrentLevelKMZFile(true);
        setShowKMZModal(true);
      } else {
        setHasCurrentLevelKMZFile(false);
        setShowKMZModal(true);
      }
    } catch (error) {
      setHasCurrentLevelKMZFile(false);
      setShowKMZModal(true);
      console.error('Error checking KMZ file availability', error);
    }
  };

  // Function to download KMZ file
  const downloadKMZ = async () => {
    setIsDownloading(true) // start downloading
    try {
      const response = await agent.kmz.download(siteId, KMZFileLevel);
      const blob = new Blob([response.data], { type: 'application/octet-stream' });

      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `${siteId}_${KMZFileLevel}.kmz`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(downloadUrl);
      link.remove();
    } catch (error) {
      console.error('Download KMZ Error:', error);
    }
    finally {
      setIsDownloading(false); // End downloading
    }
  };

  // Determine which modal message to display based on the availability of library and KMZ files
  let modalBodyMessage;
  if (!hasInundationLevels) {
    modalBodyMessage = 'There are no KMZ files currently available for the selected gauge; however, libraries may be available at a later time.';
  } else if (!hasCurrentLevelKMZFile) {
    modalBodyMessage = 'There are no KMZ files available for the current inundation level; use Scenario to simulate a more severe flood level and try again.';
  } else {
    modalBodyMessage = 'Google Earth files include grouped layers of flood inundation and flooded roadways. Layer transparency can be set in Google Earth as shown below. Care and judgement should be taken in the use of this data for emergency response, evacuations and road closures.';
  }

  return (
    <>
      {
        hasInundationLevels && (
          <>
            <PillButton size="sm" variant="random-orange" className="d-flex align-items-center justify-content-center m-1 px-2" onClick={handleDownloadKMZClick}>Download KMZ</PillButton>
            <Modal show={showKMZModal} onHide={() => setShowKMZModal(false)} centered>
              <Modal.Header closeButton closeVariant="white">
                <Modal.Title>Google Earth Download</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{modalBodyMessage}</p>
                {hasInundationLevels && hasCurrentLevelKMZFile && (
                  <img src={GoogleEarth} alt="Google Earth Setting" className="w-100" />
                )}
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex w-100 justify-content-center gap-4">
                  <Button variant="red-cancel" className="rounded-pill px-4" onClick={() => setShowKMZModal(false)}>
                    Cancel
                  </Button>
                  <PrimaryButton className="rounded-pill px-4" onClick={() => downloadKMZ(KMZFileLevel)} disabled={!hasCurrentLevelKMZFile || !hasInundationLevels || isDownloading}>
                    {isDownloading ? <Spinner animation="border" size="sm" /> : 'Download KMZ'}
                  </PrimaryButton>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        )
      }
    </>
  )
}

KMZDownloads.propTypes = {
  activeGauge: PropTypes.shape({
    attributes: PropTypes.shape({
      siteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }).isRequired
  }).isRequired,
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired
};

export default KMZDownloads;