import React from 'react';
import PropTypes from 'prop-types';

/**
  * A legend for a range slider. Note that the slider is not included in this component.
  * This is a separate component so that the legend can be reused for different sliders.
  * Not intended to be used directly, but rather as a child of a slider component.
  * @param {boolean} props.showElevation - whether to show the elevation label
 */
const ForecastSliderLegend = ({ isCoastal = false }) => {
  return (
    <div className="d-flex flex-column align-items-center" style={{ width: 115, fontSize: 14 }}>
      <div className="text-center d-flex flex-column justify-content-end" style={{ height: 35 }}>
        {!isCoastal ?
          <div className="border-bottom pb-1">Stage (ft)</div> :
          <div className="text-center d-flex flex-column justify-content-start pt-1 border-bottom pb-1" style={{ height: 50 }}>
            Elevation
            <div style={{ fontSize: 11 }}>
              (NAVD 88)
            </div>
          </div>
        }
      </div>
      <div 
        className="text-center d-flex flex-column justify-content-start pt-1" 
        style={{ height: '35px', fontSize: 14, lineHeight: '17px' }}
      >
        Hours from now
      </div>
    </div>
  );
};

ForecastSliderLegend.propTypes = {
  isCoastal: PropTypes.bool,
};

export default ForecastSliderLegend;