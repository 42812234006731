import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PrimaryButton from 'src/components/PrimaryButton';
import useGaugeStore from 'src/stores/gaugeStore';
import { confirm } from 'src/components/ConfirmationModal';
import { useForm, Controller } from 'react-hook-form';
import agent from 'src/lib/agent';
import useCounties from 'src/lib/hooks/useCounties';

const GaugeEditModal = ({ gauge, onHide, updateRowData, gaugeIndex, ownerOptions, validateGaugeName }) => {
  const showEditGaugeModal = useGaugeStore(state => state.showEditGaugeModal);
  const toggleEditGaugeModal = useGaugeStore(state => state.toggleEditGaugeModal);
  const [isSaving, setIsSaving] = useState(false);
  const [isGaugeDirty, setIsGaugeDirty] = useState(false);
  const { counties } = useCounties();
  const countyOptions = counties ?? [];
  const [viewCustomOwner, setViewCustomOwner] = useState(false);

  const { handleSubmit, reset, control, setValue, watch, formState: { errors, isDirty } } = useForm({ mode: 'onChange' });

  const watchOwner = watch('owner');

  useEffect(() => {
    setIsGaugeDirty(isDirty);
  }, [isDirty, isGaugeDirty]);

  useEffect(() => {
    if (watchOwner === 'Custom' && !viewCustomOwner) {
      setViewCustomOwner(true);
    } else {
      setViewCustomOwner(false);
    }
    setValue('customOwner', '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchOwner])

  useEffect(() => {
    if (gauge) {
      setValue('name', gauge.name);
      setValue('siteId', gauge.siteId);
      setValue('isCoastal', gauge.isCoastal);
      setValue('county', gauge.county ?? '');
      setValue('owner', gauge.owner ?? '');
      setValue('customOwner', '');
      setValue('forecastPt', gauge.forecastPt);
      setValue('forecastId', gauge.forecastId ?? '');
      setValue('nwmForecast', gauge.nwmForecast);
      setValue('rainCondition', gauge.rainCondition === 0 ? 'false' : 'true');
      setValue('gageDatum', formatInitialNumbers(gauge.gageDatum));
      setValue('bankFull', formatInitialNumbers(gauge.bankFull));
      setValue('minor', formatInitialNumbers(gauge.minor));
      setValue('moderate', formatInitialNumbers(gauge.moderate));
      setValue('major', formatInitialNumbers(gauge.major));
      setValue('isScenario', gauge.isScenario);
      setValue('svcMinElev', formatInitialNumbers(gauge.svcMinElev));
      setValue('svcMaxElev', formatInitialNumbers(gauge.svcMaxElev));
    }
  }, [gauge, setValue])

  const handleCancelEdit = () => {
    if (isGaugeDirty) {
      confirm('Are you sure you want to continue? Any unsaved changes will be lost.',
        'Unsaved Changes',
        'Continue',
        'Cancel',
        cancelEdit);
      return;
    }
    cancelEdit();
  }

  const cancelEdit = () => {
    reset();
    toggleEditGaugeModal(false);
    setIsGaugeDirty(false);
    onHide();
  }

  const onSubmit = async (data) => {
    data.inService = gauge.inService;
    data.isCoastal = data.isCoastal === 'true';
    data.forecastPt = data.forecastPt === 'true';
    data.isScenario = data.isScenario === 'true';
    data.nwmForecast = data.nwmForecast === 'true';
    data.rainCondition = data.rainCondition === 'true' ? 1 : 0;
    data.bankFull = data.bankFull === '' ? null : data.bankFull;
    data.minor = data.minor === '' ? null : data.minor;
    data.moderate = data.moderate === '' ? null : data.moderate;
    data.major = data.major === '' ? null : data.major;
    data.svcMaxElev = data.svcMaxElev === '' ? null : data.svcMaxElev;
    data.svcMinElev = data.svcMinElev === '' ? null : data.svcMinElev;

    if (viewCustomOwner) {
      data.owner = data.customOwner;
    }

    setIsSaving(true);
    try {
      let response = await agent.gauge.update(data);
      updateRowData(gaugeIndex, response);
      toast.success(`Gauge ${data.name} was successfully saved.`);
      cancelEdit();
    }
    catch (error) {
      toast.error(`There was an error saving the gauge.`);
    }
    finally {
      setIsSaving(false);
    }
  }

  const formatInitialNumbers = (value) => {
    if (!value) return '';
    const parsedNumber = parseFloat(value);
    if (isNaN(parsedNumber)) return '';
    return parsedNumber;
  }

  const validateCustomOwner = (value, formValues) => {
    if (formValues.owner === 'Custom' && !value) {
      return 'Custom owner is required.';
    }
    return true;
  }

  return (
    <Modal
      dialogClassName="modal-size-md"
      centered
      show={showEditGaugeModal}
      onHide={() => handleCancelEdit()}
      animation={true}
      backdrop="static"
      scrollable={true}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          Edit Gauge Data
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="p-3 mt-2">
          <Form onSubmit={handleSubmit(onSubmit)} id="gaugeForm">
            <Form.Group className="mb-3 required" controlId="forName">
              <Form.Label>Gauge Name</Form.Label>
              <Controller
                control={control}
                name="name"
                defaultValue={gauge?.name ?? ''}
                rules={{ required: true, maxLength: 50, validate: (value, formValues) => validateGaugeName(value, formValues) }}
                render={({ field }) => <>
                  <Form.Control
                    disabled={isSaving}
                    isInvalid={errors.name}
                    type="text"
                    placeholder="Enter Name"
                    {...field}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.type === 'maxLength' && 'Gauge name cannot exceed 50 characters.'}
                    {errors.name?.type === 'required' && 'Gauge name is required.'}
                    {errors.name?.type === 'validate' && 'Gauge name must be distinct.'}
                  </Form.Control.Feedback>
                </>
                }
              />
            </Form.Group>
            <Row className="mb-3 gap-3 gap-lg-0">
              <Form.Group as={Col} lg={6} controlId="forSiteId">
                <Form.Label>Site ID</Form.Label>
                <Controller
                  control={control}
                  name="siteId"
                  defaultValue={gauge?.siteId ?? ''}
                  render={({ field }) => <>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder="Enter Name"
                      {...field}
                    />
                  </>}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} className="required" controlId="forIsCoastal">
                <Form.Label>Is Coastal</Form.Label>
                <Controller
                  control={control}
                  name="isCoastal"
                  render={({ field }) => <>
                    <Form.Select
                      {...field}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Form.Select>
                  </>}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 gap-3 gap-lg-0">
              <Form.Group as={Col} lg={6} className="required" controlId="forCounty">
                <Form.Label>County</Form.Label>
                <Controller
                  control={control}
                  name="county"
                  defaultValue={gauge?.county ?? ''}
                  render={({ field }) => <>
                    <Form.Select
                      {...field}
                    >
                      {countyOptions.map((county, index) => <option key={index} value={county}>{county}</option>)}
                    </Form.Select>
                  </>}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} className="required" controlId="forOwner">
                <Form.Label>Owner</Form.Label>
                <Controller
                  control={control}
                  name="owner"
                  defaultValue={gauge?.owner ?? ''}
                  render={({ field }) => <>
                    <Form.Select
                      {...field}
                    >
                      {ownerOptions.map((owner, index) => <option key={index} value={owner}>{owner}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.owner?.type === 'required' && 'Owner is required.'}
                    </Form.Control.Feedback>
                  </>}
                />
              </Form.Group>
            </Row>
            {viewCustomOwner && (
              <Row className="mb-3 gap-3 gap-lg-0">
                <Col lg={6}></Col>{/* spacer */}
                <Form.Group as={Col} lg={6} className="required" controlId="forCustomOwner">
                  <Form.Label>Custom Owner</Form.Label>
                  <Controller
                    control={control}
                    name="customOwner"
                    defaultValue={gauge?.customOwner ?? ''}
                    rules={{ maxLength: 250, validate: (value, formValues) => validateCustomOwner(value, formValues) }}
                    render={({ field }) => <>
                      <Form.Control
                        disabled={isSaving}
                        type="text"
                        isInvalid={errors.customOwner}
                        placeholder="Enter Custom Owner"
                        {...field}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.customOwner?.type === 'validate' && 'Custom owner is required.'}
                        {errors.customOwner?.type === 'maxLength' && 'Custom owner can not exceed 250 characters.'}
                      </Form.Control.Feedback>
                    </>}
                  />
                </Form.Group>
              </Row>
            )}
            <Row className="mb-3 gap-3 gap-lg-0">
              <Form.Group as={Col} lg={6} className="required" controlId="forForecastPt">
                <Form.Label>RFC Forecast</Form.Label>
                <Controller
                  control={control}
                  name="forecastPt"
                  render={({ field }) => <>
                    <Form.Select
                      defaultChecked={gauge?.forecastPt ?? false}
                      {...field}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Form.Select>
                  </>}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} controlId="forecastId">
                <Form.Label>Forecast ID</Form.Label>
                <Controller
                  control={control}
                  name="forecastId"
                  defaultValue={gauge?.forecastId ?? ''}
                  rules={{ maxLength: 50 }}
                  render={({ field }) => <>
                    <Form.Control
                      disabled={isSaving}
                      type="text"
                      isInvalid={errors.forecastId}
                      placeholder="Enter Forecast ID"
                      {...field}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.forecastId?.type === 'maxLength' && 'Forecast ID cannot exceed 50 characters.'}
                    </Form.Control.Feedback>
                  </>}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 gap-3 gap-lg-0">
              <Form.Group as={Col} lg={6} className="required" controlId="forForecastPt">
                <Form.Label>NWM Forecast</Form.Label>
                <Controller
                  control={control}
                  name="nwmForecast"
                  render={({ field }) => <>
                    <Form.Select
                      defaultChecked={gauge?.nwmForecast ?? false}
                      {...field}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Form.Select>
                  </>}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} className="required" controlId="forRainCondition">
                <Form.Label>Rain</Form.Label>
                <Controller
                  control={control}
                  name="rainCondition"
                  render={({ field }) => <>
                    <Form.Select
                      defaultChecked={gauge?.rainCondition ?? false}
                      {...field}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Form.Select>
                  </>}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 gap-3 gap-lg-0">
              <Form.Group as={Col} lg={6} className="required" controlId="forGageDatum">
                <Form.Label>Gauge Datum</Form.Label>
                <Controller
                  control={control}
                  name="gageDatum"
                  defaultValue={gauge?.gageDatum ?? ''}
                  rules={{ required: true, }}
                  render={({ field }) => <>
                    <Form.Control
                      disabled={isSaving}
                      isInvalid={errors.gageDatum}
                      type="number"
                      precision={2}
                      placeholder="Enter Gauge Datum"
                      {...field}
                    // onBlur={(e) => handleNumberBlur(e.target.value, 'gageDatum')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.gageDatum?.type === 'required' && 'Gauge Datum is required.'}
                      {/* {errors.gageDatum?.type === 'maxLength' && 'Gauge Datum is required.'} */}
                    </Form.Control.Feedback>
                  </>}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} controlId="forBankFull">
                <Form.Label>Monitor (ft)</Form.Label>
                <Controller
                  control={control}
                  name="bankFull"
                  defaultValue={gauge?.bankFull ?? ''}
                  render={({ field }) => <>
                    <Form.Control
                      disabled={isSaving}
                      type="number"
                      precision={2}
                      placeholder="Enter Monitor (ft)"
                      {...field}
                    // onBlur={(e) => handleNumberBlur(e.target.value, 'bankFull')}
                    />
                  </>}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 gap-3 gap-lg-0">
              <Form.Group as={Col} lg={6} controlId="forMinor">
                <Form.Label>Minor (ft)</Form.Label>
                <Controller
                  control={control}
                  name="minor"
                  defaultValue={gauge?.minor ?? ''}
                  render={({ field }) => <>
                    <Form.Control
                      disabled={isSaving}
                      type="number"
                      precision={2}
                      placeholder="Enter Minor (ft)"
                      {...field}
                    // onBlur={(e) => handleNumberBlur(e.target.value, 'minor')}
                    />
                  </>}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} controlId="forMajor">
                <Form.Label>Major (ft)</Form.Label>
                <Controller
                  control={control}
                  name="major"
                  defaultValue={gauge?.major ?? ''}
                  render={({ field }) => <>
                    <Form.Control
                      disabled={isSaving}
                      type="number"
                      precision={2}
                      placeholder="Enter Monitor (ft)"
                      {...field}
                    // onBlur={(e) => handleNumberBlur(e.target.value, 'major')}
                    />
                  </>}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 gap-3 gap-lg-0">
              <Form.Group as={Col} lg={6} controlId="forModerate">
                <Form.Label>Moderate (ft)</Form.Label>
                <Controller
                  control={control}
                  name="moderate"
                  defaultValue={gauge?.moderate ?? ''}
                  render={({ field }) => <>
                    <Form.Control
                      disabled={isSaving}
                      type="number"
                      precision={2}
                      placeholder="Enter Moderate (ft)"
                      {...field}
                    // onBlur={(e) => handleNumberBlur(e.target.value, 'moderate')}
                    />
                  </>}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} className="required" controlId="forIsScenario">
                <Form.Label>Has Library</Form.Label>
                <Controller
                  control={control}
                  name="isScenario"
                  render={({ field }) => <>
                    <Form.Select
                      defaultChecked={gauge?.isScenario ?? false}
                      {...field}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Form.Select>
                  </>}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 gap-3 gap-lg-0">
              <Form.Group as={Col} lg={6} controlId="forSvcMinElev">
                <Form.Label>Libary Min Elev (ft)</Form.Label>
                <Controller
                  control={control}
                  name="svcMinElev"
                  defaultValue={gauge?.svcMinElev ?? ''}
                  render={({ field }) => <>
                    <Form.Control
                      disabled={isSaving}
                      type="number"
                      precision={2}
                      placeholder="Enter Libary Min Elev (ft)"
                      {...field}
                    // onBlur={(e) => handleNumberBlur(e.target.value, 'svcMinElev')}
                    />
                  </>}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} controlId="forSvcMaxElev">
                <Form.Label>Libary Max Elev (ft)</Form.Label>
                <Controller
                  control={control}
                  name="svcMaxElev"
                  defaultValue={gauge?.svcMaxElev ?? ''}
                  render={({ field }) => <>
                    <Form.Control
                      disabled={isSaving}
                      type="number"
                      precision={2}
                      placeholder="Enter Libary Max Elev (ft)"
                      {...field}
                    // onBlur={(e) => handleNumberBlur(e.target.value, 'svcMaxElev')}
                    />
                  </>}
                />
              </Form.Group>
            </Row>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex w-100 justify-content-center gap-4">
          <PrimaryButton
            variant="red-cancel"
            disabled={isSaving}
            className="rounded-pill flex-grow-1"
            onClick={() => handleCancelEdit()}
          >
            <span className="text-truncate">Cancel</span>
          </PrimaryButton>
          <PrimaryButton
            type="submit"
            form="gaugeForm"
            className="btn-green-accept rounded-pill flex-grow-1">
            {isSaving && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>}
            Save
          </PrimaryButton>
        </div>
      </Modal.Footer>
    </Modal >
  );
}

GaugeEditModal.propTypes = {
  gauge: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  updateRowData: PropTypes.func.isRequired,
  gaugeIndex: PropTypes.number.isRequired,
  ownerOptions: PropTypes.array.isRequired,
  validateGaugeName: PropTypes.func.isRequired,
};

export default GaugeEditModal;