import React, { useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PrimaryButton from 'src/components/PrimaryButton';
import agent from 'src/lib/agent';

const UnsubscribePage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const emailOptOut = async () => {
    setIsSubmitting(true);
    try {
      await agent.user.emailOptOut(token);
      toast.success('You have successfully opted out of all FIMAN emails');
    }
    catch (error) {
      toast.error('Failed to opt out of emails!');
    }
    finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-center p-2">
        <h2 className="p-3 text-blue">UNSUBSCRIBE</h2>
      </div>
      <div className="d-flex align-items-center justify-content-center p-2">
        <p>
          Click the button to confirm you would like to stop receiving email alerts from FIMAN. You can turn email alerts back on from 'My Profile' when logged into FIMAN. This does not remove the gauge from your list of Alert Subscriptions in FIMAN.
        </p>
      </div>
      <div className="d-flex align-items-center justify-content-center p-2">
        <p>If you would still like to receive email alerts, you can customize which conditions you would like to be notified about by opening Alert Settings from the Alert Subscriptions sidebar in FIMAN.</p>
      </div>
      <div className="d-flex align-items-center justify-content-center p-2">
        <PrimaryButton
          className="rounded-pill px-4"
          disabled={isSubmitting}
          onClick={() => emailOptOut()}
        >
          <span>Turn off all email notifications</span>
        </PrimaryButton>
      </div>
      <div className="d-flex align-items-center justify-content-center p-2">
        <Link to="/">Go to FIMAN home.</Link>
      </div>
    </>
  );
};

export default UnsubscribePage;