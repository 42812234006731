import { getAttachments, queryFeatures } from '@esri/arcgis-rest-feature-service';
import { useQuery } from 'react-query';
import useConfigData from 'src/lib/hooks/useConfigData';


const loadedImages = async (url, siteId) => {
  const features = await queryFeatures({
    url: url,
    where: `SITE_ID = '${siteId}'`,
    outFields: ['OBJECTID'],
    returnGeometry: false
  });

  if (features.features.length === 0) {
    return false;
  }

  const objectId = features.features[0].attributes.OBJECTID;

  const attachments = await getAttachments({
    url: url,
    featureId: objectId
  });

  return attachments.attachmentInfos.length === 0 ? false : true;
};

const useHasGaugeImages = (siteId) => {
  const { data: config } = useConfigData('Map');

  const { data, isLoading } = useQuery(['has-gauge-images', siteId], ({ queryKey }) => {
    const [, id] = queryKey;

    return loadedImages(config?.arcgisRestUrl, id);
  }, {
    enabled: config?.arcgisRestUrl !== undefined && siteId !== undefined,
  });

  return { data, isLoading };
};

export default useHasGaugeImages;