/**
 * Map irisk occupancy types to simplified insurance foundation types
 */
export const getBldgOccupancyType = (occType) => {
  var updatedOCC = occType;

  switch (occType) {
    case '1245':
    case '1580':
    case '2245':
    case '2580':
    case '3245':
    case '4245':
    case '5245':
    case '5580':
      updatedOCC = 'Single Family';
      break;
    case '1285':
    case '1620':
    case '2285':
    case '2620':
    case '3285':
    case '4285':
    case '5285':
    case '5620':
      updatedOCC = 'Temporary Lodging';
      break;
    case '1250':
    case '1585':
    case '2250':
    case '2585':
    case '3250':
    case '4250':
    case '5250':
    case '5585':
      updatedOCC = 'Mobile Home';
      break;
    case '1290':
    case '1625':
    case '2290':
    case '2625':
    case '3290':
    case '4290':
    case '5290':
    case '5625':
      updatedOCC = 'Institutional Dormitory';
      break;
    case '1295':
    case '1630':
    case '2295':
    case '2630':
    case '3295':
    case '4295':
    case '5295':
    case '5630':
      updatedOCC = 'Nursing Home';
      break;
    default:
      updatedOCC = 'Non-Residential';
      break;
  }

  return updatedOCC;
}