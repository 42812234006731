import { useQuery } from 'react-query';
import { queryFeatures } from '@esri/arcgis-rest-feature-service';
import useConfigData from 'src/lib/hooks/useConfigData';

const useHasBridgesImpacted = (gaugeId) => {
  const { data: config } = useConfigData('Map');

  const { data, isLoading } = useQuery(['has-bridges-impacted', gaugeId], ({ queryKey }) => {
    const [, gaugeId] = queryKey;
    
    return queryFeatures({
      url: config?.layers.bridges.featureUrl,
      where: `GAGE_ID='${gaugeId}'`,
      returnCountOnly: true,
      returnGeometry: false,
    });
  }, {
    enabled: gaugeId !== undefined && config !== undefined
  });

  let hasBridgesImpacted = data === undefined 
    ? false
    : data?.count > 0; 

  return { hasBridgesImpacted, isLoading };
};

export default useHasBridgesImpacted;