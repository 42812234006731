import React from 'react';
import PropTypes from 'prop-types';
import { Card, Nav } from 'react-bootstrap';

const AdminSidebar = ({ setKey, activeKey }) => {
  return (
    <Card className="admin-sidebar">
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <Card.Title className="m-0">
            Admin Console
          </Card.Title>
        </div>
      </Card.Header>
      <Card.Body>
        <Nav variant="pills" className="flex-column">
          <Nav.Item className="border-0">
            <Nav.Link active={activeKey === 'users'} onClick={() => setKey('users')}>
              Manage Users
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link active={activeKey === 'gauges'} onClick={() => setKey('gauges')} >
              Manage Gauges
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Card.Body>
    </Card>
  );

}

AdminSidebar.propTypes = {
  setKey: PropTypes.func.isRequired,
  activeKey: PropTypes.string.isRequired,
};

export default AdminSidebar;