import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SingleAccordion from 'src/components/SingleAccordion';

const HelpSm = ({ navItems }) => {
  return (
    <>
      <Card className="dark">
        <Card.Header className="primary-blue-background">
          <Card.Title className="m-1">
            FIMAN Help
          </Card.Title>
        </Card.Header>
        <Card.Body className="h-100 mobile-help">
          {navItems.map((navItem) => (
            <SingleAccordion
              key={navItem.key}
              header={navItem.title}
              eventKey={navItem.title}
              defaultEventKey={null}
            >
              <div className="flex flex-col">
                {navItem.details}
              </div>
            </SingleAccordion>
          ))}
        </Card.Body>

      </Card>

    </>
  );
};

HelpSm.propTypes = {
  navItems: PropTypes.array.isRequired,
};


export default HelpSm;