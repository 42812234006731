import React from 'react';
import PropTypes from 'prop-types';
import useMapStore from 'src/stores/mapStore';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';
import PrimaryButton from 'src/components/PrimaryButton';
import NameValueText from 'src/components/NameValueText';
import { formatDateTime } from 'src/lib/dateUtils';
import { roundToTenth } from 'src/lib/mathUtils';
import LoadingDisplay from 'src/components/LoadingDisplay';


const ForecastPeakContent = ({ useNwmModel = false, modalCallback }) => {
  const gauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails, isLoading } = useGaugeDetails(gauge?.attributes?.siteId);
  const { isDesktop } = useCustomMediaQuery();

  if (isLoading) {
    return <LoadingDisplay />;
  }

  const getForecastData = () => {
    const forecastData = useNwmModel ? gaugeDetails.nwmForecasts : gaugeDetails.forecasts;

    // If there are no forecasts in the future, then the
    // peak has already passed or the forecast data has not been updated.
    // In that case, we return 'NA'.
    const start = forecastData.find(f => f.read < 0);
    if (!start) {
      return {
        peak: null,
        peakStage: null,
        date: 'NA'
      };
    }

    let forecastRecord = forecastData.reduce((acc, current) => {
      return (current.value > acc.value && (current.read && current.read < 0)) ? current : acc;
    }, start);

    let date = new Date(forecastRecord.at);
    
    return {
      peak: useNwmModel ? gaugeDetails.nwmForecastPeak : gaugeDetails.forecastPeak,
      peakStage: useNwmModel ? gaugeDetails.nwmForecastPeakStage : gaugeDetails.forecastPeakStage,
      date: formatDateTime(date)
    };
  }

  const data = getForecastData();

  return (
    <>
      { data.peak === null &&
        <div className="d-flex justify-content-center align-items-center">
          No NWS forecast issued
        </div>
      }
      { data.peak !== null &&
        <>
          <NameValueText
            label={gaugeDetails.isCoastal ? 'Peak Elevation' : 'Peak Stage'}
            value={`${gaugeDetails.isCoastal ? roundToTenth(data.peak) : roundToTenth(data.peakStage)} ft`}
            className="d-flex justify-content-center align-items-center"
          />
          <span className="d-flex justify-content-center align-items-center py-1">{data.date}</span>
        </>
      }
      {!isDesktop && data.peak !== null &&
        <div className="d-flex flex-column mt-3">
          <PrimaryButton size="sm" className="rounded-pill py-1" onClick={() => modalCallback()}>
            <span>View Details</span>
          </PrimaryButton>
        </div>
      }
    </>
  );
}
ForecastPeakContent.propTypes = {
  useNwmModel: PropTypes.bool,
  modalCallback: PropTypes.func
};
export default ForecastPeakContent;
