import React from 'react';
import PropTypes from 'prop-types';
import Widget from './Widget';
import LoadingDisplay from 'src/components/LoadingDisplay';
import useRoadsAffectedTotals from 'src/lib/hooks/useRoadsAffectedTotals';
import useRoadsAffectedSummary from 'src/lib/hooks/useRoadsAffectedSummary';
import useMapStore from 'src/stores/mapStore';
import { roundToTenth } from 'src/lib/mathUtils';

// eslint-disable-next-line react/prop-types
const Gadget = ({ label, value }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <div style={{ fontSize: 13 }}>{label}</div>
      <div style={{ fontSize: 19 }}>{roundToTenth(value)}</div>
    </div>
  );
}

const RoadIcon = () => {
  return (
    <svg width="23.1045483px" height="15.823226px" viewBox="0 0 23.1045483 15.823226" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-282.000000, -436.000000)" fill="#FFFFFF" id="Road">
          <path d="M294.972751,439.379525 L294.972751,436 L299.339856,436 L305.104548,451.823226 L294.972751,451.823226 L294.972751,448.407934 L292.131798,448.407934 L292.131798,451.823226 L282,451.823226 L287.764692,436 L292.131798,436 L292.131798,439.379525 L294.972751,439.379525 Z M294.972751,445.594711 L294.972751,441.123839 L292.131798,441.123839 L292.131798,445.594711 L294.972751,445.594711 Z"></path>
        </g>
      </g>
    </svg>
  );
}

const RoadsAffectedWidget = ({ onActionClicked }) => {
  const activeGauge = useMapStore(state => state.activeGauge);
  const inundationLevel = useMapStore(state => state.inundationLevel);
  const { totalEntry } = useRoadsAffectedTotals(activeGauge?.attributes?.siteId, inundationLevel);
  const { data, isLoading } = useRoadsAffectedSummary(activeGauge?.attributes?.siteId, inundationLevel);

  const summary = () => {
    return data && data.total > 0 ? (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="d-flex flex-column gap-2">
          {
            totalEntry && (
              <div className="d-flex align-items-center justify-content-center gap-2">
                <RoadIcon />
                <div style={{ fontSize: 18 }}>
                  {`${data.total} Roads - ${roundToTenth(totalEntry.totalMiles)} Mi.`}
                </div>
              </div>
            )
          }
          <div className="d-flex justify-content-between gap-2">
            <Gadget label="Interstate" value={data.intRoutes} />
            <Gadget label="US Hwy" value={data.usRoutes} />
            <Gadget label="NC Hwy" value={data.ncRoutes} />
            <Gadget label="Local" value={data.localRoutes} />
          </div>
        </div>
      </div>
    ) : (
      <div className="d-flex justify-content-center align-items-center h-100">
        <span>No Roads Impacted</span>
      </div>
    );
  }

  return (
    <Widget
      title="Roads Impacted"
      onActionClick={onActionClicked}
    >
      {
        isLoading ? (
          <LoadingDisplay />
        ) : (
          <>{summary()}</>
        )
      }
    </Widget>
  );
}

RoadsAffectedWidget.propTypes = {
  onActionClicked: PropTypes.func
};

export default RoadsAffectedWidget;