import React from 'react';
import OutOfServiceIcon from 'src/components/OutOfServiceIcon';
import { GaugeMarkerColors } from 'src/constants/gaugeMarkerColors';


const RiskRatingLegend = () => {
  const ratings = [
    { color: GaugeMarkerColors.PURPLE, label: 'Major Flooding', icon: null },
    { color: GaugeMarkerColors.RED, label: 'Moderate Flooding', icon: null },
    { color: GaugeMarkerColors.ORANGE, label: 'Minor Flooding', icon: null },
    { color: GaugeMarkerColors.YELLOW, label: 'Monitor', icon: null },
    { color: GaugeMarkerColors.GREEN, label: 'Normal', icon: null },
    { color: GaugeMarkerColors.GRAY, label: 'Out of Service', icon: <OutOfServiceIcon height="9px" width="9px"/> },
  ];

  return (
    ratings.map((rating) => {
      return (
        <div className="mb-2 d-flex align-items-center d-flex" key={rating.label}>
          <div 
            className="align-items-center d-flex justify-content-center me-2 rounded-circle"
            style={{ backgroundColor: rating.color, width: 17, height: 17 }}
          >
            { rating.icon }
          </div>
          <div>{rating.label}</div>
        </div>
      )}
    )
  );
}

export default RiskRatingLegend;
