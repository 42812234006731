import React from 'react';
import { Modal } from 'react-bootstrap';
import useMapStore from '../../../stores/mapStore';
import ToolsContent from './ToolsContent';


const ToolsModal = () => {
  const showToolsModal = useMapStore(state => state.showToolsModal);
  const toggleToolsModal = useMapStore(state => state.toggleToolsModal);

  return (
    <Modal
      className="mobile-modal"
      show={showToolsModal}
      onHide={() => toggleToolsModal(false)}
      animation={true}
      backdrop="static"
      scrollable={true}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          FIMAN TOOLS
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <ToolsContent />
      </Modal.Body>
    </Modal>
  );
};

export default ToolsModal;