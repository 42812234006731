import React, { useState } from 'react';
import Widget from './Widget';
import useMapStore from 'src/stores/mapStore';
import StageContent from './StageContent';
import StageLegendModal from './StageLegendModal';
import useGaugeDetails from 'src/lib/hooks/useGaugeDetails';

const StageWidget = () => {
  const gauge = useMapStore(state => state.activeGauge);
  const { data: gaugeDetails } = useGaugeDetails(gauge?.attributes?.siteId);
  const [showLegendModal, setShowLegendModal] = useState(false)
  const getTitle = () => {
    return `Stream ${gaugeDetails?.isCoastal ? 'Elevation' : 'Stage'}`;
  }

  const handleActionClick = () => {
    setShowLegendModal(!showLegendModal);
  }

  return (
    <>
      <Widget
        title={getTitle()}
        onActionClick={handleActionClick}
      >
        <StageContent />
      </Widget>
      {gaugeDetails &&
        <StageLegendModal
          gaugeDetails={gaugeDetails}
          show={showLegendModal}
          handleClose={() => setShowLegendModal(false)}
        />
      }

    </>

  );
}

export default StageWidget;