import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';
import useCustomMediaQuery from '../lib/hooks/useCustomMediaQuery';
import LogoFull from '../assets/images/FIMAN_Logo_Full.png';
import LogoMd from '../assets/images/FIMAN_Logo_Mid.png';
import LogoSm from '../assets/images/FIMAN_Logo_Mobile.png';
import useUserStore from 'src/stores/userStore';
import useMapStore from 'src/stores/mapStore';
import PermissionFilter from 'src/features/map/auth/PermissionsFilter';
import { CognitoGroups } from 'src/constants/cognitoGroups';

const NavBar = () => {
  const { isMobile, isTablet, isDesktop } = useCustomMediaQuery();
  const user = useUserStore(state => state.user);
  const setEditUser = useUserStore(state => state.setEditUser);
  const signOut = useUserStore(state => state.signOut);
  const toggleEditUserModal = useUserStore(state => state.toggleEditUserModal);
  const toggleSignInModal = useMapStore(state => state.toggleSignInModal);

  const handleEditProfile = () => {
    setEditUser({ ...user })
    toggleEditUserModal(true);
  }
  return (
    <Navbar variant="primary-app" expand="md" className="sticky-top py-2">
      <Container fluid className="mx-md-3">
        <Navbar.Brand as={NavLink} to="/" end className="align-items-center d-flex flex-column">
          {isMobile && <img
            className="p-0"
            src={LogoSm}
            style={{ maxWidth: '150px', height: '30px' }}
            alt="NCEM Logo"
          />}
          {isTablet && <img
            className="p-0"
            src={LogoMd}
            style={{ maxWidth: '275px' }}
            alt="NCEM Logo"
          />}
          {isDesktop && <img
            className="p-0"
            src={LogoFull}
            style={{ maxWidth: '500px' }}
            alt="NCEM Logo"
          />}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="mr-3">
          <Nav className="w-100 justify-content-end pt-3 pt-md-0">
            <Nav.Link as={NavLink} to="/" end>Map</Nav.Link>
            <Nav.Link as={NavLink} to="/about">About</Nav.Link>
            {isDesktop && (
              <PermissionFilter group={CognitoGroups.ADMIN}>
                <Nav.Link as={NavLink} to="/admin">Admin</Nav.Link>
              </PermissionFilter>
            )}
            <Nav.Link as={NavLink} to="/help">Help</Nav.Link>
            {!user ? (<Nav.Link onClick={() => toggleSignInModal()}><PersonCircle />{' '} Sign In</Nav.Link>)
              : (
                <NavDropdown title={`${user.name}`}>
                  <NavDropdown.Item className="custom-dropdown-menu" onClick={() => signOut()}>Sign Out</NavDropdown.Item>
                  <NavDropdown.Item className="custom-dropdown-menu" onClick={() => handleEditProfile()}>My Profile</NavDropdown.Item>
                </NavDropdown>
              )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;