import { useQuery } from 'react-query';
import agent from '../agent';

const useConfigData = (id) => {
  return useQuery(['config', id], ({ queryKey }) => {
    const [, id] = queryKey;
    return agent.config.get(id);
  }, {
    enabled: true,
    staleTime: Infinity
  });
}

export default useConfigData;
