import create from 'zustand';
import agent from 'src/lib/agent';
import useMapStore from './mapStore';


const useAlertStore = create((set, get) => ({
  alertSubscriptions: [],
  loadingAlertSubscriptions: true,
  alertSubscriptionsInitialized: false,
  showConfigModal: false,
  editSiteId: null,
  siteAlerts: [],
  isSiteAlertsLoading: true,
  showEditModal: false,
  editAlert: null,
  isAlertDirty: false,

  toggleShowConfiguration: (show) => set((state) => ({ showConfigModal: show ?? !state.showConfigModal })),

  setEditSiteId: (siteId) => set({ editSiteId: siteId }),

  setAlertSubscriptions: (subscriptions) => set({ alertSubscriptions: subscriptions }),

  setEditAlert: (alert) => set({ editAlert: alert }),

  setIsAlertDirty: (dirty) => set({ isAlertDirty: dirty }),

  toggleShowEditModal: (show) => set((state) => ({ showEditModal: show ?? !state.showEditModal })),

  loadAlertSubscriptions: async () => {
    const mapManager = useMapStore.getState().mapManager;
    if (!mapManager) return;

    set({ loadingAlertSubscriptions: true });
    set({ alertSubscriptionsInitialized: true });

    try {
      // Gets the site ids that the user is subscribed to
      const subscriptionSiteIds = await agent.alert.subscriptions();

      const getGaugesBySiteId = async (siteIds) => {
        return Promise.all(siteIds.map(async (siteId) => {
          const results = await mapManager.queryGaugesLayer({
            where: `siteId  = '${siteId}'`,
            outFields: ['*'],
          });
          return results[0] ?? null;
        }));
      };

      const gauges = await getGaugesBySiteId(subscriptionSiteIds);

      // sort the gauges by condition, site name
      gauges.sort((a, b) => {
        const aCondition = a?.attributes?.condition ?? 0;
        const bCondition = b?.attributes?.condition ?? 0;
        const aName = a?.attributes?.name ?? '';
        const bName = b?.attributes?.name ?? '';

        if (aCondition === bCondition) {
          return aName.localeCompare(bName);
        } else {
          return bCondition - aCondition;
        }
      });

      set({ alertSubscriptions: gauges });
    } catch (error) {
      console.error(error);
    } finally {
      set({ loadingAlertSubscriptions: false });
    }
  },

  subscribeToSite: async (siteId) => {
    await agent.alert.subscribe({ siteId: siteId });
    await get().loadAlertSubscriptions();
  },

  unsubscribeFromSite: async (siteId) => {
    const siteSubscriptions = get().alertSubscriptions;
    const site = siteSubscriptions.find(s => s.attributes.siteId === siteId);

    if (!site) return;

    await agent.alert.unsubscribe({ siteId: siteId });
    await get().loadAlertSubscriptions();
  },

  unsubscribeAll: async () => {
    await agent.alert.unsubscribeAll();
    await get().loadAlertSubscriptions();
  },

  unsubscribeUserAll: async (username) => {
    await agent.alert.unsubscribeUserAll({ username });
    await get().loadAlertSubscriptions();
  },

  showSubscriptionConfiguration: async (siteId) => {
    set({ isSiteAlertsLoading: true });
    set({ editSiteId: siteId });
    set({ showConfigModal: true });
    await get().loadSiteAlerts(siteId);
    set({ isSiteAlertsLoading: false });
  },

  hideSubscriptionConfiguration: () => {
    set({ editSiteId: null });
    set({ showConfigModal: false });
    set({ siteAlerts: [] });
  },

  loadSiteAlerts: async (siteId) => {
    const alerts = await agent.alert.listBySite(siteId);
    set({ siteAlerts: alerts });
  },

  createSubscription: async (subscription) => {
    await agent.alert.createSubscription(subscription);
    await get().loadSiteAlerts(get().editSiteId);
    await get().loadAlertSubscriptions();
  },

  deleteSubscription: async (subscriptionId) => {
    await agent.alert.deleteSubscription(subscriptionId);
    await get().loadSiteAlerts(get().editSiteId);
    await get().loadAlertSubscriptions();
  },

  updateSubscription: async (subscription) => {
    await agent.alert.updateSubscription(subscription);
    await get().loadSiteAlerts(get().editSiteId);
  },
}));

export default useAlertStore;