import create from 'zustand';
import { Auth } from 'aws-amplify';
import agent from 'src/lib/agent';
import { toast } from 'react-toastify';
import { exportToExcel } from 'src/lib/dataExport';
import { formatDate, formatDateTime } from 'src/lib/dateUtils';


const useUserStore = create((set, get) => ({
  cognitoUser: null,
  user: null,
  jwtToken: null,
  tokenPayload: null,
  showEditUserModal: false,
  showAdminEditUserModal: false,
  editUser: null,
  editUserIndex: null,
  isSubmitting: false,
  showNameEditModal: false,
  showDuplicateUserModal: false,
  duplicateUserEmail: null,
  
  handleEditUser: (rowIndex, editUser) => {
    set({ editUser: editUser, editUserIndex: rowIndex });
    get().toggleAdminEditUserModal(true);
  },
  handleUpdate: async (user, updateRowDataCallback) => {
    set({ isSubmitting: true });
    try {
      if (await get().duplicateUserCheck(user.phoneNumber, user.username)) {
        toast.error('Phone number already exists for another account. Please enter a different phone number.');
        return;
      }
      let updatedUser = await agent.user.adminUpdate(user);

      //if the user is updating their own profile, update the cognito user
      if (user.username === get().user.username) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        get().setCognitoUser(cognitoUser);
      }

      updateRowDataCallback(get().editUserIndex, { ...updatedUser });
      get().toggleAdminEditUserModal(false);
      set({ editUser: null, editUserIndex: null, isSubmitting: false });
      toast.success('User updated successfully.');
    }
    catch (error) {
      toast.error('Error updating user. Please contact support.')
      set({ isSubmitting: false });
    }
    finally {
      set({ isSubmitting: false });
    }
  },
  adminDeleteUser: async (username, index, deleteRowDataCallback) => {
    set({ isSubmitting: true });
    try {
      await agent.user.adminDelete(username);
      if (username === get().user.username) {
        get().signOut();
      }
      deleteRowDataCallback(index);
      set({ isSubmitting: false });
    }
    catch (error) {
      toast.error('Error deleting user. Please contact support.')
      set({ isSubmitting: false });
    }
  },
  deleteUser: async () => {
    set({ isSubmitting: true });
    try {
      await agent.user.delete();
      get().signOut();
      set({ isSubmitting: false });
    }
    catch (error) {
      toast.error('Error deleting user. Please contact support.')
      set({ isSubmitting: false });
    }
  },
  duplicateUserCheck: async (phoneNumber, username) => {
    let isDuplicatePhone = false;
    if (phoneNumber !== '' && phoneNumber !== null) {
      isDuplicatePhone = await agent.user.duplicatePhoneCheck(phoneNumber, username);
    }
    return isDuplicatePhone;
  }, 
  handleExport: (table) => {
    const dataMap = [
      {
        columnName: 'Name',
        dataKey: 'name',
      },
      {
        columnName: 'Email',
        dataKey: 'email',
      },
      {
        columnName: 'Role',
        dataKey: 'role',
      },
      {
        columnName: 'Last Login',
        dataKey: 'lastLogin',
        converter: value => value ? formatDateTime(new Date(value)) : 'N/A',
      },
      {
        columnName: 'Phone Number',
        dataKey: 'phoneNumber',
        converter: value => value ? value : 'N/A',
      }
    ];

    const subHeaders = [
      `This report was generated from the Admin Manage Users Table`
    ];

    const filename = `manage_users_${formatDate(Date.now(), 'MMddyyy')}.xlsx`;

    exportToExcel(
      'FIMAN Users Export',
      subHeaders,
      dataMap,
      table.getFilteredRowModel().rows.map(row => row.original),
      filename,
      'Users'
    );
  },
  setIsSubmitting: (submitting) => set((state) => ({ isSubmitting: submitting ?? !state.isSubmitting })),
  toggleEditUserModal: (show) => set((state) => ({ showEditUserModal: show ?? !state.showEditUserModal })),
  toggleAdminEditUserModal: (show) => set((state) => ({ showAdminEditUserModal: show ?? !state.showAdminEditUserModal })),
  toggleNameEditModal: (show) => set((state)=> ({ showNameEditModal: show ?? !state.showNameEditModal })),
  setTokenPayload: (payload) => set({ tokenPayload: payload }),
  setJwtToken: (token) => set({ jwtToken: token }),
  setCognitoUser: (cognitoUser) => set({ cognitoUser: cognitoUser }),
  setEditUser: (user) => set({ editUser: user }),
  setUser: (user) => set({ user: user }),
  signOut: async () => {
    try {
      await Auth.signOut();
      set({ cognitoUser: null });
      set({ tokenPayload: null });
      set({ jwtToken: null });
    } catch (error) {
      console.error('error signing out: ', error);
    }
  },
  signIn: async (provider) => {
    await Auth.federatedSignIn({ provider: provider });
  },
  handleDuplicateModalClose: async () => {
    set({ duplicateUserEmail: null });
    set({ showDuplicateUserModal: false });
    get().signOut();
  }, 
  // name is optional -- only gets passed in for apple Auth
  validateUser: async (name) => {
    const validatedUser = await Auth.currentAuthenticatedUser();
    const idTokenPayload = validatedUser?.signInUserSession?.idToken?.payload;

    // this check has to be here for apple auth.  creates users without name. we want to catch
    // this before a user can access the app, and force them to enter a name or delete the account
    if (!name && !get().showNameEditModal &&(idTokenPayload?.name === null || idTokenPayload?.name === undefined || idTokenPayload?.name === '')) {
      set({ showNameEditModal: true });
      return;
    }
    let isDuplicate = await agent.user.duplicateUserCheck(idTokenPayload?.email);
    if (isDuplicate) {
      console.log('DUPLICAT CHECK WORKED'); 
      set({ duplicateUserEmail: idTokenPayload?.email });
      set({ showDuplicateUserModal: true });
      return;
    }

    const user = {
      cognitoUserId: idTokenPayload?.sub,
      // use name if passed in, otherwise use the cognito user name
      name: name ? name : idTokenPayload?.name,
      email: idTokenPayload?.email,
      username: idTokenPayload['cognito:username'],
    }
    try {
      const currentUser = await agent.user.validate(user);
      set({ user: currentUser });
    }
    catch (error)
    {
      console.error('error validating user: ', error);
      //notify user of error and sign out to keep from getting stuck in bad state
      toast.error('Error validating user. Please contact support.')
      get().signOut();
    }
  },

}));

export default useUserStore;