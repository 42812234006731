import React from 'react';
import { BuildingColors } from 'src/constants/buildingColors';

// eslint-disable-next-line react/prop-types
const BuildingIcon = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="19px" viewBox="0 0 18 19" version="1.1">
      <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-394.000000, -533.000000)">
          <g transform="translate(394.000000, 533.000000)">
            <polygon id="Path-4" fill={color} points="7 19 7 5.38722887 16.1295213 0 18 0 18 19 13.2327715 19 10.0679487 19"/>
            <rect id="Rectangle" fill="#000000" x="11" y="15" width="3" height="4"/>
            <polygon id="Path-5" fill={color} points="6 10 6 19 0 19 0 12.4092894"/>
            <rect id="Rectangle" fill="#000000" x="9" y="8" width="2" height="2"/>
            <rect id="Rectangle-Copy" fill="#000000" x="9" y="12" width="2" height="2"/>
            <rect id="Rectangle-Copy-2" fill="#000000" x="1" y="16" width="2" height="2"/>
            <rect id="Rectangle-Copy-4" fill="#000000" x="14" y="8" width="2" height="2"/>
            <rect id="Rectangle-Copy-6" fill="#000000" x="14" y="4" width="2" height="2"/>
            <rect id="Rectangle-Copy-5" fill="#000000" x="14" y="12" width="2" height="2"/>
            <rect id="Rectangle-Copy-7" fill="#000000" x="3" y="13" width="2" height="2"/>
          </g>
        </g>
      </g>
    </svg>
  )
}



const BuildingsLegend = () => {
  const heights = [
    { color: BuildingColors.DARK_PURPLE, label: '> 5 ft'},
    { color: BuildingColors.MEDIUM_PURPLE, label: '4-5 ft'},
    { color: BuildingColors.FUCHSIA, label: '3-4 ft'},
    { color: BuildingColors.RED, label: '2-3 ft'},
    { color: BuildingColors.ORANGE, label: '1-2 ft'},
    { color: BuildingColors.YELLOW, label: '0-1 ft'},
    { color: BuildingColors.BRIGHT_YELLOW, label: 'Substructure'},
  ];

  return (
    <>
      <div className="mb-2">Flood Depths</div>
      {heights.map((height) => {
        return (
          <div className=" d-flex align-items-center d-flex mb-2" key={height.label}>
            <div className="me-2 rounded-circle d-flex align-items-center justify-content-center" style={{width: 25, height: 25}}>
              <BuildingIcon color = {height.color} />
            </div>
            <div>{height.label}</div>
          </div>
        )
      })}
    </>
  );
}
  
export default BuildingsLegend;
