import React from 'react';
import PropTypes from 'prop-types';


const CenteredCell = ({ children }) => {
  return (<div className="d-flex align-items-center justify-content-center text-center">{children}</div>)
}

CenteredCell.propTypes = {
  children: PropTypes.node,
};

export default CenteredCell;