import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { AlertEvents } from 'src/constants/alertEvents';
import { roundToTenth } from 'src/lib/mathUtils';
import { convertElevationToStage, convertStageToElevation } from 'src/lib/stageConversionUtils';
import useAlertStore from 'src/stores/alertStore';
import ConditionRange from './ConditionRange';
import { confirm } from 'src/components/ConfirmationModal';
import useCustomMediaQuery from 'src/lib/hooks/useCustomMediaQuery';


const AlertEdit = ({ alert, gauge }) => {
  const isAddMode = alert === null;
  const createSubscription = useAlertStore(state => state.createSubscription);
  const toggleShowEditModal = useAlertStore(state => state.toggleShowEditModal);
  const updateSubscription = useAlertStore(state => state.updateSubscription);
  const setIsAlertDirty = useAlertStore(state => state.setIsAlertDirty);
  const isAlertDirty = useAlertStore(state => state.isAlertDirty);
  const [isSaving, setIsSaving] = useState(false);
  const { isMobile } = useCustomMediaQuery();

  const { handleSubmit, reset, trigger, control, setValue, watch, formState: { errors, isDirty } } = useForm({ mode: 'onChange' });

  const watchElevation = watch('elevation');
  const watchStage = watch('stage');
  const position = gauge?.isCoastal ? watchElevation : watchStage;

  const makeVarName = (text) => {
    return text.replace(/\s+/g, '-').toLowerCase();
  }

  const getEventValue = (id) => {
    const event = alert?.events.find(event => event.alertEventId === id);
    return event ? event.enabled : false;
  }

  const handleStageBlur = (e) => {
    const stage = e.target.value;

    if (stage === '') {
      setValue('elevation', '');
      trigger('elevation');
      return;
    }

    const elevation = convertStageToElevation(parseFloat(stage), gauge.gageDatum);

    setValue('elevation', roundToTenth(elevation));
  }

  const handleElevationBlur = (e) => {
    const elevation = e.target.value;

    if (elevation === '') {
      setValue('stage', '');
      trigger('stage');
      return;
    }

    const stage = convertElevationToStage(parseFloat(elevation), gauge.gageDatum);
    setValue('stage', roundToTenth(stage));
  }

  useEffect(() => {
    if (!isAddMode) {
      setValue('name', alert.name);
      setValue('stage', roundToTenth(convertElevationToStage(alert.value, gauge.gageDatum)));
      setValue('elevation', roundToTenth(alert.value));

      alert.events.forEach(event => {
        setValue(makeVarName(AlertEvents.getEventById(event.alertEventId)), event.enabled);
      });
    } else {
      setValue('name', '');
      setValue('stage', roundToTenth(convertElevationToStage(gauge.minor, gauge.gageDatum)));
      setValue('elevation', roundToTenth(gauge.minor));
    }
  }, [alert, isAddMode, setValue, gauge]);

  useEffect(() => {
    setIsAlertDirty(isDirty);
  }, [isDirty, setIsAlertDirty]);

  const onSubmit = async (data) => {
    setIsSaving(true);

    if (isAddMode) {
      await addAlert(data);
    } else {
      await updateAlert(data);
    }

    setIsSaving(false);
    setIsAlertDirty(false);
  }

  const addAlert = async (data) => {
    const events = AlertEvents.getEvents().map(event => {
      const varName = makeVarName(event);
      if (!(varName in data)) return null;

      return {
        alertEventId: AlertEvents.getEventIdByName(event),
        enabled: data[varName] ?? false,
        tripped: false
      }
    });

    const newAlert = {
      siteId: gauge.siteId,
      name: data.name,
      enabled: true,
      value: parseFloat(data.elevation),
      events: events.filter(event => event !== null)
    }

    await createSubscription(newAlert);
    toggleShowEditModal(false);
    reset();
  }

  const updateAlert = async (data) => {
    const events = alert.events.map(event => {
      const varName = makeVarName(AlertEvents.getEventById(event.alertEventId));
      return {
        ...event,
        enabled: data[varName]
      }
    });

    const newAlert = {
      ...alert,
      name: data.name,
      value: data.elevation,
      events: events
    }

    await updateSubscription(newAlert);
    toggleShowEditModal(false);
    reset();
  }

  const handleCancelEdit = () => {
    if (isAlertDirty) {
      confirm('Are you sure you want to continue? Any unsaved changes will be lost.',
        'Unsaved Changes',
        'Continue',
        'Cancel',
        cancelEdit);
      return;
    }
    cancelEdit();
  }
  const cancelEdit = () => {
    reset();
    toggleShowEditModal(false);
    setIsAlertDirty(false);
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="m-2 bg-black p-3 rounded-3">
          <Form.Group className="mb-3 required" controlId="formName">
            <Form.Label>Alert Name</Form.Label>
            <Controller
              control={control}
              name="name"
              defaultValue={alert?.name ?? ''}
              rules={{ required: true, maxLength: 128 }}
              render={({ field }) => <>
                <Form.Control
                  disabled={isSaving}
                  isInvalid={errors.name}
                  type="text"
                  placeholder="Enter Name"
                  {...field}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.type === 'maxLength' && 'Alert name cannot exceed 128 characters.'}
                  {errors.name?.type === 'required' && 'Alert name is required.'}
                </Form.Control.Feedback>
              </>
              }
            />
          </Form.Group>
          <Row className="mb-3 gap-3 gap-lg-0">
            {!gauge.isCoastal &&
              <Form.Group className="required" as={Col} lg={6} controlId="forStage">
                <Form.Label>Stage (ft)</Form.Label>
                <Controller
                  control={control}
                  defaultValue={alert?.stage ?? ''}
                  name="stage"
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => <>
                    <Form.Control
                      disabled={isSaving}
                      isInvalid={error !== undefined}
                      type="number"
                      step="0.1"
                      precision={1}
                      placeholder="Enter alert stage"
                      {...field}
                      onBlur={handleStageBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      Alert stage is required
                    </Form.Control.Feedback>
                  </>
                  }
                />
              </Form.Group>
            }
            <Form.Group className="required" as={Col} lg={gauge.isCoastal ? 12 : 6} controlId="forElevation">
              <Form.Label>Elevation (ft - NAVD 88)</Form.Label>
              <Controller
                control={control}
                defaultValue={alert?.elevation ?? ''}
                name="elevation"
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => <>
                  <Form.Control
                    disabled={isSaving}
                    type="number"
                    step="0.1"
                    precision={1}
                    isInvalid={error !== undefined}
                    placeholder="Enter alert elevation"
                    {...field}
                    onBlur={handleElevationBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    Alert elevation is required
                  </Form.Control.Feedback>
                </>
                }
              />
            </Form.Group>
          </Row>
        </div>
        {!isMobile &&
          <div className="m-2 bg-black p-3 rounded-3">
            <Form.Group>
              <ConditionRange gauge={gauge} value={position ? parseFloat(position) : null} />
            </Form.Group>
          </div>
        }
        <div className="m-2 bg-black p-3 rounded-3">
          <h5>Alert Me When:</h5>
          <hr />
          <div className="d-flex flex-column gap-2">
            <Form.Group as={Col} controlId="forAbove">
              <Controller
                control={control}
                name={makeVarName(AlertEvents.RISE_ABOVE)}
                render={({ field }) => <Form.Check
                  disabled={isSaving}
                  className="custom-toggle"
                  type="switch"
                  defaultChecked={getEventValue(1) ?? false}
                  label={AlertEvents.RISE_ABOVE}
                  {...field}
                />
                }
              />
            </Form.Group>
            <Form.Group as={Col} controlId="forBelow">
              <Controller
                control={control}
                name={makeVarName(AlertEvents.FALL_BELOW)}
                render={({ field }) => <Form.Check
                  disabled={isSaving}
                  className="custom-toggle"
                  type="switch"
                  defaultChecked={getEventValue(2) ?? false}
                  label={AlertEvents.FALL_BELOW}
                  {...field}
                />
                }
              />
            </Form.Group>
            {gauge.forecastPt && <>
              <Form.Group as={Col} controlId="forForecastAbove">
                <Controller
                  control={control}
                  name={makeVarName(AlertEvents.FORECAST_RISE_ABOVE)}
                  render={({ field }) => <Form.Check
                    disabled={isSaving}
                    className="custom-toggle"
                    defaultChecked={getEventValue(3) ?? false}
                    type="switch"
                    label={AlertEvents.FORECAST_RISE_ABOVE}
                    {...field}
                  />
                  }
                />
              </Form.Group>
              <Form.Group as={Col} controlId="forForecastBelow">
                <Controller control={control} name={makeVarName(AlertEvents.FORECAST_FALL_BELOW)}
                  render={({ field }) => <Form.Check
                    className="custom-toggle"
                    disabled={isSaving}
                    defaultChecked={getEventValue(4) ?? false}
                    type="switch"
                    size="lg"
                    label={AlertEvents.FORECAST_FALL_BELOW}
                    {...field}
                  />
                  }
                />
              </Form.Group>
            </>
            }
          </div>
        </div>
        <div className="d-flex justify-content-between gap-2 p-4">
          <Button
            className="rounded-pill flex-grow-1"
            variant="red-cancel"
            onClick={() => handleCancelEdit()}
          >
            Cancel
          </Button>
          <Button type="submit" className="btn-green-accept rounded-pill flex-grow-1">
            {isSaving && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>}
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

AlertEdit.propTypes = {
  alert: PropTypes.object,
  gauge: PropTypes.object.isRequired,
};

export default AlertEdit;