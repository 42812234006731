export const toTitleCase = (str) => {
  if (typeof str !== 'string' || str instanceof String) {
    return str;
  }

  // Function to capitalize the first letter of a word
  const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

  // Split the string by space and dash, then map each word to capitalize
  return str
    .split(/\s+|-/) // Split by spaces or dashes
    .map(capitalize) 
    .join(str.indexOf('-') >= 0 ? '-' : ' ');
}

export const toFilenameSafe = (str, maxLength = 100) => {
  let safeString = str.trim();

  safeString = safeString.replace(/\s+/g, '-'); // Replaces spaces with '-'
  safeString = safeString.replace(/[/\\:*?"<>|]/g, '_'); // Replaces '/', '\', ':', '*', '?', '"', '<', '>', '|'
  safeString = safeString.slice(0, maxLength);

  return safeString;
}

export const base64ToBlob = (base64, contentType) => {
  const byteCharacters = atob(base64.split(',')[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}
