import React, {useMemo} from 'react'; 
import PropTypes from 'prop-types';
import { format } from 'date-fns'
import { Paramcodes } from 'src/constants/paramcodes';
import Hydrograph from 'src/components/Hydrograph';
import { roundToTenth } from 'src/lib/mathUtils';


const FlowHydrograph = ({ gaugeDetails }) => { 
  const { historical, forecasts } = gaugeDetails;

  //filter out the data that is not needed for the chart
  const historicalData = useMemo(() => {
    let filteredHistorical = historical.filter(h => Paramcodes.FLOW_CODES.includes(h.code)); 
    return [...filteredHistorical].map(d => {
      return {
        ...d,
        dataAt: new Date(d.at), 
        dataValue: d.value
      }
    }); 
  }, [historical]);

  const forecastedData = useMemo(() => {
    let filtered = forecasts?.filter(f => Paramcodes.FLOW_CODES.includes(f.code)) ?? [];
    return [...filtered].map(d => {
      return {
        ...d,
        dataAt: new Date(d.at),
        dataValue: d.value
      }
    });
  }, [forecasts]);


  const scaleYOptions = {
    title: {
      display: true,
      text: 'Flow (cfs)'
    },
    ticks: {
      display: true,
    }
  }; 
  const tooltipCallbackOptions = {
    //adds Forecast Header to tooltip if the date is in the future
    beforeTitle: (context) => {
      if (!context[0]?.parsed.x) {
        return;
      }
      const comparisonDate = new Date(context[0].parsed.x);
      if (comparisonDate > new Date()) {
        return 'Forecast';
      }
    },
    title: () => { return null }, //removes the title from the tooltip,
    label: (context) => {
      return `Flow: ${roundToTenth(context.parsed.y)} cfs`;
    },
    afterBody: (context) => {
      if (!context[0]?.parsed.x) {
        return;
      }
      const date = new Date(context[0]?.parsed.x);

      return `Date: ${format(date, 'MMM dd, p')}`;
    },
  }; 

  return (
    <Hydrograph
      historicalData={historicalData}
      forecastedData={forecastedData}
      scaleYOptions={scaleYOptions}
      tooltipCallbackOptions={tooltipCallbackOptions}
    />
  );
}

FlowHydrograph.propTypes = {
  gaugeDetails: PropTypes.object.isRequired,
};
export default FlowHydrograph;