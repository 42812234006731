import create from 'zustand';
import { Actions } from '../constants/actions';
import { Layers } from '../constants/layers';
import { DrawerLevels } from 'src/constants/drawerLevels';
import { MapManager } from '../lib/mapManager';
import { filtersToQuery } from '../lib/utils';
import _ from 'lodash';
import useGaugeStore from './gaugeStore';
import { Tabs } from 'src/constants/tabs';
import agent from 'src/lib/agent';
import { roundToHundredth } from 'src/lib/mathUtils';
import useUserStore from './userStore';
import { CognitoGroups } from 'src/constants/cognitoGroups';


const useMapStore = create((set, get) => ({
  showToolsSidebar: true,
  showToolsModal: false,
  showWelcomeModal: true,
  showSignUpModal: false,
  showSignInModal: false,
  showWeather: false,
  showNotifications: false,
  radarTimestamp: null,
  gaugeCount: 0,
  defaultGaugeFilters: [],
  currentGaugeFilters: [],
  currentGauges: [],
  gaugesWithBridgeAndRoadData: [],
  mapManager: null,
  pointerInMapView: false,
  activeGauge: null,
  showGaugeData: false,
  currentHoverGaugeId: null,
  currentHoverBridgeId: null,
  staticHoverActive: false,
  isMobile: false,
  activeGroupLayerId: {},
  selectedGaugeAreaOfInterest: null,
  selectedGaugeAreaOfInterestSubCategory: null,
  hasAreaOfInterestGraphic: false,
  gaugeLayerIsReady: false,
  ncdotAssetsLayerIsReady: false,
  config: null,

  // This property will store the current inundation level that is being shown on the map
  // This value is relative to MSL.
  inundationLevel: null,
  // Variables to help maintain the state when moving between tabs, use the 
  // inundationLevel property to know what level is currently being shown on the map
  scenarioLevel: null,
  historyLevel: null,
  forecastIndex: null,

  activeScenarioTabGridget: null,
  activeForecastTabGridget: null,
  activeHistoryTabGridget: null,

  activeLayerIds: {}, // This object will store the active layer IDs for each group
  setActiveLayerId: (groupId, layerId) => set((state) => ({
    ...state,
    activeLayerIds: {
      ...state.activeLayerIds,
      [groupId]: layerId
    }
  })),

  setContainer: async (config, container) => {
    if (container) {
      if (!get().mapManager) {

        // set default gauge filters - page load should use defaults
        set({ defaultGaugeFilters: _.cloneDeep(config.layers[Layers.GAUGES].defaultFilters) });
        set({ currentGaugeFilters: _.cloneDeep(config.layers[Layers.GAUGES].defaultFilters) });

        const manager = new MapManager(config);
        manager.loadWidgets();
        manager.loadLayers(
          get().onLayerViewReady,
          get().onLayerViewUpdated
        );

        set({ config });

        // set callbacks
        manager.onViewClick(get().onViewClick);
        manager.onViewUpdated(get().onMapViewUpdated);
        manager.onViewHover(get().onMapViewHover);
        manager.onActionClick(get().onActionClick);
        manager.onPointerLeave(get().onPointerLeave);
        manager.onPointerEnter(get().onPointerEnter);

        // need to handle layers and state differently on mobile
        if (get().isMobile) {
          manager.onPopupClose(() => {
            get().clearAllSelectedFeatures();
          });
        }

        set({ mapManager: manager });
      }
      get().mapManager.initialize(container);
    }
  },
  setMobile: (isMobile) => set({ isMobile }),
  toggleToolsSidebar: (show) => set((state) => ({ showToolsSidebar: show ?? !state.showToolsSidebar })),
  toggleToolsModal: (show) => set((state) => ({ showToolsModal: show ?? !state.showToolsModal })),
  toggleWelcomeModal: (show) => set((state) => ({ showWelcomeModal: show ?? !state.showWelcomeModal })),
  toggleSignUpModal: (show) => set((state) => ({ showSignUpModal: show ?? !state.showSignUpModal })),
  toggleSignInModal: (show) => set((state) => ({ showSignInModal: show ?? !state.showSignInModal })),
  toggleNotifications: (show) => set((state) => ({ showNotifications: show ?? !state.showNotifications })),

  // weather layer actions
  toggleWeatherAnimation: async () => {
    const visible = get().mapManager.toggleRadarLayers(get().weatherTimestampUpdate);
    set({ showWeather: visible });
  },

  weatherTimestampUpdate: (timestamp) => {
    set({ radarTimestamp: timestamp });
  },

  // gauge actions
  toggleGauge: (show) => {
    const showGaugeData = show ?? !get().showGaugeData;
    set({ showGaugeData });
    if (!showGaugeData) {
      get().setActiveGauge(null);
    }
  },

  setActiveGauge: (activeGauge) => {
    set({ activeGauge });
    get().setScenarioLevel(null);
    get().setHistoryLevel(null);
    get().setForecastIndex(null);
    get().toggleActiveScenarioTabGridget(null);
    get().toggleActiveForecastTabGridget(null);
    get().toggleActiveHistoryTabGridget(null);
    useGaugeStore.getState().setActiveTab(Tabs.CURRENT);
    useGaugeStore.getState().toggleDrawerExpand(DrawerLevels.HALF)
  },

  setSelectedGaugeAreaOfInterest: (selectedGaugeAreaOfInterest) => {
    set({ selectedGaugeAreaOfInterest });
  },

  setSelectedGaugeAreaOfInterestSubCategory: (selectedGaugeAreaOfInterestSubCategory) => {
    set({ selectedGaugeAreaOfInterestSubCategory: selectedGaugeAreaOfInterestSubCategory });
  },

  setHasAreaOfInterestGraphic: (hasAreaOfInterestGraphic) => {
    set({ hasAreaOfInterestGraphic });
  },

  showGaugeDetails: async (gauge, hidePopup = true) => {
    if (hidePopup) {
      get().mapManager.showHoverPopup(null);
    }

    get().setActiveGauge(gauge);
    get().toggleGauge(true);
    get().zoomToGaugeLocation(gauge);
  },

  clearAllSelectedFeatures: () => {
    const mapManager = get().mapManager;
    mapManager.showHoverPopup(null);
    mapManager.hideInundationLayer();
    mapManager.hideRoadLayer();
    mapManager.hideBridgeLayer();
    mapManager.hideDefaultBridgeLayer();
    mapManager.hideFloodBuildingsLayer()

    set({ activeGauge: null });
    get().setScenarioLevel(null);
    get().setHistoryLevel(null);
    get().setForecastIndex(null);
  },

  zoomToGaugeLocation: async (gauge) => {
    if (!gauge) return;

    const mapManager = get().mapManager;
    if (!mapManager) return;

    // query for the original feature to get the geometry since the graphic
    // coordinates may not be accurate
    const result = await mapManager.queryGaugesLayer({
      where: `__OBJECTID = ${gauge?.attributes?.__OBJECTID}`,
      outFields: ['__OBJECTID'],
      returnGeometry: true,
    });

    if (result?.length > 0) {
      const feature = result[0];
      mapManager.zoomToFeatureByGeometry(feature.geometry, 16);
    }
  },

  // Callbacks for mapManager layer loading.
  onViewClick: async (graphic, layer) => {
    const mapManager = get().mapManager;

    // map background click
    if (!graphic?.geometry) {
      if (get().isMobile) {
        get().clearAllSelectedFeatures();
      }
      set({ staticHoverActive: false });
      get().mapManager.showHoverPopup(null);
    }

    if (layer?.id === Layers.GAUGES) {
      if (!get().isMobile) {
        get().showGaugeDetails(graphic);
      } else {
        await get().showGaugeDetailsMobile(graphic);
      }
    }

    if (layer?.id === Layers.FLOOD_BUILDINGS) {
      mapManager.showHoverPopup(graphic);
      set({ staticHoverActive: graphic !== null });
    }

    if (layer?.id === Layers.ROADS) {
      mapManager.showHoverPopup(graphic);
      set({ staticHoverActive: graphic !== null });
    }

    if ([Layers.BRIDGES, Layers.DEFAULT_BRIDGES, Layers.NCDOT_ASSETS_BUILDINGS, Layers.NCDOT_ASSETS_LAND].includes(layer?.id)) {
      if (get().isMobile) {
        mapManager.showHoverPopup(graphic);
      }
    }
  },

  onPointerLeave: () => {
    set({ pointerInMapView: false });
  },

  onPointerEnter: () => {
    set({ pointerInMapView: true });
  },

  showGaugeDetailsMobile: async (gauge) => {
    const mapManager = get().mapManager;

    // show popup for mobile
    get().zoomToGaugeLocation(gauge);
    mapManager.showHoverPopup(gauge);

    const gaugeDetails = await agent.gauge.get(gauge.attributes.siteId);

    const { siteId, isScenario, inService, currentElevationMsl } = gaugeDetails;

    let cognitoUser = useUserStore.getState().cognitoUser;
    const groups = cognitoUser?.signInUserSession?.idToken?.payload['cognito:groups'] ?? [];
    const isAdmin = groups?.includes(CognitoGroups.ADMIN) ?? false; 
    const isAdvanced = groups?.includes(CognitoGroups.ADVANCED) ?? false;


    if (inService) {
      const level = await get().showInundationLayer(siteId, isScenario, currentElevationMsl, null, false);
      if(isAdmin || isAdvanced){
        mapManager.showRoadLayer(siteId, level, isScenario);
        mapManager.showDefaultBridgeLayer(siteId);
        mapManager.showBridgeLayer(siteId, level, isScenario);
      }
      mapManager.showFloodBuildingsLayer(siteId, level, isScenario);
    }
  },

  onMapViewHover: (graphic, layer, mapPoint) => {
    get().handleGaugeHover(graphic, layer);
    get().handleBridgeHover(graphic, layer);
    get().handleOthersHover(graphic, layer);
    get().handleRainLayerHover(layer, mapPoint);

    // hide the popup if the hover graphic is null and the static hover is not active
    // this allows the static popup to stay open when the user
    // moves the mouse off the graphic
    if (!graphic && !get().staticHoverActive) {
      if(!get().isMobile) {
        get().mapManager.showHoverPopup(null);
      }
    }
  },

  onActionClick: (action) => {
    if (action === Actions.GAUGE_DETAILS) {
      const mapManager = get().mapManager;
      const gauge = mapManager.view.popup.selectedFeature;

      get().showGaugeDetails(gauge, false);
    }
  },

  onLayerViewReady: async (layerView) => {
    const { layer } = layerView;

    if (layer.id === Layers.GAUGES) {
      await get().querySetCurrentGauges();
      get().setGaugeCount();
      set({ gaugeLayerIsReady: true });
    }
    if (layer.id === Layers.NCDOT_ASSET_GROUP) {
      set({ ncdotAssetsLayerIsReady: true });
    }

  },

  onLayerViewUpdated: async (layerView) => {
    const { layer } = layerView;
  },

  onMapViewUpdated: async () => {

  },

  getRainEstimate: async (mapPoint, layerId) => {
    // get the weather group in the config
    const weatherGroup = get().config.layers.references.groups.filter(group => group.id === 'weather');

    if (weatherGroup.length === 0) {
      return [null, 'NoData'];
    }

    // get the layer from the weather group
    const weatherLayers = weatherGroup[0].layers.filter(layer => layer.id === layerId);
    const layer = weatherLayers.length > 0 ? weatherLayers[0] : null;

    if (!layer) {
      return [null, 'NoData'];
    }

    const response = await get().mapManager.identifyFeature(layer.url, layer.sublayers[0].id, mapPoint);

    if (response.results.length > 0) {
      // find the max attribute value. the estimate rain layers possibly return multiple features that 
      // are stacked on top of each other. find the max value to get the top most feature
      response.results.sort((a, b) => b.feature.attributes[layer.attribute] - a.feature.attributes[layer.attribute]);

      return [layer, response.results[0].feature.attributes[layer.attribute]];
    }

    // no data is returned when the point is outside the layer extent
    return [layer, 'NoData'];
  },

  handleRainLayerHover: (layer, mapPoint) => {
    if (get().isMobile) {
      return;
    }

    // if the layer is not a rain estimate layer, do nothing
    if (layer) {
      return;
    }

    if(!get().pointerInMapView) {
      get().mapManager.showHoverPopup(null);
      return;
    }

    // the estimated rainfall layers a mapimagelayer and not support by hitTest
    // so we need to manually get the rain estimate for the point

    // determine if and which rain layer is visible
    const estimateLayers = [
      Layers.ESTIMATED_RAINFALL_24H,
      Layers.ESTIMATED_RAINFALL_48H,
      Layers.ESTIMATED_RAINFALL_72H,
      Layers.ESTIMATED_RAINFALL_120H,
      Layers.ESTIMATED_RAINFALL_168H,
      Layers.FORCASTED_RAINFALL_24H,
      Layers.FORCASTED_RAINFALL_48H,
      Layers.FORCASTED_RAINFALL_72H,
      Layers.FORCASTED_RAINFALL_120H,
      Layers.FORCASTED_RAINFALL_168H,
    ];
    
    estimateLayers.forEach(async layerId => {
      const visible = get().mapManager.getLayerVisibility(layerId);
      if (visible) {
        let [layerConfig, result] = await get().getRainEstimate(mapPoint, layerId);

        // no data is returned when the point is outside the layer extent
        // or the layer is not visible
        if (result && result !== 'NoData') {
          result = roundToHundredth(result);

          get().mapManager.showHoverPopup({ 
            popupTemplate: layerConfig.popupTemplate,
            attributes: { qp: result } 
          }, false, mapPoint);
        }
      }
    });
  },

  handleGaugeHover: (graphic, layer) => {
    if (layer?.id === Layers.GAUGES) {
      // if the hover graphic is the same as the current graphic, do nothing
      // reduces the flickering of the hover popup
      if (get().currentHoverGaugeId == graphic?.attributes?.__OBJECTID) {
        return;
      }
      set({ currentHoverGaugeId: graphic?.attributes?.__OBJECTID });

      // no show on mobile
      if (!get().isMobile) {
        get().mapManager.showHoverPopup(graphic);
      }
    } else {
      set({ currentHoverGaugeId: null });
    }
  },

  handleBridgeHover: (graphic, layer) => {
    if (layer?.id === Layers.BRIDGES || layer?.id === Layers.DEFAULT_BRIDGES) {
      // if the hover graphic is the same as the current graphic, do nothing
      // reduces the flickering of the hover popup
      if (get().currentHoverBridgeId == graphic?.attributes?.OBJECTID) {
        return;
      }
      set({ currentHoverBridgeId: graphic?.attributes?.OBJECTID });

      // no need to show the hover popup on mobile
      if (!get().isMobile) {
        get().mapManager.showHoverPopup(graphic);
      }
    } else {
      set({ currentHoverBridgeId: null });
    }
  },

  handleOthersHover: (graphic, layer) => {
    if ([Layers.BRIDGES, Layers.DEFAULT_BRIDGES, Layers.NCDOT_ASSETS_BUILDINGS, Layers.NCDOT_ASSETS_LAND].includes(layer?.id)) {
      if (!get().isMobile) {
        get().mapManager.showHoverPopup(graphic);
      }
    }
  },

  updateCurrentGaugeFilters: async (newFilterGroups = get().defaultGaugeFilters) => {
    set({ currentGaugeFilters: newFilterGroups });
    await get().setGaugesWithBridgeAndRoadData();

    get().setGaugeLayerDefinitionExpression(newFilterGroups);
    get().querySetCurrentGauges();
    get().setGaugeCount();
  },

  setGaugeCount: () => {
    set({ gaugeCount: get().currentGauges?.length });
  },

  querySetCurrentGauges: async () => {
    const results = await get().mapManager.queryGaugesLayer({
      where: filtersToQuery(get().currentGaugeFilters, get().gaugesWithBridgeAndRoadData),
      outFields: ['*'],
      returnGeometry: false,
    });
    set({ currentGauges: results });
    get().setGaugeCount();
  },

  /**
   * takes in a list of filters (base is from the config) to create a sql query as the 
   * definition expression for the gauge layer.
   * @param {Array} filterGroups that should get parsed into a sql query 
   */
  setGaugeLayerDefinitionExpression: (filterGroups) => {
    let gaugeLayer = get().mapManager.getGaugeLayer();
    gaugeLayer.definitionExpression = filtersToQuery(filterGroups, get().gaugesWithBridgeAndRoadData);
  },

  /**
   * Set the gaugesWithBridgeAndRoadData property to a list of gauges that have bridge and road data.
   * used for filtering the gauge layer
   */
  setGaugesWithBridgeAndRoadData: async () => {
    if (get().gaugesWithBridgeAndRoadData.length > 0) return;
    const bridgeGauges = await get().mapManager.getGaugesWithBridgeData();
    const roadGauges = await get().mapManager.getGaugesWithRoadData();
    const gauges = [...bridgeGauges, ...roadGauges]
    const distinctGauges = [...new Set(gauges)]
    set({ gaugesWithBridgeAndRoadData: distinctGauges });
  },

  /**
   * Assumption is that only one layer in a group is visible at a time.
   * @param {string} groupId
   * @param {string} layerId
   */
  showGroupLayer: (groupId, layerId) => {
    const mapManager = get().mapManager;
    if (!mapManager) return;
    const activeGroupLayerId = get().activeGroupLayerId;
    const activeLayerId = activeGroupLayerId[groupId];

    activeLayerId && mapManager.setLayerVisibility(activeLayerId, false);
    mapManager.setLayerVisibility(layerId, true);

    set((state) => {
      const activeGroupLayerId = { ...state.activeGroupLayerId };
      activeGroupLayerId[groupId] = layerId;
      return { activeGroupLayerId };
    });
  },

  /**
   * Wraps the mapManager's showInundationLayer method and sets the inundationLevel property
   * to the level that was shown. This provides a way to know what level is currently being shown,
   * indepenent of the tab that is selected.
   */
  showInundationLayer: async (siteId, isScenario, elevation, levels = null, minSnap = false) => {
    const mapManager = get().mapManager;
    const level = await mapManager.showInundationLayer(siteId, isScenario, elevation, levels, minSnap);

    set({ inundationLevel: level });

    return level;
  },

  hideInundationLayer: () => {
    const mapManager = get().mapManager;
    mapManager.hideInundationLayer();
    set({ inundationLevel: null });
  },

  setScenarioLevel: (level) => {
    set({ scenarioLevel: level });
  },

  setHistoryLevel: (level) => {
    set({ historyLevel: level });
  },

  setForecastIndex: (index) => {
    set({ forecastIndex: index });
  },

  toggleActiveScenarioTabGridget: (gridgetId = null) => {
    // if the gridgetId is the same as current active, then toggle the activeScenarioTabGridget to null
    set((state) => ({ activeScenarioTabGridget: gridgetId === state.activeScenarioTabGridget ? null : gridgetId }));
  },
  toggleActiveForecastTabGridget: (gridgetId = null) => {
    // if the gridgetId is the same as current active, then toggle the activeScenarioTabGridget to null
    set((state) => ({ activeForecastTabGridget: gridgetId === state.activeForecastTabGridget ? null : gridgetId }));
  },
  toggleActiveHistoryTabGridget: (gridgetId = null) => {
    // if the gridgetId is the same as current active, then toggle the activeScenarioTabGridget to null
    set((state) => ({ activeHistoryTabGridget: gridgetId === state.activeHistoryTabGridget ? null : gridgetId }));
  },
}));


export default useMapStore;
