import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const PillButton = ({ children, className, ...props }) => {
  return (
    <Button
      className={`rounded-pill ${className} `}
      {...props}
    >
      {children}
    </Button>
  )
}
export default PillButton;

PillButton.propTypes = {
  children: PropTypes.node.isRequired, 
  className: PropTypes.string
}