import React, { useEffect, useState } from 'react';
import { convertStageToElevation } from 'src/lib/stageConversionUtils';
import { GridgetIds } from 'src/constants/gridgetIds';
import useMapStore from 'src/stores/mapStore';
import useHistoricPeakEvents from 'src/lib/hooks/useHistoricPeakEvents';
import useHasBridgesImpacted from 'src/lib/hooks/useHasBridgesImpacted';
import useHasRoadsAffected from 'src/lib/hooks/useHasRoadsAffected';
import LoadingDisplay from 'src/components/LoadingDisplay';
import ImpactWidget from './widgets/ImpactWidget';
import RoadsAffectedWidget from './widgets/RoadsAffectedWidget';
import HistoricalPeaksGridget from './gridgets/HistoricalPeaksGridget';
import useLibraryLevels from 'src/lib/hooks/useLibraryLevels';
import HistoricalPeakWidget from './widgets/HistoricalPeakWidget';
import HistoricalPeakContent from './widgets/HistoricalPeakContent';
import BuildingsImpactedWidget from './widgets/BuildingsImpactedWidget';
import BuildingsImpactedGridget from './gridgets/BuildingsImpactedGridget';
import RoadsImpactedGridget from './gridgets/RoadsImpactedGridget';
import BridgesImpactedWidget from './widgets/BridgesImpactedWidget';
import BridgesImpactedGridget from './gridgets/BridgesImpactedGridget';
import PermissionFilter from 'src/features/map/auth/PermissionsFilter';
import { CognitoGroups } from 'src/constants/cognitoGroups';
import useUserGroup from 'src/lib/hooks/useUserGroup';

const HistoryTabContent = () => {
  const { isAdmin, isAdvanced } = useUserGroup();
  const mapManager = useMapStore(state => state.mapManager);
  const activeGauge = useMapStore(state => state.activeGauge);
  const inundationLevel = useMapStore(state => state.inundationLevel);

  const showInundationLayer = useMapStore(state => state.showInundationLayer);
  const { hasBridgesImpacted, isLoading: isLoadingBridgeImpactedCount } = useHasBridgesImpacted(activeGauge?.attributes?.siteId);
  const { hasRoadsAffected, isLoading: isLoadingRoadsAffectedCount } = useHasRoadsAffected(activeGauge?.attributes?.siteId);

  const { levels } = useLibraryLevels(activeGauge?.attributes?.siteId);
  const { events, isLoading } = useHistoricPeakEvents(activeGauge?.attributes.siteId);

  const [initialEventId, setInitialEventId] = useState(0); // default to first
  const [activeEvent, setActiveEvent] = useState(null);

  const activeHistoryTabGridget = useMapStore(state => state.activeHistoryTabGridget);
  const toggleActiveHistoryTabGridget = useMapStore(state => state.toggleActiveHistoryTabGridget);

  useEffect(() => {
    // If there are events, use the first event as the active event
    if (events && events.length > 0) {
      setActiveEvent(events[0]);
    }
  }, [events]);

  useEffect(() => {
    // update the inundation layer based on the active event
    const updateInudationLayer = async () => {
      const { siteId, isScenario } = activeGauge.attributes;

      // round to nearest 0.5
      const mslHeight = convertStageToElevation(parseFloat(activeEvent.stage), activeGauge.attributes.gageDatum);
      const correctedMslHeight = (Math.round(mslHeight * 2) / 2).toFixed(1);

      const level = await showInundationLayer(siteId, isScenario, correctedMslHeight, levels, true);

      // update the road and bridge layers if the user is admin or advanced 
      if (isAdmin || isAdvanced) {
        mapManager.showRoadLayer(siteId, level, isScenario);
        mapManager.showBridgeLayer(siteId, level, isScenario);

      }
      mapManager.showFloodBuildingsLayer(siteId, level, isScenario);
    };

    if (activeEvent) {
      updateInudationLayer();
    }
  }, [activeEvent, activeGauge.attributes, levels, mapManager, showInundationLayer, isAdmin, isAdvanced]);

  if (isLoading || !activeEvent) {
    return (
      <LoadingDisplay />
    );
  }

  const onRowSelectionChange = (event) => {
    setActiveEvent(event);
  };

  const onHistoricalPeakWidgetExpand = () => {
    setInitialEventId(activeEvent.id);
    toggleActiveHistoryTabGridget(GridgetIds.HISTORICAL_PEAK)
  };

  return (
    <div className="d-flex flex-column w-100">
      {activeHistoryTabGridget === GridgetIds.HISTORICAL_PEAK && (
        <div className="p-2 pb-1 w-100">
          <HistoricalPeaksGridget
            onRowSelectChange={onRowSelectionChange}
            onClose={() => toggleActiveHistoryTabGridget(GridgetIds.HISTORICAL_PEAK)}
            initialEventId={initialEventId}
          />
        </div>
      )}
      {activeHistoryTabGridget === GridgetIds.BUILDINGS_IMPACTED &&
        <div className="p-2 pb-1 w-100">
          <BuildingsImpactedGridget
            level={inundationLevel}
            toggleGridget={() => toggleActiveHistoryTabGridget(GridgetIds.BUILDINGS_IMPACTED)}
          />
        </div>
      }

      {activeHistoryTabGridget === GridgetIds.ROADS_AFFECTED &&
        <div className="p-2 w-100">
          <RoadsImpactedGridget toggleGridget={() => toggleActiveHistoryTabGridget(GridgetIds.ROADS_AFFECTED)} />
        </div>
      }

      {activeHistoryTabGridget === GridgetIds.BRIDGES_IMPACTED &&
        <div className="p-2 w-100">
          <BridgesImpactedGridget toggleGridget={() => toggleActiveHistoryTabGridget(GridgetIds.BRIDGES_IMPACTED)} />
        </div>
      }

      <div className={`d-flex flex-wrap gap-2 p-2`}>
        <HistoricalPeakWidget
          activeEvent={activeEvent}
          onActionClick={() => onHistoricalPeakWidgetExpand()}
        >
          <HistoricalPeakContent peakEvent={activeEvent} />
        </HistoricalPeakWidget>
        <BuildingsImpactedWidget
          level={inundationLevel}
          onShowGridgetClicked={() => toggleActiveHistoryTabGridget(GridgetIds.BUILDINGS_IMPACTED)}
        />
        <PermissionFilter any={[CognitoGroups.ADMIN, CognitoGroups.ADVANCED]}>
          {!isLoadingRoadsAffectedCount && hasRoadsAffected && <RoadsAffectedWidget onActionClicked={() => toggleActiveHistoryTabGridget(GridgetIds.ROADS_AFFECTED)} />}
          {!isLoadingBridgeImpactedCount && hasBridgesImpacted && <BridgesImpactedWidget onActionClicked={() => toggleActiveHistoryTabGridget(GridgetIds.BRIDGES_IMPACTED)} />}
        </PermissionFilter>
        <ImpactWidget level={inundationLevel} />
      </div>
    </div>
  );
};

export default HistoryTabContent;